<template id="zoomable-media-container">
  <div class="outer-container">
    <AuthenticatedMedia
      cssClass="imposed-image"
      :style="styles"
      v-if="media.imposedImage != null && (media.imposedImage.type === 'image' || (media.imposedImage.mime != null && media.imposedImage.mime.includes('image')))"
      :mediaUrl="media.imposedImage.blobURL"
      type="image"></AuthenticatedMedia>
    <AuthenticatedMedia 
      v-if="media.type === 'image' || (media.mime != null && media.mime.includes('image'))"
      :mediaUrl="media.blobURL"
      type="image">
    </AuthenticatedMedia>
    <AuthenticatedMedia 
      v-else-if="media.type === 'video' || (media.mime != null && media.mime.includes('video'))"
      :mediaUrl="media.blobURL"
      controls
      playsinline
      :poster="(media.thumbnail) ? media.thumbnail : undefined"
      :mimeType="media.mime"
      type="video">
    </AuthenticatedMedia>
  </div>
  <!-- TODO Support canvas in the future. Maybe even the PointCanvas -->
</template>

<script>
import { computed, defineComponent } from 'vue';

import AuthenticatedMedia from '@/components/AuthenticatedMedia.vue';

export default defineComponent({
  name: 'ZoomableMediaContainer',
  components: { AuthenticatedMedia },
  props: {
    'media': Object,
    'imposedOpacity': {
      type: Number,
      default: 0.6
    }
  },
  setup(props) {
    const styles = computed(() => {
      let style = `opacity: ${props.imposedOpacity};`
      if (props.media != null && props.media.imposedImage != null && props.media.imposedImage.parameters != null) {
        let parameters = props.media.imposedImage.parameters;
        if (parameters.origin != null) style += `object-position: ${parameters.origin};`
      }
      return style;
    });

    return { styles }
  },
})
</script>

<style scoped>
.outer-container {
  position: relative;
  margin: 0px;
  padding: 0px;
  border: 0px;
}

.outer-container > *, .outer-container > :deep(*) {
  transform: none!important;
  vertical-align: middle;
}

:deep(video) {
  position: relative;
  width: auto;
  height: auto;
  max-height: var(--max-slide-height, 70vh);
  max-width: 100%;
  object-fit: contain;
}

:deep(img) {
  position: relative;
  width: auto;
  height: auto;
  object-fit: contain;
  max-height: var(--max-slide-height, 70vh);
  max-width: 100%;
}

:deep(.imposed-image) {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%!important;
  height: 100%!important;
  max-height: var(--max-slide-height, 70vh);
  max-width: 100%;
  object-fit: contain;
  object-position: center;
}
</style>
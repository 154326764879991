/* eslint-disable no-console */

import { register } from 'register-service-worker'

var refreshing; //Flag to not refresh in a loop

export function registerWithUpdateCallback(callbackFunction) {
  if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
      ready () {
        console.log(
          'App is being served from cache by a service worker.'
        )
      },
      registered () {
        console.log('Service worker has been registered.')
      },
      cached () {
        console.log('Content has been cached for offline use.')
      },
      updatefound () {
        console.log('New content is downloading.')
      },
      updated (serviceWorkerRegistration) {
        console.log('New content is available. Notifying callback!')

        //Create a listener to reload once when the new Service Worker starts activating
        navigator.serviceWorker.addEventListener('controllerchange',
          function() {
            if (refreshing) return;
            refreshing = true;
            console.log('Reloading app after service worker update');
            window.location.reload();
          },
          { once: true }
        );

        //Notify UI through callback to trigger skip waiting by user and activate new service worker
        if (callbackFunction != null) {
          callbackFunction(serviceWorkerRegistration);
        }
      },
      offline () {
        console.log('No internet connection found. App is running in offline mode.')
      },
      error (error) {
        console.error('Error during service worker registration:', error)
      }
    })
  }
}
<template>
  <div class="circle-container">
    <svg v-if="showProgress" viewBox="0 0 150 150" :style='"--progress: " + progress'>
      <circle cx="75" cy="75" r="65"></circle>
      <circle cx="75" cy="75" r="65"></circle>
    </svg>
    <div class="inner-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CircularProgress',
  components: { },
  props: {
    progress: {
      type: Number,
      default: 0
    },
    showProgress: {
      type: Boolean,
      default: true
    }
  },
  emits: ['click'],
  setup(props, { emit }){
    return { emit };
  }
});
</script>

<style scoped>
.circle-container {
  width: 150px;
  height: 150px;
  position: relative;
}

svg {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transform: rotate(-90deg);
  --maximum-dash: 408px;
}

svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: var(--color, #191919);
  stroke-opacity: 0.2;
  stroke-width: 20;
}

svg circle:nth-child(2) {
  stroke-opacity: 1;
  stroke-dasharray: var(--maximum-dash);
  stroke-dashoffset: var(--maximum-dash);
}

svg circle:nth-child(2) {
  stroke-dashoffset: calc(var(--maximum-dash) - (var(--maximum-dash) * var(--progress, 0)) / 100);
  stroke: var(--color, --ion-color-primary);
}

.inner-container {
  z-index: 1001;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--color);
}
</style>

import { ref, watch, provide, inject } from "vue";

function loadLocaleMessages() {
  const locales = require.context(
    "@/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const configureI18n = config => ({
  locale: ref(config.locale),
  fallback: config.fallback,
  i18nContent: loadLocaleMessages(),
  $getAvailableLanguages(){
    var langs = [];
    for (const [language, content] of Object.entries(this.i18nContent) ){
      langs.push({lang: language, properties: content['properties']});
    }
    return langs;
  },
  $getCurrentProperties(){
    return this.i18nContent[this.locale.value]['properties'];
  },
  $t(key) {
    if (key === undefined) return undefined;

    //Start at the messages object to go down the path
    var obj = this.i18nContent[this.locale.value]['messages'];
    //Split dot-notation to access every ancestor element piece by piece
    var ancestors = key.split('.');
    //Eg. parent.child.grand-child would be accessed iteratively with parent[child][grand-child]
    while(ancestors.length >= 1) {
      obj = obj[ancestors.shift()];
      if (obj === undefined){
        return undefined;
      }
    }

    return obj;
  }
});

const i18nSymbol = Symbol();

export function provideI18n(i18nConfig) {
  const i18n = configureI18n(i18nConfig);
  watch(() => i18n.locale.value, (newLocale) => {
    if ( ! Object.keys(i18n.i18nContent).includes(newLocale) ){
      console.error(
        "Unsupported language selected: ",
        newLocale,
        " Possible languages: ",
        Object.keys(i18n.i18nContent)
      );
      i18n.locale.value = i18n.fallback;
    }
  });
  provide(i18nSymbol, i18n);
  return i18n;
}

export function useI18n() {
  const i18n = inject(i18nSymbol);
  if (!i18n) throw new Error("i18n has to be provided first!");

  return i18n;
}

<template>
  <template v-if="show && imageProperties != null">
    <ion-icon v-if="imageProperties.icon != null" color="primary" ref="imageInstance" :class="['camera-template-image', imageProperties.preferLandscape ? 'flip-to-landscape' : undefined, isLandscape ? 'aspect-ratio-landscape' : 'aspect-ratio-portrait']" :style="targetStyle" :icon="imageProperties.icon"></ion-icon>
    <img v-else-if="imageProperties.src != null" :src="imageProperties.src" ref="imageInstance" :class="['camera-template-image', imageProperties.preferLandscape ? 'flip-to-landscape' : undefined, isLandscape ? 'aspect-ratio-landscape' : 'aspect-ratio-portrait']"/>
  </template>
</template>

<script>
import { IonIcon } from '@ionic/vue';
import { getCameraTemplate } from '@/utils/media';
import { computed, ref, watch } from 'vue';

export default {
  name: 'CameraTemplate',
  components: { IonIcon },
  props: {
    type: String,
    targetRect: Object,
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const imageInstance = ref(null);

    const imageProperties = computed(() => getCameraTemplate(props.type));

    const isLandscape = ref(false);

    const targetWidth = ref(0);
    const targetHeight = ref(0);

    const colorStyle = computed(() => (imageProperties.value != null && imageProperties.value.color != null) ? `color: var(--ion-color-${imageProperties.value.color})` : '' );

    const targetStyle = computed(() => `width: ${targetWidth.value}px; height: ${targetHeight.value}px; ${colorStyle.value}`);

    watch([() => props.targetRect, imageInstance], ([rect, currentImageInstance]) => {
      if (currentImageInstance != null && rect != null && rect.width != null && rect.height != null) {
        let width = rect.width;
        let height = rect.height;
        isLandscape.value = (width > height);
        if (currentImageInstance != null) {
          targetWidth.value = Math.ceil(width);
          targetHeight.value = Math.ceil(height);
          if (currentImageInstance instanceof HTMLImageElement) {
            currentImageInstance.width = targetWidth.value
            currentImageInstance.height = targetHeight.value
          }
        }
      }
    }, { immediate: true });

    return {
      imageInstance,
      imageProperties,
      isLandscape,
      targetWidth,
      targetHeight,
      targetStyle
    }
  }
}
</script>

<!-- This element automatically fills a relative parent to the maximum! -->
<style scoped>
ion-icon.camera-template-image {
  visibility: visible!important;
}

.camera-template-image {
  position: absolute;
}

.aspect-ratio-portrait.flip-to-landscape {
  transform: rotate(90deg);
}
</style>
<template id="value-select">
  <div v-if="showAsList" tabindex="-1" class="multi-list-container">
    <ion-reorder-group @ionItemReorder="reorderDisplayValues($event)" :disabled="disabled">
      <ion-item v-for="(value, valueIndex) in displayValueModel" :key="`${valueIndex}`" lines="inset">
        <div slot="start" class="remove-button" @click.stop="removeDisplayValueAtIndex(valueIndex)" @keydown="handleSpacebar($event, ()=>$event.target.click())">
          <ion-button fill="clear" color="danger" shape="round" :disabled="disabled">
            <ion-icon slot="icon-only" :icon="removeCircleOutline"></ion-icon>
          </ion-button>
        </div>
        <ion-input
          :name="`${key}[${valueIndex}]`"
          :type="inputParameters.type"
          :inputmode="inputParameters.inputmode"
          :step="inputParameters.step"
          enterkeyhint="next"
          :value="value"
          @ionChange="setDisplayValueAtIndex(valueIndex, $event.target.value)"
          class="value-select-input custom-input"
          :placeholder="custom_placeholder ? custom_placeholder : 
            (i18n.$t((inputParameters.type != 'number') ? ((inputParameters.type != 'color') ? 'default_interaction.enter_text' : 'default_interaction.select') : 'default_interaction.enter_value'))" 
          :disabled="disabled"
          tabindex="2">
        </ion-input>
        <ion-reorder slot="end"></ion-reorder>
      </ion-item>
      <ion-item class="add-item" lines="inset" button @click="setDisplayValueAtIndex(-1, '')">
        <div slot="start" class="add-button" @keydown="handleSpacebar($event, ()=>$event.target.click())">
          <ion-button fill="solid" color="primary" shape="round" :disabled="disabled">
            <ion-icon slot="icon-only" :icon="addOutline"></ion-icon>
          </ion-button>
        </div>
        <ion-label>{{ i18n.$t('default_interaction.add') }}</ion-label>
      </ion-item>
    </ion-reorder-group>
  </div>
  
  <div v-else :class="unit ? 'input-container with-unit' : 'input-container'" tabindex="-1">
    <!-- Show default input, when custom values are allowed but no preset values are set -->
    <template v-if="allow_custom_values && (!availableValues || overrideAvailableValues)">
      <!-- Use textarea instead of small input for text -->
      <ion-textarea v-if="inputParameters.type === 'text'"
        rows="1"
        auto-grow
        :name="key"
        :inputmode="inputParameters.inputmode"
        enterkeyhint="next"
        :value="displayValueModel"
        @ionChange="displayValueModel = $event.target.value"
        class="value-select-input custom-input"
        :placeholder="custom_placeholder ? custom_placeholder : i18n.$t('default_interaction.enter_text')"
        @keydown="blurOnEnter($event)"
        :disabled="disabled"
        tabindex="-1">
      </ion-textarea>

      <!-- Otherwise use normal input with the corresponding type -->
      <ion-input v-else
        :name="key"
        :type="inputParameters.type"
        :inputmode="inputParameters.inputmode"
        :step="inputParameters.step"
        enterkeyhint="next"
        :value="displayValueModel"
        @ionChange="displayValueModel = $event.target.value"
        class="value-select-input custom-input"
        :placeholder="custom_placeholder ? custom_placeholder : 
          (i18n.$t((inputParameters.type != 'number') ? ((inputParameters.type != 'color') ? 'default_interaction.enter_text' : 'default_interaction.select') : 'default_interaction.enter_value'))" 
        :disabled="disabled"
        tabindex="2">
      </ion-input>
    </template>

    <!-- If we don't have a custom input, show the selection separately if enabled and requirements are met, check again and not else-if, because select should alwas be shown, if not separated here. -->
    <div v-if="!(allow_custom_values && (!availableValues || overrideAvailableValues)) && showSelectValuesSeparately" class="separate-selection-container">
      <ion-radio-group :value="Array.isArray(computedValue) ? computedValue[0] : null" @ionChange="allow_multiple_values ? undefined : setValue($event.target.value)">
        <!--TODO Adapt for colors! -->
        <ion-item v-for="(value, valueIndex) in availableValues" :key="`${valueIndex}`" class="separate-selection" lines="none">
          <ion-checkbox v-if="allow_multiple_values" slot="start" :checked="isValueSet(value.value)" @ionChange="$event.target.checked ? setValue(value.value) : removeValue(value.value)"></ion-checkbox>
          <ion-radio v-else mode="md" :value="value.value" slot="start"></ion-radio>
          <ion-label>{{ value.display }}</ion-label>
        </ion-item>
        <!-- Show all custom values that have been entered as a selection, to better represent the entered value and to preserve on new selection, otherwise they get removed from the custom value when changing selection. Has no separate display value! -->
        <ion-item v-for="(value, valueIndex) in customValues" :key="`${valueIndex}_custom`" class="separate-selection custom" lines="none">
          <ion-checkbox v-if="allow_multiple_values" slot="start" :checked="isValueSet(value)" @ionChange="$event.target.checked ? setValue(value) : removeValue(value)"></ion-checkbox>
          <ion-radio v-else mode="md" :value="value" slot="start"></ion-radio>
          <ion-label>{{ value }}</ion-label>
        </ion-item>
      </ion-radio-group>
      <ion-button class="separate-selection-custom-value" fill="outline" v-if="allow_custom_values && !overrideAvailableValues" @click="setValue(OVERRIDE_AVAILABLE_VALUES_SELECT_NAME)">
        {{ i18n.$t((inputParameters.type != 'color') ? 'forms.custom_value' : 'forms.custom_color') }}
      </ion-button>
    </div>

    <!-- Wrapped in an div to prevent overlapping other elements and to group it as a custom button. Only enabled, if we do not show it separately. Also enabled when -->
    <div :class="['select-container', (disabled) ? 'disabled' : '']" v-else-if="availableValues != null" tabindex="3" @keydown="handleSpacebar($event, () => openCustomSelectInterface($event))" @click.capture="openCustomSelectInterface">
      <!-- Show select input when values for a selection are given. Hide everything except an icon if it is overriden by a custom input -->
      <ion-select
        ref="selectInput"
        :class="[(overrideAvailableValues) ? 'hidden-select-content' : '', (inputParameters.treatAsColor === true) ? 'select-custom-color' : '']"
        :placeholder="custom_placeholder ? custom_placeholder : i18n.$t('default_interaction.select')"
        :cancelText="i18n.$t('default_interaction.cancel')"
        :okText="i18n.$t('default_interaction.select')"
        interface="popover"
        :interface-options="{cssClass: 'select-entry-item'}"
        :multiple="allow_multiple_values"
        v-model="computedValue"
        :style="(inputParameters.treatAsColor === true) ? `--custom-color: ${computedValue}` : undefined"
        :disabled="disabled"
        @click.prevent
        tabindex="-1">
        <ion-select-option
          v-for="(value, valueIndex) in availableValues"
          :key="`${valueIndex}`"
          :value="value.value"
          :category="value.category"
          :order="valueIndex"
          :class="['available-select-option', (inputParameters.hideValue === true) ? 'hidden-text' : undefined, (inputParameters.treatAsColor === true) ? 'color-select-option' : undefined]"
          :textOpacity="(inputParameters.hideValue === true) ? 0 : 1"
          :background="(inputParameters.treatAsColor === true) ? value.value : undefined"
          >
          {{ value.display }}
        </ion-select-option>
        <!-- Show all custom values that have been entered as a selection, to better represent the entered value and to preserve on new selection, otherwise they get removed from the custom value when changing selection. Has no separate display value! -->
        <ion-select-option
          v-for="(value, valueIndex) in customValues"
          :key="`${valueIndex}_custom`"
          :class="['custom-value-option', (inputParameters.hideValue === true) ? 'hidden-text' : undefined, (inputParameters.treatAsColor === true) ? 'color-select-option' : undefined]"
          :value="value"
          :order="availableValues.length + valueIndex"
          :textOpacity="(inputParameters.hideValue === true) ? 0 : 0.5"
          :background="(inputParameters.treatAsColor === true) ? value : undefined"
          >
          {{ value }}
        </ion-select-option>
        <!-- Add a option to add custom values, if it is allowed and not already enabled -->
        <ion-select-option v-if="allow_custom_values && !overrideAvailableValues" class="custom-value" unfiltered :order="-1" italic :textOpacity="0.4" :value="OVERRIDE_AVAILABLE_VALUES_SELECT_NAME">{{ i18n.$t((inputParameters.type != 'color') ? 'forms.custom_value' : 'forms.custom_color') }}</ion-select-option>
      </ion-select>
      <div v-if="overrideAvailableValues" class="select-open-icon"><div class="select-open-icon-inner"></div></div>
    </div>

    <!-- Sets a padding, if no custom select is visble, otherwise custom select icon has its own padding -->
    <div v-if="availableValues == null || !overrideAvailableValues" class="default-padding"></div>
    
    <span class="unit" v-if="unit">{{ unit }}</span>
  </div>
</template>

<script>
import { IonTextarea, IonInput, IonSelect, IonSelectOption, IonRadioGroup, IonRadio, IonCheckbox, IonLabel, IonItem, IonButton, IonReorderGroup, IonReorder, IonIcon } from '@ionic/vue';
import { defineComponent, computed, ref } from 'vue';

import { useI18n } from '@/utils/i18n';

import { TYPE_API_MAPPINGS } from '@/utils/report';

import { removeCircleOutline, addOutline } from 'ionicons/icons';

import { openFilterPopupAsSelectInterface, default as filterPopupComponent } from '@/components/FilterPopup.vue';

import { handleSpacebar, blurOnEnter } from '@/utils/interaction';

import _ from 'lodash';

const PREFERRED_LABEL_POSITION = 'stacked';

//Separates the array elements in the outside model
const SELECT_ARRAY_SEPARATOR = '; ';
//Regex to detect the separator character with spaces on each side for replacing them
const SELECT_ARRAY_SEPARATOR_REGEX = /\s*;\s*/g;

export const MULTIPLE_VALUE_DISPLAY_SEPARATOR = ', ';

export const isValueSelect = function(type) {
  return (type in TYPE_API_MAPPINGS['values']);
}

export const getValueSelectParameters = function(type) {
  return {
    labelPosition: PREFERRED_LABEL_POSITION,
    componentType: 'ValueSelect',
    apiComponentType: TYPE_API_MAPPINGS['values'][type] || undefined,
    itemStyle: '--inner-padding-end: 0px'
  }
}

export const splitMultipleValues = function(value) {
  if (value != null && value.split) { 
    return value.split(SELECT_ARRAY_SEPARATOR);
  }
}

const ValueSelect = defineComponent({
  name: 'ValueSelect',
  components: { IonTextarea, IonInput, IonSelect, IonSelectOption, IonRadioGroup, IonRadio, IonCheckbox, IonLabel, IonItem, IonButton, IonReorderGroup, IonReorder, IonIcon },
  props: {
    'key': String,
    'type': String,
    'presetValue': [String, Number, Array],
    'modelValue': [String, Number, Array],
    'keepArrayValues': { //Does not convert arrays back to strings
      type: Boolean,
      default: false
    },
    'showAsList': {
      type: Boolean,
      default: false
    },
    'hideSearchbar': { //By default shows when more than a certain amount of items
      type: Boolean,
      default: false
    },
    'available_values': Array,
    'selectValuesSeparately': {
      type: Boolean,
      default: false
    },
    'seperateSelectValuesLimit': Number,
    'allow_custom_values': Boolean,
    'allow_multiple_values': Boolean,
    'custom_placeholder': String,
    'unit': String,
    'disabled': {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'update:modified'],
  setup(props, { emit }) {
    const i18n = useI18n();

    //Custom select element that is just used to trigger setting a flag - Always gets removed!
    const OVERRIDE_AVAILABLE_VALUES_SELECT_NAME = 'enable_custom_value';

    //Set different input parameters for special types
    const inputParameters = computed(() => {
      switch (props.type) {
        case 'decimal':
          return {
            type: 'number',
            inputmode: 'decimal',
            step: '.001'
          };
        case 'number':
          return {
            type: 'number',
            inputmode: 'numeric'
          };
        case 'address': //TODO Add regexes from a common js composable that check those inputs? E.g. also for color - Needs to include separator, if multiple are possible!
          return {
            type: 'text'
          };
        case 'color':
          return {
            type: 'color',
            hideValue: true,
            treatAsColor: true
          };
        default:
          //Alyways return type - Can never be undefined - text as fallback
          return {
            type: props.type || 'text'
          };
      } //TODO Add multiline textareas, where newlines don't get trimmed? - enterkeyhint needs to be changed too in that case - If so just disable blurOnEnter and change hint!
    });

    const showSelectValuesSeparately = computed(() => {
      let availableValuesCount = (availableValues.value != null && availableValues.value.length) ? availableValues.value.length : 0;
      let customValuesCount = (customValues.value != null && customValues.value.length) ? customValues.value.length : 0;
      let valuesCountSum = availableValuesCount + customValuesCount;
      //At least one can be separately visible and is lower than limit with both combined
      return (props.selectValuesSeparately && props.seperateSelectValuesLimit > 0 && valuesCountSum > 0 && valuesCountSum <= props.seperateSelectValuesLimit);
    });

    //Removes spaces with trim if this method exists, otherwise returns the original value
    //If used on the input, the spaces on the end always get removed. The input might not match the model until a non whitespace character is added.
    //All the spaces in the input in between will be preserved!
    const trimString = function(string) {
      if (string != null && string.trim){
        //Check if it ends in the separating character
        return string.trim();
      }

      return string;
    }

    //Internal processing is done as an array, even if just one value
    const processInputValue = function(value, type) {
      let processedArray;

      //If it is undefined or null, just give an empty selection - When field is reset after selecting enable_custom_value it is null e.g.
      if (value == null) {
        processedArray = [];
      }
      //If it is already an array, no processing is necessary
      else if (Array.isArray(value)) {
        processedArray = value;
      }
      //Otherwise convert it to an array by splitting at the SELECT_ARRAY_SEPARATOR, if it is a string (i.e. it has the split method)
      else if (value != null && value.split) { 
        processedArray = splitMultipleValues(value);
      }
      //If it is a non string value, just return it in an array
      else {
        processedArray = [value];
      }

      //Process the input types to be of correct type or remove whitespaces if it is a string
      processedArray = _.map(processedArray, (arrayValue) => {
        let parsedValue;

        switch (type) {  
          case 'number':
            parsedValue = parseInt(arrayValue);
            break;
          case 'decimal':
            parsedValue = parseFloat(arrayValue);
            break;
          default:
            return trimString(arrayValue);
        }

        if (parsedValue == null || isNaN(parsedValue)) return arrayValue;
        return parsedValue;
      });

      return processedArray;
    }

    //Inverse functionality from the input value processing, return values instead of array
    const convertArrayToSingleValue = function(value, internalProcessing = false) {
      //Always convert to an array for easier processing
      let valueArray = Array.isArray(value) ? value : [value];

      //Map invalid values to valid ones - keep empty strings, to preserve currently empty entries in a custom list
      valueArray = _.map(valueArray, (arrayValue) => {
        //Accommodate for an issue  where ion-input or ion-select sets an empty string that is somehow an undefined string when it should be of type undefined
        //False and 0 have to be checked separately, as they could be a valid value
        if (!arrayValue && arrayValue !== false && arrayValue !== 0){
          return undefined; //Return proper undefined
        }

        return arrayValue;
      });

      //If the setting is enabled to keep array values as is, then don't convert it - Array can be empty
      if (!internalProcessing && props.keepArrayValues) {
        return valueArray;
      }
      //If the array is empty, return undefined if no valid preset was given or null to reset it, if preset was given!
      else if (valueArray.length == 0) {
        return (props.presetValue !== undefined) ? null : undefined;
      }
      //Otherwise we convert it
      //If it is just one value inside we can return that value without converting it
      else if (valueArray.length == 1) {
        return valueArray[0];
      }
      //If we have multiple values, they are joined by SELECT_ARRAY_SEPARATOR and returned as a single string
      else {
        return valueArray.join(SELECT_ARRAY_SEPARATOR);
      }
    }

    //Set when a custom input is allowed and selected from the list
    const overrideAvailableValues = ref(false);

    const computedValue = computed({
      get: () => {
        //Check equality of both values from the outside!
        emit('update:modified', !_.isEqual(props.presetValue, props.modelValue));
        return processInputValue(props.modelValue, props.type);
      },
      set: (newValue) => {
        //Convert always to array for easier handling
        let newArray;
        if (!Array.isArray(newValue)) {
          newArray = [newValue];
        } else {
          newArray = newValue;
        }

        //Remove custom value select element, and check if it was there
        let overrideElements = _.remove(newArray, (arrayValue) => _.isEqual(arrayValue, OVERRIDE_AVAILABLE_VALUES_SELECT_NAME));
        //If a single selection is allowed, this clears the text in the custom input - Multiple selections are persisted
        if (overrideElements != null && overrideElements.length > 0) {
          //If the element was selected, allow custom values
          overrideAvailableValues.value = true;
        }

        let processedOutputValue = convertArrayToSingleValue(newArray);
        
        emit('update:modelValue', processedOutputValue);
      }
    });

    const setValue = function(selectedOption) {
      if (selectedOption != null && selectedOption.length == 0) selectedOption = null;
      if (props.allow_multiple_values) {
        computedValue.value = _.unionWith(computedValue.value, [selectedOption], _.isEqual);
      } else {
        computedValue.value = selectedOption;
      }
      
    }

    const removeValue = function(selectedOption) {
      if (Array.isArray(computedValue.value)) computedValue.value = _.differenceWith(computedValue.value, [selectedOption], _.isEqual);
      else computedValue.value = null;
    };

    const isValueSet = function(option) {
      if (Array.isArray(computedValue.value)) return _.some(computedValue.value, (value) => _.isEqual(value, option));
      else return _.isEqual(computedValue.value, option);
    }

    //Process available values to be trimmed for consistency!
    const availableValues = computed(() => {
      if (props.available_values){
        return _.map(props.available_values, (value) => {
          return _.mapValues(value, trimString);
        })
      }

      return null;
    })

    //Convert all available values to easily find them for translation when displaying them in a custom field
    //Use nested functions to declare reactive mapping functions
    const indexedAvailableValues = computed(() => {
      let index = {
        byValue: {},
        byDisplay: {},
        //Also give back unconverted ones, as object key is always string!
        originalValues: [],
        originalDisplays: []
      }

      if (availableValues.value){
        for (let availableValue of Object.values(availableValues.value)){
          if (availableValue.value != null) {
            index.byValue[availableValue.value] = availableValue.display;
            index.originalValues.push(availableValue.value);
          }
          if (availableValue.display != null) {
            index.byDisplay[availableValue.display] = availableValue.value;
            index.originalDisplays.push(availableValue.display);
          }
        }

        //Remove duplicates
        index.originalValues = _.uniq(index.originalValues);
        index.originalDisplays = _.uniq(index.originalDisplays);
      }

      //Function to map available value to its display value, if present
      const convertAvailableValueToDisplay = function(availableValue){
        if (index.byValue[availableValue] != null) {
          return index.byValue[availableValue];
        } else {
          return availableValue;
        }
      }

      const convertDisplayValueToAvailable = function(displayValue){
        if (index.byDisplay[displayValue] != null) {
          return index.byDisplay[displayValue];
        } else {
          return displayValue;
        }
      }

      return {
        index,
        convertAvailableValueToDisplay,
        convertDisplayValueToAvailable
      };
    });

    //Keeps a record of all values that are in the current entered custom values but not in available values to add them to the selection and thus preserve them when selecting again. Empty/Whitespace values are not preserved!
    const customValues = computed(() => {
      //Current value is always an array!
      let currentValue = computedValue.value;
      let currentIndex = indexedAvailableValues.value.index;
      //Remove all the available values (stored as keys in this index) from the current values by comparing it with isEqual
      return _.differenceWith(currentValue, currentIndex.originalValues, (arrayValue, differenceValue) => {
        //If it is an empty string or just whitespaces, remove it in any case from the selection
        if (_.isString(arrayValue) && !(arrayValue.trim().length)) return true;
        return _.isEqual(arrayValue, differenceValue);
      });
    });

    //Keeps track of entries that were entered in the custom input with the model value, not the localized display value. Do not auto translate them again!
    const manuallyEnteredModelValues = ref([]);

    //Input can be an array or a string with the separator. Will be saved as an array internally
    const setDisplayValueArray = function(displayValueArray) {
      //Process the value to be an array
      let newArray = processInputValue(displayValueArray, props.type);

      //Save all manually entered model values. All values that exist in the mapping index for model values as keys!
      manuallyEnteredModelValues.value = _.intersection(newArray, Object.keys(indexedAvailableValues.value.index.byValue));

      //Map each display value to be the equivalent actual available value and set it in the model
      let displayMapping = indexedAvailableValues.value.convertDisplayValueToAvailable;
      computedValue.value = _.map(newArray, displayMapping);
    }

    //Only updates at a certain index in the display value array, instead of setting the whole array. Used for when it is shown as a list
    const setDisplayValueAtIndex = function(index, displayValue) {
      let currentValues = [...displayValueModel.value];

      if (index < 0) {
        currentValues.push(displayValue);
      } else {
        //Set at the index in the array, might create sparse array
        currentValues[index] = displayValue;
      }

      setDisplayValueArray(currentValues);
    }

    const reorderDisplayValues = function(event) {
      //Complete reorders the array, updates the UI and then the new array is saved again
      setDisplayValueArray(event.detail.complete(displayValueModel.value));
    }

    //Removes value at a certain index in the display value array. Used for when it is shown as a list
    const removeDisplayValueAtIndex = function(index) {
      let currentValues = [...displayValueModel.value];

      if (index >= 0 && index < currentValues.length) {
        _.pullAt(currentValues, index);
      }

      setDisplayValueArray(currentValues); 
    }

    //Convert actual available values to display values for editing and back on saving
    const displayValueModel = computed({
      get: () => {
        //Convert current value to array if it is not already
        let currentValue = computedValue.value;
        let currentArray;
        if (!Array.isArray(currentValue)) {
          currentArray = [currentValue];
        } else {
          currentArray = currentValue;
        }

        //Map each value to the display value and process the array
        let availableValueMapping = indexedAvailableValues.value.convertAvailableValueToDisplay;
        let resultArray = _.map(currentArray, (modelValue) => {
          //Check if the modelValue had been entered, then don't translate it!
          if (manuallyEnteredModelValues.value.includes(modelValue)) {
            return modelValue;
          }
          //If it wasn't entered manually in the custom input, translate it!
          return availableValueMapping(modelValue);
        })
        if (props.showAsList) return resultArray;
        else return convertArrayToSingleValue(resultArray, true);
      },
      set: (newValue) => {
        //Replace all invalid separating characters by the proper ones, if it has that function. Prevents mistakes with wrong characters on user input and keeps consistency!
        let correctlySeparatedValue = (newValue.replaceAll) ? newValue.replaceAll(SELECT_ARRAY_SEPARATOR_REGEX, SELECT_ARRAY_SEPARATOR) : newValue;

        setDisplayValueArray(correctlySeparatedValue);
      }
    });

    const selectInput = ref(null);

    //Returns all categories in the order that they appear first in
    const uniqueCategoriesInOrder = computed(() => {
      let categories = {};

      _.forEach(availableValues.value, (value) => {
        //Go through all values and if category does not exist yet, add it with the current index as its value
        if (value != null && value.category != null && (!(value.category in categories))) categories[value.category] = Object.keys(categories).length;
      });

      return categories;
    });

    const openCustomSelectInterface = function(event) {
      if ((!(props.disabled)) && selectInput.value){
        //Remove focus from any input element to not open the keyboard accidentally
        document.activeElement.blur();
        openFilterPopupAsSelectInterface(filterPopupComponent, event, selectInput.value.$el, {
          hideSearchbar: props.hideSearchbar,
          allowMultiple: props.allow_multiple_values,
          categoryOrder: uniqueCategoriesInOrder.value
        });
      }
    }

    return {
      i18n,
      showSelectValuesSeparately,
      inputParameters,
      OVERRIDE_AVAILABLE_VALUES_SELECT_NAME,
      overrideAvailableValues,
      availableValues,
      computedValue,
      setValue,
      removeValue,
      isValueSet,
      customValues,
      setDisplayValueArray,
      setDisplayValueAtIndex,
      removeDisplayValueAtIndex,
      reorderDisplayValues,
      displayValueModel,
      selectInput,
      openCustomSelectInterface,
      handleSpacebar,
      blurOnEnter,
      removeCircleOutline,
      addOutline
    };
  }
});

export default ValueSelect;
</script>


<style>
/* Allow for longer texts in select and indent every line but the first using a negative offset on the first line in a wrapped text */
.select-entry-item .alert-radio-label, .select-entry-item .alert-checkbox-label {
  margin-left: 6px;
  white-space: normal!important;
  text-indent: -6px;
  padding-right: 6px;
}

.select-entry-item .select-interface-option ion-label {
  padding-top: 6px;
  padding-bottom: 6px;
  margin-left: 6px;
  padding-left: 6px;
  white-space: normal!important;
  text-indent: -6px;
  padding-right: 6px;
}

/* Fix that the radio buttons are displayed, even when wrapping more than two lines */
.select-entry-item .select-interface-option {
  height: auto;
  contain: content;
}

/* Hide custom value with a light color */
.select-entry-item .custom-value .alert-radio-label, .select-entry-item .custom-value .alert-checkbox-label, .select-entry-item .custom-value ion-label {
  opacity: 0.4;
  font-style: italic;
}

/* Show custom value options with a different opacity */
.select-entry-item .custom-value-option .alert-radio-label, .select-entry-item .custom-value-option .alert-checkbox-label, .select-entry-item .custom-value-option ion-label {
  opacity: 0.5;
}

/* Remove text for hidden entry item texts */
.select-entry-item .hidden-text .alert-radio-label, .select-entry-item .hidden-text .alert-checkbox-label, .select-entry-item .hidden-text ion-label {
  opacity: 0;
}

ion-textarea.custom-input > div, ion-textarea.custom-input > div > textarea {
  min-height: 45px!important;
}

/* Increase the size of custom color inputs to be visible at all times and similar to existing ion-select size */
.value-select-input.custom-input > input[type=color] {
  --input-color-height: 38px;
  min-height: var(--input-color-height)!important;
  margin-top: 6px;
  margin-bottom: 8px;
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
}

/* Add a translucent placeholder for empty color input not to show a black color as select. Hide color input itself, if empty. */
.value-select-input.custom-input:not(.has-value) > input[type=color] {
  opacity: 0.3;
  position: relative;
  visibility: hidden;
}
/* Add a custom text node with the content from the placeholder value, that is visible, although its parent input is not. Fills the whole space */
.value-select-input.custom-input:not(.has-value) > input[type=color]::before {
  width: 100%;
  height: 100%;
  line-height: var(--input-color-height);
  content: attr(placeholder);
  position: absolute;
  visibility: visible;
}
</style>


<style scoped>
.input-container {
  display: flex;
  width: 100%;
  --inner-padding-end: 16px;
  --select-padding-left: 10px;
  --select-padding-right: 4px;
  --select-padding-top: 16px;
  --select-padding-bottom: 16px;
}

.ios .input-container {
  --inner-padding-end: 10px;
  --select-padding-left: 10px;
  --select-padding-right: 7px;
  --select-padding-top: 16px;
  --select-padding-bottom: 16px;
}

.separate-selection-container {
  flex-grow: 99;
  flex-basis: 0px;
}

.separate-selection-container > ion-radio-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.separate-selection {
  --inner-padding-bottom: 0px;
  --inner-padding-top: 0px;
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  --padding-bottom: 0px;
  --padding-top: 0px;
  --padding-start: 0px;
  --padding-end: 10px;
  --min-height: 38px;
  line-height: 38px;
  --background: var(--background);
  align-items: center;
}

.separate-selection > * {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.separate-selection > ion-label {
  white-space: normal;
  line-height: 1.25em;
}

.separate-selection.custom {
  opacity: 0.65;
}

.separate-selection-custom-value {
  --color: var(--ion-color-primary-text);
  margin-top: 10px;
  margin-bottom: 15px;
  text-transform: none;
}

.unit {
  vertical-align: middle;
  padding: 8px;
  color: var(--ion-color-tertiary-text);
  font-weight: bold;
}

ion-textarea.custom-input {
  min-height: 45px;
  max-height: 200px;
}

.with-unit .custom-input {
  flex-shrink: 1;
}

.custom-input {
  flex-grow: 99;
  flex-basis: 0px;
}

.custom-input::part(placeholder) {
  flex-grow: 99;
  flex-basis: 0px;
}

/* Hide select, if both select and input are usable, to disable its button */
.hidden-select-content {
  visibility: hidden;
}
.hidden-select-content::part(placeholder), .hidden-select-content::part(text), .hidden-select-content::part(icon) {
  display: none;
}

.select-custom-color::part(text) {
  padding-top: 2px;
  padding-bottom: 2px;
  background: var(--custom-color);
  color: transparent;
  border: 1px solid gray;
}

.select-container {
  flex-grow: 1;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.select-container:not(.disabled) {
  cursor: pointer;
}

.select-container:focus {
  outline: none;
}

ion-select {
  flex-grow: 1;
  max-width: 100%;
  --padding-start: 0px;
}

/* Allow wrap of select text, and never overflow */
ion-select::part(text) {
  white-space: normal;
  overflow-wrap: anywhere;
}

.select-open-icon {
  pointer-events: none; /* Prevent catching clicks so parent can handle them */
  width: calc(10px + var(--select-padding-left, 16px) + var(--select-padding-right, 0px) + var(--inner-padding-end, 0px));
  height: calc(10px + var(--select-padding-top, 13px) + var(--select-padding-bottom, 13px));
  opacity: 0.33;
  padding-top: var(--select-padding-top, 13px);
  padding-bottom: var(--select-padding-bottom, 13px);
  padding-left: var(--select-padding-left, 16px);
  padding-right: calc(var(--select-padding-right, 0px) + var(--inner-padding-end, 0px));
  position: relative;
  flex-grow: 0;
  box-sizing: border-box;
}

.select-open-icon-inner {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  width: 0px;
  height: 0px;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  color: currentcolor;
  pointer-events: none;
}

.default-padding {
  padding-right: var(--inner-padding-end, 16px);
}

.multi-list-container {
  width: 100%;
}

.multi-list-container ion-item {
  --padding-start: 0px;
  --background: var(--background);
}

.add-item {
  --color: var(--ion-color-primary-text);
  color: var(--color);
}

.add-item ion-label {
  opacity: 0.8;
}

.remove-button {
  margin: 0;
  margin-right: 5px;
}

.add-button {
  margin: 0;
  margin-left: 6px;
  margin-right: 12px;
}

.remove-button > ion-button {
  height: auto;
  --padding-top: 5px;
  --padding-bottom: 5px;
  --padding-start: 5px;
  --padding-end: 5px;
}

.add-button > ion-button {
  font-size: 0.6em;
  height: auto;
  --padding-top: 2px;
  --padding-bottom: 2px;
  --padding-start: 2px;
  --padding-end: 2px;
}
</style>
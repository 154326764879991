<template>
  <ion-button @click="setOpen(true, $event)">
    <ion-icon slot="start" :icon="globeOutline"></ion-icon>
    <div id="language-text">{{ i18n.locale.value }}</div>
  </ion-button>
  <ion-popover
    :is-open="isOpenRef"
    css-class="language-menu"
    :event="eventRef"
    @didDismiss="setOpen(false)"
  >
    <ion-list>
      <ion-list-header>{{ i18n.$t('settings.language') }}</ion-list-header>
      <ion-item detail="false" lines="none" button class="language-item" v-for="language in i18n.$getAvailableLanguages()" :key="language.lang" @click="i18n.locale.value = language.lang; setOpen(false);">
        <ion-img class="flag" :src="'/assets/flags/'+ language.properties.flag +'.svg'"></ion-img>
        {{ language.lang }}
      </ion-item>
    </ion-list>
  </ion-popover>
</template>

<script>
import { IonPopover, IonList, IonListHeader, IonItem, IonButton, IonIcon, IonImg } from '@ionic/vue';
import { defineComponent, ref, onMounted, onUnmounted } from 'vue';

import { globeOutline } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";

export default defineComponent({
  components: { IonPopover, IonList, IonListHeader, IonItem, IonButton, IonIcon, IonImg },
  setup() {
    const i18n = useI18n();

    const isOpenRef = ref(false);
    const eventRef = ref();
    const setOpen = function(state, event) {
      if ( isOpenRef.value != state ){ //Only execute change, when it needs to be changed
        eventRef.value = event;
        if ( state == true ){
          if ( event !== undefined ){ //Only display when it can be positioned correctly with the event
            isOpenRef.value = true;
          }
        } else {
          isOpenRef.value = false;
        }
      }
    }

    const handleResize = function(){
      setOpen(false); //Close popover when resizing or rotating device screen for correct display
    }

    onMounted(() => {
      window.addEventListener("resize", handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    return { isOpenRef, eventRef, setOpen, i18n, globeOutline }
  }
});
</script>

<style>
/*popover styles cannot be scoped!*/
.language-menu {
  --width: 8em;
}
</style>

<style scoped>
#language-text, .language-item {
  text-transform: uppercase;
}

.flag {
  width: 3em;
  height: 1em;
}
</style>

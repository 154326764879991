import _ from 'lodash';

import { api } from '@/utils/api';

const MODULE_VERSION = 2; //UPDATE VERSION WITH EVERY BREAKING CHANGE OR ATTRIBUTE REMOVAL

const DEFAULTS = {
  'locations': [
    {
      descriptor: "Stall 1",
      spots: [
        {descriptor: 'Box A'},
        {descriptor: 'Box B'}
      ]
    }
  ],
  'statuses': [ //TODO Figure out optimal defaults!
    {
      descriptor: "Termin ausstehend",
      defaultClosed: false
    },
    {
      descriptor: "Auf Anfahrt",
      defaultClosed: false
    },
    {
      descriptor: "Wartebereich",
      defaultClosed: false
    },
    {
      descriptor: "In Klinik",
      defaultClosed: false
    },
    {
      descriptor: "Im OP",
      defaultClosed: false
    },
    {
      descriptor: "Verstorben",
      defaultClosed: true
    }
  ]
};

const getDefaultState = () => {
  return {
    version: undefined,
    locations: DEFAULTS['locations'],
    statuses: DEFAULTS['statuses'],
    hiddenEntryTypes: []
  }
};

export default {
  namespaced: true,

  state: getDefaultState,
  getters: {
    getHiddenEntryTypes (state) {
      return state.hiddenEntryTypes;
    },
    getLocations (state) {
      return state.locations;
    },
    getStatuses (state) {
      return state.statuses;
    }
  },
  mutations: {
    setHiddenEntryTypes (state, newHiddenEntryTypes) {
      state.hiddenEntryTypes = newHiddenEntryTypes || [];
    },
    setLocations (state, newLocations) {
      state.locations = newLocations || [];
    },
    setStatuses (state, newStatuses) {
      state.statuses = newStatuses || [];
    },
    checkVersion (state) { //Checks the version of this module and sets the state to default, if version is different
      //Used to reset state on breaking changes and to remove attributes that are no longer needed
      if (state.version !== MODULE_VERSION) {
        let defaultState = getDefaultState();
        Object.assign(state, defaultState); //Use setters to not impact any reactive functionality
        //Search for unused attributes and set them undefined, to remove them from the persisted state
        for (let key of Object.keys(state)){
          if (!(key in defaultState)){
            state[key] = undefined;
          }
        }
        state.version = MODULE_VERSION;
      }
    },
    clearPersonalData (state) {
      let defaultState = {};
      Object.assign(defaultState, getDefaultState());
      //TODO Add all of the properties here!
      state.hiddenEntryTypes = defaultState.hiddenEntryTypes;
      state.locations = defaultState.locations;
      state.statuses = defaultState.statuses;
    }
  },
  actions: {
    //Get newest changes to the customizations set by the user
    fetchCustomizations (context) { //TODO In API only allow one for each type or only give back most current one of each type, Only allow one route, setting new ones, deleting old ones!
      return new Promise((resolve, reject) => {
        api
        .get('/customizations', { fetchNewerForAnyPath: ['updated_at'] })
        .then(async response => {
          //Only get newest of each type
          let customizationEntriesByType = _.groupBy(response.data, 'type');

          //Go through each type
          _.forEach(customizationEntriesByType, (customizationEntryArray, type) => {
            let newestCustomizationEntry;

            //Take the newest one in the array
            _.forEach(customizationEntryArray, (customizationEntry) => {
              if (newestCustomizationEntry == null || ((new Date(customizationEntry.updated_at)) > (new Date(newestCustomizationEntry.updated_at)))) {
                newestCustomizationEntry = customizationEntry;
              }
            });

            //Try to load the definition
            let newDefinition = _.get(newestCustomizationEntry, ['definition']);
            
            switch (type) {
              case 'hidden_entry_types':
                context.commit('setHiddenEntryTypes', newDefinition);
                break;
              case 'locations':
                context.commit('setLocations', newDefinition);
                break;
              case 'statuses':
                context.commit('setStatuses', newDefinition);
                break;
              //TODO Add all customizations here, e.g. language setting

              default:
                break;
            }
          });
          
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    addCustomization (context, { definition, type, customized_identifier }) {
      let user = context.rootGetters['auth/getUser'];
      let uploadPomise = api.post('/customizations', {
        definition,
        type,
        customized_identifier
      }, {
        user: _.get(user, 'id')
      });
      
      return uploadPomise;
    },
    hideEntryType (context, entryTypeStructure) {
      let newHiddenEntryTypes = _.uniqWith(_.concat(context.getters.getHiddenEntryTypes, [entryTypeStructure]), _.isEqual);
      //Immediately set it
      context.commit('setHiddenEntryTypes', newHiddenEntryTypes);
      //Then start the upload
      return context.dispatch('addCustomization', { definition: newHiddenEntryTypes, type: 'hidden_entry_types'});
    },
    showEntryType (context, entryTypeStructure) {
      let newHiddenEntryTypes = _.differenceWith(context.getters.getHiddenEntryTypes, [entryTypeStructure], _.isEqual);
      //Immediately set it
      context.commit('setHiddenEntryTypes', newHiddenEntryTypes);
      //Then start the upload
      return context.dispatch('addCustomization', { definition: newHiddenEntryTypes, type: 'hidden_entry_types'});
    },
    updateLocations (context, locations) {
      //Immediately set it
      context.commit('setLocations', locations);
      //Then start the upload
      return context.dispatch('addCustomization', { definition: locations, type: 'locations'});
    },
    updateStatuses (context, statuses) {
      //Immediately set it
      context.commit('setStatuses', statuses);
      //Then start the upload
      return context.dispatch('addCustomization', { definition: statuses, type: 'statuses'});
    }
  }
}
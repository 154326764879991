<template id="boolean-input">
  <div :class="['placeholder', (disabled) ? 'disabled' : '']">{{ custom_placeholder ? custom_placeholder : '' }}</div>

  <div slot="end" id="outer-container" class="button-component">
    <!-- Show a checkbox, if requested -->
    <div v-if="useCheckbox" id="checkbox-container" tabindex="2" @keydown="handleSpacebar($event, ()=> inputValue = !inputValue )">
      <ion-checkbox
        :name="key"
        :checked="computedValue"
        :indeterminate="computedValue === undefined"
        @ionChange="computedValue = $event.target.checked"
        :disabled="disabled"
        tabindex="-1">
      </ion-checkbox>
    </div>
    <!-- Show yes/no buttons instead of checkbox by default -->
    <div v-else id="yesno-container">
      <ion-button
        :color="(computedValue === true) ? 'success' : 'medium'"
        shape="round" :fill="(computedValue === true) ? 'solid' : 'outline'"
        @click.stop="computedValue = true"
        @keydown="handleSpacebar($event, ()=>$event.target.click())"
        :disabled="disabled">
          <ion-icon slot="icon-only" :icon="checkmarkSharp"></ion-icon>
      </ion-button>
      <ion-button
        :color="(computedValue === false) ? 'danger' : 'medium'"
        shape="round"
        :fill="(computedValue === false) ? 'solid' : 'outline'"
        @click.stop="computedValue = false"
        @keydown="handleSpacebar($event, ()=>$event.target.click())"
        :disabled="disabled">
          <ion-icon slot="icon-only" :icon="closeSharp"></ion-icon>
      </ion-button>
    </div>
  </div>
</template>

<script>
import { IonButton, IonIcon, IonCheckbox } from '@ionic/vue';
import { defineComponent, computed } from 'vue';

import { checkmarkSharp, closeSharp } from 'ionicons/icons';

import { TYPE_API_MAPPINGS } from '@/utils/report';

import { handleSpacebar } from '@/utils/interaction';

import _ from 'lodash';

const PREFERRED_LABEL_POSITION = 'stacked';

export const isBooleanInput = function(type) {
  return (type in TYPE_API_MAPPINGS['boolean']);
}

export const getBooleanInputParameters = function(type) {
  return {
    labelPosition: PREFERRED_LABEL_POSITION,
    componentType: 'BooleanInput',
    apiComponentType: TYPE_API_MAPPINGS['boolean'][type] || undefined
  }
}

const BooleanInput = defineComponent({
  name: 'BooleanInput',
  components: { IonButton, IonIcon, IonCheckbox },
  props: {
    'key': String,
    'presetValue': [Boolean, String],
    'modelValue': [Boolean, String],
    'useCheckbox': {
      type: Boolean,
      default: false
    },
    'custom_placeholder': String,
    'disabled': {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'update:modified', 'invalidityMessage'],
  setup(props, { emit }) {
    const processInputValue = function(value) {
      //If it is boolean, use it
      if (value === true || value === false) return value;
      //If it is a string, convert it
      if (value === 'true') return true;
      if (value === 'false') return false;
      //Otherwise set is as undefined
      return undefined;
    }

    const processedPresetValue = computed(() => {
      return processInputValue(props.presetValue);
    });

    const computedValue = computed({
      get: () => {
        let processedModelValue = processInputValue(props.modelValue);
        //Check equality of both values from the outside after processing!
        emit('update:modified', !_.isEqual(processedPresetValue.value, processedModelValue));
        return processedModelValue;
      },
      set: (newValue) => {
        emit('update:modelValue', newValue);
      }
    });

    return { computedValue, handleSpacebar, checkmarkSharp, closeSharp };
  }
});

export default BooleanInput;
</script>

<style scoped>
#checkbox-container:focus {
  outline: none;
}

#yesno-container {
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-flex;
  margin: 0px;
  align-items: center;
  --button-size: var(--custom-button-size, 36px);
  --button-padding: var(--custom-button-padding, 5px);
  --button-spacing: var(--custom-button-spacing, 8px);
}

#yesno-container > ion-button {
  height: var(--button-size);
  max-height: var(--button-size);
  width: var(--button-size);
  max-width: var(--button-size);
  margin-top: 0px;
  margin-bottom: 0px;
  --padding-start: var(--button-padding);
  --padding-end: var(--button-padding);
  --padding-top: var(--button-padding);
  --padding-bottom: var(--button-padding);
  --border-width: 2px;
  filter: saturate(0.9)
}

#yesno-container > *:not(:first-child) {
  margin-left: var(--button-spacing);
}

.placeholder {
  min-height: 1em;
  opacity: var(--placeholder-opacity, 0.5);
  margin-top: 10px;
  margin-bottom: 15px;
  /* Prevent text selection in placeholder */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.placeholder.disabled {
  opacity: 0.3;
}
</style>
<template>
  <ion-card>
    <!-- Month Display and Selection -->
    <ion-card-header>
      <div id="main-selection-header">
        <ion-button fill="clear" @click="selectedMonthFirstDay = selectedMonthFirstDay.subtract(1, 'month')">
          <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
        </ion-button>
        <ion-button id="month-label" fill="clear" @click="setOpen(true, $event)"> 
          {{ selectedMonthFirstDay.format("MMMM YYYY") }}
        </ion-button>
        <ion-button fill="clear" @click="selectedMonthFirstDay = selectedMonthFirstDay.add(1, 'month')">
          <ion-icon slot="icon-only" :icon="chevronForwardOutline"></ion-icon>
        </ion-button>
        <ion-button fill="clear" @click="selectedMonthFirstDay = null">
          <!-- Dot for current day in calendar icon -->
          <svg style="fill: currentColor; position: absolute;" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="">
            <circle cx="35" cy="55" r="12" />
          </svg>
          <ion-icon slot="icon-only" :icon="calendarClearOutline"></ion-icon>
        </ion-button>
      </div>
    </ion-card-header>

    <!-- Popover for selection of month and year -->
    <ion-popover
      :is-open="isOpenRef"
      css-class="month-year-menu"
      :event="eventRef"
      mode="ios"
      @didDismiss="setOpen(false)"
    >
      <ion-content class="ion-padding">
        <!-- 
          The idea is, like in many calenders, if clicked on the top month and year label, a popover opens to select a month in the year.
          This month popover displays the current year at the top. If this is clicked, the content of the popover is replaced to select a year in a range of 12 years around the current year.
          This range can also be traveled at 12 year increments. 12 years is chosen to keep the same form factor for months and years in the popup.
          If the range is clicked it switches back to the month selection. When the popover is closed, the selection reverts to the previous state to be ready for the next selection.
        -->

        <!-- Popover content for selecting year -->
        <div id="year-selection" v-if="showYearSelection">
          <div id="year-selection-header">
            <ion-button fill="clear" @click="selectedPopoverYearRangeCenter = selectedPopoverYearRangeCenter.subtract(12, 'year')">
              <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
            </ion-button>
            <ion-button id="year-range-label" fill="clear" @click="setShowYearSelection(false)"> 
              {{ selectedPopoverYearRangeSummaryString }}
            </ion-button>
            <ion-button fill="clear" @click="selectedPopoverYearRangeCenter = selectedPopoverYearRangeCenter.add(12, 'year')">
              <ion-icon slot="icon-only" :icon="chevronForwardOutline"></ion-icon>
            </ion-button>
          </div>
          <div id="year-selection-container">
            <div v-for="year in selectedPopoverYearRange" :key="year" class="year">
              <ion-button class="year-selection-button" 
                :fill="calendarYear(year).isSame(selectedPopoverYear, 'year') ? 'solid' : 'clear'" 
                @click="selectedPopoverYear = calendarYear(year); setShowYearSelection(false);">
                {{ year }}
              </ion-button>
            </div>
          </div>
        </div>

        <!-- Popover content for selecting month (Default visible) -->
        <div id="month-selection" v-else>
          <div id="month-selection-header">
            <ion-button fill="clear" @click="selectedPopoverYear = selectedPopoverYear.subtract(1, 'year')">
              <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
            </ion-button>
            <ion-button id="year-label" fill="clear" @click="setShowYearSelection(true)"> 
              {{ selectedPopoverYear.format("YYYY") }}
            </ion-button>
            <ion-button fill="clear" @click="selectedPopoverYear = selectedPopoverYear.add(1, 'year')">
              <ion-icon slot="icon-only" :icon="chevronForwardOutline"></ion-icon>
            </ion-button>
          </div>
          <div id="month-selection-container">
            <div v-for="(monthName, monthIndex) in shortMonthNames" :key="monthIndex" class="month-name">
              <ion-button class="month-selection-button" 
                :fill="calendarMonthOfYear(selectedPopoverYear.year(), monthIndex).isSame(selectedMonthFirstDay, 'month') ? 'solid' : 'clear'" 
                @click="selectedMonthFirstDay = calendarMonthOfYear(selectedPopoverYear.year(), monthIndex); setOpen(false);">
                {{ monthName }}
              </ion-button>
            </div>
          </div>
        </div>
      </ion-content>
    </ion-popover>

    <!-- Main Calendar view -->
    <ion-card-content>
      <div id="weekday-container">
        <div v-for="weekday in shortWeekdays" :key="weekday" class="weekday">
          {{ weekday }}
        </div>
      </div>
      <div id="calendar-container">
        <div v-for="prevDay in previousMonthDays" :key="prevDay" class="day inactive">
          {{ prevDay }}
        </div>
        <div v-for="day in selectedMonthFirstDay.daysInMonth()" :key="day + '.' + selectedMonth.month" :class="['day', isDaySelected(day) ? 'selected' :'', isToday(day) ? 'today' :'', isDayDisabled(day) ? 'disabled' : '']">
          <!-- Click of the button sets the selected day to be the date of the selected month. Button gets highlighted if its date matches the selected date -->
          <ion-button class="day-button" 
            fill="clear" shape="round" 
            :disabled="isDayDisabled(day)"
            @click="trySetSelectedDay(day)">
            {{ day }}
          </ion-button>
          <div v-if="dateInLoadedDates(calendarDayDate(day))" class="event-indicator"></div>
        </div>
        <div v-for="nextDay in nextMonthDays" :key="nextDay" class="day inactive">
          {{ nextDay }}
        </div>
      </div>
    </ion-card-content>
  </ion-card>

</template>

<script>
import { IonCard, IonCardHeader, IonCardContent, IonButton, IonIcon, IonPopover, IonContent } from '@ionic/vue';
import { defineComponent, ref, computed, onMounted, onUnmounted } from 'vue';

import { chevronBackOutline, chevronForwardOutline, calendarClearOutline } from 'ionicons/icons';

import { useDayjs } from '@/utils/dayjs'

export default defineComponent({
  name: 'Calendar',
  components: { IonCard, IonCardHeader, IonCardContent, IonButton, IonIcon, IonPopover, IonContent },
  props: {
    selectedDay: String, //Date in format 'DD.MM.YYYY'
    selectedMonth: Object, //Object as defined in the emit below
    loadedDates: Object, //Should contain the dates as keys and the entrycount for the value,
    minDate: String //Date in format 'DD.MM.YYYY' that is the lowest that can be selected or null if no restriction
  },
  emits: ['update:selectedDay', 'update:selectedMonth', 'triedSelectLowerThanMin'],
  setup(props, { emit }) {
    const { dayjs, dayjsLocale, activeLocaleData, isReady } = useDayjs();

    //Current year will not change
    const currentYear = dayjs(currentYear).startOf('year');
    /*
      Mutable calendar state affected by localization
    */
    const selectedPopoverYearRangeCenter = ref(dayjs(currentYear)); //Set range of years to display in popover initially to start of current year. Will display 12 years around that year.
    const selectedPopoverYear = ref(dayjs(currentYear)); //Set selected year in popover to current year. Independant from selected month to not disturb it until selected
    const selectedMonthFirstDay = computed({
      get: function() { //Set selected month to the month given or the first day of the current month, if it is not set or invalid
        let date;
        if (props.selectedMonth && props.selectedMonth.start) {
          date = dayjs(props.selectedMonth.start, 'DD.MM.YYYY').startOf('month');
        }

        //If it is invalid, set it to this month as a fallback
        if (date == null || !(date.isValid())) {
          date = dayjs().startOf('month');
        }

        if (isReady.value) { //Check if locales are loaded to set it accordingly
          return date.locale(dayjsLocale.value);
        } else {
          return date;
        }
      },
      set: function(newSelectedMonthFirstDay) {
        let processedSelectedMonthFirstDay = newSelectedMonthFirstDay;
        //Use null to set it to the current month
        if (processedSelectedMonthFirstDay === null) {
          processedSelectedMonthFirstDay = dayjs().startOf('month');
        }
        if (!processedSelectedMonthFirstDay.isSame(selectedMonthFirstDay.value)){ //Only act if it was not the same which was selected
          selectedMonthUpdate(processedSelectedMonthFirstDay);
        }
      }
    }); 
    const selectedDayRef = computed({
      get: function() { //Set selected day to the day given or the start of the current day, if it is not set or invalid
        let date;
        if (props.selectedDay) {
          date = dayjs(props.selectedDay, 'DD.MM.YYYY').startOf('day');
        }

        //If it is invalid, set it to today as a fallback
        if (date == null || !(date.isValid())) {
          date = dayjs().startOf('day');
        }

        if (isReady.value) { //Check if locales are loaded to set it accordingly
          return date.locale(dayjsLocale.value);
        } else {
          return date;
        }
      },
      set: function(newSelectedDayRef) {
        if (!newSelectedDayRef.isSame(selectedDayRef)){ //Only act if it was not the same which was selected
          selectedDayRefUpdate(newSelectedDayRef);
        }
      }
    });
    
    /*
      Computed properties based on the calendar state and the active locale
    */
    const previousMonthDays = computed(() => {
      let daysNeeded = selectedMonthFirstDay.value.weekday(); //Save the weekday of the beginning of the month to calculate how many days of the previous month to show
      let oldestDayOfPreviousMonth = selectedMonthFirstDay.value.subtract(daysNeeded, 'day').date(); //Get the first day that is needed from the previous month
      return [...Array(daysNeeded).keys()].map(i => i + oldestDayOfPreviousMonth); //Create an array that ranges from the oldest day to the last day of the previous month 
    });

    const nextMonthDays = computed(() => {
      let daysNeeded = 6 - selectedMonthFirstDay.value.endOf('month').weekday(); //Save the weekday ("6-" to get the number of weekdays till the last day of the week) of the end of the month to calculate how many days of the next month to show
      return [...Array(daysNeeded).keys()].map(i => i + 1); //Count the days that are needed and return them as an array 
    });

    const shortWeekdays = computed(() => {
      //Reorder Weekdays to match locale
      let unmodifiedWeekdays = activeLocaleData.value.weekdaysMin();
      let firstDayIndex = activeLocaleData.value.firstDayOfWeek();

      let weekdays = [];

      //First add the weekdays from the first index
      for (let weekdayIndex = firstDayIndex; weekdayIndex < unmodifiedWeekdays.length; weekdayIndex++){
        weekdays.push(unmodifiedWeekdays[weekdayIndex]);
      }

      //Then add all weekdays before the index to the end
      for (let weekdayIndex = 0; weekdayIndex < firstDayIndex; weekdayIndex++){
        weekdays.push(unmodifiedWeekdays[weekdayIndex]);
      }

      return weekdays;
    });

    const shortMonthNames = computed(() => {
      return activeLocaleData.value.monthsShort();
    });

    const selectedPopoverYearRange = computed(() => {
      //Give back a range from -4 years of the center year to +8 years of the center year to cover 12 years around it
      let startYear = selectedPopoverYearRangeCenter.value.subtract(4, 'years').year();
      let endYear = selectedPopoverYearRangeCenter.value.add(8, 'years').year();
      return [...Array(endYear - startYear).keys()].map(i => i + startYear); 
    });

    const selectedPopoverYearRangeSummaryString = computed(() => { //String to display START-END, e.g. 2016-2027
      let range = selectedPopoverYearRange.value;
      return range[0] + ' - ' + range[range.length - 1];
    });

    //Only allow setting it, if it is not disabled, otherwise emit event
    const trySetSelectedDay = function(day) {
      if (!isDayDisabled.value(day)) {
        selectedDayRef.value = calendarDayDate(day)
      } else {
        emit('triedSelectLowerThanMin');
      }
    }
    
    const isDaySelected = computed(() => {
      return (day) => {
        return (props.selectedDay != null && calendarDayDate(day).isSame(selectedDayRef.value));
      }
    });

    const isToday = computed(() => {
      return (day) => {
        return calendarDayDate(day).isSame(dayjs().startOf('day'));
      }
    });

    const isDayDisabled = computed(() => {
      return (day) => {
        if (props.minDate == null) {
          return false;
        }
        return calendarDayDate(day).isBefore(dayjs(props.minDate, 'DD.MM.YYYY').startOf('day'));
      }
    });

    /*
      Helper functions to get correct datejs objects from integer indices indicating the changed year, month, day
    */
    const calendarMonthOfYear = function(year, monthIndex) {
      return selectedMonthFirstDay.value.year(year).month(monthIndex); //Return a new date with the same properties as before. Just the year and month are set to the new ones.
    }

    const calendarYear = function(year) {
      return selectedMonthFirstDay.value.year(year).startOf('year'); //Return a new date with the same properties as before. Just the year is set and the month to january
    }

    const calendarDayDate = function(day) {
      return selectedMonthFirstDay.value.date(day);
    }


    /*
      Parent interaction
    */
    //Helper function to check if the given date is in the loadedDates
    const dateInLoadedDates = computed(() => {
      return function(date){
        return date.format('DD.MM.YYYY') in props.loadedDates;
      }
    });
    //Loads the selected month to show event indicators at the given dates //TODO Prüfen, dass neu geladene Objekte beim hin- und herwechseln nicht doppelt eingetragen werden.
    const selectedMonthUpdate = function(newSelectedMonthFirstDay){
      emit('update:selectedMonth', newSelectedMonthFirstDay.format('MM.YYYY'));
    }
    //Emit event to signal to other components that a new day was selected
    const selectedDayRefUpdate = function(newselectedDayRef){
      emit('update:selectedDay', newselectedDayRef.format('DD.MM.YYYY'));
    }

    const handleResize = function(){
      setOpen(false); //Close popover when resizing or rotating device screen for correct display
    }

    //Load initial selections and add listeners for device rotation
    onMounted(() => { //FIXME Because the hidden menu from the split pane also mounts, everythin is called twice. Maybe only load once. Check if both views stay consistent when switching between them.
      window.addEventListener("resize", handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    /*
      Month and Year Selection Popover logic
    */
    const isOpenRef = ref(false);
    const showYearSelection = ref(false);
    const eventRef = ref();
    const setOpen = function(state, event) {
      if ( isOpenRef.value != state ){ //Only execute change, when it needs to be changed
        eventRef.value = event;
        if ( state == true ){
          if ( event !== undefined ){ //Only display when it can be positioned correctly with the event
            isOpenRef.value = true;
          }
        } else {
          isOpenRef.value = false;
        }
        setShowYearSelection(false); //Always start with month selection
        
      }
    }

    const setShowYearSelection = function(newState){
      // Set in function to go to the correct page when changing to year selection. The correct page is calculated based on the current year in pages of 12 years with the current one in the center
      if (newState === true){
        let difference = selectedPopoverYear.value.year() - (currentYear.year() - 4); //-4 brings us to the first year of the range shown on the selection

        let pageOffset = Math.floor(difference / 12); //Divided by 12 gives us the offset in pages, since one page has 12 years on it

        if (pageOffset < 0){ //Calculate the new offset in pages based on the offset to the current year
          selectedPopoverYearRangeCenter.value = currentYear.subtract(Math.abs(pageOffset) * 12, 'years');
        } else if (pageOffset > 0) {
          selectedPopoverYearRangeCenter.value = currentYear.add(pageOffset * 12, 'years');
        } else { //If the offset is 0, set the center to the current year
          selectedPopoverYearRangeCenter.value = dayjs(currentYear);
        }
      }
      showYearSelection.value = newState;
    }

    return { 
      chevronBackOutline,
      chevronForwardOutline,
      calendarClearOutline,
      selectedPopoverYearRangeCenter,
      selectedPopoverYear,
      selectedMonthFirstDay,
      selectedDayRef,
      selectedPopoverYearRange,
      selectedPopoverYearRangeSummaryString,
      trySetSelectedDay,
      isDaySelected,
      isToday,
      isDayDisabled,
      previousMonthDays,
      nextMonthDays,
      calendarDayDate,
      calendarMonthOfYear,
      calendarYear,
      shortWeekdays,
      shortMonthNames,
      isOpenRef,
      showYearSelection,
      eventRef,
      setOpen,
      setShowYearSelection,
      dateInLoadedDates
    };
  }
});
</script>

<style>
.month-year-menu{
  --min-width: 250px;
}
</style>

<style scoped>
ion-card {
  margin-top: 10px;
}

#main-selection-header, #month-selection-header, #year-selection-header {
  display: flex;
  justify-content: space-around;
  padding-bottom: 0;
}

#main-selection-header ion-button {
  --color: var(--ion-color-primary-text);
}

ion-card-content {
  padding-top: 0;
}

ion-button {
  --padding-start: 5px;
  --padding-end: 5px;
  height: 45px;
}

#month-label {
  width: 100%;
  text-transform: none;
  font-size: 1.5em;
}

#year-label, #year-range-label {
  width: 100%;
  text-transform: none;
}

#month-selection-container, #year-selection-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.month-name, .year {
  display: flex;
  justify-content: center;
  align-items: center;
}

.month-selection-button, .year-selection-button {
  width: 100%;
  text-transform: none;
}

#calendar-container, #weekday-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

#calendar-container {
  color: var(--ion-text-color);
}

#weekday-container {
  color: var(--ion-color-medium);
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.day, .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.day-button{
  --color: var(--ion-text-color);
  --background-hover-opacity: 0.6;
  --background-focused-opacity: 0.6;
  --color-focused: var(--ion-color-medium-dark);
  --color-hover: var(--ion-color-medium-dark);
  width: 36px;
  height: 36px;
}

.selected > .day-button{
  --color: var(--ion-color-primary-contrast);
  --background: var(--ion-color-primary);
  --color-hover: var(--ion-color-primary-contrast);
  --color-focused: var(--ion-color-primary-contrast);
}

.today > .day-button {
  --border-color: var(--ion-color-tertiary);
  --border-width: 2px;
  --border-style: solid;
}

.day.inactive {
  color: var(--ion-color-medium-light);
}

.event-indicator {
  width: 20px;
  height: 3px;
  border: 1px solid;
  border-radius: 3px;
  border-color: var(--ion-color-tertiary);
  background-color: var(--ion-color-tertiary);
  bottom: calc(4px + 5px);
  position: absolute;
}

.selected > .event-indicator {
  border-color: var(--ion-color-tertiary-contrast);
  background-color: var(--ion-color-tertiary-contrast);
}
</style>

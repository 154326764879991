<template>
  <ion-item ref="itemInstance" v-if="value !== undefined" :lines="lines">
    <ion-label>
      <h2 class="label">
        <template v-if="icon != null">
          <font-awesome-icon class="label-icon" v-if="iconIsFontAwesome" :icon="icon" />
          <ion-icon class="label-icon" v-else :icon="icon"></ion-icon>
        </template>
        <slot name="icon"></slot>
        {{ display_name }}
      </h2>
      <h3 v-if="displayValue !== undefined" 
        :class="['value-text', (itemParameters.treatAsColor) ? 'color' : '']"
        :style="(itemParameters.treatAsColor) ? `--custom-background: ${displayValue};` : undefined">
        <span>{{ displayValue || noValuePlaceholder }}</span> <span v-if="unit" class="unit">{{ unit }}</span> <!-- TODO Add some text, if no value? -->
      </h3>
      <ion-slides v-else-if="mediaArray && mediaArray.length" :options="slideOpts" :scrollbar="itemParameters.multipleFiles" :key="mediaArray">
        <ion-slide v-for="(media, mediaIndex) in mediaArray" :key="mediaIndex" @click="openGalleryModal($event, mediaIndex)">
          <MediaPreview class="preview-media" :type="media.mime" :mediaUrl="media.blobURL" :thumbnailUrl="media.thumbnail"></MediaPreview>
        </ion-slide>
      </ion-slides>
    </ion-label>
  </ion-item>
</template>

<script>
import { IonItem, IonLabel, IonSlides, IonSlide, IonIcon } from '@ionic/vue';
import { computed, defineComponent, ref, watch, onMounted, onUnmounted } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import MediaPreview from '@/components/MediaPreview.vue';

import { openGallery, default as modalComponent } from '@/components/Gallery.vue';

import { useI18n } from "@/utils/i18n";

import { localizeSingleField } from '@/utils/report';

import { isFilesInput } from '@/components/forms/inputs/FilesInput.vue';

export default defineComponent({
  name: 'ViewFormItem',
  components: { IonItem, IonLabel, IonSlides, IonSlide, IonIcon, FontAwesomeIcon, MediaPreview },
  props: {
    'display_name': String,
    'type': String,
    'unit': String,
    'value': {
      type: [String, Number, Boolean, Array, Object],
      default: undefined
    },
    'icon': [Object, String],
    'iconIsFontAwesome': {
      type: Boolean,
      default: false
    },
    'lines': {
      type: String,
      default: 'inset'
    },
    'noValuePlaceholder': {
      type: String,
      default: '—'
    }
  },
  setup(props) {
    const i18n = useI18n();

    const itemInstance = ref(null);

    const itemParameters = computed(() => {
      let type = props.type;

      return {
        type,
        multipleFiles: (mediaArray.value && mediaArray.value.length > 1),
        treatAsColor: type === 'color'
      }
    });

    //Convert values like date to corresponding display values
    const displayValue = computed(() => {
      let localizedField = localizeSingleField({ type: props.type, value: props.value}, i18n);
      //If it is a file, never show displayValue
      if (localizedField.isFile) return undefined;
      return localizedField.value;
    });

    const slideOpts = computed(() => {
      return{
        updateOnWindowResize: true,
        resizeObserver: true,
        zoom: false,
        autoHeight: false,
        slidesPerView: 'auto',
        spaceBetween: 15,
        scrollbar: ((itemParameters.value.multipleFiles) ? {
          el: '.swiper-scrollbar',
          draggable: true,
          hide: false
        } : false),
        allowTouchMove: (itemParameters.value.multipleFiles)
      };
    });

    const fileArray = computed(() => {
      if (isFilesInput(props.type) && props.value != null) {
        if (Array.isArray(props.value)){
          return props.value;
        } else {
          return [props.value];
        }
      } else {
        return null;
      }
    });

    const mediaArray = ref([]);

    const updateMediaArray = function(files) {
      if (files != null) {
        let media = [];
        for (let file of files) {
          media.push({
            mime: file.mime || itemParameters.value.type,
            thumbnail: file.thumbnail || undefined,
            blobURL: file.blobURL
          });
        }
        mediaArray.value = media;
      }
    }

    const openGalleryModal = function(event, index) {
      let offset;
      let elementHeight;
      if (event != null && event.target != null){
        let elementBounds = event.target.getBoundingClientRect();

        offset = {
          x: (elementBounds.left + elementBounds.right) / 2,
          y: (elementBounds.top + elementBounds.bottom) / 2
        };

        elementHeight = elementBounds.height;
      }

      openGallery(modalComponent, mediaArray.value, index, {}, offset, elementHeight);
    }

    //Wait for changes in the file list to update the media list
    watch(fileArray, (newFiles) => {
      updateMediaArray(newFiles);
    });

    const HIGHLIGHT_CLASS = 'highlighted-scroll-target';
    const HIGHLIGHT_TIME = 4000;

    const scrollItemIntoView = function() {
      if (itemInstance.value != null) {
        setTimeout(() => { 
          itemInstance.value.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          itemInstance.value.$el.classList.add(HIGHLIGHT_CLASS);
        }, 100); //Defer to outside of the event listener after the parent list has opened
        setTimeout(() => { 
          itemInstance.value.$el.classList.remove(HIGHLIGHT_CLASS);
        }, HIGHLIGHT_TIME); //Remove highlight after specified time
      }
    }

    //Set initial media list on mount. Watchers look for changes and update it accordingly.
    onMounted(() => {
      setTimeout(() => updateMediaArray(fileArray.value), 200); //Defer update to the view to initialize the slider only when visible. Necessary with width: max-content as width changes after initialization! Timeout is optional but ensures some waiting time!
      if (itemInstance.value != null) {
        itemInstance.value.$el.addEventListener('requestScrollToItem', scrollItemIntoView);
      }
    });

    onUnmounted(() => {
      if (itemInstance.value != null) {
        itemInstance.value.$el.removeEventListener('requestScrollToItem', scrollItemIntoView);
      }
    });

    return { i18n, itemInstance, itemParameters, displayValue, slideOpts, fileArray, openGalleryModal, mediaArray, scrollItemIntoView }
  }
});
</script>

<style scoped>
ion-slides {
  margin-top: 10px;
}

ion-slide {
  height: 100%;
  width: max-content; /* Adapts the width to the preview content --> Always maximum height in ion-slides container */
  min-width: 3em;
  max-width: 10em;
  margin-bottom: 15px;
}

.label {
  margin-bottom: 8px;
  white-space: normal!important;
  /* Prevent text selection in label */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
}

.preview-media {
  --height: 4em;
  --outer-border-width: 2px;
  --border-radius: 6px;
}

.value-text {
  text-overflow: unset;
  height: auto;
  white-space: normal;
}

.value-text.color {
  border: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));
  background: var(--custom-background, unset);
  border-radius: 4px;
}

.value-text.color > * {
  opacity: 0;
}

.unit {
  color: var(--ion-color-tertiary-text);
  font-weight: bold;
}

.label-icon {
  font-size: 1.1em;
  margin-inline-end: 10px;
}

ion-icon.label-icon {
  font-size: 1.2em;
}

.highlighted-scroll-target {
  --background: rgba(var(--ion-color-primary-rgb),0.2);
}
</style>

import { createApp } from 'vue'
import App from '@/App.vue'
import store from '@/store'
import router from '@/router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Icons used inside text for unicode usage.
*  For accessing them inside of text use this class:
*    .inline-fa-icons
*  Or css with sans-serif fallback:
*    font-family: var(--ion-font-family, sans-serif), sans-serif, 'Font Awesome 6 Free';
*/
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';

/* Custom font */
import './fonts/geist/sans/font-face.css';
import './fonts/geist/mono/font-face.css';

/* Theme variables */
import './theme/variables.css';

/* Custom designs */
import './theme/custom-designs.css';

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { registerWithUpdateCallback } from './registerServiceWorker';

registerWithUpdateCallback(function () {
  //Once there is an update, add notification to enable skipWaiting call and trigger refreshs
  if (store != null) {
    store.commit('addNotification', {
      title: 'update_available',
      description: 'restart_app',
      translate: true,
      action: 'sw_update',
      action_text: 'restart',
      no_persist: true
    });
  }
});

defineCustomElements(window);

const app = createApp(App)
//app.config.performance = true; //TODO Only enable when not in production!
app.use(store)
  .use(IonicVue)
  .use(router);

router.isReady().then(() => {
  app.mount('#app');
});

import { createAnimation } from '@ionic/vue';

export function slowModalFadeOutAnimation(baseEl) {
  const backdropAnimation = createAnimation()
    .addElement(baseEl.querySelector('ion-backdrop'))
    .fromTo('opacity', 'var(--backdrop-opacity)', '0');

  const wrapperAnimation = createAnimation()
    .addElement(baseEl.querySelector('.modal-wrapper'))
    .keyframes([
      { offset: 0, opacity: '1', transform: 'translate3d(0, 0, 0)' }, //Transform and opacity always has to be overriden for modal, otherwise it is transformed downwards and hidden by default
      { offset: 1, opacity: '0', transform: 'translate3d(0, 0, 0)' }
    ]);

  return createAnimation()
    .addElement(baseEl)
    .easing('ease-in')
    .duration(1000)
    .addAnimation([backdropAnimation, wrapperAnimation]);
}

export function slowModalFadeInAnimation(baseEl) {
  return slowModalFadeOutAnimation(baseEl).direction('reverse');
}

//Does not animate and keeps the modal in its position
export function zeroModalAnimation(baseEl) {
  const backdropAnimation = createAnimation()
    .addElement(baseEl.querySelector('ion-backdrop'))
    .fromTo('opacity', 'var(--backdrop-opacity)', 'var(--backdrop-opacity)');

  const wrapperAnimation = createAnimation()
    .addElement(baseEl.querySelector('.modal-wrapper'))
    .keyframes([
      { offset: 0, opacity: 1, transform: 'translate3d(0, 0, 0)' }, //Transform and opacity always has to be overriden for modal, otherwise it is transformed downwards and hidden by default
      { offset: 1, opacity: 1, transform: 'translate3d(0, 0, 0)' }
    ]);

  return createAnimation()
    .addElement(baseEl)
    .easing('linear')
    .duration(1)
    .addAnimation([backdropAnimation, wrapperAnimation]);
}

//Given coordinates are from top left of the window. Modal is animated from the center so we first calculate the offset from the center and then animate it.
export function createExtendAnimationFromPoint({x = undefined, y = undefined}, initialHeight = 0) {
  const windowCenterX = window.innerWidth / 2;
  const windowCenterY = window.innerHeight / 2;
  //Defaults to center if coordinate is not given
  const xOffset = (x != null) ? x : windowCenterX;
  const yOffset = (y != null) ? y : windowCenterY;

  const xOffsetFromCenter = (xOffset - windowCenterX);
  const yOffsetFromCenter = (yOffset - windowCenterY);

  const heightScale = initialHeight / window.innerHeight;
  return function(baseEl) {
    const backdropAnimation = createAnimation()
      .addElement(baseEl.querySelector('ion-backdrop'))
      .fromTo('opacity', '0', 'var(--backdrop-opacity)');

    const wrapperAnimation = createAnimation()
      .addElement(baseEl.querySelector('.modal-wrapper'))
      .keyframes([
        { offset: 0, opacity: 0, transform: `translate3d(${xOffsetFromCenter}px, ${yOffsetFromCenter}px, 0) scale(${heightScale})` }, //Transform and opacity always has to be overriden for modal, otherwise it is transformed downwards and hidden by default
        { offset: 0.2, opacity: 1 },
        { offset: 1, opacity: 1, transform: `translate3d(0, 0, 0) scale(1)` }
      ]);

    return createAnimation()
      .addElement(baseEl)
      .easing('ease-in-out')
      .duration(250)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  }
}

export function createCollapseAnimationToPoint(point, targetHeight) {
  const reverseAnimationFunction = createExtendAnimationFromPoint(point, targetHeight);
  return function(baseEl) {
    return reverseAnimationFunction(baseEl).direction('reverse');
  }
}
<template>
  <canvas ref="canvasInstance" class="bounding-box-canvas"></canvas>
</template>

<script>
import { watch, onMounted, ref } from 'vue';

import _ from 'lodash';

export default {
  name: 'CameraTemplate',
  props: {
    targetRect: Object,
    boxes: Array,
    highlightBoxIndex: Number
  },
  setup(props) {
    const canvasInstance = ref(null);

    const clearCanvas = function(){
      canvasInstance.value.getContext('2d').clearRect(0, 0, canvasInstance.value.width, canvasInstance.value.height);
    }

    const TRANSPARENT_BLACK = 'rgba(0, 0, 0, 1)';

    const OPAQUE_BACKGROUND = 'rgba(0, 0, 0, 0.7)';

    const DARK_WHITE = 'rgba(255, 255, 255, 0.4)';

    const HIGHLIGHT_COLOR = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary');

    const updateCanvas = function(drawBoxes, highlightIndex) {
      clearCanvas();

      let ctx = canvasInstance.value.getContext('2d');
      let width = canvasInstance.value.width;
      let height = canvasInstance.value.height;

      ctx.lineWidth = 12;

      let lineMargin = (ctx.lineWidth / 2);

      ctx.fillStyle = OPAQUE_BACKGROUND;
      ctx.fillRect(0, 0, width, height);

      ctx.fillStyle = TRANSPARENT_BLACK;

      _.forEach(drawBoxes, (box, boxIndex) => {
        if (box != null) {
          ctx.beginPath();
          ctx.strokeStyle = DARK_WHITE;
            
          ctx.globalCompositeOperation = "destination-out"; //Remove existing pixels where there is a color set

          //Draw outline, prefer corner points over bounding box for accuracy
          if (box.cornerPoints != null && box.cornerPoints.length) {
            let start = _.first(box.cornerPoints);
            ctx.moveTo((start.x * width), (start.y * height));
            _.forEach(_.tail(box.cornerPoints), (point) => {
              ctx.lineTo((point.x * width), (point.y * height));
            });
            ctx.closePath();
          } else if (box.boundingBox != null) {
            ctx.rect(((box.boundingBox.x * width) - lineMargin), ((box.boundingBox.y * height) - lineMargin), ((box.boundingBox.width * width) + lineMargin), ((box.boundingBox.height * height) + lineMargin));
          }
          //If highlighted, remove the opaque background by making the area transparent
          if (highlightIndex == null || boxIndex == highlightIndex) {
            ctx.fill();
            ctx.strokeStyle = HIGHLIGHT_COLOR;
          }
          ctx.globalCompositeOperation = "source-over";
          ctx.stroke();
        }
      });
    }

    watch([() => props.targetRect, () => props.boxes, () => props.highlightBoxIndex, canvasInstance], ([rect, currentBoxes, currentHighlightIndex, currentCanvasInstance]) => {
      if (currentCanvasInstance != null && rect != null && rect.width != null && rect.height != null) {
        const ratio = Math.ceil(window.devicePixelRatio);

        let width = rect.width;
        let height = rect.height;
        if (currentCanvasInstance != null) {
          currentCanvasInstance.width = Math.ceil(width * ratio);
          currentCanvasInstance.height = Math.ceil(height * ratio);
          currentCanvasInstance.style.width = `${Math.ceil(width)}px`;
          currentCanvasInstance.style.height = `${Math.ceil(height)}px`;
        }
        updateCanvas(currentBoxes, currentHighlightIndex);
      }
    }, { immediate: true });

    onMounted(() => {
      //Clear canvas on load
      clearCanvas();
    });

    return {
      canvasInstance
    }
  }
}
</script>

<!-- This element automatically fills a relative parent to the maximum! -->
<style scoped>
.bounding-box-canvas {
  position: absolute;
  /* Added font here to preload it */
  font-family: 'Font Awesome 6 Free';
  font-weight: 900;
}
</style>
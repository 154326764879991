/* These functions are duplicated in the root of the project for service workers. Always update both! */
export function checkForSameOrigin(targetURL, sourceURL, allowedPathnameRegex, returnURLInstanceOnSuccess = false) {
  //Try to convert the URL of the target and of the source and compare the origin for security when adding authentication to the requests!
  try {
    const targetURLInstance = new URL(targetURL);
    const sourceURLInstance = new URL(sourceURL);

    //Only test pathname regex, if it is set
    if (allowedPathnameRegex != null) {
      if (targetURLInstance.pathname == null || !(allowedPathnameRegex.test(targetURLInstance.pathname))) {
        return (returnURLInstanceOnSuccess) ? undefined : false;
      }
    }

    //Check if they both have the same origin, only then accept this request
    if (targetURLInstance.origin === sourceURLInstance.origin) {
      return (returnURLInstanceOnSuccess) ? targetURLInstance : true;
    }
    //Alternatively check all parts separately that the origin consists of
    if (
      targetURLInstance.protocol === sourceURLInstance.protocol &&
      targetURLInstance.hostname === sourceURLInstance.hostname &&
      targetURLInstance.port === sourceURLInstance.port
    ) {
      return (returnURLInstanceOnSuccess) ? targetURLInstance : true;
    }
  } catch {
    //If it can't be converted to a valid URL, treat it as different origin for security reasons
    return (returnURLInstanceOnSuccess) ? undefined : false;
  }

  return (returnURLInstanceOnSuccess) ? undefined : false;
}

//Generates a regex that checks if a path starts with a given string (after initial "/"). If includeSingleSubPath is >1 then each sub path in the specified count after that is considered valid (strings following not including "/"))
export function generatePathRegex(pathname, includeSubPathCount = 0) {
  let subPathStrings = '';

  //Add for every level another set
  for (let subPathIndex = 0; subPathIndex < includeSubPathCount; subPathIndex++) {
    subPathStrings = `${subPathStrings}/[^/]+`
  }
  
  return new RegExp(`^/${pathname}${subPathStrings}/?$`);
}

export function modifyCacheURLs({request/*, mode, params, event, state*/}) {
  //Try to convert the URL of the request to modify it
  try {
    const requestUrl = new URL(request.url);

    //Remove token param
    requestUrl.searchParams.delete('token');

    return requestUrl.toString();
  } catch {
    //If it can't be converted to a valid URL, don't process this request further and use original
    return request;
  }
}
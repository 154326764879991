<template id="code-scanner-select-modal">
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ i18n.$t('qr.scan-codes.select-modal.title') }}</ion-title>
        <ion-buttons class="modal-confirmation-buttons" slot="end">
            <ion-button
              class="cancel-button"
              @click="closeModal()"
              fill="outline"
              shape="round"
              color="dark" >
              <ion-icon slot="icon-only" :icon="close"></ion-icon>
            </ion-button>
            <ion-button
              class="continue-button"
              @click="continueWithCode()" 
              fill="solid"
              shape="round"
              color="primary" >
              <ion-icon slot="icon-only" :icon="arrowForward"></ion-icon>
            </ion-button>
          </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="non-scroll-container">
        <ion-card id="code-preview-container">
          <ion-card-header>
            <ion-card-title class="preview-title">
              {{ i18n.$t('qr.scan-codes.select-modal.select') }}
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <div class="preview-controls">
              <ion-button @click="goToPrevious()" fill="clear" :disabled="currentPreviewIndex <= 0">
                <ion-icon slot="icon-only" :icon="chevronBack"></ion-icon>
              </ion-button>
              <span 
                class="page-index">
                {{ currentPreviewIndex + 1 }} / {{ codeCount }}
                </span>
              <ion-button @click="goToNext()" fill="clear" :disabled="(currentPreviewIndex + 1) >= codeCount">
                <ion-icon slot="icon-only" :icon="chevronForward"></ion-icon>
              </ion-button>
            </div>

            <div class="preview-container">
              <BoundingBoxCoordinateCanvas :targetRect="imageSize" :boxes="codeBoundingBoxes" :highlightBoxIndex="currentPreviewIndex"></BoundingBoxCoordinateCanvas>
              <img ref="previewImage" id="preview-image" :src="scannedImageBlobURL" />
            </div>
          </ion-card-content>
        </ion-card>

        <ion-card id="data-container">
          <ion-list>
            <CollapsibleList class="data-list"
              :showHeader="true"
              :open="true"
              :title="i18n.$t('qr.scan-codes.select-modal.content')">

              <template v-if="currentValueIsInternal">
                <ion-item v-if="currentValue.type != null" lines="full">
                  <ion-label>
                    <h3>{{ i18n.$t('qr.scan-codes.select-modal.type') }}</h3>
                    <p>{{ i18n.$t(`qr.type.${currentValue.type}`) }}</p>
                  </ion-label>
                </ion-item>
                <ion-item v-if="currentValue.id != null || currentValue.uuid != null" lines="full">
                  <ion-label>
                    <h3>{{ i18n.$t('qr.scan-codes.select-modal.id') }}</h3>
                    <p>{{ currentValue.id || currentValue.uuid }}</p>
                  </ion-label>
                </ion-item>
              </template>
              <ion-item v-else lines="full">
                <ion-label>
                  <p>{{ currentValue }}</p>
                </ion-label>
              </ion-item>
            </CollapsibleList>
            <CollapsibleList v-if="currentAdditionalParams != null" class="data-list"
              :showHeader="true"
              :open="false"
              :title="i18n.$t('qr.scan-codes.select-modal.additional-data')">

              <ion-item v-for="(attribute, attributeIndex) in currentAdditionalParams" :key="attributeIndex" lines="full">
                <ion-label>
                  <h3>{{ attribute.displayTitle }}</h3>
                  <p>{{ attribute.displayValue }}</p>
                </ion-label>
              </ion-item>
            </CollapsibleList>
          </ion-list>
        </ion-card>
      </div>
      
    </ion-content>
  </ion-page>
</template>

<script>

import { IonPage, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonList, IonItem, IonLabel, modalController } from '@ionic/vue';
import { defineComponent, computed, ref, onBeforeUnmount, watch } from 'vue';

import CollapsibleList from '@/components/CollapsibleList.vue';

import BoundingBoxCoordinateCanvas from '@/components/BoundingBoxCoordinateCanvas.vue';

import { chevronBack, chevronForward, close, arrowForward } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";

import _ from 'lodash';

const CodeScannerSelectModal = defineComponent({
  name: 'CodeScannerSelectModal',
  components: { IonPage, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonList, IonItem, IonLabel, CollapsibleList, BoundingBoxCoordinateCanvas },
  props: {
    'scannedImageBlobURL': String,
    'codes': Array,
    'scale': Number
  },
  setup(props) {
    const i18n = useI18n();

    const closeModal = function(){
      modalController.dismiss();
    }

    const currentPreviewIndex = ref(0);

    const currentCode = computed(() => {
      return _.get(props.codes, [currentPreviewIndex.value]);
    });

    const currentValueIsInternal = computed(() => (currentCode.value != null && currentCode.value.isInternalCode));

    const currentValue = computed(() => {
      if (currentCode.value != null) {
        return currentCode.value.processedValue;
      }
      return null;
    });

    const currentAdditionalParams = computed(() => {
      if (currentValue.value != null) {
        return currentValue.value.displayParams;
      }
      return null;
    });

    const previewImage = ref(null);

    const imageSize = ref({x: 0, y: 0, width: 0, height: 0});
    const naturalImageSize = ref({width: 0, height: 0});

    const setSize = function() {
      if (previewImage.value != null) {
        imageSize.value = _.pick(previewImage.value.getBoundingClientRect(), ['width', 'height', 'x', 'y']);
        naturalImageSize.value = {
          width: previewImage.value.naturalWidth,
          height: previewImage.value.naturalHeight
        }
      }
    }

    const widthProperties = ['left', 'right', 'width', 'x'];
    const heightProperties = ['bottom', 'top', 'height', 'y'];

    const codeBoundingBoxes = computed(() => {
      let widthRatio = naturalImageSize.value.width;
      let heightRatio = naturalImageSize.value.height;
      let scaleRatio = (props.scale != null) ? props.scale : 1;

      return _.map(props.codes, (code) => {
        let boxes = _.pick(code, ['boundingBox', 'cornerPoints']);

        //Translate coordinates to relative!
        let modifiedBoxes = {
          'boundingBox': _.mapValues(_.pick(boxes['boundingBox'], _.concat(widthProperties, heightProperties)), (value, key) => {
            if (widthProperties.includes(key)) {
              return (value / scaleRatio) / widthRatio;
            } else if (heightProperties.includes(key)) {
              return (value / scaleRatio) / heightRatio;
            }
            return (value / scaleRatio);
          }),
          'cornerPoints': _.map(boxes['cornerPoints'], (point) => _.mapValues(point, (value, key) => {
            if (widthProperties.includes(key)) {
              return (value / scaleRatio) / widthRatio;
            } else if (heightProperties.includes(key)) {
              return (value / scaleRatio) / heightRatio;
            }
            return (value / scaleRatio);
          }))
        };

        return modifiedBoxes;
      });
    });

    const previewImageResizeObserver = new ResizeObserver(setSize);

    watch(previewImage, (element) => {
      if (element != null) {
        previewImageResizeObserver.observe(element);
        setSize();
      }
    }, { immediate: true });

    onBeforeUnmount(() => {
      previewImageResizeObserver.disconnect();
    });
    
    const codeCount = computed(() => {
      if (props.codes == null) return 0;
      return props.codes.length;
    });

    const goToPrevious = function() {
      if (currentPreviewIndex.value > 0) currentPreviewIndex.value--;
    }

    const goToNext = function() {
      if ((currentPreviewIndex.value + 1) < codeCount.value) currentPreviewIndex.value++;
    }

    const continueWithCode = function() {
      modalController.dismiss(currentCode.value);
    }

    return { i18n, closeModal, currentPreviewIndex, currentValueIsInternal, currentValue, currentAdditionalParams, codeBoundingBoxes, previewImage, imageSize, codeCount, goToPrevious, goToNext, continueWithCode, chevronBack, chevronForward, close, arrowForward };
  }
});

export async function openCodeScannerSelectModal(component, scannedImageBlobURL, codes, scale){
  if (component != null && scannedImageBlobURL != null && codes != null) {
    const modal = await modalController
      .create({
        component,
        componentProps: {
          scannedImageBlobURL,
          codes,
          scale
        }
      })
    modal.present();
    return modal.onWillDismiss();
  }
}

export default CodeScannerSelectModal;
</script>

<style scoped>
.non-scroll-container {
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

ion-item ion-label {
  pointer-events: none;
}

ion-item ion-label > h3 {
  color: var(--ion-color-primary-text);
}

ion-item ion-label > p {
  color: var(--ion-text-color, #000000);
}

ion-item ion-label * {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

ion-card-title {
  font-size: 1.2em;
}

ion-card {
  margin-block: 5px;
}

#code-preview-container {
  position: relative;
  flex: 1 1000 50%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
}

#code-preview-container ion-card-header {
  padding-bottom: 0px;
}

#code-preview-container ion-card-content, #code-preview-container .preview-container {
  min-height: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  max-height: 100%;
}

#code-preview-container .preview-container {
  justify-content: center;
  align-items: center;
}

#data-container {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

#data-container ion-list {
  min-height: 0;
  padding: 0px;
  display: flex;
  flex-direction: column;
  background: var(--ion-card-background, #fff);
}

#data-container ion-list ion-item, #code-preview-container ion-item {
  background-color: var(--ion-card-background, #fff);
  --background: var(--ion-card-background, #fff);
}

.data-list {
  --color: var(--ion-color-primary-text);
  color: var(--color);
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.data-list :deep(.items-container) {
  min-height: 0;
}

.preview-title {
  color: var(--ion-color-primary-text);
  display: flex;
  flex-flow: row wrap;
  column-gap: 5px;
}

.preview-title span {
  color: var(--ion-color-step-850, #262626);
  font-weight: normal;
}

.preview-controls {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-confirmation-buttons > * {
  margin-left: clamp(5px, 1.5vw, 10px);
  margin-right: clamp(5px, 1.5vw, 10px);
}

.modal-confirmation-buttons ion-button {
  height: 32px;
  width: 32px;
  --padding-start: 5px;
  --padding-end: 5px;
  --border-width: 2px;
}

#preview-image {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
  flex-shrink: 1;
  flex-basis: auto;
  overflow-y: hidden;
}
</style>
<template>
  <ion-button
    ref="button"
    :class="(loading) ? 'progress-button loading' : 'progress-button'"
    :disabled="disabled || loading"
    :color="color"
    :expand="expand"
    :fill="fill"
    :href="href"
    :shape="shape"
    :size="size"
    :strong="strong"
    :type="type"
    @click="emit('click', $event)"
  >
    <slot></slot>
    <ion-spinner v-if="loading" :name="spinner_name" class="spinner"></ion-spinner>
  </ion-button>
</template>

<script>
import { IonButton, IonSpinner } from '@ionic/vue';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'ProgressButton',
  components: { IonButton, IonSpinner },
  props: {
    color: String,
    disabled: Boolean,
    expand: String,
    fill: String,
    href: String,
    shape: String,
    size: String,
    strong: Boolean,
    type: String,
    enableProgress: Boolean,
    progress: Number,
    loading: Boolean,
    spinner_name: String
  },
  emits: ['click'],
  setup(props, { emit }){
    const button = ref(null);

    const click = function(){
      button.value.$el.click();
    }

    return { emit, button, click };
  }
});
</script>

<style scoped>
.progress-button.loading {
  opacity: 1!important;
}

.spinner {
  position: absolute;
  max-height: 80%;
  opacity: 1!important;
  --color: var(--ion-text-color, black);
}

.button-solid .spinner {
  --color: var(--ion-color-contrast, var(--ion-text-color, black));
}
</style>

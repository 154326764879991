import chroma from "chroma-js";

import { hashDataToHexString } from '@/utils/algorithms';

//Create a padded hex string up to 255 from the given number
export function toHex(number){
  return ('0' + number.toString(16)).slice(-2);
}

const COLOR_INCREMENTS = 128;
const INC_DEC_STEPS = 6; //Steps inside the color function (3xINC, 3xDEC)

const GENERATE_COLOR_COUNT = 12;
const UNIQUE_COLORS = chroma.scale('Paired').colors(GENERATE_COLOR_COUNT, null).filter((value) => {
  //Only use colors with high enough contrast
  return chroma.contrast(value, 'black') >= 1.5 && chroma.contrast(value, 'white') >= 1.5;
});
const GENERATED_COLOR_COUNT = UNIQUE_COLORS.length;

export function calculateUniqueColorCount(){
  let incrementCount = Math.ceil(255 / COLOR_INCREMENTS); //How many times can one increment/decrement the color in its 255 space
  return incrementCount * INC_DEC_STEPS;
}

/* Create a color using the hue color wheel
The hue follows the pattern of incrementing the color value to the right then decrementing its own value
One value after the other is processed this way with wrapping to the first one after the last one is reached. */
export function createColor(index){
  let color = [
    255, //red
    0, //green
    0 //blue
  ];

  //Defines the pattern of which one to decrement and increment after another
  let incrementColorOrder = [1, 2, 0];
  let decrementColorOrder = [0, 1, 2];
  let orderStep = 0;

  let incrementStep = true;

  //Each step is one increment or decrement depending on the current state
  for (let i = 0; i < index; i++){
    //First increment the value of the color in the current pattern
    if (incrementStep) {
      color[incrementColorOrder[orderStep]] += COLOR_INCREMENTS;
      if (color[incrementColorOrder[orderStep]] >= 255){ //End of increments is reached, change state to decrement
        color[incrementColorOrder[orderStep]] = 255;
        incrementStep = false;
      }
    } else {//Then decrement the value of the color in the current pattern
      color[decrementColorOrder[orderStep]] -= COLOR_INCREMENTS;
      if (color[decrementColorOrder[orderStep]] <= 0){ //End of decrements is reached, change state to increment again with next pattern
        color[decrementColorOrder[orderStep]] = 0;
        orderStep++;
        if (orderStep >= 3) orderStep = 0;
        incrementStep = true;
      }
    }
  }

  return color;
}

export async function generateUniqueColorFromIdentifierHash(identifier, addHashToString = true){
  const textAsBuffer = new TextEncoder().encode(identifier);
  const hashString = await hashDataToHexString(textAsBuffer);
  const colorString = hashString.substring(0, 6);
  if (colorString != null) return `${addHashToString ? '#' : ''}${colorString}`;
}

export const UNIQUE_HEX_COLOR_STRINGS = UNIQUE_COLORS.map((color) => color.hex());

export function createHexColorString(index){
  return UNIQUE_COLORS[index % GENERATED_COLOR_COUNT].hex();
}

export function createRGBColorString(index){
  let color = UNIQUE_COLORS[index % GENERATED_COLOR_COUNT].rgb();

  let colorString = 'rgb(' + color[0].toString() + ', ' + color[1].toString() + ', ' +  color[2].toString() + ')';

  return colorString;
}

export function hexToHSL(colorString) {
  try {
    let colorArray = chroma(colorString).hsl();

    return {
      h: Number.isNaN(colorArray[0]) ? 1 : colorArray[0],
      s: colorArray[1] | 1,
      l: colorArray[2] | 1,
      a: colorArray[3] | 1
    }
  } catch (error) {
    return {
      h: 1,
      s: 1,
      l: 1,
      a: 1
    }
  }
}

export function hexToHue(colorString) {
  let color = hexToHSL(colorString);
  return `${color.h}`;
}

export function hexToRGB(colorString) {
  try {
    return chroma(colorString).rgb();
  } catch (error) {
    return null;
  }
}

export function hexToRGBComponentString(colorString) {
  let rgbColors = hexToRGB(colorString);
  if (rgbColors == null) return null;
  return rgbColors.join(', ');
}

export function darkenColorHex(colorString){
  try {
    return chroma(colorString).darken().hex();
  } catch (error) {
    return null;
  }
}

export function brightenColorHex(colorString){
  try {
    return chroma(colorString).brighten().hex();
  } catch (error) {
    return null;
  }
}
import _ from 'lodash';

import { removeNonAlphaNumCharactersNormalize } from '@/utils/algorithms';

export function getAdditionalInfoArray(animal) {
  if (animal != null) {
    let additionalInfo = [];
    if (animal.unique_identifier != null) additionalInfo.push(`# ${animal.unique_identifier}`);
    if (animal.personal_data != null) {
      //Take the first non-null value, if any
      if (animal.personal_data.name != null) additionalInfo.push(` \uf007   ${animal.personal_data.name}`);
      if (animal.personal_data.address != null) additionalInfo.push(` \uf2bb  ${animal.personal_data.address}`);
      if (animal.personal_data.phone != null) additionalInfo.push(` \uf095  ${animal.personal_data.phone}`);
    }
    if (additionalInfo.length > 0) {
      //Replace all spaces in the strings to non-breakable ones to keep the informaion in one line
      return _.map(additionalInfo, (infoString) => infoString.replaceAll(' ', NON_BREAKABLE_SPACE));
    }
  }
  return [];
}

//Use shorter keys in store to reduce storage needs.
//Everything in one index, because flags take less storage than IDs in separate indizes.
//Only saved what is needed. Flags just don't need to be null to be considered true! Usually 1 indicating true!
export const STATUS_METADATA_SHORT_KEY_MAPPING = {
  'timestamp': 't',
  'created_at': 'g',
  'updated_at': 'm',
  'horse': 'a'
}

const NON_BREAKABLE_SPACE = '\xA0';

export function getAdditionalText(animal) {
  if (animal != null) {
    //Get info and join it into a string
    let additionalInfo = getAdditionalInfoArray(animal);
    if (additionalInfo != null && additionalInfo.length > 0) {
      return additionalInfo.join(` |${NON_BREAKABLE_SPACE}`);
    }
  }
  return undefined;
}

/**
 * Returns a comma separated list of strings that can be included in the search. They are already normalized to only contain alphanum characters only!
 */
export function getSearchMetadata(animal, joinToString = true) { //TODO Search for horses and any personal data, including race etc. - Maybe even status! And localized?
  if (animal != null) {
    let metadata = [];
    if (animal.unique_identifier != null) metadata.push(removeNonAlphaNumCharactersNormalize(animal.unique_identifier));
    if (animal.personal_data != null) {
      if (animal.personal_data.name != null) metadata.push(removeNonAlphaNumCharactersNormalize(animal.personal_data.name));
    }
    
    if (metadata.length > 0) {
      if (joinToString) return metadata.join(',');
      else return metadata;
    }
  }
  return undefined;
}

/**
 * Maps the locations array to an object with order
 */
export function mapLocationsWithOrder(locations) {
  let locationsObject = {};

  for (let index = 0; index < locations.length; index++) {
    locationsObject[locations[index].descriptor] = {
      order: index,
      spots: _.map(locations[index].spots, 'descriptor')
    }
  }

  return locationsObject;
}

export function getLocation(animal, unassignedPlaceholder, availableLocationsObject) {
  //Treat unassigned as always in the end
  let location = {
    location: {
      text: unassignedPlaceholder,
      order: -1
    },
    spot: {
      text: null,
      order: -1
    }
  };

  //Check if location exists
  if (animal != null && availableLocationsObject != null) {
    if (animal.location != null && animal.location in availableLocationsObject) {
      //Check if spot exists and set the actual ones instead of the placeholder, if both exist
      if (animal.spot != null && Array.isArray(availableLocationsObject[animal.location].spots)) {
        let spotIndex = availableLocationsObject[animal.location].spots.indexOf(animal.spot);
        if (spotIndex >= 0) {
          location.location.text = animal.location;
          location.location.order = availableLocationsObject[animal.location].order;
          
          location.spot.text = animal.spot;
          location.spot.order = spotIndex;
        }
      }
    }
  }

  return location;
}

export function getLabelCurrentLocaleOrCapitalizeFunction(locale) {
  return function(object, descriptor) {
    let label;
    
    if (object != null) label = _.get(object, ['label', locale], undefined);
    //If translated label is null or empty capitalize descriptor
    if (label == null || label.length <= 0) label = _.startCase(descriptor);

    return label;
  }
}
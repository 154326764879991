<template>
  <ion-page>
    <ion-header>
      <MainToolbar :isSimple="simpleToolbar" :title="i18n.$t('track-infect.map.title')" />
      <ion-toolbar id="select-toolbar">
        <ion-label slot="start">
          <b>Infektionskrankheit:</b>
        </ion-label>
        <ion-item id="disease-select-container">
          <ion-select id="disease-select" interface="popover" value="ehv" placeholder="PLACEHOLDER">
            <ion-select-option value="ehv">EHV-1</ion-select-option>
            <ion-select-option value="druse">Druse</ion-select-option>
            <ion-select-option value="influenza">Influenza</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-toolbar>
    </ion-header>
    <ion-content :class="isListExpanded ? 'expanded-list' : ''" :fullscreen="true" scrollY="false">
      <div class="inner-content">
        <div class=map>
          <HereMap ref="mapInstance" :enableSearch="true" :icons="icons" :iconSize="iconSize" @selected-marker-change="selectedLocation = $event"></HereMap>
        </div>
        
        <ion-card>
          <ion-button id="expand-list-button" expand="block" fill="outline" color="secondary" @click="toggleList()">
            <ion-icon slot="icon-only" :icon="chevronUp"></ion-icon>
          </ion-button>
          <div>
            <ion-content>
              <ion-list class="post-list"> <!-- TODO Aktuellsten Post hervorheben, alle anderen darunter (erst aufklappen?) -->
                <ion-list-header lines="full">
                  <ion-label id="location-header" v-if="selectedLocation !== null">
                    {{ (selectedLocation !== null && selectedLocation.description) ? selectedLocation.description : '' }}
                  </ion-label>
                  <ion-label id="location-header" v-else>
                    Standort in Karte wählen!
                  </ion-label>
                </ion-list-header>
                <ion-item v-for="post in sortedPostsByDateDesc" :key="post.id">
                  <div class="post">
                    <div class="post-title">
                      <ion-icon size="large" :icon="getStatusIcon(post.status)"></ion-icon>
                      <ion-label>{{ getDateTimeString(post.timestamp, i18n.locale) }}</ion-label>
                    </div>
                    <div class="post-detail-container">
                      <ul>
                        <li v-for="detail in post.details" :key="detail.description">{{ detail.description }}: <span :class="detail.count > 0 ? 'highlight-count' : ''">{{detail.count}}</span></li>
                      </ul>
                    </div>
                  </div>
                </ion-item>
              </ion-list>
            </ion-content>
          </div>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonContent, IonCard, IonButton, IonIcon, IonList, IonItem, IonLabel, IonToolbar, IonSelect, IonSelectOption, IonListHeader } from '@ionic/vue';
import { onMounted, ref, watch, computed } from 'vue';

import ampelRed from '@/assets/ampel/ampel_rot.svg';
import ampelOrange from '@/assets/ampel/ampel_orange.svg';
import ampelGreen from '@/assets/ampel/ampel_grün.svg';
import ampelGrey from '@/assets/ampel/ampel_grau.svg';

import ampelPinRed from '@/assets/ampel/ampel_pin_rot.svg';
import ampelPinOrange from '@/assets/ampel/ampel_pin_orange.svg';
import ampelPinGreen from '@/assets/ampel/ampel_pin_grün.svg';
import ampelPinGrey from '@/assets/ampel/ampel_pin_grau.svg';

import { chevronUp } from 'ionicons/icons';

import MainToolbar from '@/components/MainToolbar.vue';
import HereMap from '@/components/HereMap.vue';

import { useI18n } from "@/utils/i18n";

export default  {
  name: 'InfectionMap',
  components: { IonHeader, IonContent, IonPage, IonCard, IonButton, IonIcon, IonList, IonItem, IonLabel, MainToolbar, IonToolbar, IonSelect, IonSelectOption, IonListHeader, HereMap },
  props: {
    simpleToolbar: Boolean
  },
  setup() {
    const i18n = useI18n();

    const availableLocations = ref([]); //TODO These are the locations on the map. Load them dynamically based on the visible map viewport. Or store them in a database on here.
//TODO Viele Marker Eng beieinander clustern!!
    const mapInstance = ref(null);

    const selectedLocation = ref(null);

    const selectedPostsList = ref([]); 

    const isListExpanded = ref(false);

    const icons = {
      'ampel_pin_green': ampelPinGreen,
      'ampel_pin_orange': ampelPinOrange,
      'ampel_pin_red': ampelPinRed,
      'ampel_pin_grey': ampelPinGrey,
    };

    const iconSize = {w: 25, h: 63.145}; //TODO Calculate inside HereMap based on zoom given a fixed aspect ratio!

    watch(selectedLocation, (newLocation) => {
      console.log(newLocation);
      selectedPostsList.value = [ //TODO Effizienter speichern und übetragen. Text separat!
        {id: 1, timestamp: 1610123264, status: 2, horse_count: 9, details: [{description: "Neurologische Symptome", count: 0}, {description: "Fieber ohne neurologische Symptome", count: 0}, {description: "positiv getestet", count: 0}, {description: "negativ getestet", count: 0}]}, //TODO Vielleicht immer nur die Anzeigen die nicht null sind?
        {id: 2, timestamp: 1612096226, status: 3, horse_count: 9, details: [{description: "Neurologische Symptome", count: 0}, {description: "Fieber ohne neurologische Symptome", count: 2}, {description: "positiv getestet", count: 0}, {description: "negativ getestet", count: 0}]},
        {id: 3, timestamp: 1612966231, status: 3, horse_count: 9, details: [{description: "Neurologische Symptome", count: 1}, {description: "Fieber ohne neurologische Symptome", count: 2}, {description: "positiv getestet", count: 3}, {description: "negativ getestet", count: 0}]},
        {id: 4, timestamp: 1613489004, status: 2, horse_count: 9, details: [{description: "Neurologische Symptome", count: 0}, {description: "Fieber ohne neurologische Symptome", count: 0}, {description: "positiv getestet", count: 0}, {description: "negativ getestet", count: 0}]},
        {id: 5, timestamp: 1615120449, status: 1, horse_count: 9, details: [{description: "Neurologische Symptome", count: 0}, {description: "Fieber ohne neurologische Symptome", count: 0}, {description: "positiv getestet", count: 0}, {description: "negativ getestet", count: 9}]}
      ]; //TODO Implement API call. Save time as UTC. Check which time format is used and if it is correctly displayed in different languages / locations!
      isListExpanded.value = true; 
    });

    const sortedPostsByDateDesc = computed(() => {
      return selectedPostsList.value.slice().sort((a, b) => {
        return b.timestamp - a.timestamp
      })
    }); //Return a sorted shallow copy (using slice)

    const getStatusIcon = function(status, usePin = false){
      switch (status) {
        case 1:
          return usePin ? ampelPinGreen : ampelGreen;
        case 2:
          return usePin ? ampelPinOrange : ampelOrange;
        case 3:
          return usePin ? ampelPinRed : ampelRed;
        default:
          return usePin ? ampelPinGrey : ampelGrey;
      }
    }

    const getStatusIconKey = function(status, usePin = false){
      switch (status) {
        case 1:
          return usePin ? 'ampel_pin_green' : 'ampel_green';
        case 2:
          return usePin ? 'ampel_pin_orange' : 'ampel_orange';
        case 3:
          return usePin ? 'ampel_pin_red' : 'ampel_red';
        default:
          return usePin ? 'ampel_pin_grey' : 'ampel_grey';
      }
    }

    

    const toggleList = function(){
      isListExpanded.value = !isListExpanded.value;
    }

    const dateFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit', second: '2-digit' };

    /* Accepts a unix timestamp in UTC time (seconds) and returns the local date as a string, Second argument is needed so it gets called on change */
    const getDateTimeString = function(timestamp, locale)  {
      let date = new Date(timestamp*1000);
      return date.toLocaleDateString(locale.value, dateFormatOptions);
    }

    const mapLocationToMarker = function(location){
      let iconKey = getStatusIconKey(location.status, true);
      let marker = {
        id: location.id,
        description: location.description,
        coords: {lat: location.lat, lng: location.lng},
        iconKey: iconKey
      }
      return marker;
    }

    watch(
      availableLocations,
      (newLocations) => {
        mapInstance.value.placeMarkers(newLocations.map(mapLocationToMarker));
      },
      {deep: true}
    );

    onMounted(() => { //TODO Je nach Viewport laden
      setTimeout(() => {
        
        availableLocations.value = [
          {id: 1, description: "Stall 1", lat: 48.244727, lng: 11.577251, status: 0},
          {id: 2, description: "Stall 2", lat: 48.076300, lng: 11.461561, status: 1},
          {id: 3, description: "Stall 3", lat: 48.045382, lng: 11.576735, status: 2},
          {id: 4, description: "Stall 4", lat: 48.135193, lng: 11.572650, status: 3}
        ];
        
      }, 5000); //FIXME Timeout als Workaround, da es nicht vor dem Laden der Map passieren darf!
    });

    return { i18n, mapInstance, availableLocations, selectedLocation, selectedPostsList, sortedPostsByDateDesc, chevronUp, isListExpanded, toggleList, getDateTimeString, getStatusIcon, icons, iconSize };
  }
}
</script>

<style scoped>
/* Enable flex-grow and shrink on whole page */
.inner-content {
  display: flex;
  flex-flow: column;
  height: 100%;
}

/* Expand map or set to 40% height */
.map{
  height: 40vh;
  flex-grow: 1;
  transition: flex-grow 0.5s ease-in-out;
}
.expanded-list .map{
  flex-grow: 0;
}

/* Set card with list to height of button to hide the list, Allow flex-grow of list inside */
ion-card {
  flex-shrink: 1;
  transition: flex-grow 0.5s ease-in-out;
  height: 36px;
  flex-grow: 0;
  display: flex;
  flex-flow: column;
  margin-top: 8px;
  margin-bottom: 8px;
}
.expanded-list ion-card {
  flex-grow: 1;
}

/* Grow list inside to fill the container fully */
ion-card > div {
  flex-grow: 1;
}

/* Rotate button for expanded list */
#expand-list-button ion-icon {
  transition: transform 0.5s ease-in-out;
}
.expanded-list #expand-list-button ion-icon {
  transform: rotate(180deg);
}

#expand-list-button {
  margin: 0px;
  height: 36px;
}

ion-list {
  min-height: 100%;
}

#select-toolbar {
  padding-left: 24px;
  padding-right: 24px;
}

.post-list > ion-item:first-of-type > .post {
  border: 5px var(--ion-color-tertiary) solid;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 60px;
}

.post-list > ion-item:first-of-type > .post::before {
  text-transform: uppercase;
  content: "Aktueller Status";
  padding: 10px;
  color: var(--ion-color-tertiary);
  font-weight: bold;
}

.post {
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  width: 100%;
}

.post-title {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-top: 10px;
}

.post-detail-container {
  padding: 10px;
  box-sizing: border-box;
}

.highlight-count {
  font-weight: bold;
}

#location-header {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
}
</style>

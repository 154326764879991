<template id="extendable-chip">
  <ion-chip 
    :class="chipClass"
    :color="color"
    :outline="outline"
    :title="title"
    ref="instance"
    @click="handleClick"
    :style="`--custom-brightness: ${brightness}`"
    :disabled="!extendOnClick && !extendOnHover && !button">
    
    <slot name="permanent"></slot>
    <div class="extendable-container">
      <div class="extendable-label">
        <slot><ion-label v-if="title != null">{{ title }}</ion-label></slot>
      </div>
      <slot name="extended"></slot>
    </div>
  </ion-chip>
</template>

<script>
import { IonChip, IonLabel } from '@ionic/vue';
import { defineComponent, onMounted, onUnmounted, watch, ref, computed } from 'vue';

export default defineComponent({
  name: 'ExtendableChip',
  components: { IonChip, IonLabel },
  props: {
    'color': String,
    'transparentWhenClosed': {
      type: Boolean,
      default: false
    },
    'title': String,
    'extendToLeft': { //By default it always extends to the right
      type: Boolean,
      default: false
    },
    'extendOnHover': { //Only works, if button is true! Otherwise it can't be interacted with!
      type: Boolean,
      default: false
    },
    'extendOnParentItemHover': {
      type: Boolean,
      default: false
    },
    'extendOnClick': {
      type: Boolean,
      default: false
    },
    'closeOnOutsideClick': {
      type: Boolean,
      default: true
    },
    'enableClosedClick': {
      type: Boolean,
      default: true
    },
    'forwardClick': {
      type: Boolean,
      default: false
    },
    'forwardExtendedClick': {
      type: Boolean,
      default: false
    },
    'collapseTimeout': { //Close the extended chip after this timeout if opened by prop or click
      type: Number,
      default: 0
    },
    'extendedClickDebounce': {
      type: Number,
      default: 300
    },
    'extended': {
      type: Boolean,
      default: false
    },
    'button': {
      type: Boolean,
      default: false
    },
    'outline': {
      type: Boolean,
      default: false
    },
    'textIcon': {
      type: Boolean,
      default: false
    },
    'brightness': {
      type: Number,
      default: 0.85
    }
  },
  emits: ['extendedClick', 'update:extended', 'closedClick'],
  setup(props, {emit}) {
    const CHIP_CLASS = 'extendable-chip-instance';

    const instance = ref(null);

    const chipClass = computed(() => {
      return [
        CHIP_CLASS,
        props.extendToLeft ? 'reverse-layout' : undefined,
        props.extendOnHover ? 'extend-on-hover' : undefined,
        props.extendOnParentItemHover ? 'extend-on-parent-item-hover' : undefined,
        props.transparentWhenClosed ? 'transparent-when-closed' : 'opaque-when-closed',
        extendedStatus.value ? 'extended' : undefined,
        props.button ? 'button' : undefined,
        props.textIcon ? 'text-icons' : 'non-text-icons'
      ]
    })

    const extendedStatus = ref(false);

    const closeTimeoutID = ref(null);

    //Watch the prop to update the status - always updates to also set new timeouts
    watch(() => props.extended, (newStatus) => {
      extendedStatus.value = newStatus;
    });

    const closeAfterTimeout = function() {
      extendedStatus.value = false;
      //Remove timeout ID again
      closeTimeoutID.value = null;
    }

    //Watch changes in the status to apply a timeout for automatic closing. Only one timeout is present at a time
    watch(extendedStatus, (newStatus, oldStatus) => {
      //Clear old timeouts, to only react to the newest one or none, if no one is set
      if (closeTimeoutID.value != null) clearTimeout(closeTimeoutID.value);

      //If it is going to get extended and we have a valid timeout, set that timeout to close it again
      if (props.collapseTimeout != null && props.collapseTimeout > 0 && !oldStatus && newStatus === true) {
        closeTimeoutID.value = setTimeout(closeAfterTimeout, props.collapseTimeout);
      }

      emit('update:extended', newStatus);
    });

    //Use timeout to debounce the second click and delay it to prevent accidental deletes e.g.
    const extendedClickEnabled = ref(false);
    const extendedClickTimeoutID = ref(null);

    watch(extendedStatus, (newStatus) => {
      //First clear existing timeouts and reset everything
      if (extendedClickTimeoutID.value != null) clearTimeout(extendedClickTimeoutID.value);
      extendedClickEnabled.value = false;
      //If it is open, start the timeout to enable the second extended click
      if (newStatus === true) {
        extendedClickTimeoutID.value = setTimeout(() => (extendedClickEnabled.value = true), props.extendedClickDebounce);
      }
    });

    const handleClick = function(event) {
      //Only emit and forward events if it is a button
      if (props.button) {
        //Get the first inner element inside the permanent slotted element to click, if forwarding is enabled
        let innerButton;
        if (instance.value != null && instance.value.$el != null) {
          innerButton = instance.value.$el.querySelector(':scope > *:not(.extendable-container)');
        }

        //If it is extended emit and forward, if set
        if (extendedStatus.value) {
          //Check if second click is already enabled (debounce)
          if (extendedClickEnabled.value) {
            emit('extendedClick');
            if (props.forwardExtendedClick) innerButton.click(event);
          }
        } else { //If it is closed, forward normal click, if enabled
          if (props.enableClosedClick) {
            emit('closedClick');
            if (props.forwardClick) innerButton.click(event);
          }
        }
      }

      //If we extend on click, open it and don't emit anything
      if (props.extendOnClick && !(extendedStatus.value)) {
        extendedStatus.value = true;
      }
    }

    const handleOutsideClick = function(event) {    
      //Detect clicks outside of the chip element to close it again - Only if supported and not opened by hovering
      if (props.closeOnOutsideClick && !props.extendOnHover && !props.extendOnParentItemHover && instance.value != null && instance.value.$el != null && event.composedPath) { //Check support of methods
        //Check if in the clicked path the chip is included - If not it is outside!
        if(!(event.composedPath().includes(instance.value.$el))) {
          extendedStatus.value = false;
        }
      }
    }

    onMounted(() => {
      //Set initial extended status from props and add event listener for clicks ouitside the chip
      extendedStatus.value = props.extended;
      document.addEventListener('click', handleOutsideClick);
    });

    onUnmounted(() => {
      document.removeEventListener('click', handleOutsideClick);
    });

    return { instance, extendedStatus, chipClass, handleClick };
  }
});
</script>

<style scoped>
.extendable-chip-instance {
  /* Size of the whole chip. Can be any unit! */
  --custom-size: 2em;

  /* Ratio for icons and the extendable label (text) */
  --icon-ratio: 0.6;
  --text-font-ratio: 0.5;

  /* Adjustable transition speed for extending */
  --custom-transition-time: 0.1s;

  /* Calculate all sizes relatively with em given the base font size here */
  font-size: var(--custom-size, 2em)!important;

  --custom-font-color: rgba(var(--ion-color-base-rgb), var(--background-opacity, 1));

  height: 1em;
  min-width: 1em;
  border-radius: 1em;

  --custom-border-width: 2px;

  filter: grayscale(calc((1 - var(--custom-brightness, 1)) * 100%));

  border-style: solid;
  border-width: var(--custom-border-width);
  border-color: rgba(var(--ion-color-base-rgb), var(--background-opacity, 1));

  /* Set padding to the remaining size in the collapsed chip, given the set ratio of the icons. Centers the icons and keeps them steady! Works best with start justification */
  justify-content: flex-start;
  --icon-padding: calc(((1 - var(--icon-ratio, 0.7)) * 0.5 * 1em) - var(--custom-border-width));
  padding: 0px var(--icon-padding);

  /* Set background to be slightly transparent by default */
  opacity: 1;

  /* Default to opening to the right unless overwritten */
  direction: ltr;
}

/* Set background and font color for solid button, not outline */
.extendable-chip-instance:not(.chip-outline) {
  background: rgba(var(--ion-color-base-rgb), var(--background-opacity, 1));
  --custom-font-color: var(--ion-color-contrast, #fff);
}

/* Prevent text selection in chip */
.extendable-chip-instance * {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Set a default cursor if it is not a button */
.extendable-chip-instance:not(.button) {
  cursor: default;
}

/* When reversed, use different layout direction */
.extendable-chip-instance.reverse-layout {
  direction: rtl;
}

/* Set color of all inserted items to be white */
.opaque-when-closed :slotted(*), .extended :slotted(*), .extendable-chip-instance ion-label, .extendable-chip-instance ion-icon {
  color: var(--custom-font-color);
}

/* Set the font size of the label according to the text font ratio */
.extendable-label > :slotted(*) {
  font-size: calc(var(--text-font-ratio, 0.7) * 1em);
  direction: initial;
}

/* Set all icon sizes and paddings */
.non-text-icons > :slotted(*), .non-text-icons > .extendable-container > :slotted(*) {
  /* Force set base font size above */
  font-size: inherit!important;
  /* Disable margins of icons */
  margin-inline: 0px;
  margin: 0px;
  /* Set size of icon given the set ratio as a square */
  height: calc(var(--icon-ratio, 0.7) * 1em);
  width: calc(var(--icon-ratio, 0.7) * 1em);
}

/* Set transitions and initial values for the extendable container. margin-left (margin-right when reversed) makes the text appear to extend out of the icon. */
.extendable-chip-instance .extendable-container {
  overflow: hidden;
  max-width: 0px;
  margin-inline-start: 0px;
  transition: max-width var(--custom-transition-time) ease-in-out, margin-inline-start var(--custom-transition-time) ease-in-out;
}

/* Animate movement with max-width. Extended width parameter can be adjusted according to the needs. The smaller, the smoother the animation. */
ion-item:hover .extendable-chip-instance.extend-on-parent-item-hover .extendable-container,
.extendable-chip-instance.extend-on-hover:hover .extendable-container,
.extendable-chip-instance.extended .extendable-container {
  max-width: var(--extended-max-width, 250px);
}

/* Set margins around the extendable container */
ion-item:hover .extendable-chip-instance.extend-on-parent-item-hover .extendable-container,
.extendable-chip-instance.extend-on-hover:hover .extendable-container,
.extendable-chip-instance.extended .extendable-container {
  margin-inline-start: calc(var(--icon-padding) * 1.25);
  margin-inline-end: calc(var(--icon-padding) * 1.25);
}

/* Place all children in the container centered and allow relative sizing within by setting container height to 100% */
.extendable-container, .extendable-label {
  display: flex;
  align-items: center;
  height: 100%;
}

.extendable-label ion-label {
  white-space: nowrap;
}

/* Set the same spacing as for the container itself to all children after the first one for uniform spacing */
.extendable-container :not(:first-child) {
  margin-inline-start: calc(var(--icon-padding) * 1.25);
}

.transparent-when-closed:not(.extended) {
  background: transparent;
  border-color: transparent;
  --custom-font-color: var(--ion-text-color);
}
</style>

<template>
  <ion-page>
    <ion-tabs>
      <ion-tab-bar slot="bottom" v-if="!hideTabNavigation">
        <ion-tab-button ref="animalsTab" tab="animals" href="/health/animals">
          <AnimatedLogo stopPattern="cross" class="logo"></AnimatedLogo>
          <ion-label>{{ i18n.$t('analysis.title.subjects') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button class="tracking-tab" tab="analysis" href="/health/analysis">
          <AnimatedLogo stopPattern="small" class="logo"></AnimatedLogo>
          <ion-label>{{ i18n.$t('tracking.title') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="encyclopedia" href="/health/encyclopedia">
          <AnimatedLogo stopPattern="offset" class="logo"></AnimatedLogo>
          <ion-label>{{ i18n.$t('encyclopedia.title') }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonPage } from '@ionic/vue';

import { useI18n } from "@/utils/i18n";
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import AnimatedLogo from '@/components/AnimatedLogo.vue';

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonPage, AnimatedLogo },
  setup() {
    const i18n = useI18n();

    const animalsTab = ref(null);

    const smallScreenQuery = window.matchMedia("(max-width: 992px)");

    const router = useRouter();
    const route = useRoute();

    //Holds boolean if current route should hide tab navigation
    const hideTabNavigation = computed(() => {
      if ('hideTabNavigation' in route.meta) {
       return route.meta.hideTabNavigation
      }

      return false;
    });

    smallScreenQuery.addEventListener('change', (query) => { //Navigate away when screen size changes to big one and we are on the removed tab
      if (!query.matches && router.currentRoute.value.name === 'tracking') {
        animalsTab.value.$el.click(); //Use workaround as a click on the tab, since ion-tabs does not expose method select as stated
        //$el has to be used with ionic to get the element itself
      }
    });

    return {
      i18n,
      hideTabNavigation,
      animalsTab,
      router
    }
  }
}
</script>

<style scoped>
  ion-tab-button {
    --color-selected: var(--ion-color-primary-text);
  }

  @media (min-width: 992px) {
    .tracking-tab {
      display: none;
    }
  }

  .logo {
    font-size: 25px;
    margin-bottom: 5px;
  }

  .ios ion-label {
    font-size: 1.2em;
  }

  .ios .logo {
    font-size: 20px;
    margin-bottom: 2px;
  }
</style>

function sendMessageToServiceWorkerOnBecomingActive(nonActiveWorker, message, ports) {
  // When the new service worker becomes active
  nonActiveWorker.onstatechange = function() {
    if (nonActiveWorker.state === 'activated') {
      // Send message to the newly activated service worker
      nonActiveWorker.postMessage(message, ports);
    }
  };
}

async function sendMessageToServiceWorkers(message, ports, sendToActive = true, sendToWaiting = false) {
  if (navigator != null && navigator.serviceWorker != null) {
    // Get all service worker registrations
    return navigator.serviceWorker.getRegistrations().then(registrations => {
      // Iterate through each registration
      registrations.forEach(registration => {
        // Access all service worker states, send to active one and set all other states to wait for becoming active and then send
        if (sendToActive) {
          if (registration.active) {
            // Send message to the active service worker
            registration.active.postMessage(message, ports);
          }

          if (registration.installing) {
            // Send message to the installing service worker once it becomes active
            sendMessageToServiceWorkerOnBecomingActive(registration.installing, message, ports);
          }

          if (registration.waiting) {
            // Send message to the waiting service worker once it becomes active
            sendMessageToServiceWorkerOnBecomingActive(registration.waiting, message, ports);
          }

          // Listen for new service workers being installed
          registration.addEventListener('updatefound', () => {
            sendMessageToServiceWorkerOnBecomingActive(registration.installing, message, ports);
          });
        }

        if (sendToWaiting) {
          if (registration.waiting) {
            // Send message to the waiting service worker
            registration.waiting.postMessage(message, ports);
          }
        }
      }); 
    });
  }
}

export async function sendMessageToActiveServiceWorkers(message, ports) {
  return sendMessageToServiceWorkers(message, ports, true, false);
}

export async function sendMessageToWaitingServiceWorkers(message, ports) {
  return sendMessageToServiceWorkers(message, ports, false, true);
}

export async function sendMessageToAllServiceWorkers(message, ports) {
  return sendMessageToServiceWorkers(message, ports, true, true);
}
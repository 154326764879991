<template id="edit-modal">
  <form ref="formInstance" @submit="$event.preventDefault()"> <!--TODO Prevent in all forms navigation, if something is modified! In other components too! Also check that modals are closed on back button. No navigation! Use hooks to check and set confirmReturn and open it accordingly in MainToolbar! Maybe make a universal close button for all modals with that functionality implemented too! -->
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <ion-title id="title">
            {{ (!isEditingExisting) ? createTitle : ((viewModeStatus) ? viewTitle : editTitle ) }}
          </ion-title>
          <ion-buttons class="modal-confirmation-buttons" slot="end" v-if="!cancelForm">
            <ExtendableChip
              class="cancel-button"
              :color="isModified ? 'danger' : 'dark'"
              :title="((isModified) ? i18n.$t('default_interaction.discard_question') : undefined)"
              :extendOnClick="isModified"
              @extendedClick="closeModal()"
              :enableClosedClick="!(isModified)"
              @closedClick="closeModal()"
              extendToLeft
              button
              textIcon
              :collapseTimeout="5000"
              >
              <template v-slot:permanent>
                <ion-icon :icon="close" :alt="i18n.$t('default_interaction.discard_question')"></ion-icon>
              </template>
            </ExtendableChip>

            <ion-button
              class="save-button"
              v-if="!viewModeStatus"
              @click="save()"
              fill="solid"
              :disabled="!isModified"
              shape="round"
              type="submit"
              color="primary" >
              <ion-icon slot="icon-only" :icon="(isEditingExisting) ? saveOutline : add"></ion-icon>
            </ion-button>
            <ion-button
              class="edit-button"
              v-else
              @click="startEditing()" 
              fill="outline"
              shape="round"
              color="primary" >
              <ion-icon slot="icon-only" :icon="pencil"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-content v-if="formData != null">
        <ion-card v-for="(category, categoryIndex) of localizedTemplate" :key="categoryIndex">
          <ion-card-header v-if="category.label != null">
            <ion-card-title>
              <template v-if="category.icon != null">
                <font-awesome-icon class="label-icon" v-if="category.iconIsFontAwesome" :icon="category.icon" />
                <ion-icon class="label-icon" v-else :icon="category.icon"></ion-icon>
              </template>
              {{ category.label }}
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-list>
              <template v-for="(field, fieldIndex) of category.fields" :key="fieldIndex" class="field-item">
                <!-- In view mode just show the existing values as a ViewFormItem -->
                <template v-if="viewModeStatus">
                  <!-- Either a single field is used -->
                  <ViewFormItem
                    v-if="field.descriptor != null && !isFieldEmpty(getMappedFormDataValue(category, field.descriptor, field.availableValues))"
                    :display_name="field.label"
                    :type="field.type || 'text'"
                    :value="getMappedFormDataValue(category, field.descriptor, field.availableValues)"
                    :icon="field.icon"
                    :iconIsFontAwesome="field.iconIsFontAwesome" >
                  </ViewFormItem>
                  <!-- Or multiple options of which only the valid ones are shown -->
                  <template v-else>
                    <template v-for="(option, optionIndex) of field.options" :key="optionIndex">
                      <ViewFormItem
                        v-if="!isFieldEmpty(getMappedFormDataValue(category, option.descriptor, option.availableValues))"
                        :display_name="option.label"
                        :type="option.type || 'text'"
                        :value="getMappedFormDataValue(category, option.descriptor, option.availableValues)"
                        :icon="option.icon || field.icon"
                        :iconIsFontAwesome="option.iconIsFontAwesome || field.iconIsFontAwesome" >
                      </ViewFormItem> <!-- TODO Make phone number and address clickable in viewMode -->
                    </template>
                  </template>
                </template>

                <!-- In Edit Mode show all the options as FormItems -->
                <template v-else>
                  <!-- Either a single input is requested -->
                  <FormItem 
                    v-if="field.descriptor != null"
                    :required="field.required"
                    :type="field.type || 'text'"
                    :modelValue="getFormDataValue(category, field.descriptor)"
                    @update:modelValue="(value) => setFormDataValue(category, field.descriptor, value)"
                    @update:modified="(newModifiedStatus) => setFieldModified(category.descriptor, field.descriptor, newModifiedStatus)"
                    :formKey="((category.isRootAttribute) ? '' : `${category.descriptor}.`) + field.descriptor"
                    :display_name="field.label"
                    :available_values="field.availableValues"
                    :allow_custom_values="field.availableValues == null || field.allowCustomValues"
                    :allow_multiple_values="field.allowMultipleValues"
                    :exclude_existing="field.excludedValues"
                    :returnObjectValues="field.isArray"
                    :showAsList="field.isArray"
                    :customReset="field.isArray"
                    :unit="field.unit"
                    returnRawValue
                    lines="full"
                    :icon="field.icon"
                    :iconIsFontAwesome="field.iconIsFontAwesome"
                    :cancelled="cancelForm">
                  </FormItem>
                  <!-- Or multiple options of which only one can be filled out -->
                  <ion-item v-else-if="Array.isArray(field.options)" class="multi-option-field" lines="full">
                    
                    <div slot="start" :class="['reset-button', (isAnotherOptionFilledOut(category, field.options)) ? 'reset-enabled' : undefined]" @click.stop="resetFormDataFieldOptions(category, field.options);" @keydown="handleSpacebar($event, ()=>$event.target.click())">
                      <ion-button fill="clear" color="danger" shape="round">
                        <ion-icon slot="icon-only" :icon="closeCircleOutline"></ion-icon>
                      </ion-button>
                    </div>

                    <div class="multi-options-container">
                      <template v-for="(option, optionIndex) of field.options" :key="optionIndex">
                        <span v-if="optionIndex > 0" class="multi-option-or"></span>
                        <FormItem
                          :required="field.required"
                          :type="option.type || 'text'"
                          :modelValue="getFormDataValue(category, option.descriptor)"
                          @update:modelValue="(value) => setFormDataValue(category, option.descriptor, value)"
                          @update:modified="(newModifiedStatus) => setFieldModified(category.descriptor, option.descriptor, newModifiedStatus)"
                          :disabled="isAnotherOptionFilledOut(category, field.options, option.descriptor)"
                          :formKey="((category.isRootAttribute) ? '' : `${category.descriptor}.`) + option.descriptor"
                          :display_name="option.label"
                          :available_values="option.availableValues"
                          :allow_custom_values="option.availableValues == null || option.allowCustomValues"
                          :allow_multiple_values="option.allowMultipleValues"
                          :exclude_existing="option.excludedValues"
                          :returnObjectValues="option.isArray"
                          :showAsList="option.isArray"
                          :unit="option.unit"
                          :customReset="true"
                          forceStackedLabel
                          returnRawValue
                          disableResetButton
                          lines="none"
                          :style="option.style"
                          :icon="option.icon || field.icon"
                          :iconIsFontAwesome="option.iconIsFontAwesome || field.iconIsFontAwesome"
                          :cancelled="cancelForm">
                        </FormItem>
                      </template>
                    </div>
                  </ion-item>
                </template>
              </template>

              <!-- If it is not in viewMode and for this category additional fiels are allowed, show button to add additional field -->
              <ion-item class="add-field" v-if="!viewModeStatus && category.allowCustomFields" lines="none">
                <ion-button @click="addField(category.descriptor)" fill="outline">
                  {{ i18n.$t('forms.modal.add_field.title') }}
                  <ion-icon slot="end" :icon="add"></ion-icon>
                </ion-button>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>

        <!-- If we are editing an existing one show option to delete -->
        <ion-card v-if="enableDelete && isEditingExisting" class="delete-form">
          <ion-button @click="deleteForm()" color="danger">
            {{ (deleteText != null) ? deleteText : i18n.$t('default_interaction.delete') }}
            <ion-icon slot="end" :icon="trash"></ion-icon>
          </ion-button>
        </ion-card>
      </ion-content>
    </ion-page>
  </form>
</template>

<script>

import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonIcon, IonList, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonItem, modalController, alertController } from '@ionic/vue';
import { defineComponent, computed, ref, onMounted } from 'vue';

import FormItem from '@/components/forms/FormItem.vue';
import ViewFormItem from '@/components/forms/ViewFormItem.vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import ExtendableChip from '@/components/ExtendableChip.vue';

import { isFilesInput } from '@/components/forms/inputs/FilesInput.vue';

import { saveOutline, close, closeCircleOutline, pencil, add, trash } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";

import { handleSpacebar, handleEnter } from '@/utils/interaction';

import _ from 'lodash';

const EditModal = defineComponent({
  name: 'EditModal',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonIcon, IonList, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonItem, FontAwesomeIcon, FormItem, ViewFormItem, ExtendableChip },
  props: {
    modalWillClosePromise: Promise,
    createTitle: String,
    editTitle: String,
    viewTitle: String,
    deleteText: String,
    deleteQuestion: String,
    deleteConfirmation: String,
    deleteWaitTimeSeconds: { //Time to wait until delete button becomes clickable!
      type: Number,
      default: 0
    },
    presetValues: Object,
    existingValues: Object,
    treatPresetAsModified: {
      type: Boolean,
      default: false
    },
    viewMode: {
      type: Boolean,
      default: false
    },
    enableDelete: {
      type: Boolean,
      default: false
    },
    keepEmptyRootAttributes: { //Keep null values in the root of the object to delete them in the API
      type: Boolean,
      default: false
    },
    formTemplate: Object, //Defines the order of the available fields and additional metadata
    automaticLocalizationPrefix: String //If this is given, it uses i18n to translate categories and fields with this prefix, otherwise it uses existing labels
  },
  setup(props) {
    const i18n = useI18n();

    const formInstance = ref(null);

    const viewModeStatus = ref(props.viewMode);

    const cancelForm = ref(false);

    const startEditing = function() {
      viewModeStatus.value = false;
    };

    const getTranslationOrCapitalize = computed(() => {
      return function(translationPath, descriptor) {
        let label = i18n.$t(translationPath);
        //If translated label is null or empty capitalize descriptor
        if (label == null || label.length <= 0) label = _.startCase(descriptor);

        return label;
      }
    });

    //Custom additions that do not need to be localized. Saved as an object with category descriptor as the key and array of fields as a value. Order is given by localizedFormTemplate.
    const customTemplateExtensions = ref({});

    //Localizes the given template from the props. Always an array to preserve order
    const localizedFormTemplate = computed(() => {
      let template = _.cloneDeep(props.formTemplate);

      //If we are not giving a translation prefix, assume that it is already localized
      if (props.automaticLocalizationPrefix == null) return template;

      //Try to find a translation for each descriptor, otherwise capitalize it
      return _.map(template, (category) => {
        return {
          ..._.omit(category, 'fields'),
          fields: _.map(category.fields, (field) => {
            if (field.descriptor != null) {
              //Only translate if no localized label is already provided
              if (field.label == null) field.label = getTranslationOrCapitalize.value(`${props.automaticLocalizationPrefix}.${category.descriptor}.${field.descriptor}`, field.descriptor);
            } else if (Array.isArray(field.options)) {
              //Map all options if a field has mutliple options
              field.options = _.map((field.options), (option) => {
                //Only translate if no localized label is already provided
                if (option.label == null) {
                  let label = '';
                  if (option.descriptor != null) {
                    label = getTranslationOrCapitalize.value(`${props.automaticLocalizationPrefix}.${category.descriptor}.${option.descriptor}`, option.descriptor);
                  }
                  option.label = label;
                }
                return option;
              })
            }

            return field;
          }),
          //Only translate if no localized label is already provided
          label: (category.label || getTranslationOrCapitalize.value(`${props.automaticLocalizationPrefix}.${category.descriptor}.title`, category.descriptor))
        }
      })
    });

    //Merges custom and default entries 
    const localizedTemplate = computed(() => {
      let categoryArray = _.cloneDeep(localizedFormTemplate.value) || [];

      for (let category of categoryArray) {
        if (Array.isArray(category.fields)) {
          //Get all the keys to check if extension key already exists
          let templateFieldAndOptionKeys = createFlatListOfFieldAndOptionKeys(category.fields);

          //Check if extension is allowed and extensions for this category are given
          if (category.allowCustomFields && category.descriptor != null && category.descriptor in customTemplateExtensions.value) {
            //Go through each field and check if it can be added
            for (let templateExtensionField of customTemplateExtensions.value[category.descriptor]) {
              if (templateExtensionField.descriptor != null) {
                //If it is root and the descriptor is the same as a category, skip it - not allowed!
                if (category.isRootAttribute && templateExtensionField.descriptor in localizedCategories.value.withoutRoot) continue;
                //If it already exists in the template as field or option skip it
                if (templateFieldAndOptionKeys.includes(templateExtensionField.descriptor)) continue;
                //Otherwise it is valid and can be added - Set descriptor as label, because no translation exists for custom fields!
                category.fields.push({...templateExtensionField, label: templateExtensionField.descriptor});
              } 
            }
          }
        }
      }

      return categoryArray;
    });

    //Includes all categories as keys in tow objects (with and without root attributes) with their translations, and isRootAttribute flags as values
    const localizedCategories = computed(() => {
      let mappedCategories = _.map(localizedFormTemplate.value, (category) => {        
        return _.pick(category, ['descriptor', 'label', 'isRootAttribute']);
      });

      //Split the array by root and non root categories
      let [rootCategories, nonRootCategories] = _.partition(mappedCategories, (category) => category.isRootAttribute);

      //Map it to an object with descriptor as a key for both root and nonRoot
      return {
        root: _.keyBy(rootCategories, 'descriptor'),
        withRoot: _.keyBy(mappedCategories, 'descriptor'),
        withoutRoot: _.keyBy(nonRootCategories, 'descriptor')
      }
    });

    //Contains the preset and modified data
    const formData = ref(null);

    const isFieldEmpty = function(value) {
      if (value == null) return true;
      //If it is a string check for length
      if (_.isString(value)) return (value.length <= 0);
      //Otherwise non-null value is not empty
      else return false;
    }

    const isAnotherOptionFilledOut = computed(() => {
      return function(category, options, optionDescriptor = null) { //If no optionDescriptor is given, it checks all options
        //Fallback to disable inputs
        if (!Array.isArray(options) || formData.value == null) return true;

        //Check each option besides the given one, if it is occupied or not
        for (let option of options) {
          //Skip invalid and given option
          if (option.descriptor == null || option.descriptor == optionDescriptor) continue;

          //Root attribute
          if (category == null || category.isRootAttribute) {
            if (!isFieldEmpty(formData.value[option.descriptor])) return true;
          }
          //Nested attribute 
          else {
            if (formData.value[category.descriptor] != null && !isFieldEmpty(formData.value[category.descriptor][option.descriptor])) return true;
          }
        }

        return false;
      }
    });

    const getFormDataValue = computed(() => {
      return function(category, field) {
        if (category == null || category.isRootAttribute) {
          return formData.value[field];
        } else {
          return formData.value[category.descriptor][field];
        }
      }
    });

    //Return the FormData value mapped. Uses availableValues if they exist to give display value
    const getMappedFormDataValue = computed(() => {
      return function(category, field, availableValues) {
        let value;
        if (category == null || category.isRootAttribute) {
          value = formData.value[field];
        } else {
          value = formData.value[category.descriptor][field];
        }

        //Search for a mapping in availableValues. 
        if (Array.isArray(availableValues) && value != null) {
          for (let availableValue of availableValues) {
            //If found return, otherwise continue
            if (availableValue != null && availableValue.value == value && availableValue.display != null) {
              return availableValue.display;
            }
          }
        }

        //Return the unmapped value if no mapping occurred before
        return value;
      }
    });

    const setFormDataValue = computed(() => {
      return function(category, field, value) {
        if (category == null || category.isRootAttribute) {
          formData.value[field] = value;
        } else {
          formData.value[category.descriptor][field] = value;
        }
      }
    });

    //Resets all options of a given field
    const resetFormDataFieldOptions = computed(() => {
      return function(category, options) {
        //Go through all options in the template and set all values to null
        if (category != null && Array.isArray(options)) {
          for (let option of options) {
            if (category.isRootAttribute) {
              formData.value[option.descriptor] = null;
            } else {
              formData.value[category.descriptor][option.descriptor] = null;
            }
          }
        }
      }
    });

    const isEditingExisting = computed(() => {
      return (props.existingValues != null);
    });

    //Holds an object with fields that have been modified. If the status changes it is set to tru or false. Fields not in the object are considered unmodified.
    const modifiedFields = ref({});

    const isModified = computed(() => {
      if (modifiedFields.value != null) {
        //If one of the fields is modified, return modified as true
        for (let modifiedState of Object.values(modifiedFields.value)) {
          if (modifiedState === true) return true;
        }
      }

      //If it is requested: If we are not editing any existing objects and any of the fields were included in the preset value, consider as modified from an empty object!
      if (props.treatPresetAsModified && !isEditingExisting.value && props.presetValues != null) {
        for (let [presetKey, presetValue] of Object.entries(props.presetValues)) {
          if (!isFieldEmpty(presetValue) && formData.value != null && !isFieldEmpty(formData.value[presetKey])) {
            return true;
          }
        }
      }

      //Otherwise none of them are modified
      return false;
    });

    const setFieldModified = function(categoryDescriptor, fieldDescriptor, newModifiedStatus) {
      let fieldKey = `${categoryDescriptor} ${fieldDescriptor}`;
      modifiedFields.value[fieldKey] = newModifiedStatus;
    }

    const closeModal = function(){
      modalController.dismiss();
    }

    const removeEmptyValuesFromObject = function(object, recurseLevels = 0, removeEmptyObjects = true, isRoot = true) { //isRoot is true on the first call and then false on all subsequent calls
      let filteredObject = object;
      //If we have recursion, remove all empty values from objects of this level
      if (recurseLevels > 0) {
        //Map all objects inside this object to have all their empty values removed
        filteredObject = _.mapValues(filteredObject, (value) => {
          //If it is a plain object, call the same function again with a lower recurse level
          if (value != null && _.isPlainObject(value)) {
            let newObject = removeEmptyValuesFromObject(value, (recurseLevels - 1), removeEmptyObjects, false);
            //If the newObject is empty, remove it, if requested
            if (removeEmptyObjects && newObject != null && Object.keys(newObject).length <= 0) return null;
            //Otherwise just return this new object with empty values removed
            return newObject;
          }
          //Otherwise just return this value
          return value;
        });
      }

      //Map all the empty fields to null
      let mappedObject = _.mapValues(filteredObject, (value) => isFieldEmpty(value) ? null : value);

      //If we are at the root of the object and we should keep those empty attributes don't filter
      if (isRoot && props.keepEmptyRootAttributes) return mappedObject;

      //Otherwise filter out all null values from this level. Empty objects are set to null already, if requested and will get filtered out too!
      return _.omitBy(mappedObject, (value) => (value == null));
    }

    const save = function(){
      //Validate form with constraints that are set inside the FormItems and show the result with reportValidity (returns result of checkValidity)
      if (formInstance.value.reportValidity()){
        let filteredData = removeEmptyValuesFromObject(formData.value, 1, false);
        let files = {};

        //Only take the values (first level of formData) that are different from existing
        let modifiedData = _.reduce(filteredData, (reducedData, value, key) => {
          //If it is not the same as in existing, add it to the reducedData. If existingValues is null or undefined, always include the value
          if (props.existingValues == null || !(_.isEqual(value, props.existingValues[key]))) {
            reducedData[key] = value;
          }

          //Return object for next iteration
          return reducedData;
        }, {});

        //Modify some values before returning them
        _.forEach(localizedTemplate.value, (category) => {
          _.forEach(category.fields, (field) => {
            if (field != null) {
              let path = [];
              if (!category.isRootAttribute) path.push(category.descriptor);
              path.push(field.descriptor);

              //Compact arrays
              if (field.isArray) {
                _.update(modifiedData, path, (value) => {
                  if (Array.isArray(value)) return _.compact(value);
                  return value;
                })
              } else if (isFilesInput(field.type)) { //Take files into separate object
                let fileObjects = _.get(modifiedData, path, undefined);
                if (fileObjects != null) { //Can be one or multiple files
                  //Create a path string and set in files
                  files[path.join('.')] = fileObjects;
                  _.unset(modifiedData, path);
                }
              }
            }
          });
        });

        modalController.dismiss({
          action: (isEditingExisting.value) ? 'update' : 'create',
          data: modifiedData,
          files
        });
      }
    }

    const deleteForm = function() {
      alertController.create({
        backdropDismiss: false,
        header: (props.deleteQuestion != null) ? props.deleteQuestion : i18n.$t('default_interaction.delete_question'),
        subHeader: props.deleteConfirmation || undefined,
        buttons: [
          {
            text: i18n.$t('default_interaction.cancel'),
            role: 'cancel'
          },
          {
            text: i18n.$t('default_interaction.delete'),
            cssClass: 'delete-edit-modal-confirmation-okay',
            handler: () => {
              //Defer the dismiss with the delete action
              setTimeout(() => modalController.dismiss({
                action: 'delete'
              }), 1);
            },
          }
        ],
      }).then(a => {
        a.present();
        //Set a timeout to enable delete button after certain time with a countdown (each second one timeout)
        for (let remainingSeconds = props.deleteWaitTimeSeconds; remainingSeconds >= 0; remainingSeconds--) {
          setTimeout(() => {
            let deleteConfirmationButton = a.querySelector('.delete-edit-modal-confirmation-okay');
            if (deleteConfirmationButton != null) {
              deleteConfirmationButton.setAttribute('data-wait-text', i18n.$t('default_interaction.wait'));
              deleteConfirmationButton.setAttribute('data-countdown', remainingSeconds);
              //Enable in the last countdown
              if (remainingSeconds <= 0) deleteConfirmationButton.classList.add('delete-enabled');
            }
          }, (props.deleteWaitTimeSeconds - remainingSeconds) * 1000);
        }
      });
    }

    const deleteExisting = function(){
      if (isEditingExisting.value) {
        modalController.dismiss({
          action: 'delete',
          data: props.existingValues
        });
      }
    }

    //Takes an array of fields with potentially containing options and returns a flat array of keys of fields and options in those fields
    const createFlatListOfFieldAndOptionKeys = function(templateFields) {
      if (templateFields == null) return [];

      //Map to descriptors of fields and array of descriptors if multiple options are given
      let templateFieldAndOptionKeys = _.map(templateFields, (field) => {
        //Return descriptor if single field
        if (field.descriptor != null) return field.descriptor;
        //If multiple options are present, give back array of valid options descriptors
        else if (field.options != null) {
          let optionDescriptors = _.map(field.options, 'descriptor');
          return _.filter(optionDescriptors, (descriptor) => descriptor != null);
        }

        return null;
      });

      //Filter out all invalid keys
      templateFieldAndOptionKeys = _.filter(templateFieldAndOptionKeys, (descriptor) => descriptor != null);
      //Flatten the array to just have all keys, not arrays of keys inside this array
      return _.flatten(templateFieldAndOptionKeys);
    }

    const createObjectOfValidFields = function(templateFields, categoryKeys, extendTemplate = false, isRoot = false, ...sourceObjects) {
      if (!Array.isArray(templateFields)) return {};

      let extendedKeys = [];

      let templateFieldAndOptionKeys = createFlatListOfFieldAndOptionKeys(templateFields);

      let filteredObjects = _.map(sourceObjects, (sourceObject) => {
        if (sourceObject == null) return {}; //Invalid objects are skipped on assigning, hence empty
        let mappedObject = _.mapValues(sourceObject, (value, key) => {
          //Do not allow if key is category if it is root!
          if (isRoot && categoryKeys.includes(key)) return undefined;

          let currentTemplateIncludesKey = templateFieldAndOptionKeys.includes(key);

          //If fields not in the template are allowed, always set it
          if (extendTemplate && !isFieldEmpty(value)) {
            //Check if it is in the template, if not add to extended list
            if (!(currentTemplateIncludesKey)) extendedKeys.push(key);
            return value;
          }
          //If it is only restricted to fields in the template, check if it is present in the template
          else if (currentTemplateIncludesKey && !isFieldEmpty(value)) return value;
          
          return undefined;
        });

        //Remove null values from object
        return _.omitBy(mappedObject, _.isNil);
      });

      return {
        extendedKeys,
        validFieldObject: _.assign({}, ...filteredObjects)
      }
    }

    const addFieldsToTemplate = function(fieldArraysByObject) {
      //Merge existing and new object
      customTemplateExtensions.value = _.assignWith(customTemplateExtensions.value, fieldArraysByObject, (targetValue, srcValue) => {
        //Only allow array values
        let newTargetValue = Array.isArray(targetValue) ? targetValue : [];
        let newSrcValue = Array.isArray(srcValue) ? srcValue : [];

        //Add unique values (considered by their descriptor) from src and target to create a merged array
        return _.unionWith(newTargetValue, newSrcValue, (targetField, srcField) => targetField.descriptor == srcField.descriptor);
      });
    }

    //Add a field to the template. Only if it is not a category (if it is a rootAttribute) and it doesn't exist yet.
    const addField = function(categoryDescriptor) {
      const descriptorInputID = 'descriptor-input';
      //Only continue if a valid category was supplied to which it should be added
      if (categoryDescriptor != null) {
        //Ask user for the descriptor of the new field and add it when correctly entered
        let inputPromise = alertController.create({
          backdropDismiss: false,
          header: i18n.$t('forms.modal.add_field.title'),
          subHeader: i18n.$t('forms.modal.add_field.field_name'),
          inputs: [
            {
              name: 'descriptor',
              id: descriptorInputID,
              type: 'text',
              placeholder: i18n.$t('forms.modal.add_field.enter_field_name')
            }
          ],
          buttons: [
            {
              text: i18n.$t('default_interaction.cancel'),
              role: 'cancel'
            },
            {
              text: i18n.$t('forms.modal.add_field.okay'),
              cssClass: 'add-field-okay',
              handler: (data) => {
                //Show validity message, if not filled out
                inputPromise.then(descriptorInput => descriptorInput.reportValidity()); //TODO Check when adding custom fields, that it is not present yet, and if it is root attribute, check for categories too to not overwrite them
                if (data != null && data.descriptor != null && data.descriptor.length) {
                    //For now it only supports adding text fields!
                    //In the future a parameter could give a list of options to choose from that is shown if the list contains more than one option. But also needs to keep in mind, when loading and editing again!
                    let newFieldParameters = { descriptor: data.descriptor };
                    
                    //Add it as an object for the chosen category. Checks are done when creating template from custom and template fields
                    let newFieldObject = {};
                    newFieldObject[categoryDescriptor] = [newFieldParameters];
                    addFieldsToTemplate(newFieldObject);

                    //If successful, close modal
                    return true;
                }
                //If entered incorrectly, modal will not dismiss
                return false;
              },
            }
          ],
        }).then(async (a) => {
          await a.present();

          let descriptorInput = a.querySelector(`#${descriptorInputID}`);
          let addFieldOkayButton = a.querySelector(`.add-field-okay`);
          if (descriptorInput != null) {
            //Set required attribute to show message when not entered
            descriptorInput.required = true;
            //Add handler for enter to confirm modal on enter
            if (addFieldOkayButton != null) descriptorInput.addEventListener('keydown', (event) => handleEnter(event, () => addFieldOkayButton.click()));
            //Defer to focus the input
            setTimeout(() => descriptorInput.focus(), 1);
          }
          return descriptorInput;
        });        
      }
    }

    onMounted(() => {
      //Set behvaiour for closing modal. If no data was supplied, it wasn't saved. Cancel form and clear resources!
      if (props.modalWillClosePromise != null) {
        props.modalWillClosePromise.then((data) => {
          if (data.data == null) cancelForm.value = true;
        });
      }

      //Create object that contains at least all categories for reactive models to work in nested object
      //Also add preset values and existingValues to build the current object
      let mergedFormData = {};

      if (Array.isArray(localizedTemplate.value)) {
        let categoryKeys = Object.keys(localizedCategories.value.withoutRoot);

        let extendedKeysByCategory = {};

        for (let category of localizedTemplate.value) {
          //Default to root values
          //Only allow preset values, if not editing an existing object
          let presetValues = null;
          let existingValues = null;
          if (props.existingValues != null) {
            existingValues = props.existingValues;
          } else {
            presetValues = props.presetValues;
          }

          //If it is not root attribute, use the nested values, if they exist
          if (!category.isRootAttribute) {
            presetValues = (presetValues != null) ? presetValues[category.descriptor] : null;
            existingValues = (existingValues != null) ? existingValues[category.descriptor] : null;
          }

          let processedFormData = createObjectOfValidFields(category.fields, categoryKeys, category.allowCustomFields, category.isRootAttribute, (existingValues || presetValues)); //presetValues and existingValues never can exist both!
          let categoryFormData = processedFormData.validFieldObject;
          
          //Add all extended keys to the temporary object
          if (processedFormData.extendedKeys.length) extendedKeysByCategory[category.descriptor] = _.map(processedFormData.extendedKeys, (key) => {
            return { descriptor: key };
          });
          
          //If we have a root attribute, add them to the root of the new object
          if (category.isRootAttribute) {
            _.assign(mergedFormData, categoryFormData);
          } 
          //If it is a valid category, definitely add it
          else if (category.descriptor != null) {
            mergedFormData[category.descriptor] = categoryFormData;
          }
        }

        addFieldsToTemplate(extendedKeysByCategory);
      }

      formData.value = mergedFormData;
    });

    return {
      i18n,
      formInstance,
      cancelForm,
      localizedTemplate,
      viewModeStatus,
      startEditing,
      isEditingExisting,
      formData,
      isFieldEmpty,
      isAnotherOptionFilledOut,
      getFormDataValue,
      getMappedFormDataValue,
      setFormDataValue,
      resetFormDataFieldOptions,
      closeModal,
      save,
      deleteForm,
      deleteExisting,
      isModified,
      setFieldModified,
      addField,

      handleSpacebar,
      
      saveOutline,
      close,
      closeCircleOutline,
      pencil,
      add,
      trash
    };
  }
});

export async function openEditModal(component, modalProps){
  //Create a promise to signal the view is about to close to clear up resources
  let modalWillClose;
  const modalWillClosePromise = new Promise((resolve) => modalWillClose = resolve);
  if (component != null && modalProps != null && modalProps.formTemplate != null) {
    const modal = await modalController
      .create({
        backdropDismiss: false,
        component,
        componentProps: {
          ...modalProps,
          modalWillClosePromise
        }
      })
    modal.present();
    modal.onWillDismiss().then((data) => modalWillClose(data));
    return modal.onWillDismiss().then((data) => (data != null && data.data != null) ? data.data : {});
  }
}

export default EditModal;
</script>

<style>
.add-field-okay {
  color: var(--ion-color-success-shade)!important;
}

.delete-edit-modal-confirmation-okay, .discard-okay {
  color: var(--ion-color-danger)!important;
}

.delete-edit-modal-confirmation-okay:not(.delete-enabled) {
  pointer-events: none;
  cursor: default;
}

.delete-edit-modal-confirmation-okay:not(.delete-enabled) > span {
  display: none;
}

.delete-edit-modal-confirmation-okay:not(.delete-enabled):after {
  content: attr(data-wait-text) ' (' attr(data-countdown) ')';
  opacity: 0.75;
  color: var(--ion-color-medium);
}

</style>

<style scoped>
.modal-confirmation-buttons > * {
  margin-left: clamp(5px, 1.5vw, 10px);
  margin-right: clamp(5px, 1.5vw, 10px);
}

.modal-confirmation-buttons ion-button {
  height: 32px;
  width: 32px;
  --padding-start: 5px;
  --padding-end: 5px;
  --border-width: 2px;
}

.modal-confirmation-buttons #add-services-button {
  margin-inline-end: 15px;
}

.cancel-button {
  --custom-size: 32px!important;
  margin-block: 0px;
  margin-inline: 10px;
}

.cancel-button ion-icon {
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.ios #title {
  font-size: clamp(.9em, 4vw, 17px);
  font-weight: 600;
}

#title {
  font-size: clamp(.9em, 4vw, 20px);
  font-weight: 500;
  letter-spacing: 0.0125em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

ion-item {
  --background: var(--ion-card-background, #fff);
}

.multi-option-field {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  --inner-padding-end: 0px;
  --padding-start: 0px;
}

.multi-options-container > :deep(ion-item) {
  --inner-padding-end: 0px;
  --inner-padding-start: 0px;
  --padding-start: 0px;
  flex-grow: 0;
  max-width: 40%;
  min-width: 120px;
}

.multi-option-or {
  border-left: 2px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  margin-inline: 20px;
}

.multi-option-field span {
  pointer-events: none;
}

.multi-options-container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  text-align: center;
}

.reset-button {
  margin: 0;
  margin-right: 5px;
  visibility: visible;
  pointer-events: all;
  align-self: center;
}

.reset-button:not(.reset-enabled){
  visibility: hidden;
  pointer-events: none;
}

.reset-button > ion-button {
  height: auto;
  --padding-top: 5px;
  --padding-bottom: 5px;
  --padding-start: 5px;
  --padding-end: 5px;
}

.label-icon {
  margin-inline-end: 10px;
}

.ios ion-card-title {
  font-size: 1.25em;
}

ion-card-content {
  padding-bottom: 5px;
}

.add-field {
  --inner-padding-top: 15px;
  --inner-padding-bottom: 10px;
  --padding-start: 10px;
}

.add-field ion-button {
  height: 2.5em;
  --color: var(--ion-color-success-shade);
  --border-color: var(--ion-color-success-shade);
  text-transform: none;
  font-size: 0.9em;
}

.add-field ion-button:hover {
  opacity: 0.8;
}

.delete-form {
  padding: 5px 5px;
}

.delete-form ion-button {
  margin: 0px;
  width: 100%;
  text-transform: none;
}
</style>
<template id="gallery">
  <ion-page>
    <div class="gallery-controls">
      <ExtendableChip
        ref="deleteButton"
        v-if="allowDelete"
        class="gallery-delete-button"
        color="danger"
        :title="i18n.$t('forms.files.delete_confirmation')"
        v-model:extended="deleteConfirmationOpen"
        extendOnClick
        button
        extendToLeft
        @extendedClick="deleteMediaAtCurrentIndex()"
        :collapseTimeout="5000"
        >
        <template v-slot:permanent>
          <ion-icon :icon="trash" :alt="i18n.$t('forms.files.delete')"></ion-icon>
        </template>
      </ExtendableChip>
 
      <ion-button v-if="allowEdit" class="gallery-edit-button" @click="editMediaAtCurrentIndex()" color="success" fill="clear">
        <ion-icon slot="icon-only" :icon="pencil"></ion-icon>
      </ion-button>

      <ion-button class="gallery-close-button" @click="closeModal()" fill="clear">
        <ion-icon slot="icon-only" :icon="close"></ion-icon>
      </ion-button>
    </div>
    <div :class="['gallery-slides-container', slidesInitialized ? 'initialized' : undefined]">
      <ion-slides ref="slidesRef" class="gallery-slides" v-if="media" :options="slideOpts">
        <ion-slide v-for="(media, mediaIndex) in media" :key="mediaIndex">
          <ZoomableMediaContainer class="swiper-zoom-target gallery-swiper-zoom-target" :media="media" :imposedOpacity="getImposedImageOpacityForIndex(mediaIndex) / 100"></ZoomableMediaContainer>
        </ion-slide>
      </ion-slides>
    </div>
    <div class="gallery-controls-bottom">
      <div>
        <ion-button @click="zoomOut()" fill="clear" class="gallery-zoom-button" :disabled="!zoomPossible.out">
          <font-awesome-icon slot="icon-only" :icon="faMagnifyingGlassMinus" />
        </ion-button>
        <ion-button @click="zoomIn()" fill="clear" class="gallery-zoom-button"  :disabled="!zoomPossible.in">
          <font-awesome-icon slot="icon-only" :icon="faMagnifyingGlassPlus" />
        </ion-button>
      </div>

      <div v-if="imposedImageOpacity != null" class="gallery-opacity-selection">
        <ion-button @click="decreaseOpacity()" class="gallery-contrast-decrease" fill="clear" slot="start" :disabled="imposedImageOpacity <= 0">
          <ion-icon slot="icon-only" :icon="contrast"></ion-icon>
        </ion-button>
        <ion-range min="0" max="100" snaps="false" :value="imposedImageOpacity" @ionChange="(event) => imposedImageOpacity = event.target.value">
        </ion-range>
        <ion-button @click="increaseOpacity()" class="gallery-contrast-increase" fill="clear" slot="end" :disabled="imposedImageOpacity >= 100">
          <ion-icon slot="icon-only" :icon="contrast"></ion-icon>
        </ion-button>
      </div>
    
      <div>
        <ion-button @click="goToPrevious()" fill="clear" :disabled="selectedMediaIndex <= 0">
          <ion-icon slot="icon-only" :icon="chevronBack"></ion-icon>
        </ion-button>
        <span 
          class="page-index"
          :style="(media != null && media[selectedMediaIndex] != null && media[selectedMediaIndex].color != null) ? `color: var(${media[selectedMediaIndex].color}, white)` : undefined">
          {{ selectedMediaIndex + 1 }} / {{ mediaCount }}
          </span>
        <ion-button @click="goToNext()" fill="clear" :disabled="(selectedMediaIndex + 1) >= mediaCount">
          <ion-icon slot="icon-only" :icon="chevronForward"></ion-icon>
        </ion-button>
      </div>
    </div>
  </ion-page> 
</template>

<script>
import { IonPage, IonSlides, IonSlide, IonButton, IonIcon, IonRange, modalController } from '@ionic/vue';
import { computed, defineComponent, onMounted, ref } from 'vue';

import ExtendableChip from '@/components/ExtendableChip.vue';
import ZoomableMediaContainer from '@/components/ZoomableMediaContainer.vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faMagnifyingGlassPlus, faMagnifyingGlassMinus } from '@fortawesome/free-solid-svg-icons';

import { close, trash, chevronBack, chevronForward, pencil, contrast } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";

import { createCollapseAnimationToPoint, createExtendAnimationFromPoint, slowModalFadeOutAnimation } from '@/utils/animations';

import _ from 'lodash';

const MODAL_ID = 'gallery-modal-instance';

const Gallery = defineComponent({
  name: 'Gallery',
  components: { IonPage, IonSlides, IonSlide, IonButton, IonIcon, IonRange, FontAwesomeIcon, ExtendableChip, ZoomableMediaContainer },
  props: {
    'modalReadyPromise': Promise,
    'media': Array, //Expects array of objects with type, mime and blobURL set, color is an optional value that can be set to indicate media status
    'selectedIndex': {
      type: Number,
      default: 0
    },
    'allowDelete': {
      type: Boolean,
      default: false
    },
    'openDeleteConfirmation': {
      type: Boolean,
      default: false
    },
    'deleteCallback': Function,
    'allowEdit': {
      type: Boolean,
      default: false
    },
    'editCallback': Function
  },
  setup(props) {
    const i18n = useI18n();

    const deleteButton = ref(null);

    const slidesRef = ref(null);

    //Open it initially according to the prop and then set it internally to the current state
    const deleteConfirmationOpen = ref(false);

    const zoomValue = ref(1);

    const selectedMediaIndex = ref(props.selectedIndex);

    //Internal list for keeping track of deleted media - Not used for generating slides!
    const currentMedia = ref(_.cloneDeep(props.media));

    const mediaCount = computed(() => currentMedia.value.length);

    const zoomPossible = computed(() => {
      return {
        out: zoomValue.value > 1,
        in: zoomValue.value < slideOpts.value.zoom.maxRatio
      };
    });

    const slideOpts = computed(() => {
      return{
        updateOnWindowResize: true,
        centeredSlides: true,
        zoom: {
          maxRatio: 5
        },
        keyboard: {
          enabled: true
        },
        initialSlide: props.selectedIndex,
        init: false //Swiper is initalized manually, when modal is ready
      };
    });

    //Saves any changed opacity from the default value
    const userSetOpacity = ref({});
    const DEFAULT_OPACITY = 60;

    const getImposedImageOpacityForIndex = computed(() => {
      return function(mediaIndex) {
        if (mediaIndex != null) {
          if (mediaIndex in userSetOpacity.value) return userSetOpacity.value[mediaIndex];
          else if (currentMedia.value[mediaIndex] != null && currentMedia.value[mediaIndex].imposedImage != null) return DEFAULT_OPACITY;
        }
        return undefined;
      }
    });

    const imposedImageOpacity = computed({
      get: function() {
        return getImposedImageOpacityForIndex.value(selectedMediaIndex.value);
      },
      set: function(newOpacity) {
        let opacityValue = newOpacity;
        if (opacityValue < 0) opacityValue = 0;
        if (opacityValue > 100) opacityValue = 100;
        if (selectedMediaIndex.value != null) userSetOpacity.value[selectedMediaIndex.value] = opacityValue;
      }
    });

    const OPACITY_STEP = 10;

    const increaseOpacity = function() {
      imposedImageOpacity.value = imposedImageOpacity.value + OPACITY_STEP;
    }

    const decreaseOpacity = function() {
      imposedImageOpacity.value = imposedImageOpacity.value - OPACITY_STEP;
    }

    const closeModal = function(){
      modalController.dismiss(undefined, undefined, MODAL_ID);
    }

    const zoomOut = async function(){
      try {
        let swiper = await slidesRef.value.$el.getSwiper();
        if (swiper && swiper.zoom) {
          swiper.zoom.out();
        }
      } catch {
        return;
      }
    }

    const zoomIn = async function(){
      try {
        let swiper = await slidesRef.value.$el.getSwiper();
        if (swiper && swiper.zoom) {
          swiper.zoom.in();
        }
      } catch {
        return;
      }
    }

    const goToPrevious = async function(){
      try {
        let swiper = await slidesRef.value.$el.getSwiper();
        if (swiper) {
          swiper.slidePrev();
        }
      } catch {
        return;
      }
    }

    const goToNext = async function(){
      try {
        let swiper = await slidesRef.value.$el.getSwiper();
        if (swiper) {
          swiper.slideNext();
        }
      } catch {
        return;
      }
    }

    const deleteMediaAtCurrentIndex = async function() {
      if (props.deleteCallback != null) {
        try {
          let swiper = await slidesRef.value.$el.getSwiper();
          if (swiper && (swiper.activeIndex != null)) {
            props.deleteCallback(swiper.activeIndex, currentMedia.value[swiper.activeIndex]);
            //If just one is left, we close the modal
            if(mediaCount.value == 1) {
              closeModal();
            } 
            //Otherwise the slide is removed from the swiper and from the internal list. index in swiper is never sparse, even after removal!
            else {
              _.pullAt(currentMedia.value, swiper.activeIndex);
              swiper.removeSlide(swiper.activeIndex);
              //Close confirmation after deletion for next media to have all confirmation steps again!
              deleteConfirmationOpen.value = false;
            }
          }
        } catch {
          return;
        }
      }
    }

    const editMediaAtCurrentIndex = async function() {
      if (props.editCallback != null) {
        try {
          let swiper = await slidesRef.value.$el.getSwiper();
          if (swiper && (swiper.activeIndex != null)) {
            //Modify animation to slowly fade away - Better transition to editing
            let modal = await modalController.getTop();
            if (modal != null) modal.leaveAnimation = slowModalFadeOutAnimation;
            //Start editing
            props.editCallback(swiper.activeIndex, currentMedia.value[swiper.activeIndex]);
            //Close the modal, because we modify the media list potentially
            closeModal();
          }
        } catch {
          return;
        }
      }
    }

    //Signals readiness of the slides to be displayed
    const slidesInitialized = ref(false);

    const initSwiper = function(){
      try {
        slidesRef.value.$el.getSwiper().then((swiper) => {
          if (swiper) {
            swiper.on('zoomChange', (scale) => zoomValue.value = scale);
            //Listen for delete keys and interact with delete button
            swiper.on('keyPress', (keyCode) => {
              if (keyCode === 8 /* Backspace */ || keyCode === 46 /* Delete */) deleteButton.value.$el.click();
            });
            swiper.on('activeIndexChange', function () {
              selectedMediaIndex.value = this.activeIndex;
              //Close confirmation when changing media
              deleteConfirmationOpen.value = false;
              //Pause all videos in the gallery when the current active slide changes
              document.querySelectorAll('.gallery-slides-container video').forEach(videoElement => videoElement.pause());
            });
            return swiper.init();
          }
        })
        .finally(() => {
          slidesInitialized.value = true;
          setTimeout(() => deleteConfirmationOpen.value = props.openDeleteConfirmation, 100); //Set from props after initialization to keep in initial state. Timeout is used to open in when viewed.
        });
      } catch {
        return;
      }
    }

    onMounted(() => {
      //Initialize swiper manually!
      //Workaround to fix issue, where the interaction does not work properly when buttons are added. If we wait for readiness of the modal, the initialization can happen properly
      if (props.modalReadyPromise != null) {
        props.modalReadyPromise.then(() => initSwiper());
      } else {
        initSwiper();
      }
    });

    return { i18n, deleteButton, slidesRef, zoomPossible, selectedMediaIndex, mediaCount, slideOpts, slidesInitialized, deleteConfirmationOpen, closeModal, zoomOut, zoomIn, goToPrevious, goToNext, deleteMediaAtCurrentIndex, editMediaAtCurrentIndex, imposedImageOpacity, getImposedImageOpacityForIndex, increaseOpacity, decreaseOpacity, close, faMagnifyingGlassPlus, faMagnifyingGlassMinus, trash, chevronBack, chevronForward, pencil, contrast }
  }
});

export async function openGallery(component, media, selectedIndex, additionalProps, offset = {x: undefined, y: undefined}, animationInitialHeight){
  //Create a promise to signal the component when the modal is ready to initialize view elements
  let modalReady;
  const modalReadyPromise = new Promise((resolve) => modalReady = resolve);

  if (component != null && media != null) {
    const modal = await modalController
      .create({
        enterAnimation: createExtendAnimationFromPoint(offset, animationInitialHeight),
        leaveAnimation: createCollapseAnimationToPoint(offset, animationInitialHeight),
        component,
        id: MODAL_ID,
        cssClass: 'media-gallery',
        componentProps: {
          modalReadyPromise,
          media,
          selectedIndex,
          ...additionalProps
        },
      });
    const presentPromise = modal.present();
    presentPromise.then(() => modalReady());

    return presentPromise;
  }
}

export default Gallery;
</script>

<style>
.media-gallery {
  --background: transparent;
  --backdrop-opacity: 0.9!important;
  --height: 100%;
  --width: 100vw;
  --min-height: 0px;
  --max-height: 100%;
  --max-width: 100vw;
}

.media-gallery .ion-page, .media-gallery ion-page {
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.gallery-slides {
  --max-slide-height: 70vh;
  height: var(--max-slide-height, 70vh);
  margin: 0px;
}

.gallery-controls {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.gallery-controls-bottom {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding-left: 10px;
}

.gallery-controls-bottom > div {
  align-items: center;
  display: flex;
}

.gallery-controls ion-button, .gallery-controls-bottom ion-button{
  --color: white;
  --ripple-color: white;
}

.gallery-swiper-zoom-target {
  max-height: var(--max-slide-height, 70vh);
  max-width: 100vw;
}

.gallery-delete-button {
  --custom-size: 2.25em!important;
  margin-inline-start: clamp(5px, 2%, 30px);
}

.gallery-close-button {
  color: var(--ion-color-secondary-shade, #36abe0);
  margin-inline-start: clamp(5px, 2%, 30px);
}

.gallery-opacity-selection {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.gallery-opacity-selection ion-range {
  padding: 0px;
}

@media (max-width: 500px) {
  .gallery-opacity-selection ion-range {
    display: none!important;
  }
}

.gallery-contrast-decrease ion-icon, .gallery-contrast-increase ion-icon {
  position: relative;
  font-size: 24px;
}

.gallery-contrast-decrease ion-icon::after, .gallery-contrast-increase ion-icon::after {
  content: "-";
  color: white;
  position: absolute;
  top: 50%;
  right: 0%;
  font-size: 0.6em;
  vertical-align: middle;
  mix-blend-mode: difference;
}

.gallery-contrast-decrease, .gallery-contrast-increase {
  --padding-start: 10px;
  --padding-end: 10px;
}

.gallery-contrast-decrease ion-icon::after {
  content: "-";
  transform: translate(-0.25em, -57%);
}

.gallery-contrast-increase ion-icon::after {
  content: "+";
  transform: translate(-0.1em, -57%);
}

.ios .gallery-contrast-increase ion-icon::after {
  transform: translate(-0.2em, -57%);
}

/* Hide gallery until fully initialized to prevent pop-in. Use transition for smoothness */
.gallery-slides-container {
  opacity: 0;
  transition: opacity 0.15s ease-out;
}
.gallery-slides-container.initialized {
  opacity: 1;
}

.page-index {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}
</style>

<template id="date-time">
  <div class="date-time-items-container">
    <ion-item v-if="inputParameters.requestsDate" lines="none" @click="forwardClickToIonInput" :disabled="disabled">
      <ion-label position="stacked" v-if="inputParameters.requestsDate && inputParameters.requestsTime">{{ i18n.$t('default_interaction.date') }}</ion-label> <!-- Only show label, if both are asked for otherwise it is redundant -->
      <ion-input
        :name="`${key}-date`"
        :class="(computedValue.date == null || (!computedValue.date.length)) ? 'empty-date-time' : ''"
        type="date"
        :value="computedValue.date"
        @ionChange="setDate($event.target.value)"
        @keydown="blurOnEnter($event)"
        :custom-placeholder="custom_placeholder ? custom_placeholder : i18n.$t('default_interaction.select')"
        :max="maximumValue.date || undefined"
        @click="openNativeInput"
        :disabled="disabled"
        tabindex="2"></ion-input>
    </ion-item>
    <ion-item v-if="inputParameters.requestsTime" lines="none" @click="forwardClickToIonInput" :disabled="disabled">
      <ion-label position="stacked" v-if="inputParameters.requestsDate && inputParameters.requestsTime">{{ i18n.$t('default_interaction.time') }}</ion-label> <!--TODO Maybe add all ion-inputs to a reflist and update their tabindex to 0 once the list changes -->
      <ion-input
        :name="`${key}-time`"
        :class="(computedValue.time == null || (!computedValue.time.length)) ? 'empty-date-time' : ''"
        type="time"
        :value="computedValue.time"
        @ionChange="setTime($event.target.value)"
        @keydown="blurOnEnter($event)" 
        :custom-placeholder="custom_placeholder ? custom_placeholder : i18n.$t('default_interaction.select')"
        :max="(computedValue.date == maximumValue.date) ? maximumValue.time : undefined"
        @click="openNativeInput"
        :disabled="disabled"
        :tabindex="(inputParameters.requestsDate) ? 3 : 2"></ion-input> <!-- Time constraint is only applied when we are on the max day, before that it is not necessary -->
    </ion-item>
  </div>
</template>

<script>
import { IonItem, IonLabel, IonInput } from '@ionic/vue';
import { defineComponent, computed, ref, watch } from 'vue';

import { useI18n } from '@/utils/i18n';

import { useDayjs } from '@/utils/dayjs';

import { TYPE_API_MAPPINGS } from '@/utils/report';

import { blurOnEnter, openNativeInput, forwardClickToIonInput } from '@/utils/interaction';

import _ from 'lodash';

const PREFERRED_LABEL_POSITION = 'stacked';

export const isDateTime = function(type) {
  return (type in TYPE_API_MAPPINGS['datetime']);
}

export const getDateTimeParameters = function(type) {
  return {
    labelPosition: PREFERRED_LABEL_POSITION,
    componentType: 'DateTime',
    apiComponentType: TYPE_API_MAPPINGS['datetime'][type] || undefined
  }
}

const DateTime = defineComponent({
  name: 'DateTime',
  components: { IonItem, IonLabel, IonInput },
  props: {
    'key': String,
    'type': String,
    'presetValue': String,
    'modelValue': String,
    'max': [String, Number],
    'custom_placeholder': String,
    'disabled': {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'update:modified', 'invalidityMessage'],
  setup(props, { emit }) {
    const i18n = useI18n();

    const { dayjs } = useDayjs();

    const DATE_FORMAT = 'YYYY-MM-DD';

    const TIME_FORMAT = 'HH:mm';

    const EMPTY_DATE_TIME_MODEL = {
      date: null,
      dateUTC: null,
      time: null,
      timeUTC: null,
      ISOString: null
    };

    //Set input parameters depending on whether date or time or both are requested
    const inputParameters = computed(() => {
      //Fallback, show both
      if (props.type == null) {
        return {
          requestsDate: true,
          requestsTime: true,
        };
      }

      return {
        requestsDate: props.type.includes('date'),
        requestsTime: props.type.includes('time')
      };
    });

    //Relies on js internal Date for correct parsing and local timezone handling
    const processInputValue = function(value) {
      //Return in UTC and local time to use for all models and comparisons
      let dateAndTimeValues = _.cloneDeep(EMPTY_DATE_TIME_MODEL);

      //Only process given dateTime if it is valid
      if (value != null) {
        //Catch any parsing errors and return empty date!
        try {
          //Use js internal Date object for parsing the ISO String to treat 0-padded years correctly, e.g. 0001 is not 1901!
          let utcDateTime = dayjs.utc((new Date(value))).millisecond(0).second(0); //Remove precision of seconds and milliseconds!
          if (utcDateTime != null && utcDateTime.isValid()) {
            //Convert to local time
            let localDateTime = dayjs((new Date(value)));
            
            //Set individual date value for UTC and local date. Zero padded in case it is less than year 1000 to prevent bugs!
            dateAndTimeValues.dateUTC = utcDateTime.format(DATE_FORMAT).padStart(DATE_FORMAT.length, '0');
            dateAndTimeValues.date = localDateTime.format(DATE_FORMAT).padStart(DATE_FORMAT.length, '0');

            //Set individual date value for UTC and local date
            dateAndTimeValues.timeUTC = utcDateTime.format(TIME_FORMAT);
            dateAndTimeValues.time = localDateTime.format(TIME_FORMAT);

            //Set universal ISOString in UTC time
            dateAndTimeValues.ISOString = utcDateTime.toISOString();
          }
        } catch {
          return _.cloneDeep(EMPTY_DATE_TIME_MODEL);
        }
      }
      
      return dateAndTimeValues;
    }

    /*
      Parses given date or time or date and time in dateTime object. Returns dayjs object in local representation
      It relies on js internal Date object to parse the local date -> Prevents issues on ancient times, that don't apply offset properly and also treats 0-padded years correctly, e.g. 0001 is not 1901!
      If no time is given, but date, it uses midnight on that day!
      If no date is given, but time, it uses the value 0 of unix timestamp for the date!
    */
    const parseDateOrTimeOrBoth = function(dateTime) {
      if (dateTime != null) {
        //Use date or as fallback unix timestamp 0 + 1 day to save the time correctly without a date
        let newDate = dateTime.date || '1970-01-02'; //Single date is always returned in UTC, disregarding any timezones, always at midnight
        //Use time or as fallback midnight on that date
        let newTime = dateTime.time || '00:00'; //Single time without date is always returned in UTC depending on the current timezone as a whole ISO String

        //Separate with the ISO time character
        const dateTimeSeparator = 'T';

        //If only the date is required without time, make it UTC to save it as midnight at that date in UTC!
        const timeZone = (!(inputParameters.value.requestsTime)) ? 'Z' : '';

        //Catch parsing errors!
        try {
          //Parse using js Date object - in the local timezone given by the client
          let newDateTime = dayjs((new Date(`${newDate}${dateTimeSeparator}${newTime}${timeZone}`)));
        
          if (newDateTime.isValid()) {
            return newDateTime;
          }
        } catch {
          return null;
        }
      }

      return null;
    }

    const processedPresetValue = computed(() => {
      return processInputValue(props.presetValue);
    });

    //Saves the date and time values internally, for validation that it is complete before returning - initially empty
    const dateTimeModel = ref(processInputValue(props.modelValue));

    //Update the internal dateTimeModel when the model or the timezone changes
    watch(() => props.modelValue, (newModelValue) => {
      dateTimeModel.value = processInputValue(newModelValue);
    })

    //Set and get the time in UTC
    const computedValue = computed({
      get: () => {
        //Check equality of both values from the outside after processing!
        emit('update:modified', !_.isEqual(processedPresetValue.value.ISOString, dateTimeModel.value.ISOString));
        //Values from the outside are not checked for invalidity, as they are set dynamically by the app or in the past already!
        return dateTimeModel.value;
      },
      set: (newValue) => {
        dateTimeModel.value = newValue;

        //If we are missing one required part, this dateTime is invalid, do not emit it
        if (inputParameters.value.requestsDate && dateTimeModel.value.date == null) {
          //If there was a valid value set, report invalidity as incomplete field
          if (props.modelValue != null) emit('invalidityMessage', i18n.$t('forms.errors.missing_date'));
          return;
        }
        else if (inputParameters.value.requestsTime && dateTimeModel.value.time == null) {
          //If there was a valid value set, report invalidity as incomplete field
          if (props.modelValue != null) emit('invalidityMessage', i18n.$t('forms.errors.missing_time'));
          return;
        }
        else {
          let newDateTime = parseDateOrTimeOrBoth(newValue);
          if (newDateTime != null) {
            //If a maximum value is set, check if the new value is in bounds and if not which part is out of bounds
            if (maximumValue.value != null && maximumValue.value.dateTimeObject != null) {
              //Reset the part in the model that is out of bounds and report invalidity
              if (!(newDateTime.isSameOrBefore(maximumValue.value.dateTimeObject, 'day'))) {
                //Defer reset of time to let the internal model propagate to the input first
                setTimeout(() => {
                  dateTimeModel.value = {
                    date: undefined,
                    time: newValue.time
                  }
                }, 100);
                //Report date invalidity
                emit('invalidityMessage', i18n.$t('forms.errors.date_out_of_bounds'));
                return;
              } 
              //First day is checked and if it is valid check for finer granularity in seconds
              else if (!(newDateTime.isSameOrBefore(maximumValue.value.dateTimeObject, 'second'))) {
                //Defer reset of time to let the internal model propagate to the input first
                setTimeout(() => {
                  dateTimeModel.value = {
                    date: newValue.date,
                    time: undefined
                  }
                }, 100);
                //Report time invalidity
                emit('invalidityMessage', i18n.$t('forms.errors.time_out_of_bounds'));
                return;
              }
            }
            let newISOString = newDateTime.toISOString();
            emit('update:modelValue', newISOString);
            //Set invalidity message to undefined to reset invalidity
            emit('invalidityMessage', undefined);
          }
        }
      }
    });

    //Treat the given max value as UTC time and return the local equivalents too
    const maximumValue = computed(() => { //TODO In general test with dev tools different timezone and locale! Also in ViewFormItem!
      let values = processInputValue(props.max);

      return {
        ...values,
        //Calculate dayjs object once for easy comparison in the local variant
        dateTimeObject: (props.max != null) ? parseDateOrTimeOrBoth(values) : null
      };
    });

    //Set date, keep time intact
    const setDate = function(date){
      //Don't allow invalid values, to not reset it while entering a value, e.g. when starting the month with 0 it interprets it as invalid zero month and returns an empty string
      if (date != null && date.length) {
        computedValue.value = {date, time: computedValue.value.time};
      }
    }

     //Set time, keep date intact
    const setTime = function(time){
      //Don't allow invalid values
      if (time != null && time.length) {
        computedValue.value = {date: computedValue.value.date, time};
      }
    }

    return { i18n, inputParameters, computedValue, maximumValue, setDate, setTime, openNativeInput, forwardClickToIonInput, blurOnEnter };
  }
});

export default DateTime;
</script>

<style scoped>
ion-item {
  --padding-start: 0px;
  --background: var(--ion-card-background, #fff);
  --min-height: 40px;
}

.date-time-items-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  --min-height: 40px;
}

.date-time-items-container > *:not(:first-of-type) {
  --padding-start: 10px;
  border-left: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));
}

/* Only on mobile platforms show a custom placeholder text */
.ios .empty-date-time::before, .android .empty-date-time::before {
  padding: 4px 8px 4px 0px;
  opacity: 0.5;
  content: attr(custom-placeholder);
  pointer-events: none;
  width: 100%;
}

ion-input {
  --padding-bottom: 4px!important;
  --padding-top: 4px!important;
}
</style>
const MODULE_VERSION = 2; //UPDATE VERSION WITH EVERY BREAKING CHANGE OR ATTRIBUTE REMOVAL

import { calculateUniqueColorCount, createHexColorString } from '@/utils/colors.js';

const getDefaultState = () => {
  return {
    version: undefined,
    availableSkeletonDefinitions: [],
    skeletonDefinition: [], //Key is saved inside each marker for use in app logic. Array for better editing! //TODO Get from API after selecting a valid one from the list
    currentColorIndex: 0, //Keep track of which color was used, to prevent using the same color when deleting an element. Has to be reset after overflow of possible colors!
    skeletonDefinitionIdentifier: null,
    selectedSkeletonMarkerIndex: null,
    showConnectedSkeleton: true,
    analysisReference: [],
    analysisSelection: [],
    analysisCoordinates: [],
    analysisScaling: 2.5,
    analysisThreshold: 0.6,
    analysisEnableSettings: { comparison: true }
  }
};

export default {
  namespaced: true,

  state: getDefaultState,
  getters: {
    getAvailableSkeletonDefinitions (state) {
      return state.availableSkeletonDefinitions;
    },
    getSkeletonDefinition (state) {
      return state.skeletonDefinition;
    },
    getSkeletonDefinitionIdentifier (state) {
      return state.skeletonDefinitionIdentifier;
    },
    getSelectedSkeletonMarkerIndex (state) {
      return state.selectedSkeletonMarkerIndex;
    },
    getSelectedSkeletonMarkerKey (state) {
      if (state.selectedSkeletonMarkerIndex !== null && state.skeletonDefinition[state.selectedSkeletonMarkerIndex]){
        return state.skeletonDefinition[state.selectedSkeletonMarkerIndex].key;
      } else {
        return null;
      }
    },
    getSelectedSkeletonMarker (state) {
      if (state.selectedSkeletonMarkerIndex !== null && state.skeletonDefinition[state.selectedSkeletonMarkerIndex]){
        return state.skeletonDefinition[state.selectedSkeletonMarkerIndex];
      } else {
        return null;
      }
    },
    getSkeletonMarkerByKey (state){
      return function(key){
        for (let marker of state.skeletonDefinition){
          if (marker.key === key){
            return marker;
          }
        }
        return null;
      }
    },
    shouldShowConnectedSkeleton (state) {
      return state.showConnectedSkeleton;
    },
    getAnalysisReference (state) {
      return state.analysisReference;
    },
    getAnalysisSelection (state) {
      return state.analysisSelection;
    },
    getAnalysisCoordinates (state) {
      return state.analysisCoordinates;
    },
    getAnalysisScaling (state) {
      return state.analysisScaling;
    },
    getAnalysisThreshold (state) {
      return state.analysisThreshold;
    },
    getAnalysisEnableSettings (state) {
      return state.analysisEnableSettings;
    }
  },
  mutations: {
    setAvailableSkeletonDefinitions (state, newDefinitions){
      state.availableSkeletonDefinitions = newDefinitions;
    },
    setSkeletonDefinition (state, newDefinition){
      state.skeletonDefinition = newDefinition;
      if (newDefinition.length > 0){
        state.selectedSkeletonMarkerIndex = 0;
      } else {
        state.selectedSkeletonMarkerIndex = null;
      }
    },
    clearSkeletonDefinition (state){
      state.skeletonDefinition = [];
      state.selectedSkeletonMarkerIndex = null;
    },
    setSkeletonDefinitionIdentifier (state, newIdentifier){
      state.skeletonDefinitionIdentifier = newIdentifier;
    },
    addSkeletonMarker (state, { key, properties }){ //TODO Check when changing or adding, also down below, that key is defined, not empty nor null
      //If key exists, return without changing anything
      for (let marker of state.skeletonDefinition){
        if (marker.key === key) return;
      }

      let newMarker = { key: key, connectedTo: [] }; //connectedTo is an array of keys to which the point is connected
      
      if (!properties || !('color' in properties)){
        newMarker.color = createHexColorString(state.currentColorIndex);
        state.currentColorIndex++;
        if (state.currentColorIndex >= calculateUniqueColorCount()) state.currentColorIndex = 0; //If we used up all colors, start from beginning
      }

      if (properties){
        for (let [propertyKey, property] of Object.entries(properties)){
          newMarker[propertyKey] = property;
        }
      }

      state.skeletonDefinition.push(newMarker);
    },
    changeSkeletonMarkerKey (state, { oldKey, newKey }){
      //If new key exists, return without changing anything
      for (let marker of state.skeletonDefinition){
        if (marker.key === newKey) return;
      }

      //Search for old key and change it
      for (let marker of state.skeletonDefinition){
        //Search for key in all connectedTo arrays and rename there
        for (let connectedMarkerIndex = 0; marker.connectedTo && connectedMarkerIndex < marker.connectedTo.length; connectedMarkerIndex++){
          if (marker.connectedTo[connectedMarkerIndex] === oldKey){
            marker.connectedTo[connectedMarkerIndex] = newKey;
          }
        }
        if (marker.key === oldKey) marker.key = newKey;
      }
    },
    setMarkerConnection (state, { key, markerList }){
      //Search for key and change connection array
      for (let marker of state.skeletonDefinition){
        if (marker.key === key) marker.connectedTo = markerList;
      }
    },
    moveSkeletonMarker (state, { oldIndex, newIndex }) {
      //Save key to reset later
      let selectedMarkerKey = state.skeletonDefinition[state.selectedSkeletonMarkerIndex].key;

      state.skeletonDefinition.splice(newIndex, 0, state.skeletonDefinition.splice(oldIndex, 1)[0]);

      //Select the correkt key again after moving
      for (let markerIndex = 0; markerIndex < state.skeletonDefinition.length; markerIndex++){
        if (state.skeletonDefinition[markerIndex].key === selectedMarkerKey) {
          state.selectedSkeletonMarkerIndex = markerIndex;
        }
      }
    },
    removeSkeletonMarker (state, markerKey){
      if (state.selectedSkeletonMarkerIndex !== null && state.skeletonDefinition[state.selectedSkeletonMarkerIndex].key === markerKey){
        state.selectedSkeletonMarkerIndex = null;
      }

      //Save index to not mutate array in for loop
      let indexToDelete = null;

      //Search for key and delete marker
      for (let markerIndex = 0; markerIndex < state.skeletonDefinition.length; markerIndex++){
        //Search for key in all connectedTo arrays and delete there
        for (let connectedMarkerIndex = 0; state.skeletonDefinition[markerIndex].connectedTo && connectedMarkerIndex < state.skeletonDefinition[markerIndex].connectedTo.length; connectedMarkerIndex++){
          if (state.skeletonDefinition[markerIndex].connectedTo[connectedMarkerIndex] === markerKey){
            state.skeletonDefinition[markerIndex].connectedTo.splice(connectedMarkerIndex, 1);
          }
        }
        if (state.skeletonDefinition[markerIndex].key === markerKey) {
          indexToDelete = markerIndex;
        }
      }

      if (indexToDelete != null){
        state.skeletonDefinition.splice(indexToDelete, 1);
        //If we delete one before the selected one, we need to decrement the selected index to keep the same object selected - Can't be lower than 1 because markerIndex is at least 0
        if (state.selectedSkeletonMarkerIndex !== null && state.selectedSkeletonMarkerIndex > indexToDelete) {
          state.selectedSkeletonMarkerIndex--;
        }
      }
    },
    resetSelectedSkeletonMarker (state){
      if (state.skeletonDefinition.length > 0){
        state.selectedSkeletonMarkerIndex = 0;
      } else {
        state.selectedSkeletonMarkerIndex = null;
      }
    },
    changeSelectedSkeletonMarkerKey (state, markerKey){
      state.selectedSkeletonMarkerIndex = null;
      for (let markerIndex = 0; markerIndex < state.skeletonDefinition.length; markerIndex++){
        if (state.skeletonDefinition[markerIndex].key === markerKey) {
          state.selectedSkeletonMarkerIndex = markerIndex;
        }
      }
    },
    tryDecrementSelectedSkeletonMarker (state) {
      if (state.selectedSkeletonMarkerIndex > 0){
        state.selectedSkeletonMarkerIndex--;
      }
    },
    tryIncrementSelectedSkeletonMarker (state) {
      if (state.selectedSkeletonMarkerIndex >= 0 && state.selectedSkeletonMarkerIndex < (state.skeletonDefinition.length - 1)){
        state.selectedSkeletonMarkerIndex++;
      }
    },
    setShowConnectedSkeleton (state, newState) {
      state.showConnectedSkeleton = newState;
    },
    checkVersion (state) { //Checks the version of this module and sets the state to default, if version is different
      //Used to reset state on breaking changes and to remove attributes that are no longer needed
      if (state.version !== MODULE_VERSION) {
        let defaultState = getDefaultState();
        Object.assign(state, defaultState); //Use setters to not impact any reactive functionality
        //Search for unused attributes and set them undefined, to remove them from the persisted state
        for (let key of Object.keys(state)){
          if (!(key in defaultState)){
            state[key] = undefined;
          }
        }
        state.version = MODULE_VERSION;
      }
    },
    setAnalysisReference (state, newReference) {
      state.analysisReference = newReference;
    },
    setAnalysisSelection (state, newSelection) {
      state.analysisSelection = newSelection;
    },
    setAnalysisCoordinates (state, newCoordinates) {
      state.analysisCoordinates = newCoordinates;
    },
    setAnalysisScaling (state, newScaling) {
      state.analysisScaling = newScaling;
    },
    setAnalysisThreshold (state, newThreshold) {
      state.analysisThreshold = newThreshold;
    },
    setAnalysisEnableSettings (state, newEnableSettings) {
      state.analysisEnableSettings = newEnableSettings;
    },
    clearPersonalData (state) {
      let defaultState = {};
      Object.assign(defaultState, getDefaultState());
      state.availableSkeletonDefinitions = defaultState.availableSkeletonDefinitions;
    }
  },
  actions: {
    setSelectedSkeletonMarkerKey(context, markerKey){
      context.commit('changeSelectedSkeletonMarkerKey', markerKey);
    },
    fetchAvailableSkeletonDefinitions(context) {
      context.commit('setAvailableSkeletonDefinitions', []);//TODO API Call fill list of definitions or set to empty one on error / none in response
    },
    loadSkeletonDefinition(context, identifier) { //TODO Use promise and reject if error occurred
      let definition = [ { key: 'bone1', color: '#ff0000'}, { key: 'bone2', color: '#ffffff'}];
      //TODO API Call get definition
      context.commit('setSkeletonDefinition', definition);
      context.commit('setSkeletonDefinitionIdentifier', identifier);
    },
    setLocalSkeletonDefinition(context, { identifier, skeletonDefinition }){
      let newDefinition = [];
      //Only take valid properties, that we want
      for (let markerIndex = 0; markerIndex < skeletonDefinition.length; markerIndex++){
        if ( skeletonDefinition[markerIndex].key ){
          let newMarker = { key: skeletonDefinition[markerIndex].key, connectedTo: skeletonDefinition[markerIndex].connectedTo || [] }; //connectedTo is an array of keys to which the point is connected
          if (!('color' in skeletonDefinition[markerIndex])){
            newMarker.color = createHexColorString(context.state.currentColorIndex);
            context.state.currentColorIndex++;
            if (context.state.currentColorIndex >= calculateUniqueColorCount()) context.state.currentColorIndex = 0; //If we used up all colors, start from beginning
          } else {
            newMarker.color = skeletonDefinition[markerIndex].color;
          }
          newDefinition.push(newMarker);
        }
      }
      context.commit('setSkeletonDefinition', newDefinition);
      context.commit('setSkeletonDefinitionIdentifier', identifier);
    }
  }
}
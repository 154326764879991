<template>
  <div class="list-container"> <!-- TODO Check that everything is removed from CreateReport that is not necessary -->
    <div :class="['header-container', isOpen ? 'expanded' : undefined]" v-if="showHeader">
      <ion-item lines="full" @click="toggleList" button detail="false">
        <ion-icon slot="start" :icon="chevronForward"></ion-icon>
        <slot slot="default" name="label">
          <ion-label class="default-title">
            {{ title }}
          </ion-label>
        </slot>
        <ion-badge v-if="primaryIndicator != null && (primaryIndicator > 0 || showZeroIndicator)" slot="end" color="primary" :class="(showZeroIndicator && primaryIndicator <= 0) ? 'invert' : undefined">
          <ion-icon v-if="primaryIcon != null" :icon="primaryIcon"></ion-icon>{{ primaryIndicator }}
        </ion-badge>
        <ion-badge v-if="tertiaryIndicator != null && (tertiaryIndicator > 0 || showZeroIndicator)" slot="end" color="tertiary" :class="(showZeroIndicator && tertiaryIndicator <= 0) ? 'invert' : undefined">
          <ion-icon v-if="tertiaryIcon != null" :icon="tertiaryIcon"></ion-icon>{{ tertiaryIndicator }}
        </ion-badge>
        <ion-badge v-if="successIndicator != null && (successIndicator > 0 || showZeroIndicator)" slot="end" color="success" :class="(showZeroIndicator && successIndicator <= 0) ? 'invert' : undefined">
          <ion-icon v-if="successIcon != null" :icon="successIcon"></ion-icon>{{ successIndicator }}
        </ion-badge>
        <ion-badge v-if="dangerIndicator != null && (dangerIndicator > 0 || showZeroIndicator)" slot="end" color="danger" :class="(showZeroIndicator && dangerIndicator <= 0) ? 'invert' : undefined">
          <ion-icon v-if="dangerIcon != null" :icon="dangerIcon"></ion-icon>{{ dangerIndicator }}
        </ion-badge>
        <slot slot="end" name="end"></slot>
      </ion-item>
    </div>
    <div ref="itemsContainer" :class="['items-container', isOpen ? 'expanded' : undefined]" @invalid.capture="openListWhenInvalid()">
      <slot></slot>
      <ion-item v-if="showNoItemsIndicator" lines="full" class="no-items-indicator">
        <ion-label>{{ (noItemsIndicator != null) ? noItemsIndicator : i18n.$t('default_interaction.no_items') }}</ion-label>
      </ion-item>
    </div>
  </div>
</template>


<script>
import { IonItem, IonIcon, IonBadge, IonLabel } from '@ionic/vue';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';

import { chevronForward } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";

export default  {
  name: 'CollapsibleList',
  components: { IonItem, IonBadge, IonIcon, IonLabel },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    title: String,
    primaryIndicator: Number,
    primaryIcon: [Object, String],
    tertiaryIndicator: Number,
    tertiaryIcon: [Object, String],
    successIndicator: Number,
    successIcon: [Object, String],
    dangerIndicator: Number,
    dangerIcon: [Object, String],
    showNoItemsIndicator: {
      type: Boolean,
      default: false
    },
    showZeroIndicator: {
      type: Boolean,
      default: false
    },
    noItemsIndicator: String,
    openOnInvalidEvent: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }){
    const i18n = useI18n();

    const isOpenProp = computed(() => props.open);

    const isOpen = ref(isOpenProp.value);

    watch((isOpen), (newState) => {
      emit('update:open', newState);
    });

    watch((isOpenProp), (newState) => {
      isOpen.value = newState;
    });

    const toggleList = function() {
      //Only allow toggle when header is visible
      if (props.showHeader) {
        isOpen.value = !(isOpen.value);
      }
    }

    const openListWhenInvalid = function() {
      if (props.openOnInvalidEvent) {
        isOpen.value = true;
      }
    }

    const itemsContainer = ref(null);

    const openWhenScrolledToItem = function() {
      isOpen.value = true;
    }

    onMounted(() => {
      if (itemsContainer.value != null) {
        itemsContainer.value.addEventListener('requestScrollToItem', openWhenScrolledToItem, { capture: true });
      }
    });

    onUnmounted(() => {
      if (itemsContainer.value != null) {
        itemsContainer.value.removeEventListener('requestScrollToItem', openWhenScrolledToItem, { capture: true });
      }
    });

    return {
      i18n,
      isOpen,
      itemsContainer,
      toggleList,
      openListWhenInvalid,
      openWhenScrolledToItem,
      chevronForward
    };
  }
}
</script>

<style scoped>
ion-item {
  --background: var(--background);
}

.header-container {
  cursor: pointer;
}

.header-container ion-item {
  --padding-start: var(--custom-padding-start, 16px);
  --border-width: var(--collapsible-header-border-width, 0 0 1px 0);
}

/* Animate Rotation of chevron icon */
.header-container ion-item > ion-icon {
  transition: transform 0.2s ease-in-out;
}
.list-container .header-container.expanded ion-item > ion-icon {
  transform: rotate(90deg);
}

.list-container .items-container {
  margin-left: var(--custom-item-margin, 0px);
  overflow: var(--custom-overflow, auto);
}

/* Collapse items */
.list-container .items-container:not(.expanded){
  display: none;
}

.header-container > * {
  --color: var(--color, var(--ion-color-secondary-shade));
  --background: var(--background, unset);
  font-weight: bold;
}

.header-container ion-item > ion-icon {
  color: var(--color);
}

ion-badge {
  margin-left: 10px;
}

.ios ion-badge {
  margin-right: 0px;
}

ion-badge ion-icon {
  vertical-align: bottom;
  margin-right: 3px;
}

ion-badge.invert {
  background: var(--ion-color-medium-light);
  color: var(--ion-color-shade);
}

.no-items-indicator {
  display: none;
  text-align: center;
  font-weight: bold;
  color: var(--ion-color-medium);
}

.no-items-indicator:only-child {
  display: unset;
}

.default-title {
  font-weight: normal;
  white-space: normal;
}
</style>

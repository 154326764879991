<template>
  <canvas ref="canvasInstance" class="camera-canvas"></canvas>
</template>

<script>
import { createGesture } from '@ionic/vue';

import { getCoordsRelativeToElementFromClientCoords, isCoordsInRectangle } from  '@/utils/interaction';

import { onMounted, ref, watch, computed } from 'vue';

export default {
  name: 'CameraOverlayCanvas',
  props: {
    targetRect: Object,
    focusPosition: Object,
    focusDistance: Number,
    exposureCompensation: Number,
    focusLocked: {
      type: Boolean,
      default: false
    },
    focusEnabled: {
      type: Boolean,
      default: false
    },
    exposureEnabled: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'update:focusPosition',
    'update:focusDistance',
    'update:focusLocked',
    'update:exposureCompensation'
  ],
  setup(props, context) {
    const canvasInstance = ref(null);

    //Object with x, y coordinates (in percent of the image) or null if not set
    const cameraFocusPoint = ref(null);
    const focusDistance = ref(0.0);
    const exposureCompensation = ref(0.0);

    watch(() => props.focusPosition, (newFocusPosition) => {
      cameraFocusPoint.value = newFocusPosition;
      if (updateCanvas) updateCanvas();
    }, { immediate: true });

    watch(() => props.focusDistance, (newFocusDistance) => {
      if (newFocusDistance == null) focusDistance.value = 0.0;
      else focusDistance.value = newFocusDistance;
      if (updateCanvas) updateCanvas();
    }, { immediate: true });

    watch(() => props.exposureCompensation, (newExposureCompensation) => {
      if (newExposureCompensation == null) exposureCompensation.value = 0.0;
      else exposureCompensation.value = newExposureCompensation;
      if (updateCanvas) updateCanvas();
    }, { immediate: true });

    const isFocusLocked = computed({
      get: () => props.focusLocked,
      set: (newValue) => context.emit('update:focusLocked', newValue)
    });

    watch(isFocusLocked, () => {
      updateCanvas();
    });

    const MIN_EXPOSURE_VALUE = -1.0;
    const MAX_EXPOSURE_VALUE = 1.0;

    //All sizes are dependant on this one
    const CAMERA_OVERLAY_FOCUS_SIZE_PERCENT = 0.25;

    const CAMERA_OVERLAY_FOCUS_PADDING_PERCENT = 0.05;
    const CAMERA_OVERLAY_FOCUS_LINE_PERCENT = 0.2;
    const CAMERA_OVERLAY_FOCUS_RADIUS_PERCENT = 0.1;
    const CAMERA_OVERLAY_FOCUS_CIRCLE_PERCENT = 0.25;
    const CAMERA_OVERLAY_FOCUS_BUTTONS_BOTTOM_THRESHOLD = 0.2;
    const CAMERA_OVERLAY_FOCUS_SLIDER_WIDTH = 0.1;
    const CAMERA_OVERLAY_FOCUS_SLIDER_OFFSET = -0.1;
    const CAMERA_OVERLAY_FOCUS_SLIDER_ICON_OFFSET = 0.2;
    const CAMERA_OVERLAY_FOCUS_SLIDER_ICON_SIZE = 0.15;
    const CAMERA_OVERLAY_FOCUS_SLIDER_BUTTON_RATIO_OF_SLIDER = 0.4;
    const CAMERA_OVERLAY_FOCUS_SLIDER_BUTTON_PADDING_RATIO = 1.4;
    const CAMERA_OVERLAY_FOCUS_BUTTON_HEIGHT = 0.1;
    const CAMERA_OVERLAY_FOCUS_BUTTON_OFFSET = 0.0;
    const CAMERA_OVERLAY_FOCUS_BUTTON_PADDING = 0.01;
    const CAMERA_OVERLAY_FOCUS_ICON_RATIO_OF_BUTTON = 0.5;
    const CAMERA_OVERLAY_EXPOSURE_SLIDER_MIDDLE_INDICATOR_RATIO = 0.5;
    const CAMERA_OVERLAY_EXPOSURE_SLIDER_ICON_OFFSET = 0.15;
    const CAMERA_OVERLAY_EXPOSURE_SLIDER_ICON_SIZE = 0.1;
    //const CAMERA_OVERLAY_FOCUS_LOCK_SIZE = 0.2;

    //Define all icons here to preload them
    const CANVAS_ICONS = {
      'lock': '\uf023',
      'unlock': '\uf09c',
      'mountain-sun': '\ue52f',
      'expand': '\uf065',
      'seedling': '\uf4d8',
      'sun': '\uf185',
      'plus': '\u002b',
      'minus': '\uf068'
    }

    const CAMERA_OVERLAY_FOCUS_ICONS = {
      'lock': {
        'active': CANVAS_ICONS['lock'],
        'inactive': CANVAS_ICONS['unlock']
      }
    }

    const calculateOverlayFocusPositionAndSize = function(focusX, focusY, width, height) {
      let minimumSizeComponent = Math.min(width, height);
      let focusSize = Math.ceil(minimumSizeComponent * CAMERA_OVERLAY_FOCUS_SIZE_PERCENT);
      let focusPadding = Math.ceil(minimumSizeComponent * CAMERA_OVERLAY_FOCUS_PADDING_PERCENT);

      let additionalButtons = (focusY < (CAMERA_OVERLAY_FOCUS_BUTTONS_BOTTOM_THRESHOLD * height)) ? 'bottom' : 'top';

      let sliderWidth = Math.ceil(minimumSizeComponent * CAMERA_OVERLAY_FOCUS_SLIDER_WIDTH);
      let halfSliderWidth = Math.ceil(sliderWidth / 2);
      sliderWidth = halfSliderWidth * 2; //Correct because of inconsistencies when using ceil

      let buttonSize = Math.ceil(minimumSizeComponent * CAMERA_OVERLAY_FOCUS_BUTTON_HEIGHT);
      let halfButtonSize = Math.ceil(buttonSize / 2);
      buttonSize = halfButtonSize * 2; //Correct because of inconsistencies when using ceil
      let focusButtonIconSize = Math.ceil(buttonSize * CAMERA_OVERLAY_FOCUS_ICON_RATIO_OF_BUTTON);
      let focusButtonPadding = Math.ceil(minimumSizeComponent * CAMERA_OVERLAY_FOCUS_BUTTON_PADDING);

      let halfFocusSize = Math.ceil(focusSize / 2);
      focusSize = halfFocusSize * 2; //Correct because of inconsistencies when using ceil

      let correctedX = focusX;
      let correctedY = focusY;
      //Check if any are out of bounds
      let corrections = {
        left: correctedX - halfFocusSize - sliderWidth,
        right: correctedX + halfFocusSize + sliderWidth,
        top: correctedY - halfFocusSize - focusPadding,
        bottom: correctedY + halfFocusSize + focusPadding,
      }
      if (corrections.left < 0) {
        correctedX = correctedX - corrections.left;
      }
      if (corrections.right > width) {
        correctedX = correctedX - (corrections.right - width);
      }
      if (corrections.top < 0) {
        correctedY = correctedY - corrections.top;
      }
      if (corrections.bottom > height) {
        correctedY = correctedY - (corrections.bottom - height);
      }

      let sliderOffset = Math.ceil(CAMERA_OVERLAY_FOCUS_SLIDER_OFFSET * focusSize);
      let sliderIconOffset = Math.ceil(CAMERA_OVERLAY_FOCUS_SLIDER_ICON_OFFSET * focusSize);
      let exposureSliderIconOffset = Math.ceil(CAMERA_OVERLAY_EXPOSURE_SLIDER_ICON_OFFSET * focusSize);
      let maximumSlider = correctedY - halfFocusSize - sliderOffset;
      let minimumSlider = correctedY + halfFocusSize + sliderOffset;

      let maximumExposureSlider = correctedY - halfFocusSize - sliderOffset;
      let minimumExposureSlider = correctedY + halfFocusSize + sliderOffset;

      let sliderIconSize = Math.ceil(focusSize * CAMERA_OVERLAY_FOCUS_SLIDER_ICON_SIZE);
      let exposureSliderIconSize = Math.ceil(focusSize * CAMERA_OVERLAY_EXPOSURE_SLIDER_ICON_SIZE);

      //Add padding but only on the side where there is no additional controls - Those are checked separately
      let focusPaddedRectangle = {
        xMin: (correctedX - halfFocusSize),
        xMax: (correctedX + halfFocusSize),
        yMin: (correctedY - halfFocusSize - ((additionalButtons !== 'bottom') ? 0 : focusPadding)),
        yMax: (correctedY + halfFocusSize + ((additionalButtons === 'bottom') ? 0 : focusPadding))
      }

      let focusExposureInteractionArea = {
        xMin: (focusPaddedRectangle.xMin - sliderWidth),
        xMax: (focusPaddedRectangle.xMin),
        yMin: (correctedY - halfFocusSize),
        yMax: (correctedY + halfFocusSize),
      }

      let focusExposureInteractionSafeArea = {
        xMin: focusExposureInteractionArea.xMin,
        xMax: focusExposureInteractionArea.xMax,
        yMin: focusExposureInteractionArea.yMin - exposureSliderIconOffset - Math.ceil(exposureSliderIconSize / 2),
        yMax: focusExposureInteractionArea.yMax + exposureSliderIconOffset + Math.ceil(exposureSliderIconSize / 2),
      }

      let focusInteractionArea = {
        xMin: (focusPaddedRectangle.xMax),
        xMax: (focusPaddedRectangle.xMax + sliderWidth),
        yMin: (correctedY - halfFocusSize),
        yMax: (correctedY + halfFocusSize),
      }

      let focusInteractionSafeArea = {
        xMin: focusInteractionArea.xMin,
        xMax: focusInteractionArea.xMax,
        yMin: focusInteractionArea.yMin - sliderIconOffset - Math.floor(sliderIconSize / 2),
        yMax: focusInteractionArea.yMax + sliderIconOffset + Math.floor(sliderIconSize / 2),
      }

      let focusButtonsArea = {
        xMin: focusPaddedRectangle.xMin,
        xMax: focusPaddedRectangle.xMax,
        yMin: ((additionalButtons === 'bottom') ? (focusPaddedRectangle.yMax) : (focusPaddedRectangle.yMin - buttonSize)),
        yMax: ((additionalButtons === 'bottom') ? (focusPaddedRectangle.yMax + buttonSize) : (focusPaddedRectangle.yMin)),
      }

      let buttonOffset = Math.ceil(CAMERA_OVERLAY_FOCUS_BUTTON_OFFSET * focusSize);
      let focusButtonsY = (correctedY - ((halfFocusSize + halfButtonSize) * ((additionalButtons === 'bottom') ? -1 : 1)));
      let buttonPositions = ['lock'].map((key, index) => {
        //Calculate from the right edge, take padding and half a button to get to the first center - Then add for every index a (button + padding) distance
        let currentButtonX = (correctedX + halfFocusSize - halfButtonSize - buttonOffset) - (index * (buttonSize + focusButtonPadding));
        return {
          key,
          x: currentButtonX,
          xMin: currentButtonX - halfButtonSize,
          xMax: currentButtonX + halfButtonSize,
          yMin: focusButtonsY - halfButtonSize,
          yMax: focusButtonsY + halfButtonSize
        }
      });

      return {
        x: correctedX,
        y: correctedY,
        size: focusSize,
        halfSize: halfFocusSize,
        padding: focusPadding,
        sliderWidth,
        halfSliderWidth,
        sliderIconSize,
        exposureSliderIconSize,
        sliderIconOffset,
        exposureSliderIconOffset,
        focusPaddedRectangle,
        focusInteractionArea,
        focusExposureInteractionArea,
        focusInteractionSafeArea,
        focusExposureInteractionSafeArea,
        focusButtonsArea,
        focusButtons: buttonPositions,
        focusButtonsY,
        focusButtonIconSize,
        focusButtonsHalfSize: halfButtonSize,
        maximumSlider,
        minimumSlider,
        maximumExposureSlider,
        minimumExposureSlider
      }
    }

    const clearCanvas = function(){
      canvasInstance.value.getContext('2d').clearRect(0, 0, canvasInstance.value.width, canvasInstance.value.height);
    }

    //Pre-Load all possible icons by drawing them once transparently, so they will be available on first real draw
    const preLoadCanvasIcons = function() {
      let ctx = canvasInstance.value.getContext('2d');

      ctx.fillStyle = ctx.strokeStyle = 'transparent';
      ctx.font = `900 48px "Font Awesome 6 Free"`;

      for (let icon of Object.values(CANVAS_ICONS)) {
        //Draw icons at the end of the sliders
        ctx.fillText(icon, 0, 0);
      }
    }

    const updateCanvas = function() {
      clearCanvas();

      //Only draw if enabled
      if (props.focusEnabled) {
        let ctx = canvasInstance.value.getContext('2d');

        ctx.lineWidth = 2;
        ctx.fillStyle = ctx.strokeStyle = 'white';

        let width = canvasInstance.value.width;
        let height = canvasInstance.value.height;

        if (cameraFocusPoint.value != null) {
          let focusPointLocation = {
            x: cameraFocusPoint.value.x * width,
            y: cameraFocusPoint.value.y * height
          }

          let focusIndicatorPositionAndSize = calculateOverlayFocusPositionAndSize(focusPointLocation.x, focusPointLocation.y, width, height);

          let radius = CAMERA_OVERLAY_FOCUS_RADIUS_PERCENT * focusIndicatorPositionAndSize.size;
          let lineLength = CAMERA_OVERLAY_FOCUS_LINE_PERCENT * focusIndicatorPositionAndSize.size;

          //Draw a transparent circle inside the indicator
          ctx.fillStyle = ctx.strokeStyle = 'rgba(200,200,200,0.5)';
          ctx.beginPath();
          ctx.arc(focusIndicatorPositionAndSize.x, focusIndicatorPositionAndSize.y, (focusIndicatorPositionAndSize.size * CAMERA_OVERLAY_FOCUS_CIRCLE_PERCENT), 0, Math.PI * 2, true);
          ctx.fill();

          //Draw the indicator itself
          ctx.fillStyle = ctx.strokeStyle = 'white';
          for (let xDirection of [-1, 1]) {
            for (let yDirection of [-1, 1]) {
              let corner = {
                x: focusIndicatorPositionAndSize.x - (focusIndicatorPositionAndSize.halfSize * xDirection),
                y: focusIndicatorPositionAndSize.y - (focusIndicatorPositionAndSize.halfSize * yDirection)
              }

              ctx.beginPath();
              let x = corner.x;
              let y = corner.y + ((radius + lineLength) * yDirection);
              ctx.moveTo(x, y);
              y -= (lineLength * yDirection);
              ctx.lineTo(x, y);
              x += (radius * xDirection);
              y -= (radius * yDirection);
              ctx.arcTo(corner.x, corner.y, x, y, radius);
              x += (lineLength * xDirection);
              ctx.lineTo(x, y);
              ctx.stroke();
            }
          }

          
          let interactionSliderPosition = focusIndicatorPositionAndSize.halfSize + focusIndicatorPositionAndSize.halfSliderWidth;

          let x = focusIndicatorPositionAndSize.x + interactionSliderPosition;

          //Calculate position of button on slider
          let sliderValuePosition = focusIndicatorPositionAndSize.maximumSlider + ((1 - focusDistance.value) * (focusIndicatorPositionAndSize.minimumSlider - focusIndicatorPositionAndSize.maximumSlider));
          let sliderValueSize = focusIndicatorPositionAndSize.halfSliderWidth * CAMERA_OVERLAY_FOCUS_SLIDER_BUTTON_RATIO_OF_SLIDER;
          //Same calculation for exposure slider
          let exposureSliderValuePosition = (((exposureCompensation.value-MIN_EXPOSURE_VALUE)/(MIN_EXPOSURE_VALUE-MAX_EXPOSURE_VALUE) * (focusIndicatorPositionAndSize.maximumSlider-focusIndicatorPositionAndSize.minimumSlider)) * -1) + focusIndicatorPositionAndSize.minimumSlider;

          //Draw the focus interaction - excluding the slider button in between
          let beforeSliderValuePosition = sliderValuePosition - (sliderValueSize * CAMERA_OVERLAY_FOCUS_SLIDER_BUTTON_PADDING_RATIO);
          if (beforeSliderValuePosition > focusIndicatorPositionAndSize.maximumSlider) {
            ctx.beginPath();
            ctx.moveTo(x, focusIndicatorPositionAndSize.maximumSlider);
            ctx.lineTo(x, beforeSliderValuePosition);
            ctx.stroke();
          }
          let afterSliderValuePosition = sliderValuePosition + (sliderValueSize * CAMERA_OVERLAY_FOCUS_SLIDER_BUTTON_PADDING_RATIO);
          if (afterSliderValuePosition < focusIndicatorPositionAndSize.minimumSlider) {
            ctx.beginPath();
            ctx.moveTo(x, afterSliderValuePosition);
            ctx.lineTo(x, focusIndicatorPositionAndSize.minimumSlider);
            ctx.stroke();
          }

          ctx.textBaseline = 'middle';
          ctx.textAlign = 'center';
          ctx.font = `900 ${focusIndicatorPositionAndSize.sliderIconSize}px "Font Awesome 6 Free"`;

          //Draw icons at the end of the sliders
          ctx.fillText(CANVAS_ICONS['mountain-sun'], x, (focusIndicatorPositionAndSize.maximumSlider - focusIndicatorPositionAndSize.sliderIconOffset));
          ctx.font = `900 ${focusIndicatorPositionAndSize.sliderIconSize * 1.7}px "Font Awesome 6 Free"`;
          ctx.fillText(CANVAS_ICONS['expand'], x, (focusIndicatorPositionAndSize.minimumSlider + focusIndicatorPositionAndSize.sliderIconOffset));
          ctx.font = `900 ${focusIndicatorPositionAndSize.sliderIconSize * 0.7}px "Font Awesome 6 Free"`;
          ctx.fillText(CANVAS_ICONS['seedling'], x, (focusIndicatorPositionAndSize.minimumSlider + focusIndicatorPositionAndSize.sliderIconOffset));
          

          ctx.beginPath();
          ctx.arc(x, sliderValuePosition, sliderValueSize, 0, Math.PI * 2, true);
          ctx.stroke();

          if (props.exposureEnabled) {
            x = focusIndicatorPositionAndSize.x - interactionSliderPosition;

            //Draw the exposure interaction - excluding the slider button in between
            let beforeExposureSliderValuePosition = exposureSliderValuePosition - (sliderValueSize * CAMERA_OVERLAY_FOCUS_SLIDER_BUTTON_PADDING_RATIO);
            if (beforeExposureSliderValuePosition > focusIndicatorPositionAndSize.maximumExposureSlider) {
              ctx.beginPath();
              ctx.moveTo(x, focusIndicatorPositionAndSize.maximumExposureSlider);
              ctx.lineTo(x, beforeExposureSliderValuePosition);
              ctx.stroke();
            }
            let afterExposureSliderValuePosition = exposureSliderValuePosition + (sliderValueSize * CAMERA_OVERLAY_FOCUS_SLIDER_BUTTON_PADDING_RATIO);
            if (afterExposureSliderValuePosition < focusIndicatorPositionAndSize.minimumExposureSlider) {
              ctx.beginPath();
              ctx.moveTo(x, afterExposureSliderValuePosition);
              ctx.lineTo(x, focusIndicatorPositionAndSize.minimumExposureSlider);
              ctx.stroke();
            }

            //Draw indicator in the middle of the exposure slider
            let middleExposureSlider = focusIndicatorPositionAndSize.maximumExposureSlider + ((focusIndicatorPositionAndSize.minimumExposureSlider - focusIndicatorPositionAndSize.maximumExposureSlider) / 2);
            let halfMiddleIndicatorLength = (CAMERA_OVERLAY_EXPOSURE_SLIDER_MIDDLE_INDICATOR_RATIO * focusIndicatorPositionAndSize.halfSliderWidth);
            ctx.beginPath();
            ctx.moveTo((x - halfMiddleIndicatorLength), middleExposureSlider);
            ctx.lineTo((x + halfMiddleIndicatorLength), middleExposureSlider);
            ctx.stroke();

            ctx.textBaseline = 'middle';
            ctx.textAlign = 'center';
            ctx.font = `900 ${focusIndicatorPositionAndSize.exposureSliderIconSize}px "Font Awesome 6 Free"`;

            //Draw icons at the end of the sliders
            ctx.fillText(CANVAS_ICONS['plus'], x, (focusIndicatorPositionAndSize.maximumExposureSlider - focusIndicatorPositionAndSize.exposureSliderIconOffset));
            ctx.fillText(CANVAS_ICONS['minus'], x, (focusIndicatorPositionAndSize.minimumExposureSlider + focusIndicatorPositionAndSize.exposureSliderIconOffset));

            //sliderValueSize is the radius, so *2 to get the diameter
            ctx.font = `900 ${sliderValueSize * 2}px "Font Awesome 6 Free"`;
            ctx.fillText(CANVAS_ICONS['sun'], x, exposureSliderValuePosition);
          }

          ctx.font = `900 ${focusIndicatorPositionAndSize.focusButtonIconSize}px "Font Awesome 6 Free"`;
          
          for (let button of focusIndicatorPositionAndSize.focusButtons) {
            let icon = CAMERA_OVERLAY_FOCUS_ICONS[button.key];
            
            if (icon != null) {
              switch (button.key) {
                case 'lock':
                  ctx.fillText((isFocusLocked.value) ? icon.active : icon.inactive, button.x, focusIndicatorPositionAndSize.focusButtonsY);
                  break;
              }
            } else {
              ctx.fillRect(button.xMin, button.yMin, (button.xMax - button.xMin), (button.yMax - button.yMin));
            }
          }
        }
      }
    }

    watch([() => props.targetRect, canvasInstance], ([rect, currentCanvasInstance]) => {
      if (currentCanvasInstance != null && rect != null && rect.width != null && rect.height != null) {
        const ratio = Math.ceil(window.devicePixelRatio);

        let width = rect.width;
        let height = rect.height;
        if (currentCanvasInstance != null) {
          currentCanvasInstance.width = Math.ceil(width * ratio);
          currentCanvasInstance.height = Math.ceil(height * ratio);
          currentCanvasInstance.style.width = `${Math.ceil(width)}px`;
          currentCanvasInstance.style.height = `${Math.ceil(height)}px`;
        }
        updateCanvas();
      }
    }, { immediate: true });

    const interactFocus = function(detail, isEnd = false){
      if (props.focusEnabled) {
        let canvasCoords = getCoordsRelativeToElementFromClientCoords(canvasInstance.value, detail.currentX, detail.currentY);
        let startCanvasCoords = getCoordsRelativeToElementFromClientCoords(canvasInstance.value, detail.startX, detail.startY);

        if (canvasCoords != null && startCanvasCoords != null) {
          let startedInsideFocusInteractionAreas = false;

          if (cameraFocusPoint.value != null) {
            //Calculate where the interaction happened
            let focusPointLocation = {
              x: cameraFocusPoint.value.x * canvasInstance.value.width,
              y: cameraFocusPoint.value.y * canvasInstance.value.height
            }

            let focusIndicatorPositionAndSize = calculateOverlayFocusPositionAndSize(focusPointLocation.x, focusPointLocation.y, canvasInstance.value.width, canvasInstance.value.height);

            if (isCoordsInRectangle(startCanvasCoords.x, startCanvasCoords.y, focusIndicatorPositionAndSize.focusPaddedRectangle)) {
              startedInsideFocusInteractionAreas = true;
            }

            if (isCoordsInRectangle(startCanvasCoords.x, startCanvasCoords.y, focusIndicatorPositionAndSize.focusInteractionSafeArea)) {
              startedInsideFocusInteractionAreas = true;
            }

            if (isCoordsInRectangle(startCanvasCoords.x, startCanvasCoords.y, focusIndicatorPositionAndSize.focusExposureInteractionSafeArea)) {
              startedInsideFocusInteractionAreas = true;
            }

            if (isCoordsInRectangle(startCanvasCoords.x, startCanvasCoords.y, focusIndicatorPositionAndSize.focusInteractionArea)) {
              startedInsideFocusInteractionAreas = true;
              //Check the boundaries of the slider position (maximum is on the top - lower y value in canvas)
              if (canvasCoords.y < focusIndicatorPositionAndSize.maximumSlider) focusDistance.value = 1.0;
              else if (canvasCoords.y > focusIndicatorPositionAndSize.minimumSlider) focusDistance.value = 0.0;
              else {
                focusDistance.value = 1 - ((canvasCoords.y - focusIndicatorPositionAndSize.maximumSlider) / (focusIndicatorPositionAndSize.minimumSlider - focusIndicatorPositionAndSize.maximumSlider));
              }
              context.emit('update:focusDistance', focusDistance.value);
            }

            if (props.exposureEnabled && isCoordsInRectangle(startCanvasCoords.x, startCanvasCoords.y, focusIndicatorPositionAndSize.focusExposureInteractionArea)) {
              startedInsideFocusInteractionAreas = true;
              //Check the boundaries of the slider position (maximum is on the top - lower y value in canvas)
              if (canvasCoords.y < focusIndicatorPositionAndSize.maximumExposureSlider) exposureCompensation.value = MAX_EXPOSURE_VALUE;
              else if (canvasCoords.y > focusIndicatorPositionAndSize.minimumExposureSlider) exposureCompensation.value = MIN_EXPOSURE_VALUE;
              else {
                exposureCompensation.value = (((canvasCoords.y-focusIndicatorPositionAndSize.maximumExposureSlider)/(focusIndicatorPositionAndSize.maximumExposureSlider-focusIndicatorPositionAndSize.minimumExposureSlider) * (MIN_EXPOSURE_VALUE-MAX_EXPOSURE_VALUE)) * -1) + MAX_EXPOSURE_VALUE
              }
              context.emit('update:exposureCompensation', exposureCompensation.value);
            }

            if (isCoordsInRectangle(startCanvasCoords.x, startCanvasCoords.y, focusIndicatorPositionAndSize.focusButtonsArea)) {
              startedInsideFocusInteractionAreas = true;

              for (let button of focusIndicatorPositionAndSize.focusButtons) {
                if (isEnd && isCoordsInRectangle(canvasCoords.x, canvasCoords.y, button)) {
                  switch (button.key) {
                    case 'lock':
                      isFocusLocked.value = (!(isFocusLocked.value));
                      break;
                  }
                  break;
                }
              }
            }
          }

          //Only update focus if not started in focus interaction area
          if (!startedInsideFocusInteractionAreas) {
            cameraFocusPoint.value = {
              x: canvasCoords.x / canvasInstance.value.width,
              y: canvasCoords.y / canvasInstance.value.height
            };
            if (cameraFocusPoint.value != null) context.emit('update:focusPosition', cameraFocusPoint.value);
          }
        }
        
        updateCanvas();
      }
    }

    watch(() => props.focusEnabled, (newEnabledState) => {
      if (newEnabledState !== true) {
        clearCanvas();
      }
    });

    onMounted(() => {
      //Gesture to detect clicks and movement
      const gesture = createGesture({
        el: canvasInstance.value,
        threshold: 0,
        direction: undefined,
        onStart: (detail) => { interactFocus(detail) },
        onMove: (detail) => { interactFocus(detail) },
        onEnd: (detail) => { interactFocus(detail, true) }
      });
      gesture.enable();

      preLoadCanvasIcons();

      //Clear canvas on load
      clearCanvas();
    });

    return {
      canvasInstance
    }
  }
}
</script>

<!-- This element automatically fills a relative parent to the maximum! -->
<style scoped>
.camera-canvas {
  position: absolute;
  /* Added font here to preload it */
  font-family: 'Font Awesome 6 Free';
  font-weight: 900;
}
</style>
//TODO Wäre es sinnvoll, diese Datei als vue Datei zu machen?

import { alertController, toastController } from '@ionic/vue';

export const AUTH_ERRORS = [
  401, //Unauthorized
  402, //Payment Required
  //403, //Forbidden
  407, //Proxy Authentication Required
  511 //Network Authentication Required
];

//Keep track of open toasts to open them only once!
var openToasts = [];

const removeOpenToast = function(message) {
  let toastIndex = openToasts.indexOf(message);
  if (toastIndex > -1) openToasts.splice(toastIndex, 1);
}

const genericError = async function(i18n, localizedMessage, details){ //TODO Errors noch besser machen und designen (Evtl. als Modal, um Details ausklappbar zu machen oder ähnliches)
    let messageString;
    if (localizedMessage !== null){
        messageString = localizedMessage;
    } else {
        messageString = i18n.$t('error.generic_error');
    }
    
    if (details){
        messageString += '<br/><br/> <b>' + i18n.$t('error.details') + ':</b> <code>' + details + '</code>';
    }
    
    const alert = await alertController
        .create({
          header: i18n.$t('error.title'),
          message: messageString,
          buttons: [
            {
              text: i18n.$t('default_interaction.close')
            },
          ],
        });
    return alert.present();
}

const genericErrorToast = function(i18n, localizedMessage, details){ //TODO Errors noch besser machen und designen (Evtl. als Modal, um Details ausklappbar zu machen oder ähnliches)
  let messageString;
  if (localizedMessage !== null){
      messageString = localizedMessage;
  } else {
      messageString = i18n.$t('error.generic_error');
  }
  
  if (details){
      messageString += '\r\n <b>' + i18n.$t('error.details') + ':</b> <tt>' + details + '</tt>';
  }
  

  return new Promise((resolve, reject) => {
    if (!openToasts.includes(messageString)) {
      //Keep track of open toast
      openToasts.push(messageString);
      toastController.create(
        {
          header: i18n.$t('error.title'),
          position: 'top',
          message: messageString,
          duration: 0,
          color: 'danger',
          buttons: [
            {
              text: i18n.$t('default_interaction.close'),
              role: 'cancel'
            }
          ]
        }
      )
      .catch(() => {
        //Remove open toast on error
        removeOpenToast(messageString);
        reject();
      })
      .then((toast) => {
        toast.present()
        toast.onWillDismiss().then(() => {
          //Remove open toast again after closing
          removeOpenToast(messageString);
          resolve();
        });
      });
    } else {
      resolve();
    }
  });
}

export function localError(i18n, localizedMessage, details) { //TODO i18n hier selber laden
  return genericError(i18n, localizedMessage, details);
}

export function localErrorToast(i18n, localizedMessage, details) { //TODO i18n hier selber laden
  return genericErrorToast(i18n, localizedMessage, details);
}

//Save status to only show it once per unreachable message
var unreachableToastVisible = false;

function unreachableErrorToast(i18n){
  return new Promise((resolve, reject) => {
    if (!unreachableToastVisible) {
      unreachableToastVisible = true;
      toastController.create(
        {
          header: i18n.$t('error.unreachable'),
          position: 'top',
          message: i18n.$t('error.try_later'),
          duration: 0,
          color: 'danger',
          buttons: [
            {
              text: i18n.$t('default_interaction.close'),
              role: 'cancel'
            }
          ]
        }
      )
      .catch(() => {
        unreachableToastVisible = false;
        reject();
      })
      .then((toast) => {
        toast.present()
        toast.onWillDismiss().then(() => {
          unreachableToastVisible = false;
          resolve();
        });
      });
    } else {
      resolve();
    }
  });
}

export function decipherApiError(error) {
  let decipheredError = {
    messages: [],
    details: []
  }
  if (error.response && error.response.data && error.response.data.message) {
    if (Array.isArray(error.response.data.message)) {
      for (let message of error.response.data.message){
        if (message.messages && Array.isArray(message.messages)) {
          for (let messageObject of message.messages){
            if (messageObject.message) decipheredError.messages.push(messageObject.message);
          }
        } else {
          decipheredError.messages.push(message);
        }
      }
    } else {
      decipheredError.messages.push(error.response.data.message);
    }

    if (error.response.data.data && error.response.data.data.errors){ //Add error details, if given
      for (let [errorField, fieldMessage] of Object.entries(error.response.data.data.errors)){
        decipheredError.details.push(`${errorField}: ${fieldMessage}`);
      }
    }
  } else if (error.message) {
    decipheredError.messages.push(error.message);
  } else {
    decipheredError.messages.push(JSON.stringify(error));
  }

  return decipheredError;
}

export function apiErrorToast(i18n, error, showUnreachableMessage = false, showNonAuthErrors = true) { //TODO i18n hier selber laden
  return new Promise((resolve, reject) => {
    let header = i18n.$t('error.title');
    let color = 'danger';

    console.error("API Error", error);
    let errorMessage = '';
    if (error.message && error.message === 'unreachable') {
      if (showUnreachableMessage) { //Overrides usual behaviour and shows an offline toast
        return unreachableErrorToast(i18n).then(() => resolve());
      } else {
        return resolve();
      }
    } else {
      if (error.response && error.response.status && AUTH_ERRORS.includes(error.response.status)) {
        color = 'secondary';
        header = i18n.$t('error.session_expired.title');
        errorMessage = i18n.$t('error.session_expired.message');
      } else if (showNonAuthErrors) {
        if (error.response && error.response.data && error.response.data.message) {
          if (Array.isArray(error.response.data.message)) {
            for (let message of error.response.data.message){
              if (message.messages && Array.isArray(message.messages)) {
                for (let messageObject of message.messages){
                  if (messageObject.message) errorMessage += `\r\n<b>${messageObject.message}</b>`;
                }
              } else {
                errorMessage += `\r\n<b>${message}</b>`;
              }
            }
          } else {
            errorMessage += `<b>${error.response.data.message}</b>`;
          }

          if (error.response.data.data && error.response.data.data.errors){ //Add error details, if given
            errorMessage += `\r\n<i>${i18n.$t('error.details')}</i>:`;
            for (let [errorField, fieldMessage] of Object.entries(error.response.data.data.errors)){
              errorMessage += `\r\n\t<tt>${errorField}: ${fieldMessage}</tt>`;
            }
          }
        } else if (error.message) {
            errorMessage = i18n.$t('error.generic_error');
            errorMessage += `\r\n${error.message}`;
        } else {
          errorMessage = i18n.$t('error.generic_error') + `\r\n${JSON.stringify(error)}`;
        }
      } else {
        return resolve();
      }

      if (!openToasts.includes(errorMessage)) {
        //Keep track of open toast
        openToasts.push(errorMessage);
        toastController.create(
          {
            header: header,
            position: 'top',
            message: errorMessage,
            duration: 0,
            color: color,
            buttons: [
              {
                text: i18n.$t('default_interaction.close'),
                role: 'cancel'
              }
            ]
          }
        )
        .catch(() => {
          //Remove open toast on error
          removeOpenToast(errorMessage);
          reject();
        })
        .then((toast) => {
          toast.present()
          toast.onWillDismiss().then(() => {
            //Remove open toast again after closing
            removeOpenToast(errorMessage);
            resolve();
          });
        });
      } else {
        resolve();
      }
    }
  });
}
<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>
          <template v-if="customTitle != null">
            {{ customTitle }}
          </template>
          <template v-else>
          {{ i18n.$t('camera.title.' + ((capturedImage != null || capturedVideo != null) ? 'edit.' : '') + (requiresVideo ? 'video' : (stillFrame ? 'still_frame' : 'image'))) }}
          </template>
          <template v-if="editIndex != null && editCount != null && editCount > 1">
            : {{ editIndex }} / {{ editCount }}
          </template>
        </ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeModal()">{{ i18n.$t('default_interaction.close') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="false" :scroll-y="false"> <!-- FIXME Can be in the way when editing vertical image. Hide while cropping! And allow click through! Disable highlighting! -->
      <ion-card v-if="(stream != null || nativeCameraPreviewRunning || cameraErrorState || captureState.includes('after_capture')) && captureState.length > 0" class="information-container">
        <ion-icon
          :icon="(cameraErrorState && !captureState.includes('after_capture')) ? alertCircleOutline : informationCircleOutline" 
          size="large"
          :color="(cameraErrorState && !captureState.includes('after_capture')) ? 'warning' : undefined">
        </ion-icon>
        <div v-if="!cameraErrorState || (captureState.includes('after_capture'))">{{ captureHint }}</div>
        <div v-else> <!-- Show camera error only while capturing -->
          <b>
            <h2>{{ i18n.$t('camera.unavailable.title') }}</h2>
            <p>{{ i18n.$t('camera.unavailable.hint_list') }}</p>
          </b>
          <p v-for="(hint, index) of captureDescriptions" :key="index">{{ index + 1 }}. {{ hint }}</p
        ></div>
      </ion-card>
      <div v-if="capturedImage != null && enableEditing" class="image-page">
        <div class="image-outer-container">
          <EditingCanvas :show="(capturedImage != null && enableCrop)" :zIndex="1001" ref="editingOverlayCanvas" :showCameraTemplateInside="(enableTemplateOnEditing ? captureType : undefined)" :targetRect="capturedImageElementSize" :aspectRatio="aspectRatio || cameraOptions.aspectRatio" :preserveAspectRatio="aspectRatio != null" @interactionChange="isCanvasInteracting = $event" @updateAspectRatios="checkAspectRatios"></EditingCanvas>
          <img :src="capturedImage" ref="capturedImageElement">
        </div>
        <ion-item lines="none" class="editing-controls">
          <div>
            <div class="button-container">
              <ion-buttons>
                <ion-button fill="clear" color="danger" @click="clearCapturesAndRestart()">
                  <ion-icon slot="icon-only" :icon="close"></ion-icon>
                </ion-button>
              </ion-buttons>
              <div class="center-buttons">
                <ion-buttons v-if="!enforceCrop" class="crop-buttons">
                  <ion-button class="enable-crop" fill="solid" :color="enableCrop ? 'tertiary' : undefined" @click="enableCrop = !enableCrop">
                    <ion-icon slot="icon-only" :icon="cropOutline"></ion-icon>
                  </ion-button>
                </ion-buttons>
              </div>
              <ion-buttons>
                <ion-button fill="clear" color="success" @click="returnImage()" :disabled="isCanvasInteracting">
                  <ion-icon slot="icon-only" :icon="checkmark"></ion-icon>
                </ion-button>
              </ion-buttons>
            </div>
          </div>
        </ion-item>
      </div>
      <div v-if="capturedVideo != null && enableEditing" class="video-page">
        <div class="video-outer-container">
          <EditingCanvas v-if="stillFrame" :show="(capturedVideoMetadata != null && enableCrop)" :zIndex="1001" ref="editingOverlayCanvas" :showCameraTemplateInside="(enableTemplateOnEditing ? captureType : undefined)" :targetRect="capturedVideoPlayerSize" :aspectRatio="aspectRatio || cameraOptions.aspectRatio" :preserveAspectRatio="aspectRatio != null" @interactionChange="isCanvasInteracting = $event" @updateAspectRatios="checkAspectRatios"></EditingCanvas>
          <video ref="capturedVideoPlayer" @error="videoError" @loadeddata="setCapturedVideoMetadata" @durationchange="setCapturedVideoDuration" @timeupdate="setCapturedVideoTime" @play="isCapturedVideoPlaying = true" @pause="isCapturedVideoPlaying = false" muted playsinline disablePictureInPicture>
            <source :src="capturedVideo" :type="overridenFileType || undefined">
          </video>
        </div>
        <ion-item lines="none" class="editing-controls">
          <div> <!-- FIXME If in chrome macos the metadata could not be fetched, the duration might not be divisable because of the wrong framerate, thus the last tick is not at the end of the range -->
            <ion-range 
              :style="processingVideo ? 'visibility: hidden;' : ''"
              :min="capturedVideoFrameTime"
              :disabled="capturedVideoMetadata == null || isCapturedVideoPlaying || isCanvasInteracting"
              :max="(capturedVideoMetadata != null) ? capturedVideoMetadata.duration : 0"
              :step="capturedVideoFrameTime"
              color="secondary"
              :value="currentCapturedVideoPosition"
              @ionChange="(isCapturedVideoPlaying) ? null : (currentCapturedVideoPosition = $event.target.value)"
              snaps> <!-- Update of the video position is only called while the video is not running. Otherwise it will re-call its own handler -->
              <!-- TODO Make a second range for trimming in this page and only show the correct one for the format -->
              <ion-label class="frame-indicator" slot="end">
                {{ currentCapturedFrameIndex }}
                /
                {{(capturedVideoMetadata != null) ? capturedVideoMetadata.FrameCount : 0}}</ion-label>
            </ion-range>
            <div class="button-container">
              <ion-buttons>
                <ion-button fill="clear" color="danger" @click="clearCapturesAndRestart()">
                  <ion-icon slot="icon-only" :icon="close"></ion-icon>
                </ion-button>
              </ion-buttons>
              <div class="center-buttons">
                <div v-if="stillFrame" class="fill-element"></div>
                <ion-buttons class="scrub-buttons">
                  <ion-button fill="solid" color="primary" shape="round" @click="decrementCapturedVideoFrame" :disabled="capturedVideoMetadata == null || isCapturedVideoPlaying || isCanvasInteracting">
                    <ion-icon slot="icon-only" :icon="playSkipBack"></ion-icon>
                  </ion-button>
                  <ion-button fill="solid" color="secondary" @click="toggleCapturedVideoPlayState" :disabled="capturedVideoMetadata == null || isCanvasInteracting">
                    <ion-icon slot="icon-only" :icon="isCapturedVideoPlaying ? pause : play"></ion-icon>
                  </ion-button>
                  <ion-button fill="solid" color="primary" shape="round" @click="incrementCapturedVideoFrame" :disabled="capturedVideoMetadata == null || isCapturedVideoPlaying || isCanvasInteracting">
                    <ion-icon slot="icon-only" :icon="playSkipForward"></ion-icon>
                  </ion-button>
                </ion-buttons>
                <ion-buttons v-if="stillFrame && !enforceCrop" class="crop-buttons">
                  <ion-button v-if="stillFrame" class="enable-crop" fill="solid" :color="enableCrop ? 'tertiary' : undefined" @click="enableCrop = !enableCrop">
                    <ion-icon slot="icon-only" :icon="cropOutline"></ion-icon>
                  </ion-button>
                </ion-buttons>
                <div v-else-if="stillFrame" class="fill-element"></div>
              </div>
              <ion-buttons>
                <ion-button fill="clear" color="success" @click="returnVideo()" :disabled="capturedVideoMetadata == null || isCapturedVideoPlaying || isCanvasInteracting">
                  <ion-icon slot="icon-only" :icon="checkmark"></ion-icon>
                </ion-button>
              </ion-buttons>
            </div>
          </div>
        </ion-item>
      </div>
      <div v-if="!hasMediaBeenCaptured" ref="cameraPage" class="camera-page">
        <div class="camera-outer-container">
          <CameraOverlayCanvas id="camera-overlay" :targetRect="cameraOverlaySize" v-model:focusPosition="cameraFocusPoint" v-model:focusDistance="computedCameraFocusValue" v-model:focusLocked="computedCameraFocusLocked" :focusEnabled="(stream != null || nativeCameraPreviewRunning) && isCameraFocusSupported" :exposureEnabled="isCameraExposureCompensationSupported" v-model:exposureCompensation="computedCameraExposureCompensationValue"></CameraOverlayCanvas>
          <CameraTemplate id="camera-template-preview" :type="captureType" :targetRect="cameraOverlaySize" :show="stream != null || nativeCameraPreviewRunning"></CameraTemplate>
          <!-- Camera view -->
          <video ref="videoPlayer" id="camera-view" :class="[nativeCameraPreviewRunning ? 'invisible-camera-preview' : undefined]" :srcObject="stream" @error="videoError" :autoplay="nativeCameraPreviewRunning ? false : true" muted playsinline disablePictureInPicture></video> <!-- On native the video preview never plays, to not use additional resources -->
        </div>
        <ion-fab v-if="!hasMediaBeenCaptured" vertical="bottom" :horizontal="isLandscape ? 'end' : 'start'" slot="fixed">
          <input ref="mediaUpload" type="file"
          :accept="acceptFileTypeUpload"
          style="display: none;"
          @change="fileChosen">
          <ion-fab-button @click="mediaUpload.click()" :class="(cameraErrorState || onErrorHasRetried) ? 'attention' : ''"> <!-- On second try or on error highlight gallery as an option. -->
            <ion-icon :icon="image"></ion-icon>
          </ion-fab-button>
        </ion-fab>
        <ion-fab id="camera-trigger-fab" v-if="!hasMediaBeenCaptured" :vertical="isLandscape ? 'center' : 'bottom'" :horizontal="isLandscape ? 'end' : 'center'" slot="fixed"
        :style="(stream == null && nativeCameraPreviewRunning == false) ? 'display:none' : ''">
          <!-- In Safari Desktop the stream is created and the camera might need some time to turn on, while the recording is already possible. But capturing still starts from the first frame, so all good. -->
          <ion-fab-button v-if="enableManualCapture" @click="cameraTrigger()" 
            id="camera-trigger-button"
            :disabled="currentlyRecording && processingVideo">
            <CircularProgress
              class="capture-progress"
              :style="currentlyRecording ? '--color: var(--ion-color-secondary-text);' : ''"
              :progress="captureProgress"
              :showProgress="currentlyRecording">
              <ion-icon :icon="currentlyRecording ? (processingVideo ? hourglassOutline : stop) : ((requiresVideo || stillFrame) ? videocam : camera)"></ion-icon>
            </CircularProgress>
          </ion-fab-button>
          <div class="additional-camera-controls" :style="isCameraZoomSupported ? undefined : 'display: none;'">
            <ion-button ref="zoomOutButton" class="zoom-out" fill="outline" :disabled="!isZoomOutPossible">
              <font-awesome-icon slot="icon-only" :icon="faMagnifyingGlassMinus" />
            </ion-button>
            <ion-button ref="zoomInButton" class="zoom-in" fill="outline" :disabled="!isZoomInPossible">
              <font-awesome-icon slot="icon-only" :icon="faMagnifyingGlassPlus" />
            </ion-button>
          </div>
        </ion-fab>
        <ion-fab v-if="!hasMediaBeenCaptured" :vertical="isLandscape ? 'top' : 'bottom'" horizontal="end" slot="fixed"
        :style="(stream != null || nativeCameraPreviewRunning == true) && flashSupported ? '' : 'display:none'"
        :class="(captureState === 'flash_off') ? 'attention' : ''">
          <ion-fab-button @click="toggleFlash()">
            <ion-icon :icon="flashActive ? flash : flashOff"></ion-icon>
          </ion-fab-button>
        </ion-fab>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { isPlatform, IonFab, IonFabButton, IonIcon, IonPage, IonTitle, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonCard, IonItem, IonRange, IonLabel, modalController, createGesture } from '@ionic/vue';

import { camera, videocam, image, flash, flashOff, stop, hourglassOutline, informationCircleOutline, alertCircleOutline, close, checkmark, play, pause, playSkipBack, playSkipForward, cropOutline } from 'ionicons/icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faMagnifyingGlassPlus, faMagnifyingGlassMinus } from '@fortawesome/free-solid-svg-icons';

import { defineComponent, ref, computed, onMounted, watch, onBeforeUnmount } from 'vue';

import CircularProgress from '@/components/CircularProgress.vue';

import EditingCanvas from '@/components/EditingCanvas.vue';

import CameraOverlayCanvas from '@/components/CameraOverlayCanvas.vue';

import CameraTemplate from '@/components/CameraTemplate.vue';

import { useI18n } from "@/utils/i18n";

import { useBarcodeScanner } from "@/components/camera/barcodeScanner";

import { localError, localErrorToast } from '@/utils/error';

import { getVideoMetadata, getFrameInfoFromMetadata, base64ToBlob, splitDataURL, videoElementToScaledBlobOrCanvas, rescaleBlobImage } from '@/utils/media';

import { slowModalFadeOutAnimation, zeroModalAnimation } from '@/utils/animations';

import { holdButton } from '@/utils/interaction';

import { mapZeroToOneRangeToLog } from '@/utils/algorithms';

import _ from 'lodash';

const CameraModal = defineComponent({
  name: 'Camera',
  components: { IonFab, IonFabButton, IonIcon, IonPage, IonTitle, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonCard, IonItem, IonRange, IonLabel, CircularProgress, EditingCanvas, CameraOverlayCanvas, CameraTemplate, FontAwesomeIcon },
  props: {
    'requiresVideo': Boolean,
    'stillFrame': Boolean,
    'flashSuggested': Boolean,
    'captureType': String,
    'automaticCaptureType': String, //Used for QR code or automatic AI triggering //Automatic captures can set data that will get returned in addition to the image
    'automaticCaptureUseFullsize': { //Uses fullsize frames instead of thumbnail for automatic detection algorithm
      type: Boolean,
      default: false
    },
    'enableManualCapture': {
      type: Boolean,
      default: true
    },
    'enableEditing': { //Skips the editing step and immediately returns, if false
      type: Boolean,
      default: true
    },
    'width': { //TODO Check if width, height and cameraDirection can be set from here! TEST on iOS and Android
      type: Number,
      default: 1920
    },
    'height': {
      type: Number,
      default: 1080
    },
    'aspectRatio': Number,
    'cameraDirection': {
      type: String,
      default: 'back'
    },
    'selectedFile': Object,
    //Just visuals that can be set, to indicate more than one file being edited. Camera can handle only one file at a time, and has to be reopened for multiple!
    'editIndex': Number,
    'editCount': Number,
    'enableTemplateOnEditing': {
      type: Boolean,
      default: true
    },
    'customTitle': String
  },
  setup(props) {
    const i18n = useI18n();

    const cameraPage = ref(null);

    const cameraOverlayCanvas = ref(null);
    const videoPlayer = ref(null);
    const capturedVideoPlayer = ref(null);
    const capturedImageElement = ref(null);
    const editingOverlayCanvas = ref(null);
    const mediaUpload = ref(null);
    const iosCameraCanvas = ref(document.createElement('canvas'));

    const videoPlayerSize = ref({x: 0, y: 0, width: 0, height: 0});
    const capturedImageElementSize = ref({x: 0, y: 0, width: 0, height: 0});
    const capturedVideoPlayerSize = ref({x: 0, y: 0, width: 0, height: 0});
    const nativeCameraPreviewSize = ref({x: 0, y: 0, width: 0, height: 0});
    
    const cameraOverlaySize = computed(() => {
      if (nativeCameraPreviewRunning.value) {
        return nativeCameraPreviewSize.value;
      }
      return videoPlayerSize.value;
    });

    //Needs to support canvas or blob as input for processing. Needs to throw error if it only supports blow
    //Each automatic capture should give back an object with: ([optionally] data as an array, each value can have a bounding box) and can give two boolean flags: startCapture (starts recording), returnData (returns with the captured frame). Both return the current data from this function
    const setupAutomaticCapture = function() {
      switch(props.automaticCaptureType) {
        case 'barcodeScanner':
          return useBarcodeScanner();

        default:
          return null;
      }
    }

    const automaticCaptureInstance = setupAutomaticCapture();

    const hasBeenDismissed = ref(false);

    const cameraErrorState = ref(false);
    const onErrorHasRetried = ref(false);
    const enableCrop = ref(false);
    const enforceCrop = ref(false);
    const isCanvasInteracting = ref(false);

    const preSelectedFile = ref(props.selectedFile);

    //Containers and codecs specified in this order will be tried with a fallback being the default for the device
    const acceptedVideoRecordingCodecs = ['video/mp4', 'video/webm;codecs=h264', 'video/webm;codecs=vp8', 'video/webm;codecs=vp9', 'video/webm'];
    const MIN_BITRATE = 5000000;
    const BITRATE_FACTOR = 12;

    //Accomodate for limitations of mobile devices //TODO Add notification to download app for higher quality video! After Android is also available natively
    const MAX_MEDIARECORDER_VIDEOSIZE = {
      width: 800,
      height: 480
    }

    //Object with x, y coordinates (in percent of the image) or null if not set
    const cameraFocusPoint = ref(null);
    const cameraFocusDistance = ref(0.5);
    const cameraExposureCompensation = ref(0.0);
    const cameraFocusLocked = ref(false);
    const cameraZoomFactor = ref(1.0);
    const resetCameraZoomAndFocus = function() {
      cameraFocusPoint.value = null;
      cameraFocusDistance.value = 0.5;
      cameraFocusLocked.value = false;
      cameraZoomFactor.value = 1.0;
      cameraExposureCompensation.value = 0.0;
    }
    //Always should include min and max, if null then no zoom supported
    const supportedCameraZoomBoundaries = ref(null);
    const isCameraZoomSupported = computed(() => {
      return (supportedCameraZoomBoundaries.value != null &&
              supportedCameraZoomBoundaries.value.min != null &&
              supportedCameraZoomBoundaries.value.max != null);
    });
    const zoomStep = computed(() => {
      if (supportedCameraZoomBoundaries.value != null &&
          supportedCameraZoomBoundaries.value.step != null) {
        return supportedCameraZoomBoundaries.value.step;
      } else if (isCameraZoomSupported.value) {
        return (supportedCameraZoomBoundaries.value.max - supportedCameraZoomBoundaries.value.min) / 10;
      }
      return 0.1;
    });
    const isZoomOutPossible = computed(() => {
      return (isCameraZoomSupported.value && cameraZoomFactor.value != null && cameraZoomFactor.value > supportedCameraZoomBoundaries.value.min);
    });
    const isZoomInPossible = computed(() => {
      return (isCameraZoomSupported.value && cameraZoomFactor.value != null && cameraZoomFactor.value < supportedCameraZoomBoundaries.value.max);
    });

    const zoomInButton = ref(null);
    const zoomOutButton = ref(null);

    //Always should include min and max, if null then no manual focus supported
    const supportedCameraFocusBoundaries = ref(null);

    const isCameraFocusSupported = computed(() => {
      return (supportedCameraFocusBoundaries.value != null &&
              supportedCameraFocusBoundaries.value.min != null &&
              supportedCameraFocusBoundaries.value.max != null);
    });

    //Always should include min and max, if null then no exposure compensation supported
    const supportedCameraExposureBoundaries = ref(null);

    const isCameraExposureCompensationSupported = computed(() => {
      return (supportedCameraExposureBoundaries.value != null &&
              supportedCameraExposureBoundaries.value.min != null &&
              supportedCameraExposureBoundaries.value.max != null);
    });

    const calculateScaledFocusValue = function(focusRatio) {
      if (isCameraFocusSupported.value) {
        let adjustedFocusRatio = focusRatio;
        //Only scale if not native
        if (!(nativeCameraPreviewRunning.value)) {
          adjustedFocusRatio = mapZeroToOneRangeToLog(adjustedFocusRatio, true);
        }
        let currentStep = (supportedCameraFocusBoundaries.value.max - supportedCameraFocusBoundaries.value.min) * adjustedFocusRatio;

        if (supportedCameraFocusBoundaries.value.step != null) {
          //Normalize to be a multiple of step, if it exists
          let currentStepCount = Math.round(currentStep / supportedCameraFocusBoundaries.value.step);
          currentStep = supportedCameraFocusBoundaries.value.step * currentStepCount;
        }
        return (supportedCameraFocusBoundaries.value.min + currentStep);
      }
      return null;
    }
    /* const calculateFocusRatioFromValue = function(focusValue) {
      if (isCameraFocusSupported.value) {
        return (focusValue - supportedCameraFocusBoundaries.value.min) / (supportedCameraFocusBoundaries.value.max - supportedCameraFocusBoundaries.value.min)
      }
      return null;
    } */

    const calculateScaledExposureCompensationValue = function(exposureCompensation) {
      if (isCameraExposureCompensationSupported.value) {
        let usedBoundary;
        if (exposureCompensation >= 0.0) {
          usedBoundary = supportedCameraExposureBoundaries.value.max;
        } else {
          usedBoundary = supportedCameraExposureBoundaries.value.min;
        }

        let currentStep = (exposureCompensation * usedBoundary);

        if (supportedCameraExposureBoundaries.value.step != null) {
          //Normalize to be a multiple of step, if it exists
          let currentStepCount = Math.round(currentStep / supportedCameraExposureBoundaries.value.step);
          currentStep = supportedCameraExposureBoundaries.value.step * currentStepCount;
        }
        if (exposureCompensation < 0.0) {
          currentStep *= -1;
        }

        return currentStep;
      }
      return null;
    }

    //Use computed getters and setters to only update the constraints when user changed it!
    const computedCameraFocusValue = computed({
      get: () => cameraFocusDistance.value, //TODO Would be better decoupled if it is in log scale, so it can be updated from the camera at any point
      set: (newFocusRatio) => {
        cameraFocusDistance.value = newFocusRatio;
        setFocusDistance(calculateScaledFocusValue(newFocusRatio), cameraFocusLocked.value);
      }
    });
    const computedCameraFocusLocked = computed({ //FIXME Camera video element gets pulled in the foreground on iOs when it loads!
      get: () => cameraFocusLocked.value,
      set: (newLockedState) => {
        cameraFocusLocked.value = newLockedState;
        setFocusDistance(undefined, newLockedState);
      }
    });
    const computedCameraExposureCompensationValue = computed({
      get: () => cameraExposureCompensation.value,
      set: (newExposureCompensation) => {
        let scaledExposure = calculateScaledExposureCompensationValue(newExposureCompensation);
        //Snap to 0, if close enough
        cameraExposureCompensation.value = (scaledExposure > -0.1 && scaledExposure < 0.1) ? 0.0 : newExposureCompensation;
        setExposureCompensation(scaledExposure);
      }
    });

    const capturedVideoDuration = ref(0);
    const capturedVideoFrameInfo = ref(null);
    const capturedVideoMetadata = computed(() => {
      if (capturedVideoFrameInfo.value == null) {
        return null;
      }

      let duration = capturedVideoDuration.value;
      if (duration == Infinity || duration == null) {
        duration = 0;
      }

      let metadata = {
        FrameRate: (capturedVideoFrameInfo.value.FrameRate != null) ? capturedVideoFrameInfo.value.FrameRate : 25,
        duration
      }

      if (capturedVideoFrameInfo.value.FrameCount > 0) {
        metadata.FrameCount = capturedVideoFrameInfo.value.FrameCount;
      } else {
        metadata.FrameCount = Math.round(duration * metadata.FrameRate);
      }

      return metadata;
    });
    const capturedVideoFrameTime = computed(() => {
      if (capturedVideoMetadata.value != null && capturedVideoMetadata.value.FrameRate != null) {
        return 1 / capturedVideoMetadata.value.FrameRate;
      } else {
        return 0.04;
      }
    });
    const currentCapturedFrameIndex = computed(() => {
      if (capturedVideoMetadata.value != null && capturedVideoMetadata.value.FrameRate != null) {
        return Math.round(currentCapturedVideoPosition.value * capturedVideoMetadata.value.FrameRate);
      } else {
        return 0;
      }
    });

    const currentCapturedVideoTime = ref(0);
    const isCapturedVideoPlaying = ref(false);

    const setCapturedVideoTime = function() {
      //For protection reasons browsers may not update the currentTime when paused, so only use this to update while playing
      if (isCapturedVideoPlaying.value) {
        currentCapturedVideoTime.value = capturedVideoPlayer.value.currentTime;
      }
    }

    //TODO When doing a double range do not allow playing outside of trimmed area, indicate play position in some other way (pin on the bar or smth). When moving left or right handle, stop video and seek there to indicate current searching frame.
    const currentCapturedVideoPosition = computed({
      get: () => {
        return currentCapturedVideoTime.value;
      },
      set: (newPosition) => {
        if (capturedVideoPlayer.value != null) {
          if (newPosition > capturedVideoPlayer.value.duration) newPosition = capturedVideoPlayer.value.duration; //Limit max to video length
          if (currentCapturedVideoTime.value != newPosition) { //Only update if it changed
            capturedVideoPlayer.value.currentTime = currentCapturedVideoTime.value = newPosition;
          }
        }
      } 
    });

    const setCapturedVideoDuration = function() {
      capturedVideoDuration.value = capturedVideoPlayer.value.duration;
      if (capturedVideoDuration.value == Infinity) {
        capturedVideoPlayer.value.currentTime = 1e101; //Fix for webkit browsers like Chrome on macOS. Set the time to a really high value to force load the video, then we get the correct duration
      } else {
        capturedVideoPlayer.value.currentTime = capturedVideoFrameTime.value;
      }
    }

    const setCapturedVideoMetadata = function() {
      //Set default values
      currentCapturedVideoTime.value = 0;
      isCapturedVideoPlaying.value = false;
      capturedVideoPlayer.value.pause();

      //Create a File Object out of the blob from the file and then use utils to get the metadata for it
      fetch(capturedVideo.value)
      .then(videoResult => videoResult.blob())
      .then(blobFile => new File([blobFile], 'capture'))
      .then(videoFile => getVideoMetadata(videoFile))
      .then(metadata => {
        let frameInfo = getFrameInfoFromMetadata(metadata);
        if (frameInfo == null) {
          frameInfo = {
            FrameRate: 25
          }
        }
        capturedVideoFrameInfo.value = frameInfo;
      })
      .catch((error) => {
        console.error('Could not load metadata for video', error);
        capturedVideoFrameInfo.value = {
            FrameRate: 25
        };
      })
      .finally(() => {
        currentCapturedVideoTime.value = capturedVideoFrameTime.value; //Set to first frame initially
        processingVideo.value = false;
      });
    }

    //Pause video when an interaction occurs
    watch(isCanvasInteracting, (isInteracting) => {
      if (isInteracting && capturedVideoPlayer.value != null) capturedVideoPlayer.value.pause();
    })

    const toggleCapturedVideoPlayState = function(){
      if (capturedVideoPlayer.value != null) {
        if (isCapturedVideoPlaying.value) {
          capturedVideoPlayer.value.pause();
        } else {
          capturedVideoPlayer.value.play();
        }
      }
    }

    const incrementCapturedVideoFrame = function() {
      currentCapturedVideoPosition.value += capturedVideoFrameTime.value;
    }

    const decrementCapturedVideoFrame = function() {
      let newVideoPosition = currentCapturedVideoPosition.value - capturedVideoFrameTime.value;
      if (newVideoPosition > (capturedVideoFrameTime.value / 2)) { //Allow substraction only to the first frame, not below
        currentCapturedVideoPosition.value = newVideoPosition;
      }
    }

    const nativeCameraPreviewRunning = ref(false);
    const stream = ref(null); //Stores the camera stream. null if uninitialized

    const flashSupported = ref(false);
    const flashActive = ref(false);

    const RECORDING_TIMESLICE = 100; //Time in milliseconds each blob contains in a sliced recording
    const MAX_VIDEO_TIME = 60 * 1000; //Allow maximum 60 seconds of video
    const STILL_FRAME_TIME = 5000; //Time to hold still for capturing still frame

    const capturedImage = ref(null);
    const capturedVideo = ref(null);
    const currentData = ref(null);

    const overridenFileType = ref(null);

    const hasMediaBeenCaptured = computed(() => capturedImage.value != null || capturedVideo.value != null);
    const processingVideo = ref(false);

    const recorder = ref(null);
    const captureProgress = ref(-1);
    const nativeRecordingPromiseResolve = ref(null);
    const nativeImageCapturePromise = ref(null);
    const nativeImageAutomaticCapturePromise = ref(null);

    const landscapeQuery = window.matchMedia("(orientation: landscape)");

    const isLandscape = ref(landscapeQuery.matches);

    landscapeQuery.addEventListener('change', (query) => {
      isLandscape.value = query.matches;
    });

    watch([capturedVideo, capturedVideoPlayer], ([newVideo, player]) => {
      if (newVideo != null && player != null) {
        player.load(); //FIXME Sometimes the ios recording can't be loaded. Stays black. Wrong recording way? Noticed that when recording starts too fast it never fills it correctly. Some wait time between recording initialization and first frame is required! Race condition? Started recording while a frame was drawn? Maybe should defer recording flag being set until we can be certain that it is ready? Maybe a method to check readiness?
      }
    });

    watch([stream, videoPlayer], ([newStream, player]) => {
      if (newStream != null && player != null) {
        player.load();
      }
    });

    //Reset capture related states
    watch(hasMediaBeenCaptured, (captured) => {
      isCanvasInteracting.value = false;

      if (!captured) {
        //Only set when leaving capture preview again
        enableCrop.value = false;
        enforceCrop.value = false;
      }
    });

    //Check if aspect ratio matches desired one and enforce if not - Called on each start of the editing canvas, sets the correct state
    const checkAspectRatios = function(aspectRatios){
      if (props.aspectRatio != null && Math.abs(aspectRatios.canvas - aspectRatios.element) > 0.001) {
        enableCrop.value = true;
        enforceCrop.value = true;
        return;
      }
      enableCrop.value = false;
      enforceCrop.value = false;
    }

    const currentlyRecording = computed(() => {
      return recorder.value != null || (captureProgress.value >= 0 && captureProgress.value < 100);
    });

    const captureState = computed(() => {
      if (currentlyRecording.value) {
        return 'during_capture';
      } else if (hasMediaBeenCaptured.value) {
        if (!(props.enableEditing)) return ''; //Do not show any information when the modal returns immediately
        if (capturedVideo.value != null) {
          if (props.stillFrame) {
            return 'after_capture_still';
          } else {
            return ''; //TODO Add proper return, once trimming works //return 'after_capture_video'; 
          }
        } else {
          return 'after_capture_image';
        }
      } else if (props.flashSuggested && flashSupported.value && !flashActive.value) {
        return 'flash_off';
      } else {
        return 'before_capture';
      }
    });

    const captureDescriptions = computed(() => {
      let captureType = props.captureType || 'default';
      
      let hints = [];
      let captureHintObject = i18n.$t(`camera.hint.${captureType}`);
      if (captureHintObject == null) //Use default as fallback if a captureType does not exist
        captureHintObject = i18n.$t(`camera.hint.default`);
      //Exclude hints of the editor, handled separately. Also don't include flash hints if flash is not suggested.
      for (let [state, hint] of Object.entries(captureHintObject)) {
        if (!state.includes('after_capture') && !state.includes('descriptor') && !(!props.flashSuggested && state === 'flash_off')) hints.push(hint);
      }
      return hints;
    });

    const captureHint = computed(() => {
      let captureType = props.captureType || 'default';
      let hint = i18n.$t(`camera.hint.${captureType}.${captureState.value}`);
      if (hint != null && hint.length > 0) 
        return hint;
      else //Use default as fallback if a hint is not provided for that state
        return i18n.$t(`camera.hint.default.${captureState.value}`);
    });

    const acceptFileTypeUpload = computed(() => {
      if (props.requiresVideo) {
        return 'video/*';
      } else if (props.stillFrame) {
        return 'video/*,image/*'
      } else {
        return 'image/*';
      }
    });

    //Attach close listener to this modal through the modal controller when it opens
    modalController.getTop()
      .then((modal) => {
        //Close camera before it dismissed
        modal.onWillDismiss().then(() => closeCameraResources());
        //After the dismiss clear up resources - Keep them for the dismiss animation for smoother transitions
        modal.onDidDismiss().then((resultURL) => {
          //Clear the captures, when they are not the ones given back from the modal, to save on resources
          if (capturedVideo.value != null && resultURL.data != null && capturedVideo.value != resultURL.data.url) URL.revokeObjectURL(capturedVideo.value);
          capturedVideo.value = null;

          if (capturedImage.value != null && resultURL.data != null && capturedImage.value != resultURL.data.url) URL.revokeObjectURL(capturedImage.value);
          capturedImage.value = null;

          overridenFileType.value = null;
        });
      })
      

    const cameraUnavailable = function(permissionMissing = false){
      console.error('Camera unavailable. Permission missing?: ' + permissionMissing);
      cameraErrorState.value = true;
      nativeCameraPreviewRunning.value = false;
      stream.value = null;
      flashSupported.value = false;
    }

    const retryRecording = function() {
      localErrorToast(i18n, i18n.$t('camera.error_retry'));
      closeCameraResources();
      clearCaptures();
      //Only retry once during each session
      if (!onErrorHasRetried.value) {
        onErrorHasRetried.value = true;
        setTimeout(() => restartCamera(), 500); //Delay restart a bit to be ready!
      } else {
        cameraErrorState.value = true;
      }
    }

    const videoError = function(error) {
      console.error("Could not load video: ", error);
      retryRecording();
    } //FIXME After going to sleep captureStream is no longer valid! Only sometimes!

    const closeModal = function(returnValue, returnType = undefined){
      hasBeenDismissed.value = true;
      let returnObject = null;
      if (returnValue != null) {
        returnObject = {
          url: returnValue,
          type: returnType,
          metadata: currentData.value
        }
      }
      try {
        if (document.fullscreenElement != null) document.exitFullscreen();
      } catch {
        //Do nothing if fullscreen is not supported
      }
      return modalController.dismiss(returnObject);
    }

    const closeCameraResources = function(){
      if (currentlyRecording.value && recorder.value != null && recorder.value.state == "recording") {
        recorder.value.stop();
      }
      if (nativeCameraPreviewRunning.value) {
        window.plugin.CanvasCamera.stop(null, () => {
          nativeCameraPreviewRunning.value = false;
        });
      } 
      nativeRecordingPromiseResolve.value = null;
      nativeImageCapturePromise.value = null;
      nativeImageAutomaticCapturePromise.value = null;
      
      if (stream.value != null) {
        stream.value.getTracks().forEach((track) => {
          track.stop();
        });
        stream.value = null;
      }
    }

    const clearCaptures = function() {
      if (capturedVideo.value != null) URL.revokeObjectURL(capturedVideo.value);
      capturedVideo.value = null;

      if (capturedImage.value != null) URL.revokeObjectURL(capturedImage.value);
      capturedImage.value = null;

      overridenFileType.value = null;

      capturedVideoDuration.value = 0;
      capturedVideoFrameInfo.value = null;
      currentCapturedVideoTime.value = 0;
      isCapturedVideoPlaying.value = false;
    }

    const restartCamera = function() {
      //If a preset file has been given, we don't invoke the capture screen
      if (preSelectedFile.value != null) {
        //We don't capture, return
        closeModal();
      } else {
        //New capture starts, open the camera again
        initializeCamera();
      }
    }

    const clearCapturesAndRestart = function() {
      clearCaptures();
      restartCamera();
    }

    function initializeUserMediaCamera(cameraOptions){
      // Check if API is available
      if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)){
        console.error('UserMedia API is not available. Camera can not be used');
        cameraUnavailable();
        return;
      }

      let facingMode = 'environment';
      if (cameraOptions.camera == 'front') {
        facingMode = 'user';
      }

      flashActive.value = false;

      let constraints = {
        video: {
          facingMode,
          width: (props.requiresVideo || props.stillFrame) ? Math.min(cameraOptions.width, MAX_MEDIARECORDER_VIDEOSIZE.width) : cameraOptions.width,
          height: (props.requiresVideo || props.stillFrame) ? Math.min(cameraOptions.height, MAX_MEDIARECORDER_VIDEOSIZE.height) : cameraOptions.height,
          //Always allow full recording frame and let user crop later //aspectRatio: cameraOptions.aspectRatio
        },
        advanced: [{
          torch: false, //Turn off flash by default
          zoom: cameraZoomFactor.value, //Initialize zoom
          focusMode: (cameraFocusLocked.value) ? 'manual' : 'continuous'
        }] 
      };

      return navigator.mediaDevices.getUserMedia(constraints)
      .then((newStream) => {
        stream.value = newStream;

        let track = stream.value.getTracks()[0];

        // Workaround for slow initialization of camera
        window.setTimeout(() => {
          let capabilities = track.getCapabilities();
          if (!hasBeenDismissed.value) {
            if ('torch' in capabilities) {
              flashSupported.value = true;
            }
            if ('zoom' in capabilities) {
              supportedCameraZoomBoundaries.value = capabilities.zoom;
            }
            if ('focusDistance' in capabilities && 'focusMode' in capabilities) {
              supportedCameraFocusBoundaries.value = capabilities['focusDistance'];
            }
            if ('exposureCompensation' in capabilities) {
              supportedCameraExposureBoundaries.value = capabilities.exposureCompensation;
            }
          }
        }, 1000);

        if (hasBeenDismissed.value) closeCameraResources(); //Catch the case where the modal is closed before the camera has been initialized
      })
      .catch((error) => {
        console.error(error);
        cameraUnavailable(true);
      });
    }

    function setFile(file, errorCallback) {
      if (file != null && ((file instanceof Blob) || (file.blobURL != null))) { //Accept either Blob or File objects and convert them to blobURL OR use given fileObject with blobURL set!
        if (((file.type != null && file.type.includes('image')) || (file.mime != null && file.mime.includes('image'))) && acceptFileTypeUpload.value.includes('image')) { //Check if the file is an image and images are accepted
          if (file instanceof Blob) {
            capturedImage.value = URL.createObjectURL(file);
          } else if (file.blobURL != null) {
            capturedImage.value = file.blobURL;
          }
        } else if (((file.type != null && file.type.includes('video')) || (file.mime != null && file.mime.includes('video')))  && acceptFileTypeUpload.value.includes('video')) { //Check if the file is a video and videos are accepted
          if (videoPlayer.value.canPlayType(file.type || file.mime) !== '') { //Take first non-null of type or mime to check if it can be played!
            processingVideo.value = true;
            if (file instanceof Blob) {
              capturedVideo.value = URL.createObjectURL(file);
            } else if (file.blobURL != null) {
              capturedVideo.value = file.blobURL;
            }
          } else {
            let callbackComplete = Promise.resolve();
            if (errorCallback) callbackComplete = callbackComplete.then(() => errorCallback());
            return callbackComplete.then(() => localError(i18n, i18n.$t('camera.file-type-not-supported'))).then(() => false);
          }
        } else {
          let callbackComplete = Promise.resolve();
          if (errorCallback) callbackComplete = callbackComplete.then(() => errorCallback());
          return callbackComplete.then(() => localError(i18n, i18n.$t('camera.invalid-file-type'))).then(() => false);
        }
      }
      return Promise.resolve(true);
    }

    function fileChosen(){
      //Only use if upload was successful, i.e. not cancelled
      if (mediaUpload.value.files.length > 0) {
        let file = mediaUpload.value.files[0];

        if (automaticCaptureInstance != null && automaticCaptureInstance.forwardUploadedFilesToAutomaticCapture) {
          processNextPictureAutomaticCapture(file);
        } else {
          setFile(file).then((success) => {
            if (success) closeCameraResources();
          });
        }
      }
    }

    const captureNativeVideo = function(lengthInMS) {
      if (nativeCameraPreviewRunning.value) { 
        //Reset processing state just in case it didn't reset after finishing
        processingVideo.value = false;
        nativeRecordingPromiseResolve.value = null;

        let progressUpdateInterval = null;

        let nativeRecordingPromise = new Promise((resolve, reject) => {
          window.plugin.CanvasCamera.startVideoRecording((error) => {
            reject(error);
          },
          (result) => {
            if (result != null) {
              let videoURL = result.videoURL;
              if (videoURL == null) {
                reject('Native videoURL not provided!');
                return;
              }
              //Create a function to resolve from the outside
              nativeRecordingPromiseResolve.value = () => resolve(videoURL);

              captureProgress.value = 0;
              progressUpdateInterval = setInterval(() => {
                if (captureProgress.value < 100) captureProgress.value += 1;
                else {
                  clearInterval(progressUpdateInterval);
                  resolve(videoURL);
                }
              }, lengthInMS / 100); //In percent progress, so update every 100th of the total length
            }
          });
        });

        return new Promise((resolve, reject) => {
          nativeRecordingPromise.then((videoURL) => {
            window.plugin.CanvasCamera.stopVideoRecording((error) => {
              throw error;
            },
            () => {
              processingVideo.value = true;
              resolve(videoURL);
            });
          })
          .catch((error) => {
            processingVideo.value = false;
            reject(error);
          })
          .finally(() => {
            clearInterval(progressUpdateInterval); 
            captureProgress.value = -1;
            nativeRecordingPromiseResolve.value = null;
          })
        });
      }
    }

    const captureVideo = async function(lengthInMS, acceptedCodecs) { //TODO Automatically flip this, if recorded in landscape, or maybe just for selecting still image could be enough
      if (stream.value != null) {
        //Reset processing state just in case it didn't reset after finishing
        processingVideo.value = false;

        let videoBitsPerSecond = MIN_BITRATE;

        for (let track of stream.value.getVideoTracks()) {
          let settings = track.getSettings();
          //Always use the higher number for the bitrate
          videoBitsPerSecond = Math.max(videoBitsPerSecond, (settings.width * settings.height * BITRATE_FACTOR));
        }

        //If a unsupported type was requested, then don't use it and use the default one - no specific one requested
        let recordingOptions = {
          videoBitsPerSecond
        };

        //Remove all non supported codecs in case we have to try again with a different codec
        let supportedCodecs = _.filter(acceptedCodecs, (codec) => MediaRecorder.isTypeSupported(codec));

        //Take the first supported codec, if any are left
        if (supportedCodecs.length > 0) {
          recordingOptions['mimeType'] = supportedCodecs[0];
        }

        recorder.value = new MediaRecorder(stream.value, recordingOptions);

        let data = [];
        
        recorder.value.ondataavailable = event => data.push(event.data); //In default behaviour always returns full recording as a single blob

        recorder.value.start();

        //Using timeslice fixes some recordings being empty if no data is ready at the beginning yet, those empty slices get discarded.
        setTimeout(() => {
          try {
            if (recorder.value) recorder.value.requestData(); //Just creating one timeslice instead of slicing the whole blob to reduce stutter at the borders of the chunks.
          } catch (error) {
            console.error(error);
          }
        }, RECORDING_TIMESLICE); 

        let stopped = new Promise((resolve, reject) => {
          recorder.value.onstop = () => {
            let type = recordingOptions.mimeType;
            //Check each chunk for the type
            for (let chunk of data) {
              if (chunk.type.length) {
                type = chunk.type;
              }
            }
              
            let result = new Blob(data, { type });
            if (result.size > 0) { //Data has been successfully recorded
              resolve(result);
            } else {
              reject('MediaRecorder Error: Empty recording!');
            }
            
          }
          recorder.value.addEventListener('error', error => reject(error));
        });

        let progressUpdateInterval = null;
        let stopTimeout = null;

        recorder.value.onstart = () => {
          captureProgress.value = 0;
          progressUpdateInterval = setInterval(() => {
            if (captureProgress.value < 100) captureProgress.value += 1;
            else {
              clearInterval(progressUpdateInterval);
              if (recorder.value != null && recorder.value.state == "recording"){
                recorder.value.stop();
              }
            }
          }, lengthInMS / 100); //In percent progress, so update every 100th of the total length
        };

        return new Promise((resolve, reject) => {
          stopped.catch((error) => {
            //First catch and retry if more codecs are available
            let remainingCodecs = _.without(supportedCodecs, recordingOptions.mimeType);
            //If no codecs remain after the currently used one, rethrow the error
            if (remainingCodecs.length <= 0) throw error;
            //Otherwise try again with the remaining codecs
            else {
              if (recordingOptions.mimeType != null) console.log(`Error recording with mimeType '${recordingOptions.mimeType}', trying again with different mimeType.`);
              return captureVideo(lengthInMS, remainingCodecs);
            }
          })
          .then((result) => {
            processingVideo.value = true;
            resolve(result);
          })
          .catch((error) => {
            processingVideo.value = false;
            reject(error);
          })
          .finally(() => {
            clearTimeout(stopTimeout);
            clearInterval(progressUpdateInterval); 
            captureProgress.value = -1;
            recorder.value = null;
          })
        });
      }
    }

    //Only get one image at the same time from the video element
    const captureImageConcurrencyPromise = ref(Promise.resolve());

    const captureImage = async function(videoInstance, scalingOptions, returnCanvas = false){
      captureImageConcurrencyPromise.value = captureImageConcurrencyPromise.value.catch(() => null).then(() => {
        return videoElementToScaledBlobOrCanvas(videoInstance, scalingOptions, returnCanvas);
      });

      return captureImageConcurrencyPromise.value;
    }

    const cropImage = function(imageBlobURL, cropRectangle) {
      let cropCanvas = document.createElement('canvas');
      let image = new Image();
      image.crossOrigin = 'Anonymous';

      let promise = new Promise((resolve, reject) => {
        if (!('x' in cropRectangle && 'y' in cropRectangle && 'width' in cropRectangle && 'height' in cropRectangle)) reject('Missing crop attribute!');
        image.addEventListener('load', () => {
          cropCanvas.width = image.width * cropRectangle.width;
          cropCanvas.height = image.height * cropRectangle.height;
          cropCanvas.getContext('2d').drawImage(image, image.width * cropRectangle.x, image.height * cropRectangle.y, cropCanvas.width, cropCanvas.height, 0, 0, cropCanvas.width, cropCanvas.height);

          cropCanvas.toBlob((blob) => resolve(blob), 'image/png');
        });
        image.addEventListener('error', (error) => reject(error));
      });

      image.src = imageBlobURL;

      return promise;
    }

    //Return immediately without editing, if editing is disabled
    watch([() => props.enableEditing, capturedImage, capturedVideo], ([editingEnabled, image, video]) => {
      if (!editingEnabled) {
        if (image != null) returnImage();
        else if (video != null) returnVideo();
      }
    });

    const processNextPictureAutomaticCapture = function(singlePicture) {
      let imagePromise = null;

      if (automaticCaptureInstance != null) {
        //Analyze a single picture once and then continue with the stream on the next run, if nothing found
        if (singlePicture != null && singlePicture instanceof Blob) {
          imagePromise = rescaleBlobImage(singlePicture); //Remove transparency from images
        }
        //Native recording just tells the code to capture a full size or thumbnail image (based on the settings) on the next possible occasion and return it through the usual capture handler that fulfills this promise from outside
        else if (nativeCameraPreviewRunning.value) imagePromise = new Promise((resolve, reject) => {
          let frameWaitTimeout;
          nativeImageAutomaticCapturePromise.value = {
            resolve: (data) => {
              clearTimeout(frameWaitTimeout);
              resolve({ blob: data });
            },
            reject: (error) => reject(error)
          }
          if (props.automaticCaptureUseFullsize) {
            window.plugin.CanvasCamera.requestSingleFullsize((error) => {
              reject(error);
            });
          } else {
            window.plugin.CanvasCamera.requestSingleThumbnail((error) => {
              reject(error);
            });
          }
          //Add a timeout for waiting for a full size image to be returned that rejects this promise
          frameWaitTimeout = setTimeout(() => {
            reject('No frame received in time');
          }, 2000);
        });
        else if (stream.value != null) {
          imagePromise = captureImage(videoPlayer.value, (props.automaticCaptureUseFullsize) ? undefined : automaticCaptureInstance.scalingOptions, automaticCaptureInstance.supportsCanvas);
        }
      }

      //Only continue if camera is running, otherwise stop, else we run into infinite promise loop and hang the browser
      if (imagePromise != null) {
        return imagePromise.then((returnData) => {
          if (returnData == null) throw 'Invalid imageBlobOrCanvas';

          let imageBlobOrCanvas = returnData.canvas || returnData.blob; //Take first non-null value

          if (imageBlobOrCanvas == null) throw 'Invalid imageBlobOrCanvas';

          return automaticCaptureInstance.processImage(imageBlobOrCanvas).then(async (result) => { 
            if (result != null) {
              currentData.value = { ...result.data };

              //Convert canvas to blob before returning data
              if (result.returnData) {
                imageBlobOrCanvas = await new Promise((resolve, reject) => {
                  //Add a timeout for waiting for a blob to be returned that rejects this promise
                  let blobWaitTimeout = setTimeout(() => {
                    reject('No blob received in time');
                  }, 5000);
                  
                  if (typeof OffscreenCanvas !== 'undefined' && imageBlobOrCanvas instanceof OffscreenCanvas) {
                    imageBlobOrCanvas.convertToBlob({ type: 'image/png' }).then((blob) => {
                      clearTimeout(blobWaitTimeout);
                      resolve(blob);
                    }).catch(() => reject('Invalid canvas'));
                  } else if (imageBlobOrCanvas instanceof HTMLCanvasElement) {
                    imageBlobOrCanvas.toBlob((blob) => {
                      clearTimeout(blobWaitTimeout);
                      resolve(blob);
                    }, 'image/png');
                  } else {
                    clearTimeout(blobWaitTimeout);
                    resolve(imageBlobOrCanvas);
                  }
                  
                }).catch((error) => { //On error keep the original one
                  console.error('Could not convert canvas back to blob', error);
                  return imageBlobOrCanvas;
                });
              }

              if (result.returnData && imageBlobOrCanvas instanceof Blob) { //Only convert blobs!
                capturedImage.value = URL.createObjectURL(imageBlobOrCanvas);
                closeCameraResources();
              } else {
                //Only add scale when capturing, otherwise the original size is returned anyway
                if (returnData.scale != null) {
                  currentData.value = { ...currentData.value, scale: returnData.scale };
                }
                await cameraTrigger(true);
              }
            }
          });
        }).then(() => {
          //Process the next frame immediately, if successful until it has been dismissed - Use animation frame to not endlessly block the same frame
          if (!hasBeenDismissed.value && singlePicture == null) window.requestAnimationFrame(() => processNextPictureAutomaticCapture());
        }).catch(() => { //Keep processing next frame on error, but wait a timeout for the next attempt
          if (!hasBeenDismissed.value && singlePicture == null) setTimeout(() => processNextPictureAutomaticCapture(), 1000);
        });
      }
    }

    async function cameraTrigger(startOnly = false){ //FIXME Prevent that you can press record, when stream is not ready yet - Otherwise it runs into an error
      if (currentlyRecording.value && !(startOnly)) {
        if (recorder.value != null && recorder.value.state == "recording") recorder.value.stop();
        if (nativeRecordingPromiseResolve.value != null) nativeRecordingPromiseResolve.value();
      } else {
        let videoPromise = null;
        let nativeVideoPromise = null;
        if (props.requiresVideo) {
          if (nativeCameraPreviewRunning.value) nativeVideoPromise = captureNativeVideo(MAX_VIDEO_TIME);
          else videoPromise = captureVideo(MAX_VIDEO_TIME, acceptedVideoRecordingCodecs);
        } else if (props.stillFrame) {
          if (nativeCameraPreviewRunning.value) nativeVideoPromise = captureNativeVideo(STILL_FRAME_TIME);
          else videoPromise = captureVideo(STILL_FRAME_TIME, acceptedVideoRecordingCodecs);
        } else {
          let imagePromise = null;
          //Native recording just tells the code to capture a full size image on the next possible occasion and return it through the usual capture handler that fulfills this promise from outside
          if (nativeCameraPreviewRunning.value) imagePromise = new Promise((resolve, reject) => {
            let frameWaitTimeout;
            nativeImageCapturePromise.value = {
              resolve: (data) => {
                clearTimeout(frameWaitTimeout);
                resolve(data);
              },
              reject: (error) => reject(error)
            }
            window.plugin.CanvasCamera.requestSingleFullsize((error) => {
              reject(error);
            });
            //Add a timeout for waiting for a full size image to be returned that rejects this promise
            frameWaitTimeout = setTimeout(() => {
              reject('No frame received in time');
            }, 5000);
          });
          else imagePromise = captureImage(videoPlayer.value).then((returnData) => _.get(returnData, ['blob'])); //Only continue with the blob
          
          await imagePromise.then((imageBlob) => {
            capturedImage.value = URL.createObjectURL(imageBlob);
            closeCameraResources();
          })
          .catch((error) => {
            console.error(error);
            retryRecording();
          });
        }

        if (videoPromise != null) {
          await videoPromise.then((video) => {
            let recordedBlob;
            //TODO Check Chrome on Windows and mobile browsers too
            //Chrome returns matroska container that needs to be converted
            if (video instanceof Blob && !video.type.includes('matroska')) { //If we get a blob from the recorder use it
              recordedBlob = video;
            } else { //Otherwise create a webm video out of it
              recordedBlob = new Blob([video], { type: "video/webm" });
            }
            capturedVideo.value = URL.createObjectURL(recordedBlob); //iOS PWA might crash on large files while scrubbing or playing. Known issue.
            
            closeCameraResources();
          })
          .catch((error) => {
            console.error(error);
            retryRecording();
          });
        }

        if (nativeVideoPromise != null) {
          await nativeVideoPromise.then((videoURL) => {
            capturedVideo.value = videoURL;
            
            overridenFileType.value = 'video/mp4'; //Defined to be mp4 in plugin
            
            closeCameraResources();
          })
          .catch((error) => {
            console.error(error);
            retryRecording();
          });
        }
      }
    }

    function toggleFlash(){
      if (nativeCameraPreviewRunning.value) {
        window.plugin.CanvasCamera.flashMode(!flashActive.value, (error) => {
          console.error('Flash state could not be changed', error);
          flashSupported.value = null;
        }, (currentData) => {
          if (currentData != null) updateNativeiOSCameraOptions(currentData.options);
        });
      } else if (stream.value != null){
        let track = stream.value.getTracks()[0];

        let newState = !(track.getSettings().torch);

        track.applyConstraints({
          advanced: [{torch: newState}]
        })
        .then(() => {
          flashActive.value = newState;
        })
        .catch((error) => {
          console.error('Flash state could not be changed', error);
        });
      }
    }

    const steppingZoom = ref(false);

    const adjustedZoomDebounce = computed(() => {
      const baseDebounce = 500;
      return Math.min(baseDebounce * zoomStep.value, baseDebounce);
    });

    const MIN_ZOOM_DEBOUNCE = 10;

    const increaseZoom = function() {
      steppingZoom.value = true;
      holdButton(() => stepZoom(zoomStep.value), () => (steppingZoom.value && isZoomInPossible.value), adjustedZoomDebounce.value, MIN_ZOOM_DEBOUNCE);
    }

    const stopZoom = function() {
      steppingZoom.value = false;
    }

    const decreaseZoom = function() {
      steppingZoom.value = true;
      holdButton(() => stepZoom(zoomStep.value * -1), () => (steppingZoom.value && isZoomOutPossible.value), adjustedZoomDebounce.value, MIN_ZOOM_DEBOUNCE);
    }

    const stepZoom = function(stepValue){
      if (nativeCameraPreviewRunning.value) {
        window.plugin.CanvasCamera.zoom((cameraZoomFactor.value + stepValue), (error) => {
          console.error('Zoom could not be adjusted', error);
        }, (currentData) => {
          if (currentData != null) updateNativeiOSCameraOptions(currentData.options);
        });
      } else if (stream.value != null && isCameraZoomSupported.value){
        let track = stream.value.getTracks()[0];

        let currentValue = track.getSettings().zoom;

        let newValue = currentValue + stepValue;

        //Apply boundaries
        if (newValue > supportedCameraZoomBoundaries.value.max) newValue = supportedCameraZoomBoundaries.value.max;
        if (newValue < supportedCameraZoomBoundaries.value.min) newValue = supportedCameraZoomBoundaries.value.min;

        //Only apply if it changed
        if (newValue != currentValue) {
          track.applyConstraints({
            advanced: [{zoom: newValue}]
          })
          .catch((error) => {
            console.error('Zoom could not be changed', error);
          })
          .finally(() => {
            cameraZoomFactor.value = track.getSettings().zoom;
          });
        }
      }
    }

    const setFocusDistance = function(newFocus, newLockedState) {
      if (nativeCameraPreviewRunning.value) {
        window.plugin.CanvasCamera.focus({focusMode: ((newLockedState) ? 'locked' : 'continuous'), focusDistance: newFocus}, (error) => {
          console.error('Focus distance could not be adjusted', error);
        }, (currentData) => {
          if (currentData != null) updateNativeiOSCameraOptions(currentData.options);
        });
      } else if (stream.value != null && isCameraFocusSupported.value){
        let track = stream.value.getTracks()[0];

        let currentSettings = track.getSettings();

        let newSettings = {};

        //Update either focus distance, or the mode, if distance is not set
        //Only apply if it changed
        if (newFocus != null && newFocus != currentSettings.focusDistance) {
          newSettings.focusDistance = newFocus;
          newSettings.focusMode = 'manual';
        } else {
          if (newLockedState === false && currentSettings.focusMode !== 'continuous') {
            newSettings.focusMode = 'continuous';
          } else if (newLockedState === true && currentSettings.focusMode !== 'manual') {
            newSettings.focusMode = 'manual'; //FIXME Does not take the current focus into account! Maybe not possible by the implementation!
          }
        }

        if (Object.keys(newSettings).length > 0) {
          track.applyConstraints({
            advanced: [newSettings]
          })
          .catch((error) => {
            console.error('Focus could not be changed', error);
          })
          .finally(() => {
            let newAppliedSettings = track.getSettings();
            cameraFocusLocked.value = newAppliedSettings.focusMode !== 'continuous';
          });
        }
      }
    };

    const setExposureCompensation = function(newExposureCompensation) {
      if (nativeCameraPreviewRunning.value) {
        window.plugin.CanvasCamera.exposureCompensation(newExposureCompensation, (error) => {
          console.error('Exposure compensation could not be adjusted', error);
        }, (currentData) => {
          if (currentData != null) updateNativeiOSCameraOptions(currentData.options);
        });
      } else if (stream.value != null && isCameraExposureCompensationSupported.value){
        let track = stream.value.getTracks()[0];

        let currentValue = track.getSettings().exposureCompensation;

        let newValue = newExposureCompensation;

        //Apply boundaries
        if (newValue > supportedCameraExposureBoundaries.value.max) newValue = supportedCameraExposureBoundaries.value.max;
        if (newValue < supportedCameraExposureBoundaries.value.min) newValue = supportedCameraExposureBoundaries.value.min;

        //Only apply if it changed
        if (newValue != currentValue) {
          track.applyConstraints({
            advanced: [{exposureCompensation: newValue}]
          })
          .catch((error) => {
            console.error('Exposure compensation could not be changed', error);
          })
          /* .finally(() => {
            
          }) */;
        }
      }
    }

    watch(cameraFocusPoint, (newFocusPoint) => {
      if (nativeCameraPreviewRunning.value) {
        window.plugin.CanvasCamera.pointOfInterest(newFocusPoint, (error) => {
          console.error('Point of interest could not be changed', error);
        }, (currentData) => {
          if (currentData != null) updateNativeiOSCameraOptions(currentData.options);
        });
      } else if (stream.value != null && isCameraFocusSupported.value && newFocusPoint != null){
        let track = stream.value.getTracks()[0];

        let currentSettings = track.getSettings();

        //Coordinates are relative in the range from 0 to 1, scale to actual video size
        let newSettings = { pointsOfInterest: [{ x: newFocusPoint.x, y: newFocusPoint.y }]};

        //If it is not continously focusing, set it to be doing that
        if (currentSettings.focusMode !== 'continuous') {
          newSettings.focusMode = 'continuous';
        }

        track.applyConstraints({
          advanced: [newSettings]
        })
        .catch((error) => {
          console.error('Focus point could not be changed', error);
        }) //Focus ratio never changes in the settings with autofocus enabled
        .finally(() => {
          let newAppliedSettings = track.getSettings();
          cameraFocusLocked.value = newAppliedSettings.focusMode !== 'continuous';
        });
      }
    });

    const cameraOptions = computed(() => {
      return {
        width: props.width || 1920,
        height: props.height || 1080,
        aspectRatio: (props.width && props.height) ? (props.width/props.height) : 16/9,
        camera: props.cameraDirection || 'back'
      }
    });

    const updateNativeiOSCameraOptions = function(currentOptions) {
      if (currentOptions != null) {
        if (currentOptions['flashMode'] != null) {
          flashActive.value = (currentOptions['flashMode']) ? true : false;
        }
        if (currentOptions['zoom'] != null) {
          cameraZoomFactor.value = currentOptions['zoom'];
        }
        if (currentOptions['focusMode'] != null) {
          cameraFocusLocked.value = (currentOptions['focusMode'] == 'locked');
        }
        if (currentOptions['focusDistance'] != null && isCameraFocusSupported.value) {
          cameraFocusDistance.value = (currentOptions['focusDistance'] - supportedCameraFocusBoundaries.value.min) / (supportedCameraFocusBoundaries.value.max - supportedCameraFocusBoundaries.value.min);
        }
        if (currentOptions['exposureCompensation'] != null && isCameraExposureCompensationSupported.value) {
          cameraExposureCompensation.value = (currentOptions['exposureCompensation'] - supportedCameraExposureBoundaries.value.min) / (supportedCameraExposureBoundaries.value.max - supportedCameraExposureBoundaries.value.min) * (1.0 - (-1.0)) + (-1.0);
        }
        if (currentOptions['preview'] != null) {
          let newPreviewSize = _.pick(currentOptions['preview'], ['width', 'height', 'x', 'y']);
          if (newPreviewSize.width != null && newPreviewSize.width != null && newPreviewSize.x != null && newPreviewSize.y != null) {
            nativeCameraPreviewSize.value = newPreviewSize;
          }
        }
      }
    }

    const getCameraPreviewArea = function() {
      if (cameraPage.value != null) {
        let area = _.pick(cameraPage.value.getBoundingClientRect(), ['width', 'height', 'x', 'y']);
        if (area.width > 0 && area.height > 0) {
          return area;
        }
      }
    }

    //Apply new preview size when the page gets visible and attach a listener to update it on every change
    watch(cameraPage, (pageArea) => {
      if (pageArea != null) {
        cameraResizeObserver.observe(pageArea);

        let cameraPreviewArea = getCameraPreviewArea();
        if (cameraPreviewArea != null) {
          setNativeCameraPreviewFrame(cameraPreviewArea);
        }
      }
    });

    //When the camera first starts running, update the frame
    watch(nativeCameraPreviewRunning, (previewRunning) => {
      if (previewRunning) {
        let cameraPreviewArea = getCameraPreviewArea();
        if (cameraPreviewArea != null) {
          setNativeCameraPreviewFrame(cameraPreviewArea);
        }
      }
    });

    const cameraResizeObserver = new ResizeObserver(entries => {
      if (entries.length > 0) {
        let cameraPreviewArea = getCameraPreviewArea();
        if (cameraPreviewArea != null) {
          setNativeCameraPreviewFrame(cameraPreviewArea);
        }
      }
    });

    //Listern to videoPlayer, capturedImageElement and capturedVideoPlayer size changes

    //videoPlayerSize
    const setVideoPlayerSize = function() {
      if (videoPlayer.value != null) {
        videoPlayerSize.value = _.pick(videoPlayer.value.getBoundingClientRect(), ['width', 'height', 'x', 'y']);
      }
    }

    const videoPlayerResizeObserver = new ResizeObserver(setVideoPlayerSize);

    watch(videoPlayer, (element) => {
      if (element != null) {
        videoPlayerResizeObserver.observe(element);
        setVideoPlayerSize();
      }
    }, { immediate: true });

    //capturedImageElementSize
    const setCapturedImageElementSize = function() {
      if (capturedImageElement.value != null) {
        capturedImageElementSize.value = _.pick(capturedImageElement.value.getBoundingClientRect(), ['width', 'height', 'x', 'y']);
      }
    }

    const capturedImageElementResizeObserver = new ResizeObserver(setCapturedImageElementSize);

    watch(capturedImageElement, (element) => {
      if (element != null) {
        capturedImageElementResizeObserver.observe(element);
        setCapturedImageElementSize();
      }
    }, { immediate: true });

    //capturedVideoPlayerSize
    const setCapturedVideoPlayerSize = function() {
      if (capturedVideoPlayer.value != null) {
        capturedVideoPlayerSize.value = _.pick(capturedVideoPlayer.value.getBoundingClientRect(), ['width', 'height', 'x', 'y']);
      }
    }

    const capturedVideoPlayerResizeObserver = new ResizeObserver(setCapturedVideoPlayerSize);

    watch(capturedVideoPlayer, (element) => {
      if (element != null) {
        capturedVideoPlayerResizeObserver.observe(element);
        setCapturedVideoPlayerSize();
      }
    }, { immediate: true });

    onBeforeUnmount(() => {
      cameraResizeObserver.disconnect();
      capturedImageElementResizeObserver.disconnect();
      videoPlayerResizeObserver.disconnect();
      capturedVideoPlayerResizeObserver.disconnect();
    });

    const setNativeCameraPreviewFrame = function(newFrame /* {x, y, width, height} */) {
      if (nativeCameraPreviewRunning.value) {
        window.plugin.CanvasCamera.setPreviewFrame(newFrame, (error) => {
          console.error('Preview frame could not be adjusted', error);
        }, (currentData) => {
          if (currentData != null) updateNativeiOSCameraOptions(currentData.options);
        });
      }
    }

    const initializeNativeiOSCamera = function(cameraOptions) {
      window.plugin.CanvasCamera.initialize(null, iosCameraCanvas.value);

      let options = {
          cameraFacing: cameraOptions.camera,
          width: cameraOptions.width,
          height: cameraOptions.height,
          canvas: {
            width: cameraOptions.width,
            height: cameraOptions.height
          },
          capture: {
            width: cameraOptions.width,
            height: cameraOptions.height
          },
          preview: getCameraPreviewArea() || undefined, //Get the camera size object or undefined
          fps: 60,
          use: 'data',
          flashMode: false,
          hasThumbnail: true,
          thumbnailRatio: 0.4,
          generateOutputOnlyOnRequest: true, //Only create a new frame when requested - Used when preview is behind the WebView and we just need frames for analysis
          disableFullsize: true,
          drawOffscreen: true
      };

      flashActive.value = false;

      let cameraStartPromise = new Promise((resolve, reject) => {
        window.plugin.CanvasCamera.start(options, reject, (data) => { //TODO Maybe could make it more efficient by not sending all the attributes every single time a picture is sent back!
          resolve(_.omit(data, 'output'));
          //This function gets called every time a new frame arrives, and can only take the full size image or also thumbnails. If the setting is enabled to return output upon request this function gets called whenever a new frame is requested
          //If full size or thumbnail data is available, send it to the waiting promises
          try {
            if (data != null && data.output != null && data.output.images != null) {
              if(data.output.images.fullsize != null && data.output.images.fullsize.data != null) {
                if (nativeImageCapturePromise.value != null || (props.automaticCaptureUseFullsize && nativeImageAutomaticCapturePromise.value != null)) {
                  let components = splitDataURL(data.output.images.fullsize.data);
                  let blob = base64ToBlob(components.data, components.mime);
                  if (nativeImageCapturePromise.value != null) nativeImageCapturePromise.value.resolve(blob);
                  if (props.automaticCaptureUseFullsize && nativeImageAutomaticCapturePromise.value != null) nativeImageAutomaticCapturePromise.value.resolve(blob);
                }
              }
              if(data.output.images.thumbnail != null && data.output.images.thumbnail.data != null) {
                if (!(props.automaticCaptureUseFullsize) && nativeImageAutomaticCapturePromise.value != null) {
                  let components = splitDataURL(data.output.images.thumbnail.data);
                  let blob = base64ToBlob(components.data, components.mime);
                  if (!(props.automaticCaptureUseFullsize) && nativeImageAutomaticCapturePromise.value != null) nativeImageAutomaticCapturePromise.value.resolve(blob);
                }
              }
            }
          } catch (error) {
            if (nativeImageCapturePromise.value != null) nativeImageCapturePromise.value.reject(error);
            if (nativeImageAutomaticCapturePromise.value != null) nativeImageAutomaticCapturePromise.value.reject(error);
          }
          
        });
        //Request a single thumbnail after initialization to get information about the camera (above callback function) and confirm it is working
        window.plugin.CanvasCamera.requestSingleThumbnail((error) => {
          reject(error);
        });
      });
      
      return cameraStartPromise
      .then((initializationData) => {
        nativeCameraPreviewRunning.value = true;        

        stream.value = iosCameraCanvas.value.captureStream();

        //Enable hiding of other UI elements and transparent parents on start
        if (!(hasBeenDismissed.value)) document.body.classList.add('hideNonModal', 'transparentParents');

        if (initializationData != null) {
          if (initializationData.capabilities != null) {
            if ('torch' in initializationData.capabilities) {
              flashSupported.value = true;
            }
            if ('zoom' in initializationData.capabilities) {
              supportedCameraZoomBoundaries.value = initializationData.capabilities.zoom;
            }
            if ('focusDistance' in initializationData.capabilities && 'focusMode' in initializationData.capabilities) {
              supportedCameraFocusBoundaries.value = initializationData.capabilities['focusDistance'];
            }
            if ('exposureCompensation' in initializationData.capabilities) {
              supportedCameraExposureBoundaries.value = initializationData.capabilities['exposureCompensation'];
            }
          }

          //Set initial values of all the options
          if (initializationData.options != null) updateNativeiOSCameraOptions(initializationData.options);
        }

        if (hasBeenDismissed.value) closeCameraResources(); //Catch the case where the modal is closed before the camera has been initialized
      }).catch(() => {
        cameraUnavailable(true);
      }) 
    }

    const initializeCamera = function() {
      resetCameraZoomAndFocus();
      cameraErrorState.value = false;
      if (preSelectedFile.value != null) {
        cameraErrorState.value = true; //Use gallery button as fallback
      } else {
        try {
          let initializationPromise;
          if (isPlatform('hybrid') && isPlatform('ios')) {
            initializationPromise = initializeNativeiOSCamera(cameraOptions.value);
          } else {
            initializationPromise = initializeUserMediaCamera(cameraOptions.value);
          }
          initializationPromise.then(() => processNextPictureAutomaticCapture());
        } catch (error) { //Catch any unforeseen errors and set error state
          console.error('Uncaught error when initializing camera', error);
          cameraUnavailable();
        }
      }
    }

    const returnVideo = function(){
      if (props.stillFrame) {
        let finalImageBlobURL;
        let oldImage;
        let imagePromise = captureImage(capturedVideoPlayer.value).then((returnData) => _.get(returnData, ['blob'])).then((imageBlob) => finalImageBlobURL = URL.createObjectURL(imageBlob)); //Only continue with the blob

        if (enableCrop.value && editingOverlayCanvas.value != null) {
          imagePromise = imagePromise.then(() => {
            oldImage = finalImageBlobURL;
          })
          .then(() => cropImage(oldImage, editingOverlayCanvas.value.getSelectionRectangle()))
          .then((imageBlob) => {
            finalImageBlobURL = URL.createObjectURL(imageBlob);
            URL.revokeObjectURL(oldImage);
          })
          .catch((error) => console.error('Could not crop image!', error));
        }
        imagePromise.finally(() => {
          if (finalImageBlobURL != null)
            closeModal(finalImageBlobURL);
          else
            localErrorToast(i18n, i18n.$t('camera.error_retry_finalize'));
        });

        return;
      }
      //TODO Trim video here if it is requested or return untrimmed one if no trimming is necessary
      if (capturedVideo.value != null)
        closeModal(capturedVideo.value, overridenFileType.value); //Video is by design always requested as mp4 for better cross-compatibility
      else
        localErrorToast(i18n, i18n.$t('camera.error_retry_finalize'));
      //TODO Maybe the video can be converted on the client side to mp4 or smth like that if it is not already
    }

    const returnImage = function(){
      if (enableCrop.value && editingOverlayCanvas.value != null) {
        let oldImage = capturedImage.value;
        cropImage(oldImage, editingOverlayCanvas.value.getSelectionRectangle()).then((imageBlob) => {
          capturedImage.value = URL.createObjectURL(imageBlob);
          URL.revokeObjectURL(oldImage);
        })
        .catch((error) => console.error('Could not crop image!', error))
        .finally(() => {
          if (capturedImage.value != null)
            closeModal(capturedImage.value);
          else
            localErrorToast(i18n, i18n.$t('camera.error_retry_finalize'));
        });
      } else {
        if (capturedImage.value != null)
          closeModal(capturedImage.value);
        else
          localErrorToast(i18n, i18n.$t('camera.error_retry_finalize'));
      }
    }

    const createZoomGesture = function(buttonInstance, actionInstance) {
      if (buttonInstance != null && buttonInstance.$el != null && actionInstance != null) {
        const zoomGesture = createGesture({
          el: buttonInstance.$el,
          threshold: 0,
          direction: undefined,
          onStart: () => { actionInstance() },
          onEnd: () => { stopZoom() }
        });
        zoomGesture.enable();
      }
    }

    watch(zoomOutButton, (buttonInstance) => createZoomGesture(buttonInstance, decreaseZoom));
    watch(zoomInButton, (buttonInstance) => createZoomGesture(buttonInstance, increaseZoom));

    onMounted(() => {      
      //Only valid if cameraOptions are present - Workaround for bug -> When closing another modal closely before or after opening camera, onMounted gets called without any of the computedValues being present. Visually everything works as intended! Modal is only shown once!
      if (cameraOptions.value != null) {
        if (preSelectedFile.value != null) {
          setFile(preSelectedFile.value, () => {
            preSelectedFile.value = null;
            initializeCamera();
          });
        } else {
          initializeCamera();
        }
      }
    });

    return {
      i18n,
      isLandscape,
      closeModal,
      clearCaptures,
      clearCapturesAndRestart,
      videoError,
      cameraPage,
      cameraOverlayCanvas,
      cameraFocusPoint,
      cameraFocusDistance,
      cameraFocusLocked,
      videoPlayer,
      capturedVideoPlayer,
      capturedImageElement,
      editingOverlayCanvas,
      isCanvasInteracting,
      videoPlayerSize,
      capturedImageElementSize,
      capturedVideoPlayerSize,
      cameraOverlaySize,
      currentCapturedVideoPosition,
      currentCapturedFrameIndex,
      capturedVideoMetadata,
      capturedVideoFrameTime,
      currentCapturedVideoTime,
      isCapturedVideoPlaying,
      cameraErrorState,
      onErrorHasRetried,
      cameraOptions,
      enableCrop,
      enforceCrop,
      setCapturedVideoTime,
      setCapturedVideoDuration,
      setCapturedVideoMetadata,
      toggleCapturedVideoPlayState,
      incrementCapturedVideoFrame,
      decrementCapturedVideoFrame,
      mediaUpload,
      acceptFileTypeUpload,
      nativeCameraPreviewRunning,
      currentlyRecording,
      capturedImage,
      capturedVideo,
      overridenFileType,
      hasMediaBeenCaptured,
      checkAspectRatios,
      processingVideo,
      captureProgress,
      captureState,
      captureDescriptions,
      captureHint,
      stream,
      fileChosen,
      cameraTrigger,
      toggleFlash,
      returnVideo,
      returnImage,
      camera,
      videocam,
      flashSupported,
      flashActive,
      computedCameraFocusValue,
      computedCameraFocusLocked,
      computedCameraExposureCompensationValue,
      supportedCameraZoomBoundaries,
      isCameraZoomSupported,
      isCameraFocusSupported,
      isCameraExposureCompensationSupported,
      isZoomOutPossible,
      isZoomInPossible,
      zoomOutButton,
      zoomInButton,
      increaseZoom,
      decreaseZoom,
      stopZoom,
      image,
      flash,
      flashOff,
      stop,
      hourglassOutline,
      informationCircleOutline,
      alertCircleOutline,
      close,
      checkmark,
      play,
      pause,
      playSkipBack,
      playSkipForward,
      cropOutline,
      faMagnifyingGlassPlus,
      faMagnifyingGlassMinus
    };
  }
});

export function parseCameraOptions(fileType, captureOptions = {}) { 
  return {
    requiresVideo: fileType.includes('video'),
    stillFrame: (fileType.includes('image') && captureOptions['still_frame'] === true) || undefined, //Allow still frame only for images. Set if enabled
    captureType: captureOptions['capture_type'] || undefined,
    flashSuggested: captureOptions['flash_suggested'] || undefined,
    aspectRatio: captureOptions['aspect_ratio'] || undefined,
  }
}

export async function openCameraModal(component, properties, noFadeInAnimation = false, slowFadeOutAnimation = false, returnBeforeClose = false, beforeCloseCallback){
  if (component != null) {
    const modal = await modalController
      .create({
        //If enabled slowly fade out for a transition in between multiple modals. enterAnimation is disabled so the other modal does not appear from somewhere and stays put.
        enterAnimation: noFadeInAnimation ? zeroModalAnimation : undefined,
        leaveAnimation: slowFadeOutAnimation ? slowModalFadeOutAnimation : undefined,
        component,
        cssClass: ['camera-modal', 'singleVisible'],
        componentProps: properties,
        showBackdrop: false
      })
    try {
      await document.body.requestFullscreen();
    } catch {
      //Do nothing if fullscreen is not supported
    }
    modal.present();
    if (beforeCloseCallback != null) modal.onWillDismiss().then(() => beforeCloseCallback());

    //Disable hiding of other UI elements and transparent parents when the modal will close
    modal.onWillDismiss().then(() => document.body.classList.remove('hideNonModal', 'transparentParents'));

    return (returnBeforeClose) ? modal.onWillDismiss() : modal.onDidDismiss(); //Use return of either before or after closing depending on this flag. Both contain the data! Default is false to wait for resource cleanup before displaying anything!
  }
}

export default CameraModal;
</script>

<style>
.camera-modal {
  --width: 100%;
  --height: 100%;
}

.transparentParents .camera-modal {
  --background: transparent;
}
</style>

<style scoped>
.transparentParents ion-content {
  --background: transparent;
}

ion-fab-button {
  --background: var(--ion-background-color, #fff);
  --background-activated: var(--ion-background-color, #fff);
  --background-focused: var(--ion-background-color, #fff);
  --background-hover: var(--ion-background-color, #fff);
  --border-color: var(--ion-color-primary-text, #fff);
  --color: var(--ion-color-primary-text, #fff);
  --border-width: 2px;
  --border-style: solid;
}
.transparentParents .camera-page {
  background: transparent;
}
.camera-page, .image-page, .video-page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.camera-outer-container, .video-outer-container, .image-outer-container {
  width: 100%;
  position: relative; /*Position has to be relative for aspect ratio to work*/
  overflow: hidden;
  flex-grow: 10;
  flex-shrink: 1;
  /* Fix for webkit sometimes ignoring its z-index on initial placement */
  transform: translateZ(0);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.camera-outer-container > video {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: translateZ(0);
}

ion-fab {
  transform: translateZ(1px);
}

.camera-outer-container, .video-outer-container, .image-outer-container {
  display: flex;
  
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.image-outer-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.video-outer-container > video {
  max-width: 100%;
  max-height: 100%;
}

.editing-controls {
  width: 100%;
  --padding-start: 10px;
  --inner-padding-end: 10px;
  flex-shrink: 0;
}

.editing-controls > div {
  width: 100%;
  display: flex;
  flex-flow: column;
}

.editing-controls .button-container {
  margin-top: 5px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-bottom: 5px;
}

.editing-controls .button-container .center-buttons .scrub-buttons > * {
  margin: 0px auto;
}

/*Create a box with aspect-ratio of 1:1*/
/*.camera-outer-container:before {
	content: '';
	float: left;
	padding-bottom: 100%;
}*/

#camera-view {
  max-width: 100%;
  max-height: 100%;
}

.invisible-camera-preview {
  opacity: 0;
}

.capture-progress {
  font-size: 1.75em;
}

.information-container {
  left: 0px;
  right: 0px;
  position: absolute;
  z-index: 1001;
  --background: rgba(var(--ion-card-background-rgb, 255,255,255), 0.9);
  --color: var(--ion-text-color, black);
  margin-top: 10px;

  display: flex;
  flex-flow: row;
  align-items: center;
  padding-right: 10px;
}

.information-container > * {
  opacity: 1!important;
  padding: 10px 0px 10px 10px;
}

.information-container ion-icon {
  min-width: 24px;
  max-width: 24px;
}

.frame-indicator {
  text-align: center;
  min-width: 50px;
}

@keyframes attention-scaling {
    0% {
        transform: scale(1.0) translateY(0px);
    }
    50% {
        transform: scale(1.15) translateY(-5px);
    }
    100% {
        transform: scale(1.0) translateY(0px);
    }
}

.attention {
  animation: attention-scaling 0.5s ease-in-out 0s infinite;
}

.center-buttons {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.crop-buttons, .fill-element {
  flex: 1;
}

.scrub-buttons {
  flex: 1;
  min-width: 150px;
  max-width: 200px;
}

.enable-crop {
  margin: 0px;
}

.crop-buttons {
  justify-content: flex-end;
  margin: 0px 3vw;
}

.crop-buttons.ios {
  margin: 0px 4vw;
}

 /* TODO Overlay possible, but inverted colors look not good! */
/*#camera-overlay {
  mix-blend-mode: exclusion;
}*/

#camera-overlay {
  z-index: 1001;
}

#camera-template-preview, #camera-template-edit-image, #camera-template-edit-video {
  z-index: 1000;
}

#camera-trigger-fab {
  min-height: 56px;
  min-width: 56px;
  --vertical-additional-size-ratio: 135%;
  --horizontal-additional-size-ratio: 190%;
  --vertical-additional-margin: 35px;
  --horiontal-additional-margin: 0px;
}

#camera-trigger-fab.fab-vertical-bottom {
  bottom: calc(10px + var(--vertical-additional-margin, 0px));
}

#camera-trigger-fab.fab-horizontal-end {
  right: calc(10px + var(--horiontal-additional-margin, 0px) + var(--ion-safe-area-right, 0px));
}

.additional-camera-controls {
  position: absolute;
  top: calc(var(--vertical-additional-size-ratio, 0%) * -0.5);
  left: calc(var(--horizontal-additional-size-ratio, 0%) * -0.5);
  height: calc(100% + var(--vertical-additional-size-ratio, 0%));
  width: calc(100% + var(--horizontal-additional-size-ratio, 0%));
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1.5fr 1fr;
  column-gap: 5px;
  row-gap: 5px;
  z-index: -10;
}

#camera-trigger-button::before {
  --background-overlap-ratio: 25%;
  top: calc(var(--background-overlap-ratio, 0%) * -0.5);
  left: calc(var(--background-overlap-ratio, 0%) * -0.5);
  border-radius: 50%;
  content: "";
  background: var(--background);
  position: absolute;
  height: calc(100% + var(--background-overlap-ratio, 0%));
  width: calc(100% + var(--background-overlap-ratio, 0%));
  z-index: -5;
}

.additional-camera-controls ion-button {
  margin: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  width: 100%;
  height: 100%;
  grid-row: 2;
  font-size: 18px;
  --background: var(--ion-background-color, #fff);
}

@media not (orientation: landscape) {
  .additional-camera-controls ion-button.zoom-out::part(native) {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .additional-camera-controls ion-button.zoom-in::part(native) {
    border-top-right-radius: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

@media (orientation: landscape) {
  .information-container {
    width: 85%;
  }

  #camera-trigger-fab {
    --vertical-additional-size-ratio: 190%;
    --horizontal-additional-size-ratio: 135%;
    --vertical-additional-margin: 0px;
    --horiontal-additional-margin: 35px;
  }

  .additional-camera-controls ion-button.zoom-out::part(native) {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 20px;
  }

  .additional-camera-controls ion-button.zoom-in::part(native) {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 20px;
  }

  /* Position in reverse order on the side of the screen */
  .additional-camera-controls ion-button {
    grid-column: 2;
  }

  .additional-camera-controls ion-button.zoom-out {
    grid-row: 2;
  }

  .additional-camera-controls ion-button.zoom-in {
    grid-row: 1;
  }

  .additional-camera-controls {
    grid-template-columns: 1.5fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
}

@media (max-height: 350px) and (orientation: landscape) {
  #camera-trigger-fab {
    --vertical-additional-size-ratio: 100%;
  }
}
</style>

<template id="log-modal">
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <div id="title" @click="clickedTitle()">
          <ion-label>{{ i18n.$t('log.title') }} {{(stringifiedLogs != null ? `(${stringifiedLogs.length})` : '')}}</ion-label>
        </div>
        <ion-buttons slot="end">
          <ion-button fill="solid" color="success" class="download-button" @click="exportLogs()"> 
            <ion-icon slot="icon-only" :icon="downloadOutline"></ion-icon>
          </ion-button>
          <ion-button @click="closeModal()">{{ i18n.$t('default_interaction.close') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <a ref="downloadElement" class="invisible-input" target="_blank"></a>
      <ion-list>
        <ion-item-divider>
          <ion-label>{{ i18n.$t('log.debug_part.browserInfo') }}</ion-label>
        </ion-item-divider>
        <ion-item>
          <ion-label>
            <p>{{ browserInfo }}</p>
          </ion-label>
        </ion-item>
        <ion-item-divider>
          <ion-label>{{ i18n.$t('log.debug_part.uploadStatus') }}</ion-label>
        </ion-item-divider>
        <ion-item v-for="status, id in uploadStatus" :key="id">
          <ion-label>
            <h2>U{{ id }}: {{ (status.details != null) ? status.details.timestamp : '' }}</h2>
            <p v-for="(statusProperty, index) in status" :key="index"><b>{{ index }}:</b> {{ statusProperty }}</p>
          </ion-label>
        </ion-item>
        <ion-item-divider>
          <ion-label>{{ i18n.$t('log.debug_part.storageInfos') }}</ion-label>
        </ion-item-divider>
        <ion-item v-for="storage, index in storageInfos" :key="index">
          <ion-label>
            <h2><b>{{ storage.name }}</b></h2>
            <h3><b>{{ storage.driver }}</b></h3>
            <ul>
              <li v-for="key, keyIndex in storage.keys" :key="keyIndex">{{ key }}</li>
            </ul>
          </ion-label>
        </ion-item>
        <ion-item-divider>
          <ion-label>{{ i18n.$t('log.debug_part.logs') }}</ion-label>
        </ion-item-divider>
        <ion-item v-for="logEntry in stringifiedLogs" :key="logEntry.timestamp">
          <ion-label>
            <h4>{{ formatTimestamp(logEntry.timestamp) }}</h4>
            <h2 :style="'color: var(' + getLogColor(logEntry.type) + ');'">{{ i18n.$t('log.type.' + logEntry.type) }}</h2>
            <pre class="log-text" v-for="(log, index) in logEntry.log" :key="index">{{ log }}</pre>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>

import { IonPage, IonHeader, IonToolbar, IonButtons, IonButton, IonContent, IonIcon, IonList, IonItem, IonItemDivider, IonLabel, modalController, alertController } from '@ionic/vue';
import { computed, defineComponent, ref } from 'vue';

import { downloadOutline } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";

import { useStore } from 'vuex';

import { apiErrorToast } from '@/utils/error';

import { useDayjs } from '@/utils/dayjs';

const LogModal = defineComponent({
  name: 'LogModal',
  components: { IonPage, IonHeader, IonToolbar, IonButtons, IonButton, IonContent, IonIcon, IonList, IonItem, IonItemDivider, IonLabel, },
  setup() {
    const i18n = useI18n();

    const store = useStore();

    const { dayjs } = useDayjs();

    const downloadElement = ref(null);

    const appVersion = process.env.VUE_APP_VERSION;

    const JWT_CLICK_COUNT = 10;
    const CLICK_TIMEOUT_MS = 5000;

    const titleClickCount = ref(0);

    const logs = ref(null);
    const uploadStatus = computed(() => store.getters['reports/getReportUploadStatus']); //TODO Replace all removed functions with new ones!
    const storageInfos = ref(null);
    const browserInfo = computed(() => navigator.userAgent);
    
    store.getters['getLogs']().then((newLogs) => logs.value = newLogs);
    store.getters['getStorageInfos'].then((newInfos) => storageInfos.value = newInfos);

    const stringifiedLogs = computed(() => {
      if (logs.value == null) {
        return null;
      }

      let logArray = [];

      for (let logEntry of logs.value) {
        let newLog = [];
        
        if (logEntry.log != null) {
          for (let log of logEntry.log) {
            if (log instanceof Error) { //Handler Error Object differently
              if (log.stack != null) newLog.push(log.stack);
              else newLog.push(log.toString());
            } else {
              newLog.push(log);
            }
          }
        }

        logArray.push({
          ...logEntry,
          log: newLog
        });
      }

      return logArray.reverse();
    });

    const closeModal = function(){
      modalController.dismiss();
    }

    const formatTimestamp = function(timestamp){
      return dayjs(parseInt(timestamp)).format('LL LTS');
    }

    const exportLogs = function() {
      let exportString = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify({ logs: stringifiedLogs.value, uploadStatus: uploadStatus.value, storageInfos: storageInfos.value, userAgent: browserInfo.value }, null, 2));

      downloadElement.value.setAttribute('href', exportString);
      downloadElement.value.setAttribute('download', 'Logs_' + appVersion + '_' + dayjs.utc().format('YYYY.MM.DD_HH-mm') + '.json');
      downloadElement.value.click();
    }

    const getLogColor = function(type){
      switch (type) {
        case 'warn':
          return '--ion-color-warning';
        case 'error':
          return '--ion-color-danger'
        default:
          return undefined;
      }
    }

    const askForJWTandLogin = function() {
      return alertController
        .create({
          backdropDismiss: false,
          header: 'JWT Login',
          subHeader: 'Enter JWT:',
          inputs: [
            {
              name: 'jwt',
              type: 'password',
              placeholder: 'JWT',
            },
          ],
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel'
            },
            {
              text: 'Login',
              cssClass: 'login-okay',
              handler: (data) => {
                if (data != null && data.jwt != null && data.jwt.length) {
                  store.dispatch('auth/changeUserWithJWT', data.jwt).catch((error) => {
                    apiErrorToast(i18n, error, true);
                  });
                }
              },
            }
          ],
        })
        .then(a => a.present())
    }

    const clickedTitle = function() {
      if (titleClickCount.value <= 0) {
        //Reset click count if not fast enough
        setTimeout(() => (titleClickCount.value = 0), CLICK_TIMEOUT_MS);
      }
      titleClickCount.value ++;
      if (titleClickCount.value >= JWT_CLICK_COUNT) {
        titleClickCount.value = 0;
        askForJWTandLogin();
      }
    }

    return { i18n, stringifiedLogs, uploadStatus, storageInfos, browserInfo, downloadElement, closeModal, formatTimestamp, exportLogs, getLogColor, clickedTitle, downloadOutline };
  }
});

export async function openLogModal(component){
  if (component != null) {
    const modal = await modalController
      .create({
        component
      })
    modal.present();
    return modal.onWillDismiss();
  }
}

export default LogModal;
</script>

<style>
.login-okay {
  color: var(--ion-color-success-shade)!important;
}
</style>

<style scoped>
.download-button {
  margin: 0px 10px
}

.invisible-input {
  visibility: hidden;
  display: none;
}

ion-item ion-label * {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

#title {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.ios #title ion-label {
  font-size: clamp(.8em, 4vw, 17px);
  font-weight: 600;
}

#title ion-label {
  width: 100%;
  padding: 0px 10px;
  font-size: clamp(.8em, 4vw, 20px);
  font-weight: 500;
  letter-spacing: 0.0125em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}

.log-text {
  white-space: pre-wrap;
  font-size: 0.75em;
}

ul {
  font-size: 0.75em;
}
</style>
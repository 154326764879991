import _ from 'lodash';

import { sha256 } from 'hash-wasm';

//Decimates the data to fit in the given width and returns a new array without mutating the original
export function minMaxDecimation(data, availableWidth) {
    let start = 0;
    let count = data.length;
    let avgX = 0;
    let countX = 0;
    let i, point, x, y, prevX, minIndex, maxIndex, startIndex, minY, maxY;
    const decimated = [];
    const endIndex = start + count - 1;

    const xMin = data[start].x;
    const xMax = data[endIndex].x;
    const dx = xMax - xMin;

    for (i = start; i < start + count; ++i) {
        point = data[i];
        x = (point.x - xMin) / dx * availableWidth;
        y = point.y;
        const truncX = x | 0;

        if (truncX === prevX) {
        // Determine `minY` / `maxY` and `avgX` while we stay within same x-position
        if (y < minY) {
            minY = y;
            minIndex = i;
        } else if (y > maxY) {
            maxY = y;
            maxIndex = i;
        }
        // For first point in group, countX is `0`, so average will be `x` / 1.
        // Use point.x here because we're computing the average data `x` value
        avgX = (countX * avgX + point.x) / ++countX;
        } else {
        // Push up to 4 points, 3 for the last interval and the first point for this interval
        const lastIndex = i - 1;

        if (minIndex != null && maxIndex != null) {
            // The interval is defined by 4 points: start, min, max, end.
            // The starting point is already considered at this point, so we need to determine which
            // of the other points to add. We need to sort these points to ensure the decimated data
            // is still sorted and then ensure there are no duplicates.
            const intermediateIndex1 = Math.min(minIndex, maxIndex);
            const intermediateIndex2 = Math.max(minIndex, maxIndex);

            if (intermediateIndex1 !== startIndex && intermediateIndex1 !== lastIndex) {
            decimated.push({
                ...data[intermediateIndex1],
                x: avgX,
            });
            }
            if (intermediateIndex2 !== startIndex && intermediateIndex2 !== lastIndex) {
            decimated.push({
                ...data[intermediateIndex2],
                x: avgX
            });
            }
        }

        // lastIndex === startIndex will occur when a range has only 1 point which could
        // happen with very uneven data
        if (i > 0 && lastIndex !== startIndex) {
            // Last point in the previous interval
            decimated.push(data[lastIndex]);
        }

        // Start of the new interval
        decimated.push(point);
        prevX = truncX;
        countX = 0;
        minY = maxY = y;
        minIndex = maxIndex = startIndex = i;
        }
    }

    return decimated;
}

export function createSortFunctionByArray(sortArray, byValue) {
    if (!Array.isArray(sortArray)) return undefined;
    
    return function(a, b) {
        //Look for the index of the value (byValue takes a property of each value to look for, if defined)
        const aIndex = sortArray.indexOf((byValue != null) ? a[byValue] : a);
        const bIndex = sortArray.indexOf((byValue != null) ? b[byValue] : b);

        //Compares the indizes and sorts them to the end (Infinity), if not found
        return (((aIndex > -1) ? aIndex : Infinity) - ((bIndex > -1) ? bIndex : Infinity));
    }
}

export function createOrderSortFunction(object, sortNullValuesFirst = false, locale) {
    return function(firstKey, secondKey) {
        //Move undefined or null always to the front, if requested
        if (sortNullValuesFirst) {
            //Save both null conditions
            let firstNullValue = (firstKey == 'null' || firstKey == 'undefined');
            let secondNullValue = (secondKey == 'null' || secondKey == 'undefined');

            //If first is null and second is not, move first before second
            if (firstNullValue && !secondNullValue) return -1;
            //If second is null and first is not, move first after second
            if (!firstNullValue && secondNullValue) return 1;
            //If both are null, compare the other parameters...
        }

        if (object != null && object[firstKey] != null && object[secondKey] != null && object[firstKey].order != null && object[secondKey].order != null) {
            if (object[firstKey].order < object[secondKey].order) return -1;
            if (object[firstKey].order > object[secondKey].order) return 1;
        }
        return firstKey.localeCompare(secondKey, (locale || undefined));
    }
  }

export function generateRandomHexString(length) {
    return [...Array(length)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
}

//Replaces all special RegExp characters in a string with an escaped version
export function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function objectContainsNonNullValues(object) {
    if (object == null) return false;

    let values = Object.values(object);
    if (values == null) return false;

    return values.filter((value) => value != null).length > 0;
}

const NON_ALPHANUM_CHARS_REGEX = /[^a-z0-9]/ig

export function removeNonAlphaNumCharactersNormalize(originalString) {
    //Has to be valid string to be converted!
    if (originalString == null || !(_.isString(originalString))) return null;
    return originalString.toLowerCase().replaceAll(NON_ALPHANUM_CHARS_REGEX, '');
}

export function containsAlphaNumString(searchString, includingString) {
    if (searchString == null || includingString == null) return false;
    let normalizedSearchString = removeNonAlphaNumCharactersNormalize(searchString);
    let normalizedIncludingString = removeNonAlphaNumCharactersNormalize(includingString);
    if (normalizedSearchString == null || normalizedIncludingString == null) return false;
    return (normalizedSearchString.includes(normalizedIncludingString));
}


//Invert means the biggest steps are in the lower part of the range. The lower the logScalingStepSize, the closer the steps are overall
export function mapZeroToOneRangeToLog(value, invertLogScaling = false, logScalingStepSize = 500) {
    let preparedValue = (invertLogScaling) ? (1 - value) : value; 

    //Start at one, apply logarithm and scale back down
    let mappedValue = Math.log(1 + (preparedValue * logScalingStepSize)) / Math.log(1 + logScalingStepSize)

    return (invertLogScaling) ? (1 - mappedValue) : mappedValue;
}

/**
 *
 * Based on https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
 *  
 */
export function arrayBufferToHexString(buffer) {
    const bytesArray = Array.from(new Uint8Array(buffer));
    return bytesArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}

export async function hashDataToHexString(data) {
    //Use built in method in browser if possible
    if (crypto != null && crypto.subtle != null && crypto.subtle.digest != null) {
        return crypto.subtle.digest('SHA-256', data).then((hashBuffer) => {
            const hashString = arrayBufferToHexString(hashBuffer);
            return hashString;
        });
    } else { //Otherwise use implementation from WASM library
        return await sha256(data);
    }
}
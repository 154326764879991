<template id="form-item">
  <!-- Item is rerendered when disabled state changes, because ion-item can get stuck in this state. Using key to re-render -->
  <ion-item ref="entryItemRef" :class="`entry-item ${statusStyle} ${platformStyle} ${(itemParameters.customReset) ? 'custom-reset' : ''} ${(itemParameters.insetHeader) ? 'inset-header' : ''}`" :style="(itemParameters.itemStyle != null) ? itemParameters.itemStyle : undefined" :lines="lines" :key="disabled" @ionBlur="reportInputValidity()" detail="false">
    <ion-label :class="['label-with-icon', 'wrapping-label', (disabled) ? 'disabled' : '']" :position="itemParameters.labelPosition">
      <template v-if="icon != null">
        <font-awesome-icon class="label-icon" v-if="iconIsFontAwesome" :icon="icon" />
        <ion-icon class="label-icon" v-else :icon="icon"></ion-icon>
      </template>
      {{ display_name }}
    </ion-label>

    <div v-if="!(itemParameters.customReset)" slot="start" :class="['reset-button', (resetEnabled) ? 'reset-enabled' : undefined]" @click.stop="resetEntry" @keydown="handleSpacebar($event, ()=>$event.target.click())">
      <ion-button fill="clear" color="danger" shape="round" :disabled="disabled">
        <ion-icon slot="icon-only" :icon="closeCircleOutline"></ion-icon>
      </ion-button>
    </div>

    <!-- Add custom input to set a custom validity to, that gets checked, before submit. Has no other purpose -->
    <input tabindex="-1" inputmode="none" @focus.stop="$event => cancelFocus($event)" type="text" class="invisible-input custom-validity" ref="customValidityInput" :name="`${internalKey}${formKeySeparator}customValidity`" />

    <!-- Choose the correct input variant based on the requested type -->
    <BooleanInput
      v-if="itemParameters.componentType === 'BooleanInput'"
      :key="internalKey"
      v-model="computedValue"
      :presetValue="presetValue"
      @update:modified="setModified"
      @invalidityMessage="setCustomInputValidityMessage"
      :custom_placeholder="custom_placeholder"
      :disabled="disabled"
      >
    </BooleanInput>
    <ValueSelect
      v-if="itemParameters.componentType === 'ValueSelect'"
      :key="internalKey"
      :type="type"
      v-model="computedValue"
      :presetValue="presetValue"
      @update:modified="setModified"
      @invalidityMessage="setCustomInputValidityMessage"  
      :allow_custom_values="allow_custom_values"
      :allow_multiple_values="allow_multiple_values"
      :custom_placeholder="custom_placeholder"
      :available_values="available_values"
      :selectValuesSeparately="selectValuesSeparately"
      :seperateSelectValuesLimit="seperateSelectValuesLimit"
      :keepArrayValues="returnObjectValues"
      :showAsList="showAsList"
      :hideSearchbar="hideSearchbar"
      :unit="unit"
      :disabled="disabled"
      > 
    </ValueSelect>
    <DateTime
      v-if="itemParameters.componentType === 'DateTime'"
      :key="internalKey"
      :type="type"
      v-model="computedValue"
      :presetValue="presetValue"
      @update:modified="setModified"
      @invalidityMessage="setCustomInputValidityMessage"
      :custom_placeholder="custom_placeholder"
      :max="max"
      :disabled="disabled"
      >
    </DateTime>
    <FilesInput
      v-if="itemParameters.componentType === 'FilesInput'"
      :key="internalKey"
      :type="type"
      v-model="computedValue"
      :presetValue="presetValue"
      @update:modified="setModified"
      @invalidityMessage="setCustomInputValidityMessage"
      :captureOptions="capture_options"
      :custom_placeholder="custom_placeholder"
      :disabled="disabled"
      >
    </FilesInput>

    <!-- TODO Test tabbing behaviour -->
  </ion-item>
</template>

<script>

import { IonItem, IonLabel, IonButton, IonIcon, isPlatform } from '@ionic/vue';
import { defineComponent, computed, watch, ref, onMounted } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { closeCircleOutline } from 'ionicons/icons';

import { useI18n } from '@/utils/i18n';

import { convertFieldToComponentType, extractValueFromField } from '@/utils/report';

import { handleSpacebar, cancelFocus } from '@/utils/interaction';

import _ from 'lodash';

import {isBooleanInput, getBooleanInputParameters, default as BooleanInput} from '@/components/forms/inputs/BooleanInput.vue';
import {isValueSelect, getValueSelectParameters, default as ValueSelect} from '@/components/forms/inputs/ValueSelect.vue';
import {isDateTime, getDateTimeParameters, default as DateTime} from '@/components/forms/inputs/DateTime.vue';
import {isFilesInput, getFilesInputParameters, default as FilesInput} from '@/components/forms/inputs/FilesInput.vue';

export default defineComponent({
  name: 'FormItem',
  components: { IonItem, IonLabel, IonButton, IonIcon, FontAwesomeIcon, BooleanInput, ValueSelect, DateTime, FilesInput },
  props: {
    'icon': [Object, String],
    'iconIsFontAwesome': {
      type: Boolean,
      default: false
    },
    'lines': {
      type: String,
      default: 'inset'
    },
    'formKey': {
      type: String,
      default: 'no_key'
    },
    'indexValue': [String, Number],
    'formKeySeparator': {
      type: String,
      default: '->'
    },
    'display_name': String,
    'type': String,
    'pro_only': Boolean, //TODO Hide if not a veterenarian and pro only is set
    'allow_custom_values': Boolean,
    'allow_multiple_values': Boolean,
    'unit': String,
    'available_values': Array,
    'exclude_existing': Array,
    //Show available_values in a select separately, if it is true up to the set limit
    'selectValuesSeparately': {
      type: Boolean,
      default: false
    },
    'seperateSelectValuesLimit': {
      type: Number,
      default: 10
    },
    'modelValue': { //Input of modelValue is just evaluated once to use as a preset value
      type: [String, Number, Boolean, Array, Object],
      default: undefined
    },
    'convertPresetValue': {
      type: [String, Number, Boolean, Array, Object],
      default: undefined
    },
    'custom_placeholder': String,
    'required': Boolean,
    'max': [String, Number], //TODO Maybe use for other inputs than date too
    'capture_options': {
      type: Object,
      default: () => {
        return {};
      }
    },
    'disabled': {
      type: Boolean,
      default: false
    },
    'forceStackedLabel': {
      type: Boolean,
      default: false
    },
    'forceFixedLabel': {
      type: Boolean,
      default: false
    },
    'returnRawValue': {
      type: Boolean,
      default: false
    },
    'returnObjectValues': { //If set to true, it will not convert to single values if supported by the input
      type: Boolean,
      default: false
    },
    'showAsList': { //If set to true, it will show ValueSelect as a list with the option to add and remove values
      type: Boolean,
      default: false
    },
    'hideSearchbar': {
      type: Boolean,
      default: false
    },
    'customReset': {
      type: Boolean,
      default: false
    },
    'disableResetButton': {
      type: Boolean,
      default: false
    },
    'cancelled': { //TODO Don't forget to set for CreateReport when leaving without saving!
      type: Boolean,
      default: false
    }
    //TODO Add option for additional constraints for regular inputs like text or number (e.g. maxlength). They should be checked automatically by the validation routine!
  },
  emits: ['update:modelValue', 'update:modified', 'update:invalid', 'update:preset', 'update:convertPresetValue'],
  setup(props, { emit }) {  
    const i18n = useI18n();

    //Use internal model to save the value
    const modelProp = computed(() => {
      let newModel = props.modelValue;
      return extractValueFromField(newModel);
    });
    const convertPresetProp = computed(() => {
      let newModel = props.convertPresetValue;
      return extractValueFromField(newModel);   
    })
    //Save the initial value as model and preset value
    const internalModel = ref(null);
    const presetValue = ref(modelProp.value);

    const indexProp = computed(() => props.indexValue);

    //True if any value is set (For files, if any files are selected)
    const hasValues = computed(() => {
      return {
        model: (internalModel.value != null), //Model being null means no value, even if it has been reset from a value
        preset: (presetValue.value !== undefined) //Preset is only valid if it is not undefined
      }
    });

    //Holds the current status of modification from the given presetValue (or from empty if presetValue is not set) - Checked inside of each component
    const isModified = ref(false);

    const setModified = function(newModifiedStatus) {
      isModified.value = newModifiedStatus;
      emit('update:modified', newModifiedStatus);
    }

    //Element refs to check validity
    const entryItemRef = ref(null);
    const customValidityInput = ref(null);

    //These variables define the validity status. If any of them is set, the corresponding message is displayed
    const isRequiredAttributeSatisfied = computed(() => (!props.required || hasValues.value.model)); //Check if either it is not required or a value is set
    //Show an error for duplicates
    const isExistingValueExcluded = computed(() => (props.exclude_existing != null && Array.isArray(props.exclude_existing) && internalModel.value != null && props.exclude_existing.includes(internalModel.value)));
    const inputValidityMessage = ref(null); //Holds a validity message from the HTML input components, if any is set
    const customInputValidityMessage = ref(null); //Holds a validity message from the custom input component, if set

    const setCustomInputValidityMessage = function(newCustomInputValidityMessage) {
      customInputValidityMessage.value = newCustomInputValidityMessage;
    }

    //Set when a validity message is set on the customValidityInput -> Any errors exist
    const isInvalid = ref(false);

    /**
     * All validity checks are performed with inputs inside a form. When the forms validity is reported, the custom inputs show the validity and the reportValidity returns if it is valid.
     * So checking the form for validity is an option to prevent sending until it is valid!
     * A hidden input gets a customValidity set that describes its current state.
     * Once the model value changes all the inputs are checked and any errors are set to the custom validity of the hidden input.
     * Any other constraints also set the customValidity message.
     * All messages are forwarded through this watcher to the customValidityInput. We watch it too, in case it is not set yet!
     */
    watch([isRequiredAttributeSatisfied, inputValidityMessage, customInputValidityMessage, customValidityInput, isExistingValueExcluded], (newValues, oldValues) => {
      //Only check validity on changes!
      if (_.isEqual(newValues, oldValues)) return;

      let [requiredSatisfied, newValidityMessage, newCustomValidityMessage, currentCustomValidityInput, existingValueExcluded] = newValues;

      //If the customValidityInput is not ready, return
      if (currentCustomValidityInput == null) return;
      
      //Set a validity message according to the status. Required status comes last, as we want to show the user the wrong input first, custom before internal status
      let validityMessage = null;
      if (newCustomValidityMessage != null) {
        validityMessage = newCustomValidityMessage;
      } else if (newValidityMessage != null) {
        validityMessage = newValidityMessage;
      } else if (existingValueExcluded) {
        validityMessage = i18n.$t('default_interaction.duplicate_input') || 'No duplicates allowed';
      } else if (!requiredSatisfied) {
        validityMessage = i18n.$t('default_interaction.input_required') || 'Input required'; //Use translated input required or fallback string
      }

      //If no message is provided, we set it to empty string to signify no error
      if (validityMessage == null) {
        isInvalid.value = false;
        emit('update:invalid', false);
        currentCustomValidityInput.setCustomValidity('');
      } else {
        isInvalid.value = true;
        emit('update:invalid', true);
        currentCustomValidityInput.setCustomValidity(validityMessage);
      }
    });

    const checkInputValidity = function(){
      if (entryItemRef.value != null){
        //Search all HTML inputs, that are not our customValidityInput for invalidities, otherwise it retriggers its own invalidity in a loop!
        //Works with custom components (shadow DOM) as well
        for (let input of entryItemRef.value.$el.querySelectorAll('input:not(.custom-validity)')){
          //Use the first invalidity found and return
          if (!input.checkValidity()){
            inputValidityMessage.value = input.validationMessage; //TODO Could be fetched from "validity" manually with i18n to not use user agent language. Fallback to validation message is required so string is never empty!
            return; 
          }
        }
      }
      inputValidityMessage.value = null; //If no invalidity is found reset this value
    }

    const reportInputValidity = function(){
      //Report the new validity to the user on blur instead of change to not interrupt the user typing - First check is usually not reported as the values have not been set yet!
      setTimeout(() => customValidityInput.value.reportValidity(), 100);
    }

    //Flag that is set when the first change happens that is not externally!
    const internalModelUpdated = ref(false);

    //Use function to set the internalModel for validation checks on every change! Gives back the new corrected value!
    const setInternalModel = function(newModel, externalChange = false) {
      let newInternalModelValue;
      //If it is null or undefined, set it correctly according to the preset state
      if (newModel == null) {
        //If a preset value was set, then reset it to null to signify the value change -> Used to detect a removed existing value
        if (hasValues.value.preset) {
          newInternalModelValue = null;
        } 
        //Without a preset value, reset it to undefined to signify that this value was never entered
        else {
          newInternalModelValue = undefined;
        }
        customInputValidityMessage.value = null; //Reset custom invalidity as there is no value to check!
      } else {
        newInternalModelValue = newModel;
        //Update flag when the first non-null value is set from the inside
        if (!externalChange) {
          internalModelUpdated.value = true;
        } else {
          //Consider every external value the preset value, if it was set before the internal model sets a value for the first time
          if (internalModelUpdated.value != true) {
            presetValue.value = newModel;
          }
        }
      }

      //updated is only true if it actually changes. Preset not taken into account anymore, so removing the preset also counts as an update
      let updated = !(_.isEqual(internalModel.value, newInternalModelValue));

      internalModel.value = newInternalModelValue;

      //Defer validity check and delay it when externally changed to ensure the internal model has set the values already in the input fields and the invalidity message
      const timeout = (externalChange) ? 500 : 0;
      //Custom Fields might not set the value inside, so check might never be triggered!
      setTimeout(() => checkInputValidity(), timeout);
    
      return [newInternalModelValue, updated];
    }

    const key = computed(() => props.formKey);

    //This key is never used to set values
    const internalKey = computed(() => `${props.formKey}${props.formKeySeparator}${props.indexValue}`);

    //Either returns the raw value or the API componentObject
    const getReturnValue = function(value, componentKey, componentIndex, returnRawComponentValue = false) {
      //The parent has to check for files and move them in a FormData object accordingly (Convert them to File objects in a copy of the object if necessary just before sending!)
      let component = {
        'type': itemParameters.value.apiComponentType,
        'key': componentKey,
        'index': (componentIndex != null) ? (_.isNumber(componentIndex) ? componentIndex.toString() : componentIndex) : undefined
      }

      component = convertFieldToComponentType(component);

      let rawValue = value; //Do not compact arrays, as it creates issues with ValueSelect and separate fields that are added!

      if (rawValue === undefined) { //just check for undefined, when returning empty one. null is not considered empty, as it is used to reset values in the API
        return undefined;
      } else if (itemParameters.value.isFile) { //If it is a file input the model value is a file list
        if (itemParameters.value.multipleFiles) {
          if (returnRawComponentValue) return rawValue;
          component['files'] = rawValue;
        } else {
          //Get the first element if it is a valid array or the value itself otherwise (can be null or undefined)
          let file = (Array.isArray(rawValue) && rawValue.length > 0) ? rawValue[0] : rawValue;
          if (returnRawComponentValue) return file;
          component['file'] = file
        }
      } else { //Otherwise return the given value
        if (returnRawComponentValue) return rawValue;
        component['value'] = rawValue; //value can be null
      }

      return component; 
    }

    const computedValue = computed({
      get: () => {
        if (props.cancelled === true) return null; //On cancel reset the model value in every custom input - This triggers resource cleanups, e.g. for removed files!
        else return internalModel.value;
      },
      set: (newValue) => {
        //Prevent changes when cancelled, as we reset the model values!
        if (!props.cancelled) {
          let [newInternalModel, updated] = setInternalModel(newValue);
        
          if (updated) emit('update:modelValue', getReturnValue(newInternalModel, key.value, indexProp.value, props.returnRawValue), key.value);
        }
      }
    });

    const resetEntry = function(){
      computedValue.value = null;
    }

    const itemParameters = computed(() => {
      //Set default values - used unless overwritten in the following
      let parameters = {
        labelPosition: 'stacked',
        componentType: 'ValueSelect',
        apiComponentType: 'text',
        insetHeader: props.showAsList,
        disableResetButton: props.showAsList,
        customReset: props.customReset,
        isFile: false,
        multipleFiles: false
      }

      //Add new custom inputs here to be recognized
      if (isBooleanInput(props.type)) {
        _.assign(parameters, getBooleanInputParameters(props.type));
      } else if (isValueSelect(props.type)) {
        _.assign(parameters, getValueSelectParameters(props.type));
      } else if (isDateTime(props.type)) {
        _.assign(parameters, getDateTimeParameters(props.type));
      } else if (isFilesInput(props.type)) {
        _.assign(parameters, getFilesInputParameters(props.type));
      } else { //Use text input as a fallback!
        _.assign(parameters, getValueSelectParameters('text'));
      }

      //Check if a labelPosition is enforced. Set last to overwrite any other options
      if (props.forceStackedLabel === true) {
        parameters.labelPosition = 'stacked';
      } else if (props.forceFixedLabel === true) {
        parameters.labelPosition = 'fixed';
      }

      return parameters;
    })

    //Can be used to check if platform is mobile android device to set specific styles
    const platformStyle = computed(() => {
      if (isPlatform('android')) return 'android';
      else return '';
    });

    const statusStyle = computed(() => {
      //Invalidity is always the state that overrides all others
      if (isInvalid.value) {
        return 'invalid';
      } 
      //If it is not invalid and modified it is not in preset state, as it is modified from the given preset (preset can be undefined, modified from an empty value)
      else if (isModified.value) {
        return 'modified';
      } 
      //If it is not modified from preset (includes the check for preset being undefined), preset state is only valid if a preset was supplied
      else if (hasValues.value.preset) {
        return 'preset';
      } 
      //If it is valid, is not different from the preset and the preset was undefined, the field is considered factory state and thus has no specific status
      else {
        return '';
      }
    });

    watch(statusStyle, (newStatus) => {
      if (newStatus === 'preset') emit('update:preset', true);
      else emit('update:preset', false);
    }, { immediate: true })

    const canBeReset = computed(() => {
      return (!props.disableResetButton && !itemParameters.value.disableResetButton);
    });

    const resetEnabled = computed(() => {
      return (canBeReset.value && hasValues.value.model);
    });

    //Watch the modelValue for changes in case the prop was not transmitted yet, also set once immediately to propagate the change back
    watch([modelProp, indexProp], (newValues, oldValues) => {
      let [newModel, newIndex] = newValues;
      let [, oldIndex] = oldValues;
      //Only react on change of the props to avoid recursion
      if (_.isEqual(newValues, oldValues)) return;
      //Update the internal model - consider undefined and null both to be null from external sources! Is already implied in setInternalModel
      let [newInternalModel, updated] = setInternalModel(newModel, true);
      //Emit changes, as setInternalModel might modify the internal vlaue
      if (updated || (oldIndex != newIndex)) emit('update:modelValue', getReturnValue(newInternalModel, key.value, newIndex, props.returnRawValue), key.value);
    }, {immediate: true});

    watch([convertPresetProp, indexProp, key], (newValues, oldValues) => {
      let [newValue, newIndex, newKey] = newValues;
      //Only react on change of the props to avoid recursion
      if (_.isEqual(newValues, oldValues)) return;
      //Emit changes to the presetProp as converted values
      emit('update:convertPresetValue', getReturnValue(newValue, newKey, newIndex, props.returnRawValue), newKey);
    }, {immediate: true})

    //Validate and set result on mount to show initially missing fields
    onMounted(() => {
      checkInputValidity();
    });

    return {
      entryItemRef,
      customValidityInput,

      presetValue,
      computedValue,
      key,
      internalKey,
      hasValues,
      itemParameters,
      platformStyle,
      statusStyle,
      canBeReset,
      resetEnabled,
      setModified,
      setCustomInputValidityMessage,
      handleSpacebar,
      cancelFocus,
      reportInputValidity,

      closeCircleOutline,
      resetEntry
    };
  }
});
</script>

<style scoped>
.entry-item {
  display: flex;
  align-items: center;
  --padding-start: 0px;
}

.entry-item.inset-header .wrapping-label {
  margin-left: calc((3*5px) + 1.75em);
}

.ios .entry-item.inset-header .wrapping-label {
  margin-left: calc((3*5px) + 1.95em);
}

.wrapping-label {
  margin-bottom: 8px;
  white-space: normal!important;
  /* Prevent text selection in label */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.wrapping-label.label-fixed {
  flex: 1 1 0%;
  width: 100%;
  max-width: initial;
}

/* Fix to move it to better position for no overlap */
.wrapping-label.label-stacked:not(.ios) {
  transform: translateY(40%) scale(0.85);
}

.wrapping-label.disabled {
  opacity: 0.3;
}

.invalid > ion-label {
  color: var(--ion-color-danger)!important;
}

.modified > ion-label {
  color: var(--ion-color-success-shade)!important;
}

.preset > ion-label {
  color: var(--ion-color-primary-text)!important;
}

.invisible-input {
  /* Avoid reflow of other elements */
  position: absolute;
  /* Show any invalidity popups in the center of the element. Moved down slightly to better point at the missing input. */
  left: 50%;
  bottom: 25%;
  /* Hide any possibly visible parts of the input */
  background: var(--ion-background-color, white);
  border: none;
  opacity: 0;
  z-index: -10;
  /* Prevent user clicks on element, that would open the dialog twice */
  pointer-events: none;
  /* Make the element as small as possible, so it still can show the validity popup */
  width: 1px;
  height: 1px;
}

.reset-button {
  margin: 0;
  margin-right: 5px;
  visibility: visible;
  pointer-events: all;
}

.reset-button:not(.reset-enabled){
  visibility: hidden;
  pointer-events: none;
}

.reset-button > ion-button {
  height: auto;
  --padding-top: 5px;
  --padding-bottom: 5px;
  --padding-start: 5px;
  --padding-end: 5px;
}

.label-icon {
  font-size: 1.1em;
  margin-inline-end: 10px;
}

ion-icon.label-icon {
  font-size: 1.2em;
}

.label-with-icon {
  display: flex;
  align-items: center;
}
</style>

<template>
  <ion-app>
    <!--Display menu (Tracking Tab) when on a large device-->
    <ion-split-pane when="(min-width: 992px)" id="pane" content-id="main">
      <!--  the side menu  -->
      <ion-menu :disabled="!sideMenuComponentIsValid" menu-id="side-component" side="start" content-id="main" :swipe-gesture="sideMenuComponentIsValid">
        <!-- Dynamic component loading through router meta field to give ability for different side menus in different parts of the app -->
        <template v-if="sideMenuComponentIsValid">
          <component :is="(sideMenuComponentIsValid) ? route.meta.sideMenuComponent : ''" simpleToolbar></component>
        </template>
      </ion-menu>

      <!-- the main content -->
      <ion-router-outlet id="main"/>
    </ion-split-pane>
    <SettingsMenu :isAuthenticated="isAuthenticated" :isUserPresent="isUserPresent" :userIdentification="userEmail"></SettingsMenu>
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, IonSplitPane, IonMenu, getPlatforms } from '@ionic/vue';
import { defineComponent, onMounted, computed, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import { provideI18n } from "@/utils/i18n";
import { provideDayjs } from "@/utils/dayjs";

import { apiErrorToast } from '@/utils/error';
import { createDocumentClickHandler } from '@/utils/file_download';

import SettingsMenu from "@/components/SettingsMenu.vue";

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
    IonMenu,
    SettingsMenu
  },
  setup(){
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const languageString = navigator.language || navigator.userLanguage || '';
    const initialLanguage = languageString.split(/[_-]/)[0].toLowerCase(); //TODO Store language as a setting and use user agent if none is set

    var uploadRetryInterval = null;
    const UPLOAD_RETRY_WAIT_TIME_MINS = 5; //TODO Maybe make it a setting in the sync overview dialog

    var APIUpdateInterval = null;
    const API_UPDATE_INTERVAL_TIME_MINS = 5;

    const USER_UPDATE_INTERVAL_MINS = 10;

    var reportTypeCachingTimeout = null;
    const REPORT_TYPE_CACHING_WAITTIME_SECS = 5;

    const i18n = provideI18n({
      locale: initialLanguage,
      fallback: "en"
    });

    const cacheReportTypes = function() {
      //First reset other caching attempts, to not run them in parallel
      clearTimeout(reportTypeCachingTimeout);
      reportTypeCachingTimeout = null;

      //Fetch all most recent reportTypes - All others will be fetched on demand or have been fetched when using them in the past
      let reportTypeIndex = store.getters['reports/getReportTypeIndex'];
      let reportTypeFetchChain = Promise.resolve(); //Create a resolved promise as a start for the chain
      for (let reportType of Object.values(reportTypeIndex)) {
        //Get the newest reportType version of each one, check if it is already cached (just for not refetching every time and to show in UI)
        //And if not cached yet, fetch it one after the other in a promise chain
        if (reportType.id != null && !reportType.cached) {
          reportTypeFetchChain = reportTypeFetchChain.then( //TODO Delay or move to ServieWorker. Makes app hang!
            () => store.dispatch('reports/fetchReportType', reportType.id)
          )
          .catch(() => true); //On fulfillment or on error continue with the next one
        }
      }
    }

    const updateAPIInformation = function(){ //TODO Show loading indicator somewhere centrally, while it is still loading. Especially after first login!!
      //TODO Add automatic refresh maybe through filter (maybe filter always in the request for newer than last request! --> Could result in caching if not overwritten on new fetch) --> updated_at newer than now; Maybe also move this whole update routine into api.js and regularly call it
      
      if (isAuthenticated.value === true) { //Only try if authenticated
        store.dispatch('customization/fetchCustomizations')
        .then(() => store.dispatch('horses/fetchHorses'))
        .then(() => store.dispatch('horses/fetchStatuses'))
        .then(() => store.dispatch('reports/fetchReportTypeIndex'))
        .then(() => reportTypeCachingTimeout = setTimeout(cacheReportTypes, (REPORT_TYPE_CACHING_WAITTIME_SECS * 1000)))
        .then(() => store.dispatch('reports/fetchMainCategories'))
        .then(() => store.dispatch('reports/fetchReportIndex'))
        .then(() => store.dispatch('encyclopedia/fetchEncyclopediaIndex'))
        .catch((error) => {
          if (error.response && error.response.status && error.response.status == 403) return; //Ignore Forbidden, in case user logs out
          apiErrorToast(i18n, error);
        });
      }
    }

    const startAPIUpdateInterval = function(){ //TODO Maybe call later in a background thread when it is a hybrid app or with service worker (Could be on online status change events?)
      if (isAuthenticated.value === true) updateAPIInformation(); //Only try if authenticated
      clearInterval(APIUpdateInterval);
      APIUpdateInterval = setInterval(updateAPIInformation, API_UPDATE_INTERVAL_TIME_MINS * 60 * 1000);
    }


    //Set the value to use, when no preference has been saved
    function changeDarkMode(newValue){
      store.dispatch('setDarkModeAutomaticValue', newValue);
    }

    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addEventListener('change', (query) => changeDarkMode(query.matches));

    function refreshUserStatus(){
      store.dispatch('auth/fetchUser').catch((error) => {
        apiErrorToast(i18n, error, false, false); //Enable error toast to show end of session
      });
    }

    // Check preference on startup and set initial route attributes
    onMounted(() => {
      document.addEventListener('click', createDocumentClickHandler(i18n, getPlatforms()));
      addAndRemoveSideMenuMediaQueryListeners(route.meta.sideMenuMediaQuery); //If this query is set, it disables the side menu completely at certain sizes
      changeDarkMode(prefersDark.matches);

      if (isAuthenticated.value === true){ //Already logged in on load
        refreshUserStatus();
      }
    });

    setInterval(refreshUserStatus, USER_UPDATE_INTERVAL_MINS * 60 * 1000);

    const isAuthenticated = computed(() => store.getters['auth/isAuthenticated']);

    const userEmail = computed(() => store.getters['auth/getEmail']);

    const isUserPresent = computed(() => store.getters['auth/isUserPresent']);

    //Since the mediaQuery instance does not change when the screen size changes, only the status of it, we need to watch every new query for changes in its status
    //This is needed so the sideMenuComponentIsValid computed property fires a change even when just the query status changes
    const sideMenuConstraintActive = ref(true); //Start with true (Side Menu is hidden)

    const reactToSideMenuMediaQuery = function(query){
      sideMenuConstraintActive.value = query.matches;
    }

    //Called on start (reload) and on change of the mediaQuery, aka if navigation inside the router is performed without reload
    const addAndRemoveSideMenuMediaQueryListeners = function(newQuery, prevQuery){
      if(prevQuery){
        prevQuery.removeEventListener('change', reactToSideMenuMediaQuery);
        sideMenuConstraintActive.value = true; //Set default when removing the listener
      }

      if(newQuery){
        sideMenuConstraintActive.value = newQuery.matches; //Set initial value when adding the listener
        newQuery.addEventListener('change', reactToSideMenuMediaQuery);
      } else { //If no valid constraint anymore, remove all constraints
        sideMenuConstraintActive.value = false;
      }
    }

    //Watch the mediaQuery in the current routing session (Reload resets the routing session)
    watch(
      () => route.meta.sideMenuMediaQuery,
      addAndRemoveSideMenuMediaQueryListeners
    )

    const sideMenuComponentIsValid = computed(() => {
      if ('sideMenuComponent' in route.meta && typeof route.meta.sideMenuComponent !== 'undefined'){
        //Check if the media query constraint allows this component
        if (sideMenuConstraintActive.value){
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    })

    //Handle navigation on authentication change
    //On logout redirect to login page
    //On login redirect to the last page or to the home page
    watch(
      () => store.getters['auth/isAuthenticated'],
      (isAuthenticated) => {
        if (isAuthenticated === true){ //Login
          updateAPIInformation();

          if (route.query.redirect){ //If redirect in the current query is set, it will try to go there
            router.replace(route.query.redirect); //TODO Every route has to check if all data has been supplied after the redirect or else redirect back to home.
          } else {
            router.replace('/'); //Else Go to home page on login
          }
        } else { //Logout
          if(!route.meta.noAuthenticationRequired) { //Redirect only, if authentication is required for the current route
            router.replace({ name: 'login' }); //TODO Maybe replace animation on login and logout
          }
        }
          
      }
    )

    const retryUploads = function() {
      store.dispatch('retryUploads').catch((errors) => console.error('Automatic retry failed', errors));
    }

    const startUploadRetryInterval = function(){ //TODO Maybe call later in a background thread when it is a hybrid app or with service worker (Could be on online status change events?)
      //Retry once and then after the wait time again. Will only trigger those again that are not currently running.
      retryUploads();
      clearInterval(uploadRetryInterval);
      uploadRetryInterval = setInterval(retryUploads, UPLOAD_RETRY_WAIT_TIME_MINS * 60 * 1000);
    }

    watch(
      () => store.getters['isOnline'],
      (isOnline) => {
        if (isOnline === true){
          //Try upload when online
          startUploadRetryInterval();
          //Update all the indizes regularly
          startAPIUpdateInterval();
        } else { 
          //When offline stop trying
          clearInterval(uploadRetryInterval);
          uploadRetryInterval = null;
          //And stop updating the API indizes
          clearInterval(APIUpdateInterval);
          APIUpdateInterval = null;
        }
      }
    );

    startUploadRetryInterval();
    startAPIUpdateInterval();

    //Provide localized dayjs globally
    provideDayjs({
      localeRef: i18n.locale,
      availableLanguages: i18n.$getAvailableLanguages()
    });

    return { isAuthenticated, userEmail, isUserPresent, route, sideMenuComponentIsValid };
  }
});
</script>

<style>
#pane{
  --side-min-width: 400px;
  --side-max-width: 33%;
}
</style>
<template>
  <ion-page id="custom-page">
    <ion-header>
      <MainToolbar :isSimple="simpleToolbar" :title="i18n.$t('tracking.title')" />
    </ion-header>
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="refreshReportIndex($event.target)">
        <ion-refresher-content></ion-refresher-content> <!-- TODO Make refresher spin until all the reports have been loaded. On first opening after login none have loaded yet! -->
      </ion-refresher>
      <ion-searchbar :disabled="isOffline" :placeholder="i18n.$t(isOffline ? 'default_interaction.search_offline' : 'default_interaction.search')" @keyup="blurOnEnter" @ionBlur="activeSearch = $event.target.value" @ionClear="activeSearch = null"></ion-searchbar>
      <div class="filter-container">
        <div class="chip-container">
            <ion-chip color="primary" outline :class="['date-chip', 'highlight', ((isSelectingDate) ? 'active' : undefined)]" @click="toggleSelectDate()">
              <ion-icon :icon="calendarNumber"></ion-icon>
              <ion-label v-if="selectedDay != null">{{ dayToLocalFormatShort(selectedDay) }}</ion-label>
              <ion-label v-else>{{ i18n.$t('tracking.no-day-selected-hint-short') }}</ion-label>
            </ion-chip>
            <ion-chip :color="(selectedTimespanDay != null) ? 'primary' : 'medium'" outline :class="['date-chip', ((isSelectingTimespanDate) ? 'active' : undefined), ((selectedTimespanDay != null) ? 'highlight' : undefined)]">
              <ion-icon :icon="calendar" @click="toggleSelectEndDate()" class="chip-icon"></ion-icon>
              <ion-label v-if="selectedTimespanDay != null" @click="toggleSelectEndDate()">{{ dayToLocalFormatShort(selectedTimespanDay) }}</ion-label>
              <ion-label v-else @click="toggleSelectEndDate()"><b>+</b> {{ i18n.$t('tracking.to-day-hint') }}</ion-label>
              <ion-icon v-if="selectedTimespanDay != null" :icon="closeCircle" @click="selectedTimespanDay = null" class="chip-close"></ion-icon>
            </ion-chip>
            <ion-chip v-for="filterType in Object.keys(activeFilters).sort()" :key="filterType" color="tertiary" class="filter-chip" outline>
              <ion-icon :icon="getFilterIcon(filterType)" @click="editFilter($event, filterType)" class="chip-icon"></ion-icon>
              <ion-label @click="editFilter($event, filterType)">
                <span v-if="availableFilters[filterType] != null" class="chip-filter-name">{{availableFilters[filterType].localizedName }}: </span>
                <span v-if="activeFilters != null && Array.isArray(activeFilters[filterType]) && activeFilters[filterType].length > 1">{{ activeFilters[filterType].length }} {{ i18n.$t('tracking.selected') }}</span>
                <span v-else-if="activeFilters != null && Array.isArray(activeFilters[filterType]) && activeFilters[filterType].length == 1">{{ getLocalizedFilterName(filterType, activeFilters[filterType][0]) }}</span>
                <span v-else>{{ getLocalizedFilterName(filterType, activeFilters[filterType]) }}</span>
              </ion-label>
              <ion-icon :icon="closeCircle" @click="removeFilter(filterType)" class="chip-close"></ion-icon>
            </ion-chip>
        </div>
        <ion-button :disabled="!Object.keys(availableUnusedFilters).length" @click="editFilter($event)" shape="round" fill="solid" color="primary" class="filter-edit-button">
          <ion-icon slot="icon-only" :icon="filter"></ion-icon>
          <b>+</b>
        </ion-button>
      </div>
      <Calendar :class="(isSelectingDate || isSelectingTimespanDate) ? 'calendar' : 'calendar hidden'" v-model:selectedDay="currentlySelectingDay" v-model:selectedMonth="selectedMonth" :loadedDates="reportIndexForCurrentSelectedMonth" :minDate="isSelectingTimespanDate ? selectedDay : undefined" />
      
      <ion-item v-if="selectedDay == null" lines="none" id="selected-date">
        <ion-label>{{ i18n.$t('tracking.no-day-selected-hint') }}</ion-label>
      </ion-item>
      
      <ion-item lines="none" id="entries-hint">
        <ion-label v-if="filteredReportCount <= 0">{{ i18n.$t('tracking.no-entries-hint') }}</ion-label>
        <ion-label v-else :key="filteredReportCount">{{ i18n.$t('tracking.found-entries') }}: {{ filteredReportCount }}</ion-label>
      </ion-item>
      <div v-for="currentMonth in Object.keys(filteredReportIndex).sort(sortDate)" :key="currentMonth">
        <hr>
        <ion-item lines="none" class="current-month">
          <ion-label>{{ monthToLocalFormat(currentMonth) }}</ion-label>
        </ion-item>
        <div v-for="currentDay in Object.keys(filteredReportIndex[currentMonth]).sort(sortDate)" :key="currentDay">
          <ion-item lines="none" class="current-date">
            <ion-label>{{ dayInMonthToLocalFormat(currentDay, currentMonth) }}</ion-label>
          </ion-item>
          <ion-card v-for="(horseEntries, horse) in filteredReportIndex[currentMonth][currentDay].horses" :key="horse">
            <ion-card-header>
              <ion-card-title class="horse-name-title">{{ (horse != 'null') ? getHorseName(horse) : i18n.$t('tracking.horse_not_assigned') }}</ion-card-title> <!-- TODO Split horse IDs here, before fetching to catch multiple horses -->
            </ion-card-header>

            <ion-card-content>
              <ion-list>
                <ion-item v-for="entry in Object.values(horseEntries).sort(sortByTimestamp)" :key="`${entry.connection}${entry.id}`"
                  class="report-entry-item"
                  detail
                  button
                  @click="navigateToReport(entry.id, entry.connection)">

                  <ion-label>
                    <template v-if="entry.connection === true && entry.reports != null">
                      <template v-for="(connectedHorseEntries, connectedHorse) in entry.reports" :key="connectedHorse">
                        <!-- If the rare case happens that more than one horse is assigned in a connection, show which reports belong to which horse --> <!-- TODO Test if this case works! -->
                        <h1 v-if="Object.keys(entry.reports).length > 1">{{ (connectedHorse != 'null') ? getHorseName(connectedHorse) : i18n.$t('tracking.horse_not_assigned') }}</h1>
                        <h2 class="multi-span-text">
                          <span v-for="(connectedType, typeIndex) in mapToPropertyUnique(connectedHorseEntries, 'type', 'main_category')" :key="typeIndex">
                            {{ getLocalizedMainCategory(connectedType) }}
                          </span>
                        </h2>
                        <h3 class="multi-span-text">
                          <span v-for="(connectedTimestamp, timestampIndex) in mapToPropertyUnique(connectedHorseEntries, 'timestamp')" :key="timestampIndex">
                            {{ timestampToDateTime(connectedTimestamp) || i18n.$t('tracking.no_timestamp') }}
                          </span>
                        </h3>
                        <p class="wrap text-with-badge multi-span-text">
                          <ion-badge>{{ connectedHorseEntries.length }}<ion-icon :icon="documentTextOutline"></ion-icon></ion-badge>
                          <!-- Unique by descriptor as this is what the user sees -->
                          <span v-for="(connectedType, typeIndex) in mapToPropertyUnique(connectedHorseEntries, 'type', 'descriptor')" :key="typeIndex">
                            {{ getLocalizedReportTypeName(connectedType) }}
                          </span>
                        </p>
                        <p class="wrap text-with-icon multi-span-text" v-if="mergeAnalyses(connectedHorseEntries).length">
                          <AnimatedLogo name="cycle" stopPattern="cross" class="logo inline-icon" duration="3000" :enabled="areAnyAnalysesUncompleted(mergeAnalyses(connectedHorseEntries))"></AnimatedLogo>
                          {{ i18n.$t('tracking.analyses.title') }} &mdash;
                          <span v-for="(analysisCount, statusKey) in countAnalyses(mergeAnalyses(connectedHorseEntries))" :key="statusKey">
                            {{ i18n.$t(`tracking.analyses.${statusKey}`) }}: {{ analysisCount }}
                          </span>
                        </p>
                      </template>
                    </template>
                    
                    <template v-else>
                        <h2>{{ getLocalizedMainCategory(entry.type) }}</h2>
                        <h3>{{ timestampToDateTime(entry.timestamp) || i18n.$t('tracking.no_timestamp') }}</h3>
                        <p class="wrap">{{ getLocalizedReportTypeName(entry.type) }}</p>
                        <p class="wrap text-with-icon multi-span-text" v-if="entry.analyses != null && entry.analyses.length">
                          <AnimatedLogo name="cycle" stopPattern="cross" class="logo inline-icon" duration="3000" :enabled="areAnyAnalysesUncompleted(entry.analyses)"></AnimatedLogo>
                          {{ i18n.$t('tracking.analyses.title') }} &mdash;
                          <span v-for="(analysisCount, statusKey) in countAnalyses(entry.analyses)" :key="statusKey">
                            {{ i18n.$t(`tracking.analyses.${statusKey}`) }}:&nbsp;{{ analysisCount }}
                          </span>
                        </p>
                    </template>
                  </ion-label>

                  <!-- TODO Use same check as being uploaded as ViewReport? Then it would only show the status while still being uploaded! -->
                  <ion-button 
                    v-if="entry.uploadStatus != null && getUploadStatus(entry.uploadStatus.key) != null"
                    :key="getUploadStatus(entry.uploadStatus.key).design.identifier"
                    shape="round"
                    fill="clear"
                    class="upload-progress-button"
                    :color="getUploadStatus(entry.uploadStatus.key).design.shortColor"
                    @click.stop="showUploadStatus()"> <!--TODO Maybe add green pencil icon to reports that are being updated, also in upload overview, and also maybe on already updated ones -->
                    <CircularProgress
                      class="upload-progress"
                      :style="'--color: var(' + getUploadStatus(entry.uploadStatus.key).design.color + ');'"
                      :progress="(getUploadStatus(entry.uploadStatus.key).status.progress >= 0) ? getUploadStatus(entry.uploadStatus.key).status.progress : 0">
                      <ion-icon :icon="getUploadStatus(entry.uploadStatus.key).design.icon"></ion-icon>
                    </CircularProgress>
                  </ion-button> <!-- TODO Maybe add icon to indicate cached reports? -->
                </ion-item>
              </ion-list>
            </ion-card-content>
          </ion-card>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonList, IonItem, IonLabel, IonRefresher, IonRefresherContent, IonIcon, IonButton, IonSearchbar, IonChip, IonBadge } from '@ionic/vue';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';


import { calendarNumber, calendar, closeCircle, search, filter, location, list, bookmark, documentTextOutline, gitCommit } from 'ionicons/icons';

import horseIcon from '@/assets/icons/horse.svg';

import MainToolbar from '@/components/MainToolbar.vue';
import AnimatedLogo from '@/components/AnimatedLogo.vue';
import Calendar from '@/components/Calendar.vue';
import CircularProgress from '@/components/CircularProgress.vue';

import { useI18n } from "@/utils/i18n";

import { apiErrorToast, localErrorToast } from '@/utils/error';

import { useDayjs } from '@/utils/dayjs';

import { blurOnEnter } from '@/utils/interaction';

import { mapLocationsWithOrder, getLocation, getAdditionalText, getSearchMetadata } from '@/utils/animals';

import { removeNonAlphaNumCharactersNormalize } from '@/utils/algorithms';

import { openUploadStatusModal, default as uploadModalComponent } from '@/components/UploadStatusModal.vue';
import { openFilterPopup, default as filterPopupComponent } from '@/components/FilterPopup.vue';

import _ from 'lodash';

export default  {
  name: 'Tracking',
  components: { IonHeader, IonContent, IonPage, MainToolbar, Calendar, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonList, IonItem, IonLabel, IonRefresher, IonRefresherContent, CircularProgress, IonIcon, IonButton, IonSearchbar, IonChip, IonBadge, AnimatedLogo },
  props: {
    simpleToolbar: Boolean
  },
  setup() {
    const i18n = useI18n();

    const store = useStore();

    const router = useRouter();

    const { dayjs, dayjsLocale, isReady } = useDayjs();

    const isSelectingDate = ref(false);

    const isSelectingTimespanDate = ref(false);

    const FALLBACK_AVATAR = horseIcon;
    const FALLBACK_AVATAR_IS_FONT_AWESOME = false;

    const isOffline = computed(() => {
      return !(store.getters.isOnline);
    });

    const setSelectedMonthFromDay = function(day){
      if (day != null) {
        let currentDay = dayjs.utc(day, 'DD.MM.YYYY');
        if (currentDay != null && currentDay.isValid()) {
          selectedMonth.value = currentDay.format('MM.YYYY');
          return true;
        }
      }
      return false;
    }

    const toggleSelectDate = function(){
      //If we make it visible, set current month to the month of the current day
      if (!isSelectingDate.value) setSelectedMonthFromDay(selectedDay.value);
      isSelectingDate.value = !isSelectingDate.value;
      isSelectingTimespanDate.value = false;
    }

    const toggleSelectEndDate = function(){
      //If we make it visible, set current month to the month of the current day
      if (!isSelectingTimespanDate.value) setSelectedMonthFromDay(selectedTimespanDay.value);
      isSelectingTimespanDate.value = !isSelectingTimespanDate.value;
      isSelectingDate.value = false;
    }

    const FILTER_ICONS = { //TODO Add icon for horse and control_examination
      stage: gitCommit,
      category: bookmark,
      type: list,
      location: location
    }

    const getFilterIcon = function(filterType) {
      if (filterType in FILTER_ICONS) {
        return FILTER_ICONS[filterType];
      }
      return filter;
    }

    const reportIndexForCurrentSelectedMonth = computed(() => store.getters['reports/getLocalizedReportCountsForCurrentSelectedMonth']);

    const activeSearch = ref(null);

    const searchedReports = ref(null);

    //Search is calculated immediately on load (empty) and when it or the index changes!
    watch([activeSearch, reportIndexForCurrentSelectedMonth], ([newSearch]) => {
      if (newSearch != null && newSearch.length) {
        //TODO Maybe also search reports in upload cache locally and add their tempId U+index. For now all of them are included in the applyFilter function, because there are not a lot.
        store.dispatch('reports/searchReports', newSearch)
        .then((searchedReportIndex) => {
          searchedReports.value = searchedReportIndex;
        })
        .catch((error) => {
          apiErrorToast(i18n, error);
          searchedReports.value = null;
        })
      } else { //No valid search term, reset filter
        searchedReports.value = null;
      }
    }, {deep: true, immediate: true});

    //TODO TEST: Filters should be reapplied automatically, when they change or the list of reports changes, becusee it will be a computed value

    const editFilter = function(event, filterType) {
      let value = null;
      if (activeFilters.value != null && activeFilters.value[filterType] != null) {
        value = activeFilters.value[filterType];
      }
      openFilterPopup(filterPopupComponent, event, _.assign({}, availableUnusedFilters.value, _.pick(availableFilters.value, filterType)), filterType, value, { 
          customNoEntriesHint: i18n.$t('filter.no_entries.filter_date_dependancy'),
          fallbackAvatarIcon: FALLBACK_AVATAR,
          fallbackAvatarIconIsFontAwesome: FALLBACK_AVATAR_IS_FONT_AWESOME,
        })
        .then((data) => {
          if (data != null && data.data != null && data.data['filter'] != null && data.data['value'] != null) {
            activeFilters.value[data.data['filter']] = data.data['value'];
          }
        })
    }

    const removeFilter = function(filterType) {
      if (filterType in activeFilters.value) 
        delete activeFilters.value[filterType];
    }

    const getLocalizedFilterName = computed(() => {
      return (filterType, filterValue) => {
        if (availableFilters.value != null && filterType in availableFilters.value && availableFilters.value[filterType].categories != null) {
          for (let category of Object.values(availableFilters.value[filterType].categories)) {
            for (let option of category.options) {
              if (_.isEqual(option.value, filterValue)) return option.localizedName;
            }
          }
        }
        else return '';
      }
    });

    //TODO Don't load all reports when date is undefined? Or load all? Check behaviuor when no date or date range is selected
    //TODO Maybe add filter later to include only reports with media (Needs search from API again!)

    const sortDate = function(firstDate, secondDate) {
      let firstValue = firstDate.split('.').reverse().join();
      let secondValue = secondDate.split('.').reverse().join();
      if (firstValue < secondValue) return -1;
      if (firstValue > secondValue) return 1;
      return 0;
    }

    const sortByTimestamp = function(firstObject, secondObject) {
      //Take the timestamp if it exists, or if an array of multiple is given, try to get the first one or set it to null
      let firstTimestamp = firstObject.timestamp || ((Array.isArray(firstObject.timestamps)) ? firstObject.timestamps[0] : null);
      let secondTimestamp = secondObject.timestamp || ((Array.isArray(secondObject.timestamps)) ? secondObject.timestamps[0] : null);
      if (firstTimestamp != null && secondTimestamp != null) {
        if (firstTimestamp < secondTimestamp) return -1;
        if (firstTimestamp > secondTimestamp) return 1;
      }
      return 0;
    }

    const selectedDay = computed({
      get: () => store.getters['reports/getSelectedDay'],
      set: newValue => {
        store.dispatch('reports/updateSelectedDay', newValue);
      }
    });

    const selectedTimespanDay = computed({
      get: () => store.getters['reports/getSelectedTimespanDay'],
      set: newValue => {
        store.dispatch('reports/updateSelectedTimespanDay', newValue);
      }
    });

    const timestampToDateTime = computed(() => {
      return (timestamp) => {
        if (timestamp == null) return null;
        try {
          //Use js internal Date object for parsing the ISO String to treat 0-padded years correctly, e.g. 0001 is not 1901!
          let localDateTime = dayjs((new Date(timestamp))).millisecond(0).second(0); //Remove precision of seconds and milliseconds!
          if (localDateTime != null && localDateTime.isValid()) {         
            return localDateTime.format('LT');
          }
        } catch {
          //Skip conversion to date on error!
          return null;
        }
      }
    });

    const getLocalizedMainCategoryById = function(mainCategoryId){
      let categoryNames = store.getters['reports/getMainCategoryNamesById'](mainCategoryId);
      if (categoryNames) {
        return categoryNames[i18n.locale.value];
      } else {
        return null;
      }
    }

    //Returns the report type if it already is a full object, otherwise tries to get it from store, if it is just an ID
    const getReportType = function(reportTypeOrID) {
      if (reportTypeOrID != null) {
        if (_.isObject(reportTypeOrID)) {
          return reportTypeOrID;
        } else {
          return store.getters['reports/getReportTypesByIdentifiers'](reportTypeOrID);
        }
        
      }
    }

    const getLocalizedMainCategory = function(reportTypeOrID){
      let reportType = getReportType(reportTypeOrID);
      if (reportType != null && reportType['main_category'] != null) {
        return getLocalizedMainCategoryById(reportType['main_category']);
      }
      return null;
    }

    const getLocalizedReportTypeNameByDescriptor = function(descriptor){
      if (descriptor) {
        return descriptor; //TODO Use the translated name here. Translate in API definition in reports too!
      } else { //TODO Can get it directly from each report
        return null;
      }
    }

    const getLocalizedReportTypeName = function(reportTypeOrID){
      let reportType = getReportType(reportTypeOrID);
      if (reportType != null && reportType.descriptor != null) {
        return getLocalizedReportTypeNameByDescriptor(reportType.descriptor);
      } else {
        return null;
      }
    }

    const getHorseName = function(horseId){
      let horse = store.getters['horses/getPersonalInfoById'](horseId);
      if (horse) {
        return horse.name;
      } else {
        return null;
      }
    }

    const mergeAnalyses = function(reports) {
      let analyses = [];
      for (const report of Object.values(reports)) {
        if (report.analyses != null && Array.isArray(report.analyses) && report.analyses.length) {
          for (let newAnalysis of report.analyses) analyses.push(newAnalysis);
        }
      }
      return analyses;
    }

    const countAnalyses = function(analyses) {
      if (analyses == null) return null;
      return _.countBy(analyses, (analysis) => {
        if (analysis != null && analysis.status != null && analysis.status.completed) {
          return 'completed';
        } else if (analysis != null && analysis.status != null && analysis.status.queued) {
          return 'queued';
        } else {
          return 'unqueued';
        }
      });
    }

    const areAnyAnalysesUncompleted = function(analyses) {
      if (analyses == null) return null;
      return _.some(analyses, (analysis) => {
        if (analysis != null && analysis.status != null) {
          if (analysis.status.completed) return false;
        }
        return true;
      });
    }

    const availableReportTypes = ref([]);

    const availableLocationsObject = computed(() => {
      return mapLocationsWithOrder(store.getters['customization/getLocations']);
    });

    const unassignedPlaceholder = computed(() => {
      return i18n.$t('analysis.subject.unassigned');
    });

    const reportTypeFilters = computed(() => {
      if (availableReportTypes.value == null) return null;

      return _.mapValues(availableReportTypes.value['byHierarchy'], (examinationStage, examinationStageDescriptor) => {
        if (examinationStage == null && examinationStage.categories != null) return null;
        
        let options = [];

        _.forEach(examinationStage.categories, (category, categoryDescriptor) => {
          if (category.types != null) _.forEach(category.types, (type, typeDescriptor) => { //TODO How to treat spaces at the end or slightly different writing? Cleanup? Trim? So they don't appear twice!
            options.push({
              value: type.identifiers,
              localizedName: typeDescriptor, //TODO Translate descriptor. Get translated version in return from model?
              indicatorText: (categoryDescriptor != 'null') ? categoryDescriptor : undefined,
              order: parseInt(`${(category.order > 0) ? category.order : 0}${(type.order > 0) ? type.order : 0}`), //Take both order properties into account, category preceding type
              searchMetadata: [ //Has to be array of normalized strings
                removeNonAlphaNumCharactersNormalize(examinationStageDescriptor),
                removeNonAlphaNumCharactersNormalize(categoryDescriptor)
              ]
            });
          });
        })

        return {
          order: examinationStage.order,
          options
        }
      });
    });

    const animalFilters = computed(() => {
      let animals = _.map(store.getters['horses/getHorseInfos'], (animal, animalId) => {
        let location = getLocation(animal, unassignedPlaceholder.value, availableLocationsObject.value);
        return {
          value: animalId,
          localizedName: animal.name || i18n.$t('report.create.horse_no_name'),
          additionalText: getAdditionalText(animal),
          wrapAdditionalText: true,
          location: location,
          order: (location != null && location.spot != null) ? location.spot.order : undefined,
          indicatorText: (location != null && location.spot != null) ? location.spot.text : undefined,
          italic: animal.name == null,
          showAvatar: true,
          avatarSrc: (animal != null && animal.image != null) ? animal.image.blobURL : undefined,
          avatarColor: (animal != null) ? animal.color : undefined,
          searchMetadata: getSearchMetadata(animal, false)
        };
      });
      let animalsByLocation = _.reduce(animals, (result, animal) => {
        //Default unordered fallback
        let animalLocation = 'null';
        let animalLocationOrder = -2;
        if (animal != null && animal.location != null && animal.location.location != null) {
          if (animal.location.location.text != null) animalLocation = animal.location.location.text;
          if (animal.location.location.order != null) animalLocationOrder = animal.location.location.order;
        }

        //Either get existing object or create
        let locationObject = (result[animalLocation] || (result[animalLocation] = { order: animalLocationOrder, options: [] }));

        locationObject.options.push(animal);

        return result;
      }, {});
      
      let unassignedAnimalText = i18n.$t('tracking.filters.horse.not_assigned');

      //Add unassigned horses
      _.update(animalsByLocation, [unassignedAnimalText], (unassignedLocation) => {
        return {
          ...unassignedLocation,
          order: -3,
          options: _.concat((unassignedLocation != null && unassignedLocation.options != null) ? unassignedLocation.options : [], [{ value: 'null', localizedName: unassignedAnimalText, italic: true, order: -1 }])
        }
      });

      return animalsByLocation;
    });

    const mainCategoryFilters = computed(() => { //TODO If in the future as localized text, change from ID here
      if (availableReportTypes.value['mainCategories'] == null) return null;
      return { null: { options: _.map(availableReportTypes.value['mainCategories'], (category) => { return { value: category, localizedName: getLocalizedMainCategoryById(category) } }) } }; //TODO Order?
    });

    const stageFilters = computed(() => {
      let noStageText = i18n.$t('analysis.protocols.no_examination_stage');

      return { null: { 
        options: _.compact(_.map(reportTypeFilters.value, (stage, stageDescriptor) => {
            if (stageDescriptor == 'null') return { value: 'null', localizedName: noStageText, italic: true, order: 0 }; //Always at the beginning, other options order is +1

            return { value: stageDescriptor, localizedName: stageDescriptor, order: (parseInt(stage.order) + 1) } //TODO store needs to give the localized versions too! And then translate above in the reportTypeFilers and here!
          }))
        }
      }
    });

    const availableLocationFilters = computed(() => {
      return { null: { options: _.map(store.getters['reports/getLocationValues'], (value) => { return { value, localizedName: i18n.$t(`report.location.${value}`) };}) } };
    });

    const availableFilters = computed(() => {
      return { //TODO Set order properties when they are ordered! Most of them have already.
        'stage': { order: 0, localizedName: i18n.$t('analysis.protocols.examination_stage'), allowMultiple: true, categories: stageFilters.value },
        'category': { order: 1, localizedName: i18n.$t('tracking.filters.category'), allowMultiple: true, categories: mainCategoryFilters.value },
        'type': { order: 2, localizedName: i18n.$t('tracking.filters.type'), allowMultiple: true, categories: reportTypeFilters.value },
        'horse': { order: 3, localizedName: i18n.$t('tracking.filters.horse.name'), allowMultiple: true, categories: animalFilters.value },
        'location': { order: 4, localizedName: i18n.$t('tracking.filters.location'), allowMultiple: true, categories: availableLocationFilters.value },
        'control_examination': { order: 5, localizedName: i18n.$t('tracking.filters.control_examination.name'), categories: { 
          null: { options: [ {value: false, localizedName: i18n.$t('tracking.filters.control_examination.false')}, {value: true, localizedName: i18n.$t('tracking.filters.control_examination.true')} ] } }
        }
      };
    });

    //Filters that change based on the currently visible report time range or other filters depending on each other - Depends on the list of animals
    const DYNAMIC_FILTERS = ['stage', 'category', 'type'];

    const activeFilters = computed({
      get: () => store.getters['reports/getFilters'],
      set: filters => {
        store.commit('reports/setFilters', filters); //TODO Might only work if it is re-set and not modified
      }
    });

    //Remove all the filters that are not available anymore, when they change, also done on start
    watch(availableFilters, (newFilters, oldFilters) => {
      //Only react when there is something to check and we have a change
      if (newFilters != null && activeFilters.value != null && !_.isEqual(newFilters, oldFilters)) {
        for (let currentFilter of DYNAMIC_FILTERS) {
          if (newFilters[currentFilter] != null && newFilters[currentFilter].categories != null && activeFilters.value[currentFilter] != null) {
            //Get only the filters that are in the active and in the available ones
            activeFilters.value[currentFilter] = _.intersectionWith(activeFilters.value[currentFilter],
                                                                    _.flatMap(newFilters[currentFilter].categories, (category) => category.options), //Take all the options without being sorted in categories
                                                                    (filter, availableFilterOption) => _.isEqual(filter, availableFilterOption.value)); //Compare the value of each option with the value of the active filter
            if (Array.isArray(activeFilters.value[currentFilter]) && activeFilters.value[currentFilter].length == 0) {
              delete activeFilters.value[currentFilter];
            }
          }
        }
      }
    }, {deep: true, immediate: true});

    const availableUnusedFilters = computed(() => {
      return _.omit(availableFilters.value, Object.keys(activeFilters.value));
    });
    
    //Filtered index is updated by watcher
    const filteredReportIndex = ref({}); //TODO Filtered index Only includes new ones not the old, updated ones, is that by design? Probably for the best. Keep in mind when searching in the API.

    //Sums up all the counts inside each day
    const filteredReportCount = computed(() => {
      if (filteredReportIndex.value != null) {
        let sum = _.sum(_.map(filteredReportIndex.value, (month) => {
          return _.sum(_.map(month, (day) => {
            return day.count;
          }));
        }));
        if (!isNaN(sum)) return sum;
      }
      return 0;
    });

    //asyncFilterFunction updates, if the index of the reports changes!
    const asyncFilterFunction = computed(() => store.getters['reports/getReportIndexFilterAsyncFunction']);

    //Starts filtering when one of the dependencies changes and once on load
    watch([activeFilters, searchedReports, asyncFilterFunction], ([newFilters, newSearchedReports, newAsyncFilterFunction]) => {
      if (newAsyncFilterFunction != null) { 
        newAsyncFilterFunction(newFilters, newSearchedReports)
          .then(({index, reportTypes}) => {
            filteredReportIndex.value = index;
            availableReportTypes.value = reportTypes;
          })
          .catch(error => {
            console.error('Error filtering reports', error);
            localErrorToast(i18n, error);

            filteredReportIndex.value = {};
            availableReportTypes.value = {};
          });
      } else {
        filteredReportIndex.value = {};
        availableReportTypes.value = {};
      }
    }, {deep: true, immediate: true});

    //Get and set the currently selected date that is edited, or none if nothing is selected
    const currentlySelectingDay = computed({
      get: () => {
        if (isSelectingDate.value) {
          return selectedDay.value;
        } else if (isSelectingTimespanDate.value) {
          return selectedTimespanDay.value;
        } else {
          return null
        }
      },
      set: newValue => {
        if (isSelectingDate.value) {
          selectedDay.value = newValue;
          isSelectingDate.value = false;
        } else if (isSelectingTimespanDate.value) {
          selectedTimespanDay.value = newValue;
          isSelectingTimespanDate.value = false;
        }
      }
    });

    //TODO REDO Those?
    const dayToLocalFormat = computed(() => {
      return (day) => {
        if (isReady.value) {
          return dayjs(day, 'DD.MM.YYYY').locale(dayjsLocale.value).format('LL');
        } else {
          return dayjs(day, 'DD.MM.YYYY').format('LL');
        }
      }
    });

    const dayInMonthToLocalFormat = computed(() => {
      return (day, month) => {
        return dayToLocalFormat.value(`${day}.${month}`);
      }
    });

    const dayToLocalFormatShort = computed(() => {
      return (day) => {
        if (isReady.value) {
          return dayjs(day, 'DD.MM.YYYY').locale(dayjsLocale.value).format('L');
        } else {
          return dayjs(day, 'DD.MM.YYYY').format('L');
        }
      }
    });

    const monthToLocalFormat = computed(() => {
      return (month) => {
        if (isReady.value) {
          return dayjs(month, 'MM.YYYY').locale(dayjsLocale.value).format('MMMM YYYY');
        } else {
          return dayjs(month, 'MM.YYYY').format('MMMM YYYY');
        }
      }
    });

    const selectedMonth = computed({
      get: () => store.getters['reports/getSelectedMonth'],
      set: newValue => {
        store.dispatch('reports/updateSelectedMonth', newValue);
      }
    });

    const navigateToReport = function(reportOrConnectionId, isConnection){
      let newRoute;
      if (isConnection === true) {
        newRoute = {name: 'view-connected-reports', params: { connection_id: reportOrConnectionId }};
      } else {
        newRoute = {name: 'view-report', params: { id: reportOrConnectionId }};
      }

      let currentRoute = router.currentRoute.value;
      //If we are already navigated to a report route, replace it, else push it
      if (currentRoute.name === 'view-report' || currentRoute.name === 'view-connected-reports') {
        router.replace(newRoute);
      } else {
        router.push(newRoute);
      }
    }

    const refreshReportIndex = function(refresher = null){ //TODO Add automatic refresh in an interval!
      console.log("FETCH REPORT INDEX FROM TRACKING"); //FIXME Called twice because of side menu that is not even visible!
      store.dispatch('reports/fetchReportIndex')
        .then(() => {
          if (refresher != null){
            refresher.complete();
          }
        })
        .catch(error => {
          if (refresher != null){
            refresher.cancel();
          }
          apiErrorToast(i18n, error);
        });
    }

    const mapToPropertyUnique = function(array, property, uniqueBy) {
      //Return empty array if invalid
      if (array == null || property == null) return [];
      //Othwerwise map each entry in the array to the given property and return a unique array
      const mappedArray = _.map(array, property);
      //If a uniqueBy property is given, check uniqueness by that parameter
      if (uniqueBy != null) {
        return _.uniqBy(mappedArray, uniqueBy);
      } else { //Otherwise check whole entry for uniqueness
        return _.uniq(mappedArray);
      }
    }

    const getUploadStatusDesign = function(status){
      return store.getters['getUploadStatusDesign'](status);
    }

    const processStatus = function(status) {
      return _.keyBy(_.map((status || []), (statusObject) => {
        let originalStatus = (statusObject != null) ? statusObject.status : null;
        let mappedStatus = {
          ..._.pick(statusObject, ['key', 'index']),
          status: originalStatus,
          design: getUploadStatusDesign(originalStatus)
        };
        return mappedStatus;
      }), 'key');
    }

    //Get all statuses and map them into their displayed texts and design. Mapped to object with the keys being the upload status keys
    const reportUploadStatus = computed(() => processStatus(store.getters['reports/getUploadStatus']));
    const reportConnectionUploadStatus = computed(() => processStatus(store.getters['reports/getConnectionUploadStatus']));

    //Returns a function to get the upload status for the given key, if it exists
    const getUploadStatus = computed(() => (key) => {
      if (reportUploadStatus.value != null && key != null) {
        //Check both report and connection for this uploding key
        if (key in reportUploadStatus.value) return reportUploadStatus.value[key];
        if (key in reportConnectionUploadStatus.value) return reportConnectionUploadStatus.value[key];
      }
    });

    const showUploadStatus = function(){
      openUploadStatusModal(uploadModalComponent);
    }

    onMounted(() => {
      refreshReportIndex();
    });

    return {
      i18n,
      router,
      selectedDay,
      selectedTimespanDay,
      currentlySelectingDay,
      dayToLocalFormat,
      dayInMonthToLocalFormat,
      dayToLocalFormatShort,
      monthToLocalFormat,
      sortDate,
      sortByTimestamp,
      selectedMonth,
      store,
      timestampToDateTime,
      getLocalizedMainCategory,
      getLocalizedReportTypeName,
      getHorseName,
      mergeAnalyses,
      countAnalyses,
      areAnyAnalysesUncompleted,
      navigateToReport,
      refreshReportIndex,
      getUploadStatus,
      getUploadStatusDesign,
      showUploadStatus,
      mapToPropertyUnique,
      isSelectingDate,
      isSelectingTimespanDate,
      isOffline,
      toggleSelectDate,
      toggleSelectEndDate,
      getFilterIcon,
      getLocalizedFilterName,
      reportIndexForCurrentSelectedMonth,
      filteredReportIndex,
      filteredReportCount,
      availableFilters,
      availableUnusedFilters,
      activeSearch,
      activeFilters,
      removeFilter,
      editFilter,
      blurOnEnter,
      calendarNumber,
      calendar,
      closeCircle,
      documentTextOutline,
      search,
      filter
    };
  }
}
</script>

<style scoped>
/* Fix to show the refresher behind the content */
#custom-page {
  background: var(--ion-background-color, #fff);
}
ion-content { 
  --background: transparent;
}


ion-searchbar {
  background: var(--ion-background-color, #fff);
  --background: var(--ion-card-background, #fff);
}

ion-card {
  margin-top: 10px;
}

.horse-name-title {
  font-size: 1.25em;
}

.current-date {
  font-size: 1.25em;
  font-weight: bold;
  text-align: center;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 10;
}

.current-month {
  font-size: 1.5em;
  --background: var(--ion-background-color);
  text-align: center;
  color: var(--ion-color-primary-text)
}

#entries-hint {
  color: var(--ion-color-medium);
  font-weight: bold;
  text-align: center;
  font-size: clamp(.5em, 4vw, 17px);
}

ion-card-content {
  background-color: var(--ion-card-background, #fff);
}

.report-entry-item {
  --padding-start: 0px;
  --background: var(--ion-card-background, #fff);
}

.report-entry-item ion-label {
  margin-left: 8px;
}

.wrap {
  text-overflow: unset;
  height: auto;
  white-space: normal;
}

.upload-progress-button {
  height: 2.5em;
  width: 2.5em;
  --padding-bottom: 0px;
  --padding-top: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  margin: 0px;
  font-size: inherit;
}

.upload-progress {
  width: 2.5em;
  height: 2.5em;
}

.filter-container {
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  width: 100%;
}

.chip-container {
  margin: 0px;
  padding: 0px;
  flex-grow: 1;
  flex-basis: 0px;
  flex-shrink: 1;
  display: flex;
  flex-flow: row wrap;
  width: 0%;
}

.ios .filter-container {
  padding-right: 16px;
  padding-left: 16px;
}

.date-chip {
  color: var(--ion-color-base);
}

.date-chip.highlight {
  color: var(--ion-color-primary-text);
}

.date-chip.active {
  color: var(--ion-color-primary-contrast, 0, 0, 0);
  background: rgba(var(--ion-color-primary-rgb, 0, 0, 0), 1);
}

.filter-chip {
  color: var(--ion-color-tertiary-text);
}

.calendar {
  transition: max-height 0.4s ease-in-out;
  max-height: 450px;
}

.calendar.hidden {
  max-height: 0px;
}

.text-with-badge {
  margin-top: 5px;
  margin-bottom: 5px;
}

.text-with-badge ion-badge {
  vertical-align: bottom;
  margin-right: 5px;
  --background: var(--ion-color-medium-light);
  --color: var(--ion-color-medium-light-contrast);
}

p.wrap {
  margin-bottom: 0px;
}

.text-with-icon {
  line-height: 32px;
}

.text-with-icon ion-icon {
  vertical-align: -0.2em;
  margin-right: 5px;
  color: var(--ion-color-primary-text);
}

.text-with-icon .logo {
  vertical-align: -0.25em;
  margin-right: 5px;
  color: var(--ion-color-primary-text);
}

ion-badge ion-icon {
  margin-left: 2px;
  vertical-align: bottom;
}

.multi-span-text span:not(:last-of-type)::after {
  content: ", ";
}

ion-chip {
  /* Disable select on ion-chip texts */
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

ion-chip ion-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 10px;
  padding-bottom: 10px;
}

.chip-icon {
  padding: 5px 5px 5px 0px;
  margin-right: 0px;
}

.chip-close {
  padding: 5px 0px 5px 5px;
  margin-left: 0px;
  height: 1em;
  width: 1em;
  min-width: 1em;
}

hr {
  border: 2px solid var(--ion-color-medium-light);
  background: var(--ion-color-medium-light);
  border-radius: 2px;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.filter-edit-button {
  height: 32px;
  width: 32px;
  font-size: 1.5em;
  --padding-start: 6px;
  --padding-end: 6px;
  --padding-top: 3px;
}

.filter-edit-button b {
  font-size: 0.7em;
  right: -27%;
  bottom: 12%;
  position: absolute;
  padding: 0px;
  margin: 0px;
}

.filter-edit-button ion-icon, .filter-edit-button b {
  pointer-events: none;
}

.ios .filter-edit-button b {
  font-size: 0.6em;
  right: -15%;
  bottom: 12%;
}

.chip-filter-name {
  font-weight: 250;
}

.inline-icon {
  font-size: 20px;
}

</style>
<template>
  <!-- Don't set disabled, or else it will block interaction on the whole screen. Only close it when disabling -->
  <ion-menu side="end" menu-id="settings" content-id="pane" swipe-gesture="false">
    <!-- Header with User Details is only relevant for logged in users, so it is disabled if not authenticated -->
    <ion-header>  
      <ion-toolbar class="user-info">
        <div class="welcome-header">
          <ion-label class="welcome-label">{{ i18n.$t("settings.welcome") }}</ion-label>
          <ion-buttons>
            <ion-button class="settings-button" fill="clear" shape="round" disabled> <ion-icon slot="icon-only" :icon="settingsOutline"></ion-icon></ion-button>
          </ion-buttons>
        </div>
        <ion-label>
          <span v-if="isAuthenticated">{{ userIdentification || '' }}</span>
          <span v-else class="login-prompt" @click="navigateTo('/login')">{{ i18n.$t("login.login") }} <ion-icon :icon="logInOutline"></ion-icon></span>
        </ion-label>
        
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <!-- Only enable minimum settings if not logged in -->
      <ion-list v-if="isUserPresent">
        <ion-item :detail="false" button @click="store.dispatch('auth/logout').catch(() => {})" lines="full">
          <ion-icon slot="start" :icon="logOutOutline"></ion-icon>
          <ion-label>{{ i18n.$t("login.logout") }}</ion-label>
        </ion-item>
      </ion-list>
      <ion-list v-if="openNotifications != null && openNotifications.length > 0">
        <ion-list-header class="notification-header">
          {{ i18n.$t("settings.notifications") }}
        </ion-list-header>
        <ion-item v-for="(notification, notificationIndex) in openNotifications" :key="notificationIndex">
          <ion-label class="notification-text">
            <h2>{{ notification.title }}</h2>
            <h3 v-if="notification.description != null">{{ notification.description }}</h3>
          </ion-label>
          <ProgressButton class="notification-button" slot="end" color="secondary" spinner_name="crescent" fill="solid" @click="completeNotification(notificationIndex)" :disabled="isOtherNotificationCurrentlyCompleting(notificationIndex)" :loading="isCurrentlyCompleting(notificationIndex)">
            <span v-if="notification.action_text != null">{{ notification.action_text }}</span>
            <ion-icon v-else slot="icon-only" :icon="checkmarkOutline"></ion-icon>
          </ProgressButton>
        </ion-item>
      </ion-list>
      <ion-list>
        <ion-list-header>
          {{ i18n.$t("settings.settings") }}
        </ion-list-header>
        <ion-item lines="inset">
          <!-- Dark mode supports one of three states: AUTO (Use device preference), ON, OFF -->
          <ion-icon slot="start"
            :icon="darkModeActive ? moon : moonOutline">
          </ion-icon>
          <ion-label>{{ i18n.$t("settings.dark_mode") }}</ion-label>
          <ion-select :value="darkModePreference" interface="popover" @ionChange="darkModePreference = $event.target.value">
            <ion-select-option :value="undefined">{{ i18n.$t("settings.auto") }}</ion-select-option>
            <ion-select-option :value="true">{{ i18n.$t("settings.on") }}</ion-select-option>
            <ion-select-option :value="false">{{ i18n.$t("settings.off") }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item v-if="isAuthenticated" button :detail="false" @click="showUploadStatus()">
          <ion-icon slot="start" :icon="cloudUploadOutline">
          </ion-icon>
          <ion-label>{{ i18n.$t("upload-status.title") }}</ion-label>
        </ion-item>
        <ion-list-header>
          {{ i18n.$t("settings.other") }}
        </ion-list-header>
        <ion-item v-if="store.getters['auth/hasPermission']('Tools')" button :detail="false" @click="navigateTo('/tools')">
          <ion-icon slot="start" :icon="constructOutline">
          </ion-icon>
          <ion-label>{{ i18n.$t("tools.title") }}</ion-label>
        </ion-item>
        <ion-item button :detail="false" @click="openHrefAsModal(`${websiteBaseURL}agb`, i18n.$t('settings.agb'))">
          <ion-icon slot="start" :icon="readerOutline">
          </ion-icon>
          <ion-label>{{ i18n.$t("settings.agb") }}</ion-label>
        </ion-item>
        <ion-item button :detail="false" @click="openHrefAsModal(`${websiteBaseURL}privacy`, i18n.$t('settings.privacy'))">
          <ion-icon slot="start" :icon="fingerPrint">
          </ion-icon>
          <ion-label>{{ i18n.$t("settings.privacy") }}</ion-label>
        </ion-item>
        <ion-item button :detail="false" @click="openHrefAsModal(`${websiteBaseURL}impressum`, i18n.$t('settings.impressum'))">
          <ion-icon slot="start" :icon="information">
          </ion-icon>
          <ion-label>{{ i18n.$t("settings.impressum") }}</ion-label>
        </ion-item>
        <ion-item button :detail="false" @click="openHrefAsModal(`/licenses/ionic.txt`, i18n.$t('settings.license.ionic'))">
          <ion-icon slot="start" :icon="libraryOutline">
          </ion-icon>
          <ion-label>{{ i18n.$t("settings.license.ionic") }}</ion-label>
        </ion-item>
        <ion-item button :detail="false" @click="openHrefAsModal(`/licenses/geist.txt`, i18n.$t('settings.license.geist'))">
          <ion-icon slot="start" :icon="libraryOutline">
          </ion-icon>
          <ion-label>{{ i18n.$t("settings.license.geist") }}</ion-label>
        </ion-item>
        <ion-list-header>
          {{ i18n.$t("settings.communication") }}
        </ion-list-header>
        <ion-item v-if="isAuthenticated" button :detail="false" @click="navigateTo('/general-feedback')">
          <ion-icon slot="start" :icon="chatboxEllipsesOutline">
          </ion-icon>
          <ion-label>{{ i18n.$t("general-feedback.title") }}</ion-label>
        </ion-item>
        <ion-item :detail="false" :href="`${websiteBaseURL}mail/info`">
          <ion-icon slot="start" :icon="at">
          </ion-icon>
          <ion-label>{{ i18n.$t("settings.contact") }}</ion-label>
        </ion-item>
      </ion-list>
      <div id="app-version">
        <span @click="showLogs()"> {{ i18n.$t("settings.app_version") }}: {{ appVersion }} </span>
      </div>
    </ion-content>
  </ion-menu>
</template>

<script>
import { IonMenu, IonHeader, IonContent, IonToolbar, IonList, IonListHeader, IonItem, IonSelect, IonSelectOption, IonLabel, IonIcon, IonButtons, IonButton, menuController } from '@ionic/vue';
import { defineComponent, computed, watch, ref } from 'vue';
import { useStore } from 'vuex';

import { moon, moonOutline, logOutOutline, constructOutline, readerOutline, fingerPrint, information, at, libraryOutline, cloudUploadOutline, chatboxEllipsesOutline, settingsOutline, logInOutline, checkmarkOutline } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";
import { useRouter } from 'vue-router';

import _ from 'lodash';

import ProgressButton from '@/components/ProgressButton.vue';

import {openIFrameModal, default as modalComponent} from '@/components/IFrameModal.vue';
import {openUploadStatusModal, default as uploadModalComponent} from '@/components/UploadStatusModal.vue';
import {openLogModal, default as logModalComponent} from '@/components/LogModal.vue';

export default defineComponent({
  name: 'SettingsMenu',
  props: {
    isAuthenticated: Boolean,
    isUserPresent: Boolean,
    userIdentification: String
  },
  components: {
    IonMenu, IonHeader, IonContent, IonToolbar, IonList, IonListHeader, IonItem, IonSelect, IonSelectOption, IonLabel, IonIcon, IonButtons, IonButton, ProgressButton
  },
  setup(props){
    const store = useStore();

    const i18n = useI18n();

    const router = useRouter();

    const appVersion = process.env.VUE_APP_VERSION;

    const websiteBaseURL = process.env.VUE_APP_WEBSITE_URL;

    //Close the menu when authentication changes, because it results in navigation
    watch(
      () => props.isAuthenticated,
      () => {
        menuController.close('settings');
      }
    )

    //Only one notification can be completing at once!
    const currentlyCompletingNotification = ref(null);

    const TRANSLATED_NOTIFICATION_PROPERTIES = ['title', 'description', 'action_text'];

    const openNotifications = computed(() => {
      let notifications = store.getters['getNotifications'];

      notifications = _.map(notifications, (notification) => {
        //Translate only properties that should be translated, otherwise just take the original value
        return _.mapValues(notification, (notificationProperty, notificationPropertyKey) => {
          if (notification.translate === true && TRANSLATED_NOTIFICATION_PROPERTIES.includes(notificationPropertyKey)) {
            let translatedValue = i18n.$t(`notifications.${notificationProperty}`);
            if (translatedValue != null) return translatedValue;
          }
          //If no translation found, or the original value should be used, return it
          return notificationProperty;
        });
      })

      return notifications;
    });

    const completeNotification = async function(notificationIndex) {
      currentlyCompletingNotification.value = notificationIndex;
      await store.dispatch('completeNotification', notificationIndex);
      currentlyCompletingNotification.value = null;
    }

    const isCurrentlyCompleting = computed(() => function(notificationIndex) {
      return (currentlyCompletingNotification.value != null && notificationIndex == currentlyCompletingNotification.value);
    });

    const isOtherNotificationCurrentlyCompleting = computed(() => function(notificationIndex) {
      return (currentlyCompletingNotification.value != null && notificationIndex != currentlyCompletingNotification.value);
    });

    const darkModeActive = computed(() => store.getters.isDarkModeActive );
    const darkModePreference = computed({
      get: () => store.getters.isDarkModePreferred,
      set: newValue => {
        if (newValue === true || newValue === false){
          store.dispatch('setDarkModePreference', newValue);
        } else {
          store.dispatch('setDarkModePreference', undefined)
        }
      }
    })

    const openHrefAsModal = function(href, title){
      menuController.close('settings');
      openIFrameModal(modalComponent, title, href);
    }

    const navigateTo = function(href){
      menuController.close('settings');
      router.push(href)
    }

    const showUploadStatus = function(){
      menuController.close('settings');
      openUploadStatusModal(uploadModalComponent);
    }

    const showLogs = function(){
      menuController.close('settings');
      openLogModal(logModalComponent);
    }

    return { store, i18n, openNotifications, isCurrentlyCompleting, isOtherNotificationCurrentlyCompleting, completeNotification, darkModeActive, darkModePreference, appVersion, websiteBaseURL, moon, moonOutline, logOutOutline, constructOutline, readerOutline, fingerPrint, information, at, libraryOutline, cloudUploadOutline, chatboxEllipsesOutline, settingsOutline, logInOutline, router, checkmarkOutline, openHrefAsModal, navigateTo, showUploadStatus, showLogs }
  }
});
</script>

<style scoped>

.user-info {
  padding: 20px;
  padding-top: 20px!important;
  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary-contrast);
}

.welcome-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.welcome-label {
  font-size: 1.5em;
  font-weight: bold;
}

ion-title {
  padding: 0px;
}

.login-prompt {
  cursor: pointer;
}

.login-prompt ion-icon {
  vertical-align: bottom;
  font-size: 1.5em;
}

ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-content::part(scroll) {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

#app-version {
  width: 100%;
  font-size: 0.8em;
  flex: 4;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

#app-version > * {
  padding: 20px 10px;
  width: 100%;
  text-align: end;
}

.notification-header {
  color: var(--ion-color-secondary-shade);
}

.notification-text {
  margin-left: 10px;
  white-space: normal!important;
}

.notification-text h2 {
  font-weight: 500;
}

.notification-text h3 {
  margin-top: 5px;
}

.notification-button {
  --custom-brightness: 0.8;
  height: 32px;
  filter: grayscale(calc((1 - var(--custom-brightness, 1)) * 100%));
}

</style>

'use strict';

import { toastController } from '@ionic/vue';

import { blobToBase64Chunks } from '@/utils/media'

import { Plugins, FilesystemDirectory, FilesystemEncoding } from '@capacitor/core';

const { Filesystem } = Plugins;

import { saveAs } from 'file-saver';

export function createDocumentClickHandler(i18n, platforms, errorCallback) {
  return function(event) {
    let element = event.target;

    //go through the whole event chain to the top
    while (element != null && element != document) {
      if (element.tagName === "A" && element.hasAttribute("download")) {
        event.preventDefault();

        fetch(element.getAttribute("href"))
          .then((response) => response.blob())
          .then((blob) => download(i18n, element.getAttribute("download"), blob, blob.type, platforms))
          .catch((error) => {
            if (errorCallback && typeof errorCallback === 'function') errorCallback(error);
          });

        return;
      }

      element = element.parentNode;
    }
  }
}

export const ILLEGAL_FILE_OR_FOLDER_CHARACTERS = /[/\\?%*:|"<>]/g;

//Removes all illegal characters in filenames and folder names and optionally replaces them with a different character
export const sanitizeFileOrFolderName = function(pathString, replacementCharacter = '') {
  return pathString.replace(ILLEGAL_FILE_OR_FOLDER_CHARACTERS, replacementCharacter);
}

export const FILE_EXTENSION_MAPPING = {
  'quicktime': 'MOV'
}

var successToastOpen = false;
var errorToastOpen = false;

function showSuccessToast(i18n) {
  //Only show one instance
  if (successToastOpen === true) return;
  successToastOpen = true;
  toastController.create(
    {
      message: i18n.$t('files.download.success'),
      position: 'top',
      duration: 5000,
      color: 'success',
      buttons: [
        {
          text: i18n.$t('default_interaction.close'),
          role: 'cancel'
        }
      ]
    }
  ).then((toast) => {
    toast.present();
    toast.onWillDismiss().then(() => successToastOpen = false);
  });
}

function showErrorToast(i18n) {
  //Only show one instance
  if (errorToastOpen === true) return;
  errorToastOpen = true;
  toastController.create(
    {
      message: i18n.$t('files.download.error'),
      position: 'top',
      duration: 0,
      color: 'danger',
      buttons: [
        {
          text: i18n.$t('default_interaction.close'),
          role: 'cancel'
        }
      ]
    }
  ).then((toast) => {
    toast.present();
    toast.onWillDismiss().then(() => errorToastOpen = false);
  });
}

//Write to file by appending base64 chunks, otherwise we run into memory issues
export async function writeBlobToFile(blob, storageLocation, relativePath) {
  if (storageLocation == null) throw 'No storage location specified';
  if (!(blob instanceof Blob)) throw 'Trying to write non-blob object as a blob';
  // First create and truncate the file
  return Filesystem.writeFile({
    directory: storageLocation,
    path: relativePath,
    recursive: true,
    data: ""
  }).then(function ({uri}) {
    // Now write the file incrementally so we do not exceed our memory limits when
    // attempting to Base64 encode the entire Blob at once.
    return blobToBase64Chunks(blob, (base64Chunk) => {
      return Filesystem.appendFile({
        directory: storageLocation,
        path: relativePath,
        data: base64Chunk
      });
    }).then(function () {
      return uri;
    });
  });
}

export async function writeStringToFile(string, storageLocation, relativePath) {
  if (storageLocation == null) throw 'No storage location specified';
  // First create and truncate the file
  return Filesystem.writeFile({
    directory: storageLocation,
    path: relativePath,
    recursive: true,
    data: string,
    encoding: FilesystemEncoding.UTF8
  }).then(function ({uri}) {
      return uri;
  });
}

export async function getFileUri(storageLocation, relativePath) {
  if (storageLocation == null) throw 'No storage location specified';
  return Filesystem.getUri({
    directory: storageLocation,
    path: relativePath
  })
  .then(function ({uri}) {
    return uri;
  })
  .catch(function (error) {
    //If entry does not exist, this returns null
    if (error.message.includes('Entry does not exist')) return null;
    throw error; //Otherwise rethrow the error 
  });
}

export async function getFileInfo(storageLocation, relativePath) {
  if (storageLocation == null) throw 'No storage location specified';
  return Filesystem.stat({
    directory: storageLocation,
    path: relativePath
  })
  .catch(function () {
    //If entry does not exist, this returns null
    return null;
  });
}

export async function renameFile(storageLocation, oldRelativePath, newRelativePath) {
  if (storageLocation == null) throw 'No storage location specified';
  return Filesystem.rename({
    directory: storageLocation,
    from: oldRelativePath,
    to: newRelativePath
  });
}

export async function deleteFile(storageLocation, relativePath) {
  if (storageLocation == null) throw 'No storage location specified';
  return Filesystem.deleteFile({
    directory: storageLocation,
    path: relativePath
  });
}

export async function deleteDirectory(storageLocation, relativePath, recursive = false) {
  if (storageLocation == null) throw 'No storage location specified';
  return Filesystem.rmdir({
    directory: storageLocation,
    path: relativePath,
    recursive
  });
}

export function download(i18n, requestedFilename, blob, mimeType, platforms, openAfterSave = true) {
  return new Promise((resolve, reject) => {
    let filename = sanitizeFileOrFolderName(requestedFilename, '_');
    if (platforms.includes('hybrid') && window.cordova) {
      let storageLocation = '';
      let relativeTargetDirectory = '';

      if (platforms.includes('android')) {
        storageLocation = FilesystemDirectory.Documents; //TODO It’s not accesible on Android 10 unless the app enables legacy External Storage by adding android:requestLegacyExternalStorage="true" in the application tag in the AndroidManifest.xml. It’s not accesible on Android 11 or newer.
        relativeTargetDirectory = 'anirec/'; //Add a subpath to not store directly inside the documents folder
      }
      if (platforms.includes('ios')) {
        storageLocation = FilesystemDirectory.Documents;
      }

      writeBlobToFile(blob, storageLocation, `${relativeTargetDirectory}${filename}`) //TODO Maybe add optional relativePath to filename when on native to better sort the files
      .then((url) => {
        console.log(`Saved file at ${url}`);
        resolve(url);
        if (openAfterSave) {
          window.cordova.plugins.fileOpener2.open(url, mimeType, {
            error: function error(error) {
              console.log('Could not open file. Showing hint instead.', error);
              showSuccessToast(i18n); //Only shown when it can't be opened
              reject(error);
            }
          });
        } else {
          showSuccessToast(i18n);
        }
      })
      .catch((error) => {
          console.error('Error saving file', error);
          showErrorToast(i18n);
          reject(error);
        }
      );
    } else {
      saveAs(blob, filename);
      console.log(`Saved file as ${filename}`);
      showSuccessToast(i18n);
      resolve(filename);
    }
  });
}
<template>
  <ion-page>
    <ion-header>
      <MainToolbar :isSimple="simpleToolbar" :title="i18n.$t('tools.skeleton-point-select.title')" />
      <ion-toolbar>
        <ion-item class="definition-select-container" lines="none">
          <ion-label>
            {{ i18n.$t('tools.skeleton-point-select.definition') + ':' }}
          </ion-label>
          <ion-select 
            class="definition-select"
            :disabled="availableSkeletonDefinitions.length === 0" 
            :placeholder="availableSkeletonDefinitions.length > 0 ? i18n.$t('tools.skeleton-point-select.select-definition') : i18n.$t('tools.skeleton-point-select.no-definition-available')"
            :value="store.getters['skeleton/getSkeletonDefinitionIdentifier']"
            interface="action-sheet"
            @ionChange="confirmAndLoadDefinition($event);" >
            <ion-select-option v-for="identifier in availableSkeletonDefinitions" :key="identifier" :value="identifier">
              {{ identifier }}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-list ref="markerListRef">
          <ion-radio-group :value="selectedSkeletonMarkerKey" @ionChange="markerSelected">
            <ion-reorder-group @ionItemReorder="reorderSkeletonDefinition($event)" :disabled="false">
              <ion-item-sliding v-for="marker of skeletonDefinition" :key="marker.key">
                
                <ion-item class="marker-item" :style="'--color: '+marker.color">
                  <ion-reorder slot="start"></ion-reorder>
                  <ion-label class="text-with-icon">
                    {{ marker.key }} 
                    <ion-icon v-if="(marker.connectedTo && marker.connectedTo.length > 0)" :icon="analytics"></ion-icon>
                  </ion-label>
                  <ion-radio slot="end" :value="marker.key"></ion-radio>
                </ion-item>
                

                <ion-item-options side="end">
                  <ion-item-option @click="closeSlidingItems();openDeleteConfirmation(marker.key)" color="danger"><ion-icon slot="icon-only" :icon="trash"></ion-icon></ion-item-option>
                  <ion-item-option @click="closeSlidingItems();connectMarker(marker)" color="primary"><ion-icon slot="icon-only" :icon="analytics"></ion-icon></ion-item-option>
                  <ion-item-option @click="closeSlidingItems();editMarker(marker.key)" color="success"><ion-icon slot="icon-only" :icon="pencil"></ion-icon></ion-item-option>
                </ion-item-options>
              </ion-item-sliding>
            </ion-reorder-group>
          </ion-radio-group>
        <!-- Allows scrolling beyond the list to interact with items without the FAB blocking interaction -->
        <div id="over-scroll"></div>
      </ion-list>

      <ion-fab vertical="bottom" horizontal="start" slot="fixed">
        <ion-fab-button color="success">
          <ion-icon :icon="ellipsisVertical"></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="top">
          <ion-fab-button color="primary" @click="exportDefinition()">
            <ion-icon :icon="download"></ion-icon>
          </ion-fab-button>
          <ion-fab-button color="primary" @click="uploadElement.click()">
            <ion-icon :icon="folder"></ion-icon>
          </ion-fab-button>
          <ion-fab-button color="danger" @click="openClearConfirmation()">
            <ion-icon :icon="trash"></ion-icon>
          </ion-fab-button>
        </ion-fab-list>
      </ion-fab>
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button color="success" @click="addMarker()">
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
      </ion-fab>

      <a ref="downloadElement" class="invisible-input" target="_blank"></a>
      <input ref="uploadElement" @change="readDefinitionFile($event.target.files);$event.target.value = null;" type="file" accept=".json,application/json" class="invisible-input"/>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonToolbar, IonItem, IonLabel, IonSelect, IonSelectOption, IonContent, IonList, IonReorderGroup, IonReorder, IonItemSliding, IonItemOptions, IonItemOption, IonFab, IonFabList, IonFabButton, IonIcon, IonRadioGroup, IonRadio, alertController } from '@ionic/vue';

import MainToolbar from '@/components/MainToolbar.vue';

import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import { trash, pencil, ellipsisVertical, download, folder, add, analytics } from 'ionicons/icons';

import { localError } from "@/utils/error";

import { useI18n } from "@/utils/i18n";

export default  {
  name: 'SkeletonPointSelect',
  components: { IonHeader, IonToolbar, IonItem, IonLabel, IonSelect, IonSelectOption, IonContent, IonList, IonReorderGroup, IonReorder, IonItemSliding, IonItemOptions, IonItemOption, IonFab, IonFabList, IonFabButton, IonIcon, IonRadioGroup, IonRadio, IonPage, MainToolbar },
  props: {
    simpleToolbar: Boolean
  },
  setup() {
    const i18n = useI18n();

    const store = useStore();

    const markerListRef = ref(null);

    const downloadElement = ref(null);
    const uploadElement = ref(null);

    const closeSlidingItems = function(){
      markerListRef.value.$el.closeSlidingItems();
    }

    const availableSkeletonDefinitions = computed(() => 
      store.getters['skeleton/getAvailableSkeletonDefinitions']
    );

    const selectedSkeletonMarkerKey = computed({
      get: () => store.getters['skeleton/getSelectedSkeletonMarkerKey'],
      set: key => {
        store.dispatch('skeleton/setSelectedSkeletonMarkerKey', key);
      }
    });

    const markerSelected = function(event){
      selectedSkeletonMarkerKey.value = event.detail.value;
    }

    const skeletonDefinition = computed(() => store.getters['skeleton/getSkeletonDefinition'])

    const openDeleteConfirmation = async function(key){
      let messageString = i18n.$t('tools.skeleton-point-select.delete-confirmation.message.before') + 
      '<b>' + key + '</b>' + 
      i18n.$t('tools.skeleton-point-select.delete-confirmation.message.after') +
      ' <br/><br/> ' + 
      i18n.$t('tools.skeleton-point-select.delete-confirmation.message.newline');
      const alert = await alertController
        .create({
          cssClass: 'delete-confirmation-alert',
          header: i18n.$t('tools.skeleton-point-select.delete-confirmation.title'),
          message: messageString,
          buttons: [
            {
              text: i18n.$t('default_interaction.cancel'),
              role: 'cancel'
            },
            {
              text: i18n.$t('tools.skeleton-point-select.delete-confirmation.delete'),
              cssClass: 'delete-confirmation-okay',
              handler: () => {
                store.commit('skeleton/removeSkeletonMarker', key);
              },
            },
          ],
        });
      return alert.present();
    }
    
    const openClearConfirmation = async function(){
      let messageString = i18n.$t('tools.skeleton-point-select.clear-confirmation.message.before') + 
      '<br/><br/>' + 
      i18n.$t('tools.skeleton-point-select.clear-confirmation.message.newline');
        const alert = await alertController
        .create({
          cssClass: 'clear-confirmation-alert',
          header: i18n.$t('tools.skeleton-point-select.clear-confirmation.title'),
          message: messageString,
          buttons: [
            {
              text: i18n.$t('default_interaction.cancel'),
              role: 'cancel'
            },
            {
              text: i18n.$t('tools.skeleton-point-select.clear-confirmation.clear'),
              cssClass: 'clear-confirmation-okay',
              handler: () => {
                store.commit('skeleton/clearSkeletonDefinition');
              },
            },
          ],
        });
      return alert.present();
    }

    const reorderSkeletonDefinition = function(event){
      event.detail.complete(false); //Call with false, because we reorder ourselves
      store.commit('skeleton/moveSkeletonMarker', { oldIndex: event.detail.from, newIndex: event.detail.to });
    }

    const exportDefinition = function(){
      openNameDialog('definition', 'definition', store.getters['skeleton/getSkeletonDefinitionIdentifier'], (filename) => {
        let exportString = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(skeletonDefinition.value, null, 2));

        downloadElement.value.setAttribute('href', exportString);
        downloadElement.value.setAttribute('download', filename + '.json')
        downloadElement.value.click();
      });
      
    }

    const loadLocalDefinition = function(identifier, jsonArray){
      store.dispatch('skeleton/setLocalSkeletonDefinition', { identifier: identifier, skeletonDefinition: jsonArray });
    }

    const confirmAndLoadDefinition = async function(event){
      let newIdentifier = event.detail.value;
      //Only show the dialog, if the new value differs from the current one --> Prevents reopening when reseting the value inside the alert
      if (newIdentifier !== store.getters['skeleton/getSkeletonDefinitionIdentifier']){
        let messageString = i18n.$t('tools.skeleton-point-select.load-confirmation.message.before') + 
        '<b>' + newIdentifier + '</b>' + 
        i18n.$t('tools.skeleton-point-select.load-confirmation.message.after') + 
        ' <br/><br/> ' + 
        i18n.$t('tools.skeleton-point-select.load-confirmation.message.newline');
        const alert = await alertController
          .create({
            cssClass: 'load-confirmation-alert',
            header: i18n.$t('tools.skeleton-point-select.load-confirmation.title'),
            message: messageString,
            buttons: [
              {
                text: i18n.$t('default_interaction.cancel'),
                role: 'cancel',
                handler: () => {
                  event.target.value = store.getters['skeleton/getSkeletonDefinitionIdentifier']; //Reset value to the actual one in store
                },
              },
              {
                text: i18n.$t('tools.skeleton-point-select.load-confirmation.load'),
                cssClass: 'load-confirmation-okay',
                handler: () => {
                  store.dispatch('skeleton/loadSkeletonDefinition', newIdentifier).catch(() =>{
                    event.target.value = store.getters['skeleton/getSkeletonDefinitionIdentifier']; //Reset value to the actual one in store
                  });
                },
              },
            ],
          });
        return alert.present();
      }
    }

    const readDefinitionFile = function(files){
      if (files.length === 1){
        try {
          let jsonFile = files[0];
          //Remove file extension
          let definitionIdentifier = jsonFile.name.replace(/\.[^/.]+$/, '');

          let reader = new FileReader(); 
          reader.onload = function(){ 
            try {
              loadLocalDefinition(definitionIdentifier, JSON.parse(reader.result));
            } catch (error) {
              localError(i18n, i18n.$t('tools.skeleton-point-select.load_error'), error.message);
            }
          } 
                  
          reader.readAsText(jsonFile);
        } catch (error) {
          localError(i18n, i18n.$t('tools.skeleton-point-select.load_error'), error.message);
        }
      }
    }

    /* Generic alert dialog to input a name and call the given callback with the input, if it was confirmed */
    const openNameDialog = async function(descriptorId, okTextId, value = null, callback = null){
      const alert = await alertController
        .create({
          cssClass: 'name-input-alert',
          header: i18n.$t('tools.skeleton-point-select.name-dialog.title.' + descriptorId),
          inputs: [
            {
              name: 'nameInput',
              id: 'alert-name-input',
              placeholder: i18n.$t('tools.skeleton-point-select.name-dialog.placeholder.' + descriptorId),
              value: value
            },
          ],
          buttons: [
            {
              text: i18n.$t('default_interaction.cancel'),
              role: 'cancel'
            },
            {
              text: i18n.$t('tools.skeleton-point-select.name-dialog.ok.' + okTextId),
              cssClass: 'name-input-okay',
              handler: (alertData) => {
                if (callback !== null && alertData.nameInput.length > 0){
                  callback(alertData.nameInput);
                } else {
                  return false;
                }
              },
            },
          ],
        });
      return alert.present();
    }

    const editMarker = function(oldKey){
      openNameDialog('marker', 'marker_edit', oldKey, (newKey) => {
        store.commit('skeleton/changeSkeletonMarkerKey', { oldKey: oldKey, newKey: newKey });
      });
    }

    const addMarker = function(){
      openNameDialog('marker', 'marker_add', null, (newKey) => {
        store.commit('skeleton/addSkeletonMarker', { key: newKey });
      });
    }

    const connectMarker = async function(connectToMarker){
      if (connectToMarker.key) {
        let inputs = [];

        //Build list of inputs for all possible markers
        for (let marker of skeletonDefinition.value) {
          if (marker.key && marker.key !== connectToMarker.key) { //Exclude own key from list of possible values
            inputs.push({
                type: 'checkbox',
                label: marker.key,
                value: marker.key,
                checked: (connectToMarker.connectedTo && connectToMarker.connectedTo.includes(marker.key))
            });
          }
        }

        //Only show selection if there is a marker to select (i.e. more than one in definition)
        if (inputs.length > 0){ 
          const alert = await alertController
            .create({
              cssClass: 'connect-marker-alert',
              header: i18n.$t('tools.skeleton-point-select.connect-marker.title.before') + connectToMarker.key + i18n.$t('tools.skeleton-point-select.connect-marker.title.after'),
              inputs: inputs,
              buttons: [
                {
                  text: i18n.$t('default_interaction.cancel'),
                  role: 'cancel'
                },
                {
                  text: i18n.$t('tools.skeleton-point-select.connect-marker.connect'),
                  cssClass: 'connect-marker-okay',
                  handler: (alertData) => {
                    let connectMarkerList = [...alertData]; //Spread to copy array for modification
                    //Check if own point is part of connection - should never happen, because it is excluded in the chooser!
                    let ownMarkerIndex = connectMarkerList.indexOf(connectToMarker.key);
                    if (ownMarkerIndex >= 0){
                      connectMarkerList.splice(ownMarkerIndex, 1);
                    }
                    store.commit('skeleton/setMarkerConnection', {key: connectToMarker.key, markerList: connectMarkerList});
                  },
                },
              ],
            });
          return alert.present();
        }
      }
    }

    onMounted(() => {
      //Reset selection to the first one or nothing
      store.commit('skeleton/resetSelectedSkeletonMarker');
      //Get all available Definitions from the API
      store.dispatch('skeleton/fetchAvailableSkeletonDefinitions');
    });

    return {
      i18n,
      store,
      closeSlidingItems,
      markerListRef,
      markerSelected,
      availableSkeletonDefinitions,
      selectedSkeletonMarkerKey,
      skeletonDefinition,
      openDeleteConfirmation,
      openClearConfirmation,
      reorderSkeletonDefinition,
      exportDefinition,
      readDefinitionFile,
      confirmAndLoadDefinition,
      downloadElement,
      uploadElement,
      openNameDialog,
      editMarker,
      addMarker,
      connectMarker,
      trash,
      pencil,
      ellipsisVertical,
      download,
      folder,
      add,
      analytics
    };
  }
}
</script>

<style>
.delete-confirmation-okay, .load-confirmation-okay, .clear-confirmation-okay {
  color: var(--ion-color-danger)!important;
}

.name-input-okay, .connect-marker-okay {
  color: var(--ion-color-success-shade)!important;
}
</style>

<style scoped>
ion-content {
  --background: var(--ion-item-background);
}

.definition-select-container {
  display: flex;
  
}

.definition-select-container ion-label {
  opacity: 1!important;
  flex: 0 1 auto;
}

.definition-select {
  flex: 3 0 auto;
  --placeholder-opacity: 1;
  opacity: 1;
  max-width: 100%;
}

.definition-select::part(placeholder) {
  flex: 3 0 auto;
}

#over-scroll {
  width: 100%;
  height: 100px;
}

.invisible-input {
  visibility: hidden;
  display: none;
}

.text-with-icon {
  display: flex;
  align-items: center;
}

.text-with-icon > ion-icon {
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
}

.marker-item {
  --background: #1e1e1e;
  --border-color: #5a5a5a;
}
</style>

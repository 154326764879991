//On pressing the spacebar it calls the given handler
export function handleSpacebar(event, handler){
  if (event.key === ' ') {
    event.preventDefault();
    handler(event);
  }
}

export function handleEnter(event, handler, blur=true){
  //If enter key was pressed, blur
  if(event.key === 'Enter') {
    event.preventDefault();
    if (blur) event.target.blur()
    handler(event);
  }
}

export function blurOnEnter(event){
  //If enter key was pressed, blur
  if(event.key === 'Enter') {
    event.preventDefault();
    event.target.blur();
  }
}

//Workaround for custom placeholder that grabs the clicks and doesn't forward to native input! E.g. on Chrome Android
export function openNativeInput(event) {
  if (event.target.getInputElement) event.target.getInputElement().then((element) => {
    if (element != null) {
      if (element.showPicker) element.showPicker();
      else if (element.click) element.click();
    }
  });
}

//Workaround for not opening the input on clicking in it the second time! E.g. on Chrome Android
export function forwardClickToIonInput(event) {
  let inputs = event.target.getElementsByTagName('ion-input');
  if (inputs.length > 0 && inputs[0].click) setTimeout(() => inputs[0].click(), 0);
}

//Always blurs and prevents the default handler
//Usually used on elements with hidden elements with tabindex -1
export function cancelFocus(event, shouldScrollIntoView = true) {
  if (shouldScrollIntoView) event.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
  event.preventDefault();
  event.target.blur();
}

//Creates comparison function that also takes array for comparison (multiple values) into account for the given attribute value of the object
export function createIonSelectComparisonFunction(attributeValue) {
  return (object1, object2) => {
    //Handle array case
    if (Array.isArray(object2)) {
      if (!object1 || !object1[attributeValue]) {
        return false;
      }
      return object2.find(value => value && value[attributeValue] === object1[attributeValue]);
    }
    //Otherwise compare directly
    return object1 && object2 ? object1[attributeValue] === object2[attributeValue] : object1 === object2;
  };
}

/* Calculate the coordinates inside the element based on where on the client window the gloabl coordinates are */
export function getCoordsRelativeToElementFromClientCoords(element, clientX, clientY) {
  let elementBoundary = element.getBoundingClientRect();

  let { width, height } = getComputedStyle(element);
  //Scale on high dpi screens (CSS is smaller than actual value)
  let scalingRatios = { x: 1, y: 1 };
  try {
    if (width != null && element.width != null) {
      let parsedWidth = parseFloat(width.replaceAll(/[^0-9.]/g, ''));
      scalingRatios.x = element.width / parsedWidth;
    }

    if (height != null && element.height != null) {
      let parsedHeight = parseFloat(height.replaceAll(/[^0-9.]/g, ''));
      scalingRatios.y = element.height / parsedHeight;
    }
  } catch {
    //Do not apply if it could not be successfully determined
    scalingRatios = { x: 1, y: 1 };
  }

  let x;
  //Handle edge cases in ifs
  if (clientX < elementBoundary.left) { //Outside left boundary
    x = 0;
  } else if (clientX > elementBoundary.right) { //Outside right boundary
    x = elementBoundary.width;
  } else { //Inside boundary
    x = clientX - elementBoundary.left;
  }

  x *= scalingRatios.x;

  let y;
  //Handle edge cases in ifs
  if (clientY < elementBoundary.top) { //Outside top boundary
    y = 0;
  } else if (clientY > elementBoundary.bottom) { //Outside bottom boundary
    y = elementBoundary.height;
  } else { //Inside boundary
    y = clientY - elementBoundary.top;
  }

  y *= scalingRatios.y;

  return {
    x,
    y
  }
}

export function isCoordsInRectangle(x, y, {xMin, xMax, yMin, yMax}) {
  if (x >= xMin && x <= xMax && y >= yMin && y <= yMax) return true;
  return false;
}

export function holdButton(action, checkIfHeld, debounce = 500, minDebounce = 100, reduceDebounce = 0.9) {
  if (action != null && checkIfHeld != null && checkIfHeld()) {
    action();
    setTimeout(() => holdButton(action, checkIfHeld, Math.max((debounce * reduceDebounce), minDebounce), minDebounce, reduceDebounce), debounce);
  }
}

export function scrollToElementIfNotVisible(element, options) {
  if (element == null) return null;
  // Get the bounding rectangle of the element
  const rect = element.getBoundingClientRect();
  
  // Get the viewport dimensions
  const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
  const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

  // Check if the element is within the viewport
  const isVisible = (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= windowHeight &&
      rect.right <= windowWidth
  );

  // Scroll into view if the element is not visible
  if (!isVisible) {
      element.scrollIntoView(options);
  }
}
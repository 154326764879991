export const horseGenders = [
  { options: [ 
      {  value: 'female',          de: 'Stute',    en: 'Mare'      },
      {  value: 'male',            de: 'Hengst',   en: 'Stallion'  },
      {  value: 'male_castrated',  de: 'Wallach',  en: 'Gelding'   }
    ]
  }
];
export const horseCoatColors = [
  { 
    options: [ 
      {  value: 'Braun',             de: 'Braun',              en: 'Bay'                       },
      {  value: 'Dunkelbraun',       de: 'Dunkelbraun',        en: 'Dark bay'                  },
      {  value: 'Fuchs',             de: 'Fuchs',              en: 'Chestnut'                  },
      {  value: 'Dunkelfuchs',       de: 'Dunkelfuchs',        en: 'Dark chestnut'             },
      {  value: 'Rappe',             de: 'Rappe',              en: 'Black'                     },
      {  value: 'Schimmel',          de: 'Schimmel',           en: 'Grey'                      },
      {  value: 'Falbe',             de: 'Falbe',              en: 'Dun'                       },
      {  value: 'Isabell',           de: 'Isabell',            en: 'Palomino'                  },
      {  value: 'Braunschecke',      de: 'Braunschecke',       en: 'Bay pinto'                 },
      {  value: 'Fuchsschecke',      de: 'Fuchsschecke',       en: 'Chestnut pinto'            },
      {  value: 'Tigerschecke',      de: 'Tigerschecke',       en: 'Leopard'                   },
      {  value: 'Fuchstigerschecke', de: 'Fuchstigerschecke',  en: 'Chestnut leopard'          },
      {  value: 'Rapptigerschecke',  de: 'Rapptigerschecke',   en: 'Black leopard'             },
      {  value: 'Rappschecke',       de: 'Rappschecke',        en: 'Black pinto'               },
      {  value: 'Cremello',          de: 'Cremello',           en: 'Cremello'                  },
      {  value: 'Perlino',           de: 'Perlino',            en: 'Perlino'                   },
      {  value: 'Braunfalbe',        de: 'Braunfalbe',         en: 'Bay dun'                   },
      {  value: 'braunwindfarben',   de: 'braunwindfarben',    en: 'Bay roan'                  },
      {  value: 'erdfarben',         de: 'erdfarben',          en: 'Grullo'                    },
      {  value: 'Fuchsfalbe',        de: 'Fuchsfalbe',         en: 'Red dun'                   },
      {  value: 'Leuchtrappe',       de: 'Leuchtrappe',        en: 'Smoky black'               },
      {  value: 'Mausfalbe',         de: 'Mausfalbe',          en: 'Grullo'/*Or 'Mouse dun'*/  }, 
      {  value: 'rappwindfarben',    de: 'rappwindfarben',     en: 'Black roan'                },
      {  value: 'Silver Smoky',      de: 'Silver Smoky',       en: 'Silver smoky'              },
      {  value: 'Splash Schecke',    de: 'Splash Schecke',     en: 'Splash pinto'              },
      {  value: 'Tobiano Schecke',   de: 'Tobiano Schecke',    en: 'Tobiano pinto'             },
      {  value: 'Overo Schecke',     de: 'Overo Schecke',      en: 'Overo pinto'               }
    ]
  }
];

export const horseRaces = [
  {
    category: { de: 'Allgemein', en: 'Generic' },
    options: [
      {  value: 'Appaloosa',      de: 'Appaloosa',      en: 'Appaloosa'                                                                   },
      {  value: 'Araber',         de: 'Araber',         en: 'Arabian'                                                                     },
      {  value: 'Friese',         de: 'Friese',         en: 'Friesian'                                                                    },
      {  value: 'Isländer',       de: 'Isländer',       en: 'Icelandic Horse'                                                             },
      {  value: 'Kaltblut',       de: 'Kaltblut',       en: 'Cold-blooded Horse' /* Oder 'Draft Horse' */                                 }, 
      {  value: 'Pony',           de: 'Pony',           en: 'Pony'                                                                        },
      {  value: 'Portugiese',     de: 'Portugiese',     en: 'Portuguese Horse' /* Oder 'Lusitano' (typisch für portugiesische Pferde) */  }, 
      {  value: 'Quarter Horse',  de: 'Quarter Horse',  en: 'Quarter Horse'                                                               },
      {  value: 'Reitpony',       de: 'Reitpony',       en: 'Riding Pony'                                                                 },
      {  value: 'Spanier',        de: 'Spanier',        en: 'Spanish Horse' /* Oder 'Andalusian' */                                       }, 
      {  value: 'Traber',         de: 'Traber',         en: 'Trotter'                                                                     },
      {  value: 'Vollblut',       de: 'Vollblut',       en: 'Thoroughbred'                                                                },
      {  value: 'Warmblut',       de: 'Warmblut',       en: 'Warmblood'                                                                   },
    ]
  },
  {
    category: { de: 'Warmblut', en: 'Warmblood' },
    options: [
      {  value: 'American Warmblood',                         de: 'American Warmblood',                                 en: 'American Warmblood'                          },
      {  value: 'Australian Warmblood',                       de: 'Australian Warmblood',                               en: 'Australian Warmblood'                        },
      {  value: 'Bayerisches Warmblut',                       de: 'Bayerisches Warmblut',                               en: 'Bavarian Warmblood'                          },
      {  value: 'Belgisches Warmblut',                        de: 'Belgisches Warmblut',                                en: 'Belgian Warmblood'                           },
      {  value: 'Brandenburger Warmblut',                     de: 'Brandenburger Warmblut',                             en: 'Brandenburger'                               },
      {  value: 'Britisches Warmblut',                        de: 'Britisches Warmblut',                                en: 'British Warmblood'                           },
      {  value: 'Budjonny',                                   de: 'Budjonny',                                           en: 'Budyonny'                                    },
      {  value: 'Calabreser',                                 de: 'Calabreser',                                         en: 'Calabrese'                                   },
      {  value: 'Canadian Sport Horse',                       de: 'Canadian Sport Horse',                               en: 'Canadian Sport Horse'                        },
      {  value: 'Cheval de Selle Francais',                   de: 'Cheval de Selle Francais, Französisches Reitpferd',  en: 'Selle Français'                              },
      {  value: 'Dänisches Warmblut',                         de: 'Dänisches Warmblut',                                 en: 'Danish Warmblood'                            },
      {  value: 'Deutsches Reitpferd',                        de: 'Deutsches Reitpferd (keine eigene Pferderasse)',     en: 'German Riding Horse (not a distinct breed)'  },
      {  value: 'Deutsches Sportpferd',                       de: 'Deutsches Sportpferd',                               en: 'German Sport Horse'                          },
      {  value: 'Groninger',                                  de: 'Groninger',                                          en: 'Groninger'                                   },
      {  value: 'Hannoveraner',                               de: 'Hannoveraner',                                       en: 'Hanoverian'                                  },
      {  value: 'Hessisches Warmblut',                        de: 'Hessisches Warmblut',                                en: 'Hessian Warmblood'                           },
      {  value: 'Holsteiner',                                 de: 'Holsteiner',                                         en: 'Holsteiner'                                  },
      {  value: 'KWPN, Niederländisches Warmblut',            de: 'KWPN, Niederländisches Warmblut',                    en: 'KWPN, Dutch Warmblood'                       },
      {  value: 'Lettisches Warmblut',                        de: 'Lettisches Warmblut',                                en: 'Latvian Warmblood'                           },
      {  value: 'Mecklenburger Warmblut',                     de: 'Mecklenburger Warmblut',                             en: 'Mecklenburg Warmblood'                       },
      {  value: 'Nonius',                                     de: 'Nonius',                                             en: 'Nonius'                                      },
      {  value: 'Österreichisches Warmblut',                  de: 'Österreichisches Warmblut',                          en: 'Austrian Warmblood'                          },
      {  value: 'Oldenburger',                                de: 'Oldenburger',                                        en: 'Oldenburg'                                   },
      {  value: 'Rheinisches Warmblut',                       de: 'Rheinisches Warmblut',                               en: 'Rhenish Warmblood'                           },
      {  value: 'Rottaler Pferd',                             de: 'Rottaler Pferd',                                     en: 'Rottaler'                                    },
      {  value: 'Sachsen-Anhaltiner Warmblut',                de: 'Sachsen-Anhaltiner Warmblut',                        en: 'Saxony-Anhalt Warmblood'                     },
      {  value: 'Sächsisch-Thüringisches Schweres Warmblut',  de: 'Sächsisch-Thüringisches Schweres Warmblut',          en: 'Saxon-Thuringian Heavy Warmblood'            },
      {  value: 'Salerner',                                   de: 'Salerner',                                           en: 'Salernitano'                                 },
      {  value: 'Sardisches Pferd',                           de: 'Sardisches Pferd',                                   en: 'Sardinian Horse'                             },
      {  value: 'Schwedisches Warmblut',                      de: 'Schwedisches Warmblut',                              en: 'Swedish Warmblood'                           },
      {  value: 'Schweizer Warmblut',                         de: 'Schweizer Warmblut',                                 en: 'Swiss Warmblood'                             },
      {  value: 'Sella Italianao',                            de: 'Sella Italianao',                                    en: 'Italian Saddle Horse (Sella Italiano)'       },
      {  value: 'Slowakisches Warmblut',                      de: 'Slowakisches Warmblut',                              en: 'Slovak Warmblood'                            },
      {  value: 'Tschechisches Warmblut',                     de: 'Tschechisches Warmblut',                             en: 'Czech Warmblood'                             },
      {  value: 'Ukrainer',                                   de: 'Ukrainer',                                           en: 'Ukrainian Riding Horse'                      },
      {  value: 'Ungarisches Sportpferd',                     de: 'Ungarisches Sportpferd',                             en: 'Hungarian Sport Horse'                       },
      {  value: 'Waler',                                      de: 'Waler',                                              en: 'Waler'                                       },
      {  value: 'Westfale',                                   de: 'Westfale',                                           en: 'Westphalian'                                 },
      {  value: 'Wielkopolski',                               de: 'Wielkopolski',                                       en: 'Wielkopolski'                                },
      {  value: 'Württemberger Warmblut',                     de: 'Württemberger Warmblut',                             en: 'Württemberg Warmblood'                       },
      {  value: 'Zangersheider Pferd',                        de: 'Zangersheider Pferd',                                en: 'Zangersheide'                                },
      {  value: 'Zweibrücker Warmblut',                       de: 'Zweibrücker Warmblut',                               en: 'Zweibrücker'                                 },
    ]
  },
  {
    category: { de: 'Häufig vorkommend', en: 'Common' },
    options: [
      {  value: 'American Shetland Pony',                de: 'American Shetland Pony',                                                   en: 'American Shetland Pony'                                          },
      {  value: 'American Standardbred',                 de: 'American Standardbred',                                                    en: 'American Standardbred'                                           },
      {  value: 'Andalusier',                            de: 'Andalusier',                                                               en: 'Andalusian'                                                      },
      {  value: 'Anglo-Araber',                          de: 'Anglo-Araber',                                                             en: 'Anglo-Arabian'                                                   },
      {  value: 'Appaloosa Sport Horse',                 de: 'Appaloosa Sport Horse',                                                    en: 'Appaloosa Sport Horse'                                           },
      {  value: 'Ara-Appaloosa',                         de: 'Ara-Appaloosa',                                                            en: 'Ara-Appaloosa'                                                   },
      {  value: 'Araber-Berber',                         de: 'Araber-Berber',                                                            en: 'Arab-Barb'                                                       },
      {  value: 'Araberpinto',                           de: 'Araberpinto',                                                              en: 'Arabian Pinto'                                                   },
      {  value: 'Arabisches Halbblut',                   de: 'Arabisches Halbblut',                                                      en: 'Arabian Part-bred'                                               },
      {  value: 'Araber-Haflinger',                      de: 'Araber-Haflinger',                                                         en: 'Arabian Haflinger'                                               },
      {  value: 'Arabofriese',                           de: 'Arabofriese',                                                              en: 'Arabo-Friesian'                                                  },
      {  value: 'Australian Riding Pony',                de: 'Australian Riding Pony',                                                   en: 'Australian Riding Pony'                                          },
      {  value: 'Australian Stock Horse',                de: 'Australian Stock Horse',                                                   en: 'Australian Stock Horse'                                          },
      {  value: 'Australian Pony',                       de: 'Australian Pony',                                                          en: 'Australian Pony'                                                 },
      {  value: 'Belgisches Reitpony',                   de: 'Belgisches Reitpony',                                                      en: 'Belgian Riding Pony'                                             },
      {  value: 'Belgisches Kaltblut, Brabanter',        de: 'Belgisches Kaltblut, Brabanter',                                           en: 'Belgian Draft, Brabant'                                          },
      {  value: 'Berber',                                de: 'Berber',                                                                   en: 'Barb'                                                            },
      {  value: 'Bretone',                               de: 'Bretone',                                                                  en: 'Breton'                                                          },
      {  value: 'Camargue-Pferd',                        de: 'Camargue-Pferd',                                                           en: 'Camargue Horse'                                                  },
      {  value: 'Clydesdale',                            de: 'Clydesdale',                                                               en: 'Clydesdale'                                                      },
      {  value: 'Colorado Ranger',                       de: 'Colorado Ranger',                                                          en: 'Colorado Ranger'                                                 },
      {  value: 'Comtois',                               de: 'Comtois',                                                                  en: 'Comtois'                                                         },
      {  value: 'Connemara-Pony',                        de: 'Connemara-Pony',                                                           en: 'Connemara Pony'                                                  },
      {  value: 'Criollo',                               de: 'Criollo',                                                                  en: 'Criollo'                                                         },
      {  value: 'Cruzado Portugues',                     de: 'Cruzado Portugues',                                                        en: 'Portuguese Crossbred'                                            },
      {  value: 'Dales-Pony',                            de: 'Dales-Pony',                                                               en: 'Dales Pony'                                                      },
      {  value: 'Dartmoor-Pony',                         de: 'Dartmoor-Pony',                                                            en: 'Dartmoor Pony'                                                   },
      {  value: 'Deutsches Classic-Pony',                de: 'Deutsches Classic-Pony',                                                   en: 'German Classic Pony'                                             },
      {  value: 'Deutsches Part-Bred Shetland Pony',     de: 'Deutsches Part-Bred Shetland Pony',                                        en: 'German Part-bred Shetland Pony'                                  },
      {  value: 'Deutsches Reitpony',                    de: 'Deutsches Reitpony',                                                       en: 'German Riding Pony'                                              },
      {  value: 'Dülmener Wildpferd',                    de: 'Dülmener Wildpferd',                                                       en: 'Dülmen Horse'                                                    },
      {  value: 'Edelbluthaflinger',                     de: 'Edelbluthaflinger',                                                        en: 'Noble Haflinger'                                                 },
      {  value: 'Englisches Vollblut',                   de: 'Englisches Vollblut',                                                      en: 'Thoroughbred'                                                    },
      {  value: 'Europäisches Appaloosa Pony',           de: 'Europäisches Appaloosa Pony',                                              en: 'European Appaloosa Pony'                                         },
      {  value: 'Exmoor-Pony',                           de: 'Exmoor-Pony',                                                              en: 'Exmoor Pony'                                                     },
      {  value: 'Färöerpony',                            de: 'Färöerpony',                                                               en: 'Faroe Pony'                                                      },
      {  value: 'Falabella',                             de: 'Falabella',                                                                en: 'Falabella'                                                       },
      {  value: 'Fell-Pony',                             de: 'Fell-Pony',                                                                en: 'Fell Pony'                                                       },
      {  value: 'Finnpferd',                             de: 'Finnpferd',                                                                en: 'Finnhorse'                                                       },
      {  value: 'Freiberger',                            de: 'Freiberger',                                                               en: 'Franches-Montagnes'                                              },
      {  value: 'Gotland-Pony',                          de: 'Gotland-Pony',                                                             en: 'Gotland Pony'                                                    },
      {  value: 'Hack',                                  de: 'Hack',                                                                     en: 'Hack'                                                            },
      {  value: 'Haflinger',                             de: 'Haflinger',                                                                en: 'Haflinger'                                                       },
      {  value: 'Highland-Pony',                         de: 'Highland-Pony',                                                            en: 'Highland Pony'                                                   },
      {  value: 'Hispano-Araber',                        de: 'Hispano-Araber',                                                           en: 'Hispano-Arabian'                                                 },
      {  value: 'Hokkaido-Pony',                         de: 'Hokkaido-Pony',                                                            en: 'Hokkaido Pony'                                                   },
      {  value: 'Huzule',                                de: 'Huzule',                                                                   en: 'Hucul'                                                           },
      {  value: 'Indisches Halbblut',                    de: 'Indisches Halbblut',                                                       en: 'Indian Half-bred'                                                },
      {  value: 'Irisches Sportpferd',                   de: 'Irisches Sportpferd',                                                      en: 'Irish Sport Horse'                                               },
      {  value: 'Irish Draught Horse',                   de: 'Irish Draught Horse',                                                      en: 'Irish Draught Horse'                                             },
      {  value: 'Islandpferd',                           de: 'Islandpferd',                                                              en: 'Icelandic Horse'                                                 },
      {  value: 'Jütländer',                             de: 'Jütländer',                                                                en: 'Jutland Horse'                                                   },
      {  value: 'Kabardiner',                            de: 'Kabardiner',                                                               en: 'Kabardian'                                                       },
      {  value: 'Kartäuser-Pferd',                       de: 'Kartäuser-Pferd',                                                          en: 'Carthusian Horse'                                                },
      {  value: 'Kentucky Mountain Saddle Horse',        de: 'Kentucky Mountain Saddle Horse',                                           en: 'Kentucky Mountain Saddle Horse'                                  },
      {  value: 'Kladruber',                             de: 'Kladruber',                                                                en: 'Kladruber'                                                       },
      {  value: 'Kleines Deutsches Reitpferd',           de: 'Kleines Deutsches Reitpferd',                                              en: 'Small German Riding Horse'                                       },
      {  value: 'Knabstrupper',                          de: 'Knabstrupper',                                                             en: 'Knabstrupper'                                                    },
      {  value: 'Konik',                                 de: 'Konik',                                                                    en: 'Konik'                                                           },
      {  value: 'Kroatisches Halbblut',                  de: 'Kroatisches Halbblut',                                                     en: 'Croatian Half-bred'                                              },
      {  value: 'Kustanai',                              de: 'Kustanai',                                                                 en: 'Kustanair'                                                       },
      {  value: 'Leutstettener',                         de: 'Leutstettener',                                                            en: 'Leutstetten Horse'                                               },
      {  value: 'Lewitzer',                              de: 'Lewitzer',                                                                 en: 'Lewitzer'                                                        },
      {  value: 'Lipizzaner',                            de: 'Lipizzaner',                                                               en: 'Lipizzaner'                                                      },
      {  value: 'Litauisches Kaltblut',                  de: 'Litauisches Kaltblut',                                                     en: 'Lithuanian Heavy Draught'                                        },
      {  value: 'Lokaier',                               de: 'Lokaier',                                                                  en: 'Lokai'                                                           },
      {  value: 'Lusitano',                              de: 'Lusitano',                                                                 en: 'Lusitano'                                                        },
      {  value: 'Lusoaraber',                            de: 'Lusoaraber',                                                               en: 'Luso-Arabian'                                                    },
      {  value: 'Malakan',                               de: 'Malakan',                                                                  en: 'Malakan'                                                         },
      {  value: 'Mangalarda Marchador',                  de: 'Mangalarda Marchador',                                                     en: 'Mangalarga Marchador'                                            },
      {  value: 'Mangalarda Paulista',                   de: 'Mangalarda Paulista',                                                      en: 'Mangalarga Paulista'                                             },
      {  value: 'Maremmano',                             de: 'Maremmano',                                                                en: 'Maremmano'                                                       },
      {  value: 'Marwari',                               de: 'Marwari',                                                                  en: 'Marwari'                                                         },
      {  value: 'Medimurec',                             de: 'Medimurec',                                                                en: 'Medimurje Horse'                                                 },
      {  value: 'Menorquiner',                           de: 'Menorquiner',                                                              en: 'Menorquín'                                                       },
      {  value: 'Mérens',                                de: 'Mérens',                                                                   en: 'Mérens'                                                          },
      {  value: 'Minishetlandpony',                      de: 'Minishetlandpony',                                                         en: 'Miniature Shetland Pony'                                         },
      {  value: 'Misaki-Pony',                           de: 'Misaki-Pony',                                                              en: 'Misaki Pony'                                                     },
      {  value: 'Missouri Foxtrotter',                   de: 'Missouri Foxtrotter',                                                      en: 'Missouri Fox Trotter'                                            },
      {  value: 'Miyako-Pony',                           de: 'Miyako-Pony',                                                              en: 'Miyako Pony'                                                     },
      {  value: 'Mongolisches Pferd',                    de: 'Mongolisches Pferd',                                                       en: 'Mongolian Horse'                                                 },
      {  value: 'Morab',                                 de: 'Morab',                                                                    en: 'Morab'                                                           },
      {  value: 'Morgan',                                de: 'Morgan',                                                                   en: 'Morgan'                                                          },
      {  value: 'Murgese',                               de: 'Murgese',                                                                  en: 'Murgese'                                                         },
      {  value: 'Mustang',                               de: 'Mustang',                                                                  en: 'Mustang'                                                         },
      {  value: 'Nederlands Mini Paarden',               de: 'Nederlands Mini Paarden',                                                  en: 'Netherlands Miniature Horse'                                     },
      {  value: 'New-Forest-Pony',                       de: 'New-Forest-Pony',                                                          en: 'New Forest Pony'                                                 },
      {  value: 'Niederländisches Kaltblut',             de: 'Niederländisches Kaltblut',                                                en: 'Dutch Draft'                                                     },
      {  value: 'Nooitgedacht Pony',                     de: 'Nooitgedacht Pony',                                                        en: 'Nooitgedacht Pony'                                               },
      {  value: 'Nordlands-Lyngspferd',                  de: 'Nordlands-Lyngspferd',                                                     en: 'Nordlandshest/Lyngshest'                                         },
      {  value: 'Nordschwedisches Kaltblut',             de: 'Nordschwedisches Kaltblut',                                                en: 'North Swedish Horse'                                             },
      {  value: 'Norfolk Trotter',                       de: 'Norfolk Trotter',                                                          en: 'Norfolk Trotter'                                                 },
      {  value: 'Noriker',                               de: 'Noriker',                                                                  en: 'Noriker'                                                         },
      {  value: 'Norwegisches Fjordpferd',               de: 'Norwegisches Fjordpferd',                                                  en: 'Norwegian Fjord Horse'                                           },
      {  value: 'NRPS-Pony, Niederländisches Reitpony',  de: 'NRPS-Pony, Niederländisches Reitpony',                                     en: 'NRPS Pony, Dutch Riding Pony'                                    },
      {  value: 'Orlow-Rostopchiner',                    de: 'Orlow-Rostopchiner',                                                       en: 'Orlov-Rostopchin'                                                },
      {  value: 'Orlow-Traber',                          de: 'Orlow-Traber',                                                             en: 'Orlov Trotter'                                                   },
      {  value: 'Ostfriese/Alt-Oldenburger',             de: 'Ostfriese/Alt-Oldenburger',                                                en: 'East Frisian/Alt-Oldenburg'                                      },
      {  value: 'Paint Horse',                           de: 'Paint Horse',                                                              en: 'Paint Horse'                                                     },
      {  value: 'Panjepferd',                            de: 'Panjepferd (eher Bezeichnung für Landwirtschaftspferd als eigene Rasse)',  en: 'Draft Horse (term for work horse rather than a distinct breed)'  },
      {  value: 'Pantaneiro',                            de: 'Pantaneiro',                                                               en: 'Pantaneiro'                                                      },
      {  value: 'Paso Fino',                             de: 'Paso Fino',                                                                en: 'Paso Fino'                                                       },
      {  value: 'Paso Iberoamericano',                   de: 'Paso Iberoamericano',                                                      en: 'Ibero-American Paso'                                             },
      {  value: 'Paso Peruano',                          de: 'Paso Peruano',                                                             en: 'Peruvian Paso'                                                   },
      {  value: 'Percheron',                             de: 'Percheron',                                                                en: 'Percheron'                                                       },
      {  value: 'Pfalz-Ardenner',                        de: 'Pfalz-Ardenner',                                                           en: 'Palatine Ardennes'                                               },
      {  value: 'Polo Argentino',                        de: 'Polo Argentino',                                                           en: 'Argentine Polo Pony'                                             },
      {  value: 'Poney francais de selle',               de: 'Poney francais de selle',                                                  en: 'French Saddle Pony'                                              },
      {  value: 'Pony of the Americas',                  de: 'Pony of the Americas',                                                     en: 'Pony of the Americas'                                            },
      {  value: 'Portugiesisches Sportpferd',            de: 'Portugiesisches Sportpferd',                                               en: 'Portuguese Sport Horse'                                          },
      {  value: 'Pura Raza Espanola',                    de: 'Pura Raza Espanola',                                                       en: 'Pure Spanish Horse'                                              },
      {  value: 'Quarab',                                de: 'Quarab',                                                                   en: 'Quarab'                                                          },
      {  value: 'Quarter Pony',                          de: 'Quarter Pony',                                                             en: 'Quarter Pony'                                                    },
      {  value: 'Quarterhorse',                          de: 'Quarterhorse',                                                             en: 'Quarter Horse'                                                   },
      {  value: 'Quba',                                  de: 'Quba',                                                                     en: 'Quba'                                                            },
      {  value: 'Racking Horse',                         de: 'Racking Horse',                                                            en: 'Racking Horse'                                                   },
      {  value: 'Rheinisch-Deutsches Kaltblut',          de: 'Rheinisch-Deutsches Kaltblut',                                             en: 'Rhenish-German Coldblood'                                        },
      {  value: 'Rocky Mountain Horse',                  de: 'Rocky Mountain Horse',                                                     en: 'Rocky Mountain Horse'                                            },
      {  value: 'Russischer Traber',                     de: 'Russischer Traber',                                                        en: 'Russian Trotter'                                                 },
      {  value: 'Russisches Kaltblut',                   de: 'Russisches Kaltblut',                                                      en: 'Russian Heavy Draught'                                           },
      {  value: 'Sächsisch-Thüringisches Kaltblut',      de: 'Sächsisch-Thüringisches Kaltblut',                                         en: 'Saxon-Thuringian Coldblood'                                      },
      {  value: 'Schwarzwälder Kaltblut',                de: 'Schwarzwälder Kaltblut',                                                   en: 'Black Forest Horse'                                              },
      {  value: 'Schweden-Ardenner',                     de: 'Schweden-Ardenner',                                                        en: 'Swedish Ardennes'                                                },
      {  value: 'Shagya-Araber',                         de: 'Shagya-Araber',                                                            en: 'Shagya Arabian'                                                  },
      {  value: 'Shetlandpony',                          de: 'Shetlandpony',                                                             en: 'Shetland Pony'                                                   },
      {  value: 'Shire Horse',                           de: 'Shire Horse',                                                              en: 'Shire Horse'                                                     },
      {  value: 'Skandinavischer Kaltbluttraber',        de: 'Skandinavischer Kaltbluttraber',                                           en: 'Scandinavian Coldblood Trotter'                                  },
      {  value: 'Sowjetisches Kaltblut',                 de: 'Sowjetisches Kaltblut',                                                    en: 'Soviet Heavy Draught'                                            },
      {  value: 'Spotted Saddle Horse',                  de: 'Spotted Saddle Horse',                                                     en: 'Spotted Saddle Horse'                                            },
      {  value: 'Suffolk Punch',                         de: 'Suffolk Punch',                                                            en: 'Suffolk Punch'                                                   },
      {  value: 'Sumba-Pony',                            de: 'Sumba-Pony',                                                               en: 'Sumba Pony'                                                      },
      {  value: 'Taishu-Pony',                           de: 'Taishu-Pony',                                                              en: 'Taishu Pony'                                                     },
      {  value: 'Tennessee Walking Horse',               de: 'Tennessee Walking Horse',                                                  en: 'Tennessee Walking Horse'                                         },
      {  value: 'Tersker',                               de: 'Tersker',                                                                  en: 'Tersk Horse'                                                     },
      {  value: 'Tiger Horse',                           de: 'Tiger Horse',                                                              en: 'Tiger Horse'                                                     },
      {  value: 'Timor-Pony',                            de: 'Timor-Pony',                                                               en: 'Timor Pony'                                                      },
      {  value: 'Tinker',                                de: 'Tinker',                                                                   en: 'Gypsy Vanner'                                                    },
      {  value: 'Tokara-Pony',                           de: 'Tokara-Pony',                                                              en: 'Tokara Pony'                                                     },
      {  value: 'Tori',                                  de: 'Tori',                                                                     en: 'Tori'                                                            },
      {  value: 'Trait du Nord',                         de: 'Trait du Nord',                                                            en: 'Trait du Nord'                                                   },
      {  value: 'Trakehner',                             de: 'Trakehner',                                                                en: 'Trakehner'                                                       },
      {  value: 'Trotteur Francais',                     de: 'Trotteur Francais',                                                        en: 'French Trotter'                                                  },
      {  value: 'Tuigpaard',                             de: 'Tuigpaard',                                                                en: 'Dutch Harness Horse'                                             },
      {  value: 'Urfreiberger',                          de: 'Urfreiberger',                                                             en: 'Original Freiberger'                                             },
      {  value: 'Uzunyayla',                             de: 'Uzunyayla',                                                                en: 'Uzunyayla'                                                       },
      {  value: 'Vlaampferd',                            de: 'Vlaampferd',                                                               en: 'Vlaamperd'                                                       },
      {  value: 'Vollblutaraber',                        de: 'Vollblutaraber',                                                           en: 'Purebred Arabian'                                                },
      {  value: 'Weißrussisches Kaltblut',               de: 'Weißrussisches Kaltblut',                                                  en: 'Belarus Harness Horse'                                           },
      {  value: 'Welara',                                de: 'Welara',                                                                   en: 'Welara'                                                          },
      {  value: 'Walkaloosa',                            de: 'Walkaloosa',                                                               en: 'Walkaloosa'                                                      },
      {  value: 'Welsh-Pony',                            de: 'Welsh-Pony',                                                               en: 'Welsh Pony'                                                      },
      {  value: 'Welsh-Pony A, Welsh-Mountain',          de: 'Welsh-Pony A, Welsh-Mountain',                                             en: 'Welsh Pony A, Welsh Mountain Pony'                               },
      {  value: 'Welsh-Pony B',                          de: 'Welsh-Pony B',                                                             en: 'Welsh Pony B'                                                    },
      {  value: 'Welsh-Pony C',                          de: 'Welsh-Pony C',                                                             en: 'Welsh Pony C'                                                    },
      {  value: 'Welsh-Pony D, Welsh Cob',               de: 'Welsh-Pony D, Welsh Cob',                                                  en: 'Welsh Pony D, Welsh Cob'                                         },
      {  value: 'Wjatka',                                de: 'Wjatka',                                                                   en: 'Vyatka'                                                          },
      {  value: 'Wladimirer Kaltblut',                   de: 'Wladimirer Kaltblut',                                                      en: 'Vladimir Heavy Draught'                                          },
      {  value: 'Woronesch-Pferd',                       de: 'Woronesch-Pferd',                                                          en: 'Voronezh Horse'                                                  },
      {  value: 'Yonaguni-Pony',                         de: 'Yonaguni-Pony',                                                            en: 'Yonaguni Pony'                                                   },
      {  value: 'Žemaitukas',                            de: 'Žemaitukas',                                                               en: 'Žemaitukas'                                                      },
    ]
  },
  {
    category: { de: 'Selten', en: 'Rare' },
    options: [
      {  value: 'Abessinier',                    de: 'Abessinier',                    en: 'Abyssinian'                    },
      {  value: 'Achal-Tekkiner',                de: 'Achal-Tekkiner',                en: 'Akhal-Teke'                    },
      {  value: 'Aegidienberger',                de: 'Aegidienberger',                en: 'Aegidienberger'                },
      {  value: 'Ainos-Wildpferd',               de: 'Ainos-Wildpferd',               en: 'Ainos Wild Horse'              },
      {  value: 'Albaner',                       de: 'Albaner',                       en: 'Albanian'                      },
      {  value: 'Altai',                         de: 'Altai',                         en: 'Altai'                         },
      {  value: 'Altér Real',                    de: 'Altér Real',                    en: 'Altér Real'                    },
      {  value: 'American Bashkir Curly Horse',  de: 'American Bashkir Curly Horse',  en: 'American Bashkir Curly Horse'  },
      {  value: 'American Cream Draft Horse',    de: 'American Cream Draft Horse',    en: 'American Cream Draft Horse'    },
      {  value: 'American Miniature Horse',      de: 'American Miniature Horse',      en: 'American Miniature Horse'      },
      {  value: 'American Saddlebred',           de: 'American Saddlebred',           en: 'American Saddlebred'           },
      {  value: 'Anadolu',                       de: 'Anadolu',                       en: 'Anadolu'                       },
      {  value: 'Anglo-Normanne',                de: 'Anglo-Normanne',                en: 'Anglo-Norman'                  },
      {  value: 'Ardenner',                      de: 'Ardenner',                      en: 'Ardennes'                      },
      {  value: 'Arenberg-Nordkirchener Pony',   de: 'Arenberg-Nordkirchener Pony',   en: 'Arenberg-Nordkirchen Pony'     },
      {  value: 'Arravani',                      de: 'Arravani',                      en: 'Arravani'                      },
      {  value: 'Assateague-Pony',               de: 'Assateague-Pony',               en: 'Assateague Pony'               },
      {  value: 'Asturcon-Pony',                 de: 'Asturcon-Pony',                 en: 'Asturcon Pony'                 },
      {  value: 'Auxois',                        de: 'Auxois',                        en: 'Auxois'                        },
      {  value: 'Azteke',                        de: 'Azteke',                        en: 'Azteca'                        },
      {  value: 'Baleare',                       de: 'Baleare',                       en: 'Balearic'                      },
      {  value: 'Ban-ei Keiba',                  de: 'Ban-ei Keiba',                  en: 'Ban-ei'                        },
      {  value: 'Banker Horse',                  de: 'Banker Horse',                  en: 'Banker Horse'                  },
      {  value: 'Bardigiano',                    de: 'Bardigiano',                    en: 'Bardigiano'                    },
      {  value: 'Baschkire',                     de: 'Baschkire',                     en: 'Bashkir'                       },
      {  value: 'Basuto Pony',                   de: 'Basuto Pony',                   en: 'Basuto Pony'                   },
      {  value: 'Boerpferd',                     de: 'Boerpferd',                     en: 'Boerperd'                      },
      {  value: 'Bosniak',                       de: 'Bosniak',                       en: 'Bosnian'                       },
      {  value: 'Boulonnais',                    de: 'Boulonnais',                    en: 'Boulonnais'                    },
      {  value: 'British Spotted Pony',          de: 'British Spotted Pony',          en: 'British Spotted Pony'          },
      {  value: 'Brumby',                        de: 'Brumby',                        en: 'Brumby'                        },
      {  value: 'Burguete',                      de: 'Burguete',                      en: 'Burguete'                      },
      {  value: 'Camarillo White Horse',         de: 'Camarillo White Horse',         en: 'Camarillo White Horse'         },
      {  value: 'Campeiro',                      de: 'Campeiro',                      en: 'Campeiro'                      },
      {  value: 'Campolina',                     de: 'Campolina',                     en: 'Campolina'                     },
      {  value: 'Canadian Horse',                de: 'Canadian Horse',                en: 'Canadian Horse'                },
      {  value: 'Canik',                         de: 'Canik',                         en: 'Canik'                         },
      {  value: 'Cheju Pony',                    de: 'Cheju Pony',                    en: 'Jeju Pony'                     },
      {  value: 'Chilenischer Corralero',        de: 'Chilenischer Corralero',        en: 'Chilean Corralero'             },
      {  value: 'Chinesischer Guoxia',           de: 'Chinesischer Guoxia',           en: 'Chinese Guoxia'                },
      {  value: 'Cirit',                         de: 'Cirit',                         en: 'Cirit'                         },
      {  value: 'Cleveland Bay',                 de: 'Cleveland Bay',                 en: 'Cleveland Bay'                 },
      {  value: 'Cukurova',                      de: 'Cukurova',                      en: 'Cukurova'                      },
      {  value: 'Danubier',                      de: 'Danubier',                      en: 'Danube'                        },
      {  value: 'Delibos',                       de: 'Delibos',                       en: 'Deliboz'                       },
      {  value: 'Dillenburger Ramsnase',         de: 'Dillenburger Ramsnase',         en: 'Dillenburg Ramnose'            },
      {  value: 'Dölepferd',                     de: 'Dölepferd',                     en: 'Dole Horse'                    },
      {  value: 'Dombes-Halbblut',               de: 'Dombes-Halbblut',               en: 'Dombes Half-blood'             },
      {  value: 'Don-Pferd',                     de: 'Don-Pferd',                     en: 'Don Horse'                     },
      {  value: 'Dongolavi',                     de: 'Dongolavi',                     en: 'Dongola'                       },
      {  value: 'Eriskay Pony',                  de: 'Eriskay Pony',                  en: 'Eriskay Pony'                  },
      {  value: 'Estnischer Klepper',            de: 'Estnischer Klepper',            en: 'Estonian Native'               },
      {  value: 'Frederiksborger',               de: 'Frederiksborger',               en: 'Frederiksborg'                 },
      {  value: 'Furioso-North Star',            de: 'Furioso-North Star',            en: 'Furioso-North Star'            },
      {  value: 'Galiceno-Pony',                 de: 'Galiceno-Pony',                 en: 'Galiceno Pony'                 },
      {  value: 'Galloway',                      de: 'Galloway',                      en: 'Galloway'                      },
      {  value: 'Garrano',                       de: 'Garrano',                       en: 'Garrano'                       },
      {  value: 'Gelderländer',                  de: 'Gelderländer',                  en: 'Gelderland'                    },
      {  value: 'Genete',                        de: 'Genete',                        en: 'Genete'                        },
      {  value: 'Giara-Pferd',                   de: 'Giara-Pferd',                   en: 'Giara Horse'                   },
      {  value: 'Gidran',                        de: 'Gidran',                        en: 'Gidran'                        },
      {  value: 'Hackney',                       de: 'Hackney',                       en: 'Hackney'                       },
      {  value: 'Heckpferd',                     de: 'Heckpferd',                     en: 'Heck Horse'                    },
      {  value: 'Hinis',                         de: 'Hinis',                         en: 'Hinis'                         },
      {  value: 'Italienisches Kaltblut',        de: 'Italienisches Kaltblut',        en: 'Italian Heavy Draught'         },
      {  value: 'Jakuten-Pferd',                 de: 'Jakuten-Pferd',                 en: 'Yakutian Horse'                },
      {  value: 'Java-Pony',                     de: 'Java-Pony',                     en: 'Java Pony'                     },
      {  value: 'Karabagh',                      de: 'Karabagh',                      en: 'Karabakh Horse'                },
      {  value: 'Karabaier',                     de: 'Karabaier',                     en: 'Karabair'                      },
      {  value: 'Karacabeyer',                   de: 'Karacabeyer',                   en: 'Karacabey'                     },
      {  value: 'Karachai',                      de: 'Karachai',                      en: 'Karachai'                      },
      {  value: 'Kaspisches Kleinpferd',         de: 'Kaspisches Kleinpferd',         en: 'Caspian'                       },
      {  value: 'Kathiawari',                    de: 'Kathiawari',                    en: 'Kathiawari'                    },
      {  value: 'Kazakh',                        de: 'Kazakh',                        en: 'Kazakh'                        },
      {  value: 'Kerry Bog Pony',                de: 'Kerry Bog Pony',                en: 'Kerry Bog Pony'                },
      {  value: 'Kinsky-Pferd',                  de: 'Kinsky-Pferd',                  en: 'Kinsky Horse'                  },
      {  value: 'Kisbéri',                       de: 'Kisbéri',                       en: 'Kisber Felver'                 },
      {  value: 'Kiso-Pony',                     de: 'Kiso-Pony',                     en: 'Kiso Pony'                     },
      {  value: 'Korsisches Pony',               de: 'Korsisches Pony',               en: 'Corsican Pony'                 },
      {  value: 'Kushum',                        de: 'Kushum',                        en: 'Kushum'                        },
      {  value: 'Landais-Pony',                  de: 'Landais-Pony',                  en: 'Landais Pony'                  },
      {  value: 'Leinhardener',                  de: 'Leinhardener',                  en: 'Leinhardener'                  },
      {  value: 'Lehmkuhlener Pony',             de: 'Lehmkuhlener Pony',             en: 'Lehmkuhlener Pony'             },
      {  value: 'Leonharder',                    de: 'Leonharder',                    en: 'Leonharder'                    },
      {  value: 'Lettisches Kaltblut',           de: 'Lettisches Kaltblut',           en: 'Latvian Heavy Draught'         },
      {  value: 'Liebenthaler Pferd',            de: 'Liebenthaler Pferd',            en: 'Liebenthaler Horse'            },
      {  value: 'Losino',                        de: 'Losino',                        en: 'Losino'                        },
      {  value: 'Messara-Pferd',                 de: 'Messara-Pferd',                 en: 'Messara Horse'                 },
      {  value: 'Namibisches Wildpferd',         de: 'Namibisches Wildpferd',         en: 'Namib Desert Horse'            },
      {  value: 'Nangchen',                      de: 'Nangchen',                      en: 'Nangchen Horse'                },
      {  value: 'Neapolitaner',                  de: 'Neapolitaner',                  en: 'Neapolitan Horse'              },
      {  value: 'Peneia-Pony',                   de: 'Peneia-Pony',                   en: 'Peneia Pony'                   },
      {  value: 'Pindos-Pony',                   de: 'Pindos-Pony',                   en: 'Pindos Pony'                   },
      {  value: 'Poitevin',                      de: 'Poitevin',                      en: 'Poitevin'                      },
      {  value: 'Pottok-Pony',                   de: 'Pottok-Pony',                   en: 'Pottok Pony'                   },
      {  value: 'Posavina-Pferd',                de: 'Posavina-Pferd',                en: 'Posavina Horse'                },
      {  value: 'Przewalski-Pferd',              de: 'Przewalski-Pferd',              en: 'Przewalski Horse'              },
      {  value: 'Rahvan',                        de: 'Rahvan',                        en: 'Rahvan'                        },
      {  value: 'Retuerta-Pferd',                de: 'Retuerta-Pferd',                en: 'Retuerta Horse'                },
      {  value: 'Riwoche',                       de: 'Riwoche',                       en: 'Riwoche'                       },
      {  value: 'Sable Island Pony',             de: 'Sable Island Pony',             en: 'Sable Island Pony'             },
      {  value: 'Sanhe',                         de: 'Sanhe',                         en: 'Sanhe'                         },
      {  value: 'Schleswiger Kaltblut',          de: 'Schleswiger Kaltblut',          en: 'Schleswig Coldblood'           },
      {  value: 'Senner Pferd',                  de: 'Senner Pferd',                  en: 'Senner Horse'                  },
      {  value: 'Skyros-Pony',                   de: 'Skyros-Pony',                   en: 'Skyros Pony'                   },
      {  value: 'Slovensky sportovy pony',       de: 'Slovensky sportovy pony',       en: 'Slovak Sport Pony'             },
      {  value: 'Somali Pony',                   de: 'Somali Pony',                   en: 'Somali Pony'                   },
      {  value: 'Sorraia',                       de: 'Sorraia',                       en: 'Sorraia'                       },
      {  value: 'Sudan Country Bred',            de: 'Sudan Country Bred',            en: 'Sudanese Country-bred'         },
      {  value: 'Thessalisches Pferd',           de: 'Thessalisches Pferd',           en: 'Thessalian Horse'              },
      {  value: 'Tschernomor-Pferd',             de: 'Tschernomor-Pferd',             en: 'Chernomor Horse'               },
      {  value: 'Turkmene',                      de: 'Turkmene',                      en: 'Turkmen Horse'                 },
    ]
  }
];
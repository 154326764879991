import ZXingWasmFile from "zxing-wasm/dist/reader/zxing_reader.wasm";

import {
  setZXingModuleOverrides,
  BarcodeDetector,
} from "barcode-detector";

setZXingModuleOverrides({
  locateFile: (path, prefix) => {
    if (path.endsWith(".wasm")) {
      return ZXingWasmFile;
    }
    return prefix + path;
  },
});

export function useBarcodeScanner() {
  const forwardUploadedFilesToAutomaticCapture = true;

  const barcodeDetector = new BarcodeDetector();

  const supportsCanvas = true;

  const scalingOptions = { minTargetSize: 480 } ; //Size in pixels that the minimum axis of the image should be
  
  const processImage = async function(imageBlobOrCanvas) {
    if (imageBlobOrCanvas == null) throw 'Image is invalid';
    return barcodeDetector.detect(imageBlobOrCanvas) //TODO Add validation for barcodes?
      .then((codes) => {
        if (codes != null && codes.length) {
          return {
            data: {
              codes
            },
            returnData: true
          };
        }
        return null;
      }).catch((error) => {
        console.error('Could not decode barcode', error);
        throw error;
      });
  }

  return { processImage, forwardUploadedFilesToAutomaticCapture, scalingOptions, supportsCanvas }
}
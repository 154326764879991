import _ from 'lodash';

import { api, createStorage } from '@/utils/api';
import { escapeRegExp } from '@/utils/algorithms';

import { Drivers } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
//memoryDriver is Fallback option to save to RAM in case the memory in other drivers is not enough. Gets deleted on app restart or when browser needs memory!
import memoryDriver from 'localforage-memoryStorageDriver';

const encyclopediaIndexStorage = createStorage({
  name: '__encyclopedia_index',
  driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, /* Drivers.LocalStorage, */ memoryDriver._driver] //Skip localStorage, as index grows too big!
},
[CordovaSQLiteDriver, memoryDriver]
);

const ENCYCLOPEDIA_INDEX_FETCH_ROUTE = '/encyclopedias';

const HIDDEN_INDEX_TYPES = ['implementation_technical'];


const clearEncyclopediaIndex = function() {
  return encyclopediaIndexStorage.clear()
  .then(() => api.clearRouteMetadata(ENCYCLOPEDIA_INDEX_FETCH_ROUTE)); //Clear route metadata for encyclopedia, to fetch them freshly again!
}

const MODULE_VERSION = 1; //UPDATE VERSION WITH EVERY BREAKING CHANGE OR ATTRIBUTE REMOVAL

const getDefaultState = () => {
  return {
    version: undefined,
    mostRecentEncyclopediaUpdate: null
  }
};

export default {
  namespaced: true,

  state: getDefaultState,
  getters: {
    getEncyclopediaIndexAsyncFunction (state) {
      //If no entries, give back empty index. Referencing the most recent update also enables recalculating the index only when there are new entries in the storage.
      if (state.mostRecentEncyclopediaUpdate == null) return (async function(){return ({})});

      //Return async function
      return (async function() {
        let encyclopediaEntries = {};

        await encyclopediaIndexStorage.forEach((entry, entryId) => {
          //Skip hidden or invalid types
          let type = entry['type'];
          if (type == null || HIDDEN_INDEX_TYPES.includes(type)) return;
          
          //Get category - null means general information not assigned to a category
          let category = entry['main_category'] || null;
          
          //If the type doesn' exist yet, create it
          if (!(type in encyclopediaEntries)) encyclopediaEntries[type] = {};

          //If the category doesn't exist yet, create it
          if (!(category in encyclopediaEntries[type])) encyclopediaEntries[type][category] = {};
          encyclopediaEntries[type][category][entryId] = _.omit(entry, ['type', 'main_category']);
        });

        return encyclopediaEntries;
      });
    },
    getEncyclopediaEntryByIdentifier (state) {
      if (state.mostRecentEncyclopediaUpdate == null) return (async function(){return ({})});

      //Return async function
      return async function(identifier) {
        let entry = await encyclopediaIndexStorage.get(identifier);
        //Set back the identifier
        if (entry != null) {
          entry.identifier = identifier;
        }
        return entry;
      };
    },
    getDescriptorToIdMappingAsyncFunction (state, getters) {
      let indexPromise = getters['getEncyclopediaIndexAsyncFunction']();

      //Return async function
      return (async function() {
        //For each language and the general descriptors the desriptors are used as keys (lowercase) and the IDs as values
        let mapping = {};
        for (let typeEntries of Object.values((await indexPromise))) {
          for (let categoryEntries of Object.values(typeEntries)) {
            for (let [entryId, entry] of Object.entries(categoryEntries)) {
              //Add the general descriptor under 'null' locale
              if (entry['descriptor'] != null) _.setWith(mapping, [null, entry['descriptor'].toLowerCase()], entryId, Object);

              //If valid translations exist, use them for the mapped index as well
              if (entry['translations'] != null) {
                for (let [locale, translation] of Object.entries(entry['translations'])) {
                  if (locale != 'null' && translation['descriptor'] != null) {
                    //Set at the correct locale and descriptor
                    _.setWith(mapping, [locale, translation['descriptor'].toLowerCase()], entryId, Object);
                  }
                }
              }
            }
          }
        }

        return mapping;
      });
    },
    getEncyclopediaEntryByDescriptor (state, getters) {
      let mappingPromise = getters['getDescriptorToIdMappingAsyncFunction']();
      let identifierGetter = getters['getEncyclopediaEntryByIdentifier'];
      //Check the provided locale and then the null locale (general, if requested) for matches (in lowercase!)
      return async function(locale, descriptor, includeGeneralDescriptors = true) {
        let mapping = await mappingPromise;
        let mappedDescriptor = descriptor.toLowerCase();
        if (mapping != null) {
          //Check the provided locale, if it is found there
          if (locale in mapping && mappedDescriptor in mapping[locale]) {
            let entry = await identifierGetter(mapping[locale][mappedDescriptor]);
            if (entry != null) return entry;
          }
          //Otherwise check the general mapping for no specific locale, if enabled
          if (includeGeneralDescriptors === true && 'null' in mapping && mappedDescriptor in mapping['null']) {
            let entry = await identifierGetter(mapping['null'][mappedDescriptor]);
            if (entry != null) return entry;
          }
        }
      }
    },
    getDescriptorRegexAsyncFunction (state, getters) { //TODO Use this to add links in free form text by splitting at the regex and linking with the ID
      let mappingPromise = getters['getDescriptorToIdMappingAsyncFunction']();
      //Create a regex that finds all the descriptors of the given locale
      return async function(locale) {
        let mapping = await mappingPromise;
        if (mapping != null && locale in mapping) {
          //First take all descriptors and escape all special characters
          let escapedDescriptors = _.map(Object.keys(mapping[locale]), (descriptor) => escapeRegExp(descriptor));
          //Then join them with or to make a regulare expression (case insensitive and global)
          return new RegExp(_.join(escapedDescriptors, '|'), 'gi');
        }
      }
    }
  },
  mutations: {
    updateMostRecentUpdate(state, newMostRecentUpdate) {
      //Update metadata information about the index in different storage, if changed or not set yet
      if (newMostRecentUpdate != null && (state.mostRecentEncyclopediaUpdate == null || ((new Date(newMostRecentUpdate)) > (new Date(state.mostRecentEncyclopediaUpdate)))) ) {
        state.mostRecentEncyclopediaUpdate = newMostRecentUpdate;
      }
    },
    checkVersion (state) { //Checks the version of this module and sets the state to default, if version is different
      //Used to reset state on breaking changes and to remove attributes that are no longer needed
      if (state.version !== MODULE_VERSION) {
        let defaultState = getDefaultState();
        Object.assign(state, defaultState); //Use setters to not impact any reactive functionality
        //Search for unused attributes and set them undefined, to remove them from the persisted state
        for (let key of Object.keys(state)){
          if (!(key in defaultState)){
            state[key] = undefined;
          }
        }
        clearEncyclopediaIndex();
        state.version = MODULE_VERSION;
      }
    },
    clearPersonalData (state) {
      let defaultState = {};
      Object.assign(defaultState, getDefaultState());
      state.mostRecentEncyclopediaUpdate = defaultState.mostRecentEncyclopediaUpdate;
      clearEncyclopediaIndex();
    }
  },
  actions: {
    //Get newest encyclopedia entries
    fetchEncyclopediaIndex (context) {
      return new Promise((resolve, reject) => {
        api
        .get(ENCYCLOPEDIA_INDEX_FETCH_ROUTE, { fetchNewerForAnyPath: ['updated_at'] }) //TODO It saves the newest fetch, but what if they are not saved sucessfully in the next step? Can we reset the timestamps on error?
        .then(async response => {
          let mostRecentUpdate;
          for (let encyclopediaEntry of response.data){
            if (encyclopediaEntry['updated_at'] != null && (mostRecentUpdate == null || ((new Date(encyclopediaEntry['updated_at'])) > (new Date(mostRecentUpdate)))) ) {
              mostRecentUpdate = encyclopediaEntry['updated_at'];
            }

            if (encyclopediaEntry['identifier'] != null) await encyclopediaIndexStorage.set(encyclopediaEntry['identifier'], _.omit(encyclopediaEntry, 'identifier'));
          }

          //If everything was successful, update the new timestamp to trigger recalculation of the cached index
          context.commit('updateMostRecentUpdate', mostRecentUpdate);

          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    fetchEncyclopediaEntry (context, {identifier, locale}) {
      return new Promise((resolve, reject) => { 
        context.getters['getEncyclopediaEntryByIdentifier'](identifier)
        .then((encyclopediaIndexEntry) => {
          return api
            .get(`/encyclopedias/${identifier}`, {
              params: { 
                locale,
                'updated_at': (encyclopediaIndexEntry != null) ? encyclopediaIndexEntry['updated_at'] : undefined //Apply a version indicator to all changing API responses that are cached
              }
            });
        })
        .then(async response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    createNewEncyclopediaEntry (context, { entry, files }) {
      let user = context.rootGetters['auth/getUser'];
      let uploadPomise = api.post('/encyclopedias', entry, {
        files,
        user: _.get(user, 'id')
      });
      return uploadPomise.then((uploadStatus) => uploadStatus.completionPromise).then(response => response.data);
    },
  }
}
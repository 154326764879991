<template>
  <canvas ref="canvasInstance" class="editing-canvas" :style="`z-index: ${zIndex};`" :class="(show ? undefined : 'hidden-canvas')"></canvas>
  <img id="camera-template-image" v-if="show && cameraTemplateImageProperties != null" :src="cameraTemplateImageProperties.src" ref="cameraTemplateImageInstance" @load="updateCanvas()"/>
</template>

<script>
import { createGesture } from '@ionic/vue';

import _ from 'lodash';

import { getCameraTemplate } from '@/utils/media';

import { getCoordsRelativeToElementFromClientCoords } from  '@/utils/interaction';

import { onMounted, ref, watch, computed } from 'vue';

export default {
  name: 'EditingCanvas',
  props: {
    targetRect: Object,
    aspectRatio: {
      type: Number,
      default: 1 //Use square cutout as default
    },
    preserveAspectRatio: Boolean,
    showCameraTemplateInside: String,
    show: {
      type: Boolean,
      default: false
    },
    zIndex: {
      type: Number,
      default: 1001
    }
  },
  emits: [
    'updateSelection',
    'interactionChange',
    'updateAspectRatios'
  ],
  setup(props, context) {
    const canvasInstance = ref(null);

    const canvasInteracting = ref(false);

    const canvasAspectRatio = ref(1);

    const cameraTemplateImageInstance = ref(null);

    const cameraTemplateImageProperties = computed(() => getCameraTemplate(props.showCameraTemplateInside));

    const ELEMENT_RADIUS = 20;
    const ELEMENT_INTERACTION_RADIUS = ELEMENT_RADIUS * 2; //Radius in which interaction with a interaction element is still detected

    //The initial rectangles aspect ratio is made to fit a square (1:1) container and scaled later
    const INITIAL_RECTANGLE_MAX_SIZE = 0.9;

    //The bigger component fills the given space, the other one is fit to the aspect ratio
    const initialAspectRatioRectangle = {
      x: (props.aspectRatio >= 1) ? INITIAL_RECTANGLE_MAX_SIZE : (INITIAL_RECTANGLE_MAX_SIZE * props.aspectRatio),
      y: (props.aspectRatio <= 1) ? INITIAL_RECTANGLE_MAX_SIZE : (INITIAL_RECTANGLE_MAX_SIZE / props.aspectRatio),
    };

    //The corners of the selection square are given as percentages of the image
    const initialInteractionElements = ref({
      left_upper: {
        x: 0.5 - (initialAspectRatioRectangle.x / 2),
        y: 0.5 - (initialAspectRatioRectangle.y / 2)
      },
      right_lower: {
        x: 0.5 + (initialAspectRatioRectangle.x / 2),
        y: 0.5 + (initialAspectRatioRectangle.y / 2)
      }
    });
    
    const interactionElements = ref(_.cloneDeep(initialInteractionElements.value));

    //Function to rescale the rectangle to fit the aspect ratio of the target
    const changeRectangleAspectRatio = function(targetAspectRatio) {
      //Both interaction elements must be there
      if (!('left_upper' in interactionElements.value && 'right_lower' in interactionElements.value)) {
        return;
      }
      
      // The container is vertical rectangle
      if (targetAspectRatio < 1) {
        // The rectangle inside is vertical too
        if (props.aspectRatio < 1) {
          // The container is taller or same height than the rectangle inside, set width to the maximum and scale height accordingly
          if (targetAspectRatio <= props.aspectRatio) {
            interactionElements.value.left_upper.x = 0.5 - ((INITIAL_RECTANGLE_MAX_SIZE) / 2);
            interactionElements.value.right_lower.x = 0.5 + ((INITIAL_RECTANGLE_MAX_SIZE) / 2);
            
            interactionElements.value.left_upper.y = 0.5 - (((INITIAL_RECTANGLE_MAX_SIZE / props.aspectRatio) * targetAspectRatio) / 2);
            interactionElements.value.right_lower.y = 0.5 + (((INITIAL_RECTANGLE_MAX_SIZE / props.aspectRatio) * targetAspectRatio) / 2);
          } 
          // Otherwise set height to maximum and scale width accordingly
          else {
            interactionElements.value.left_upper.x = 0.5 - (((INITIAL_RECTANGLE_MAX_SIZE * props.aspectRatio) / targetAspectRatio) / 2);
            interactionElements.value.right_lower.x = 0.5 + (((INITIAL_RECTANGLE_MAX_SIZE * props.aspectRatio) / targetAspectRatio) / 2);
            
            interactionElements.value.left_upper.y = 0.5 - ((INITIAL_RECTANGLE_MAX_SIZE) / 2);
            interactionElements.value.right_lower.y = 0.5 + ((INITIAL_RECTANGLE_MAX_SIZE) / 2);
          }
        }
        // The rectangle inside is horizontal or square
        else if (props.aspectRatio >= 1) {
          interactionElements.value.left_upper.x = 0.5 - ((INITIAL_RECTANGLE_MAX_SIZE) / 2);
          interactionElements.value.right_lower.x = 0.5 + ((INITIAL_RECTANGLE_MAX_SIZE) / 2);

          // The height needs to be scaled down to be the same relatively to the higher container
          interactionElements.value.left_upper.y = 0.5 - (((INITIAL_RECTANGLE_MAX_SIZE / props.aspectRatio) * targetAspectRatio)/2);
          interactionElements.value.right_lower.y = 0.5 + (((INITIAL_RECTANGLE_MAX_SIZE / props.aspectRatio) * targetAspectRatio)/2);
        }
      }
      // The container is horizontal rectangle
      else if (targetAspectRatio > 1) {
        // The rectangle inside is horizontal too
        if (props.aspectRatio > 1) {
          // The container is wider or same width than the rectangle inside, set height to the maximum and scale width accordingly
          if (targetAspectRatio >= props.aspectRatio) {
            interactionElements.value.left_upper.x = 0.5 - (((INITIAL_RECTANGLE_MAX_SIZE * props.aspectRatio) / targetAspectRatio) / 2);
            interactionElements.value.right_lower.x = 0.5 + (((INITIAL_RECTANGLE_MAX_SIZE * props.aspectRatio) / targetAspectRatio) / 2);
            
            interactionElements.value.left_upper.y = 0.5 - ((INITIAL_RECTANGLE_MAX_SIZE) / 2);
            interactionElements.value.right_lower.y = 0.5 + ((INITIAL_RECTANGLE_MAX_SIZE) / 2);
          } 
          // Otherwise set width to maximum and scale height accordingly
          else {
            interactionElements.value.left_upper.x = 0.5 - ((INITIAL_RECTANGLE_MAX_SIZE) / 2);
            interactionElements.value.right_lower.x = 0.5 + ((INITIAL_RECTANGLE_MAX_SIZE) / 2);
            
            interactionElements.value.left_upper.y = 0.5 - (((INITIAL_RECTANGLE_MAX_SIZE / props.aspectRatio) * targetAspectRatio) / 2);
            interactionElements.value.right_lower.y = 0.5 + (((INITIAL_RECTANGLE_MAX_SIZE / props.aspectRatio) * targetAspectRatio) / 2);
          }
        }
        // The rectangle inside is vertical or square
        else if (props.aspectRatio <= 1) {
          // The width needs to be scaled down to be the same relatively to the wider container
          interactionElements.value.left_upper.x = 0.5 - (((INITIAL_RECTANGLE_MAX_SIZE * props.aspectRatio) / targetAspectRatio)/2);
          interactionElements.value.right_lower.x = 0.5 + (((INITIAL_RECTANGLE_MAX_SIZE * props.aspectRatio) / targetAspectRatio)/2);

          interactionElements.value.left_upper.y = 0.5 - ((INITIAL_RECTANGLE_MAX_SIZE) / 2);
          interactionElements.value.right_lower.y = 0.5 + ((INITIAL_RECTANGLE_MAX_SIZE) / 2);
        }
      } 
      //The container is square, apply the initial aspect ratio again
      else {
        interactionElements.value.left_upper.x = 0.5 - ((initialAspectRatioRectangle.x) / 2);
        interactionElements.value.right_lower.x = 0.5 + ((initialAspectRatioRectangle.x) / 2);

        interactionElements.value.left_upper.y = 0.5 - ((initialAspectRatioRectangle.y) / 2);
        interactionElements.value.right_lower.y = 0.5 + ((initialAspectRatioRectangle.y) / 2);
      }

      //Set this aspect ratio as the initial one, before the user made any changes
      initialInteractionElements.value = _.cloneDeep(interactionElements.value);
    }

    const clearCanvas = function(){
      canvasInstance.value.getContext('2d').clearRect(0, 0, canvasInstance.value.width, canvasInstance.value.height);
    }

    context.emit('updateAspectRatios', {
      canvas: canvasAspectRatio.value,
      element: props.aspectRatio
    });

    watch([canvasAspectRatio, () => props.aspectRatio], ([canvas, element]) => {
      context.emit('updateAspectRatios', {
        canvas,
        element
      });
    });

    watch(() => interactionElements.value, () => updateCanvas(), { deep: true });

    watch(() => canvasInteracting.value, (interacting) => context.emit('interactionChange', interacting));
    
    const updateCanvas = function() {
      clearCanvas();
      let ctx = canvasInstance.value.getContext('2d');

      //Both interaction elements must be there
      if (!('left_upper' in interactionElements.value && 'right_lower' in interactionElements.value)) {
        return;
      }

      let leftUpperCanvas = {
        x: interactionElements.value.left_upper.x * canvasInstance.value.width,
        y: interactionElements.value.left_upper.y * canvasInstance.value.height
      }

      let rightLowerCanvas = {
        x: interactionElements.value.right_lower.x * canvasInstance.value.width,
        y: interactionElements.value.right_lower.y * canvasInstance.value.height
      }

      ctx.fillStyle = ctx.strokeStyle = 'rgba(0, 0, 0, 0.7)';

      //Draw the whole image to be a blur
      ctx.fillRect(0, 0, canvasInstance.value.width, canvasInstance.value.height);
      //Cut the visible part out of the image
      ctx.clearRect(leftUpperCanvas.x, leftUpperCanvas.y, (rightLowerCanvas.x - leftUpperCanvas.x), (rightLowerCanvas.y - leftUpperCanvas.y));

      //Draw a template image for correct alignment in the middle of it while preserving its aspect ratio
      if (cameraTemplateImageInstance.value != null) {
        let imageWidth = cameraTemplateImageInstance.value.width;
        let imageHeight = cameraTemplateImageInstance.value.height;
        let canvasAreaWidth = (rightLowerCanvas.x - leftUpperCanvas.x);
        let canvasAreaHeight = (rightLowerCanvas.y - leftUpperCanvas.y);
        let targetWidth = canvasAreaWidth;
        let targetHeight = canvasAreaHeight;

        let targetRatio = (targetWidth / targetHeight);
        let imageRatio = (imageWidth / imageHeight);

        if (imageRatio > targetRatio) {
          targetHeight = ((targetWidth * imageHeight) / imageWidth);
        } else if (imageRatio < targetRatio) {
          targetWidth = ((targetHeight * imageWidth) / imageHeight);
        }

        let offsetX = (canvasAreaWidth - targetWidth) / 2;
        let offsetY = (canvasAreaHeight - targetHeight) / 2;
        
        ctx.drawImage(cameraTemplateImageInstance.value, (leftUpperCanvas.x + offsetX), (leftUpperCanvas.y + offsetY), targetWidth, targetHeight);
      }


      //Draw lines between the interaction elements
      ctx.lineWidth = 3;
      ctx.fillStyle = ctx.strokeStyle = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-secondary');

      let lines = [
        //Top line
        {from: {x: leftUpperCanvas.x, y: leftUpperCanvas.y}, to: {x: rightLowerCanvas.x, y: leftUpperCanvas.y}},
        //Right line
        {from: {x: rightLowerCanvas.x, y: leftUpperCanvas.y}, to: {x: rightLowerCanvas.x, y: rightLowerCanvas.y}},
        //Bottom line
        {from: {x: rightLowerCanvas.x, y: rightLowerCanvas.y}, to: {x: leftUpperCanvas.x, y: rightLowerCanvas.y}},
        //Left line
        {from: {x: leftUpperCanvas.x, y: rightLowerCanvas.y}, to: {x: leftUpperCanvas.x, y: leftUpperCanvas.y}},
      ]

      //Draw lines first seperately to not overlap the dots
      for (let line of lines) {
        ctx.beginPath();
        ctx.moveTo(line.from.x, line.from.y);
        ctx.lineTo(line.to.x, line.to.y);
        ctx.closePath();
        ctx.stroke();
      }


      for (let element of Object.values(interactionElements.value)) {
        let canvasX = element.x * canvasInstance.value.width;
        let canvasY = element.y * canvasInstance.value.height;

        ctx.beginPath();
        ctx.arc(canvasX, canvasY, ELEMENT_RADIUS, 0, Math.PI * 2, true);
        ctx.fill();
      }
    }

    /* Gets the closest element to the given location, but only in the ELEMENT_INTERACTION_RADIUS */
    const getClosestInteractionElementAt = function(canvasX, canvasY, excludedKey = null){
      let closestElementKey;
      let closestElementDistance;

      //Get the nearest element to where the interaction ended
      for (let [key, element] of Object.entries(interactionElements.value)) {
        if (key !== excludedKey){
          let xDistance = Math.abs((element.x * canvasInstance.value.width) - canvasX);
          let yDistance = Math.abs((element.y * canvasInstance.value.height) - canvasY);
          let smallestDistance = Math.min(xDistance, yDistance);

          //Test for every element if the distance (in image coordinates) is smaller than ELEMENT_INTERACTION_RADIUS
          if (xDistance < ELEMENT_INTERACTION_RADIUS && yDistance < ELEMENT_INTERACTION_RADIUS) {
            //Set the currently closest if none is set or it is closer to the interaction
            if (!closestElementDistance || smallestDistance < closestElementDistance){
              closestElementKey = key;
              closestElementDistance = smallestDistance;
            }
          }
        }
      }

      return closestElementKey;
    }

    //Left upper has to be always above and to the left of right_lower - Gives back an object of the illegal coordinates and their corrected versions
    const getNewShapeIllegalCoordinates = function(newKey, newCoordinates, startCoordinates){
      let targetAspectRatio = canvasInstance.value.width / canvasInstance.value.height;
      let illegalCoordinates = {};
      let relativeRadius = {
        x: ELEMENT_INTERACTION_RADIUS / canvasInstance.value.width,
        y: ELEMENT_INTERACTION_RADIUS / canvasInstance.value.height
      }
      if (newKey === 'left_upper' && 'right_lower' in interactionElements.value) {
        if (newCoordinates.x >= (interactionElements.value['right_lower'].x - relativeRadius.x)) illegalCoordinates.x = (interactionElements.value['right_lower'].x - relativeRadius.x);
        if (newCoordinates.y >= (interactionElements.value['right_lower'].y - relativeRadius.y)) illegalCoordinates.y = (interactionElements.value['right_lower'].y - relativeRadius.y);
      }
      if (newKey === 'right_lower' && 'left_upper' in interactionElements.value) {
        if (newCoordinates.x <= (interactionElements.value['left_upper'].x + relativeRadius.x)) illegalCoordinates.x = (interactionElements.value['left_upper'].x + relativeRadius.x);
        if (newCoordinates.y <= (interactionElements.value['left_upper'].y + relativeRadius.y)) illegalCoordinates.y = (interactionElements.value['left_upper'].y + relativeRadius.y);
      }

      //If the aspect ratio should be preserved, search for the rectangle that has the correct aspect ratio and is closest to the requested rectangle
      if (props.preserveAspectRatio) {
        //Set the corrected coordinates in temporary version
        let newInteractionElementCoordinates = _.cloneDeep(interactionElements.value);
        newInteractionElementCoordinates[newKey] = _.assign({}, newCoordinates, illegalCoordinates);
        
        if ('left_upper' in newInteractionElementCoordinates && 'right_lower' in newInteractionElementCoordinates) {
          let width = newInteractionElementCoordinates['right_lower'].x - newInteractionElementCoordinates['left_upper'].x;
          let height = newInteractionElementCoordinates['right_lower'].y - newInteractionElementCoordinates['left_upper'].y;

          //Calculate 2 rectangles with correct aspect ratio from the respective other axis
          let correctedWidth = (height * props.aspectRatio) / targetAspectRatio;
          let correctedHeight = (width / props.aspectRatio) * targetAspectRatio;

          //Calculate the difference to the targeted rectangle
          let heightDifference = correctedHeight - height;
          let widthDifference = correctedWidth - width;

          //Calculate in which axis the most movement happened, to choose the opposite axis for primary calculation
          let movementX = startCoordinates.x - newInteractionElementCoordinates[newKey].x;
          let movementY = startCoordinates.y - newInteractionElementCoordinates[newKey].y;

          //Accomodate movement for aspect ratios for correct decision
          if (props.aspectRatio >= 1) {
            movementY *= props.aspectRatio;
          } else {
            movementX /= props.aspectRatio;
          }
          if (targetAspectRatio >= 1) {
            movementY /= targetAspectRatio;
          } else {
            movementX *= targetAspectRatio;
          }

          let correctedCoordinatesX;
          let correctedCoordinatesY;

          //Calculate both possible corrected rectangles to verify that they are not illegal
          if (newKey === 'left_upper') {
            //If the width or height gets bigger, the left_upper needs to move into the negative direction
            correctedCoordinatesX = {
              x: newInteractionElementCoordinates[newKey].x - widthDifference,
              y: newInteractionElementCoordinates[newKey].y
            }
            correctedCoordinatesY = {
              x: newInteractionElementCoordinates[newKey].x,
              y: newInteractionElementCoordinates[newKey].y - heightDifference
            }
          }
          if (newKey === 'right_lower') {
             //If the width or height gets bigger, the right_lower needs to move into the positive direction
            correctedCoordinatesX = {
              x: newInteractionElementCoordinates[newKey].x + widthDifference,
              y: newInteractionElementCoordinates[newKey].y
            }
            correctedCoordinatesY = {
              x: newInteractionElementCoordinates[newKey].x,
              y: newInteractionElementCoordinates[newKey].y + heightDifference
            }
          }

          //Check if any of the corrections is illegal, if illegal, set to null
          if (correctedCoordinatesX.x > 1 || correctedCoordinatesX.x < 0) {
            correctedCoordinatesX = null;
          }
          if (correctedCoordinatesY.y > 1 || correctedCoordinatesY.y < 0) {
            correctedCoordinatesY = null
          }

          //If both are illegal, return the original coordinates
          if (correctedCoordinatesX == null && correctedCoordinatesY == null) {
            return _.assign({}, interactionElements.value[newKey]);
          }
          //If just X is illegal, return Y
          else if (correctedCoordinatesX == null) {
            return correctedCoordinatesY;
          }
          //If just Y is illegal, return X
          else if (correctedCoordinatesY == null) {
            return correctedCoordinatesX;
          }
          //If both of them are legal, choose the direction with the smallest movement
          else {
            if ((newKey === 'left_upper' && (movementX > movementY)) || (newKey === 'right_lower' && (movementX < movementY))) {
              return correctedCoordinatesX;
            } else { //If they are equal, it doesn't matter
              return correctedCoordinatesY;
            }
          }
        }
        //If keys are missing, return original one for no change
        return _.assign({}, interactionElements.value[newKey]);
      }

      return illegalCoordinates;
    }

    const areRelativeCoordinatesInsideInteractionSquare = function(coordinates) {
      if (!('left_upper' in interactionElements.value && 'right_lower' in interactionElements.value)) return false;

      if (
        coordinates.x > interactionElements.value['left_upper'].x && coordinates.x < interactionElements.value['right_lower'].x
        &&
        coordinates.y > interactionElements.value['left_upper'].y && coordinates.y < interactionElements.value['right_lower'].y
      ) return true;
      else return false;
    }

    /* Catch Start of Click or Movement in canvas to get the interaction element where the interaction started
    Set data later used by other callbacks in this gesture */
    const canvasStartInteract = function(detail){
      let canvasCoords = getCoordsRelativeToElementFromClientCoords(canvasInstance.value, detail.startX, detail.startY);

      //Set data with the closest marker on start, the frame and the current ratio - Can be read in any other callback
      detail.data = { 
        closestInteractionElementKeyAtStart: getClosestInteractionElementAt(canvasCoords.x, canvasCoords.y)
      }

      //If a element key was at the starting point of this interaction
      if (detail.data.closestInteractionElementKeyAtStart){
        canvasInteracting.value = true;
        let relativeElementCoordinates = {
          x: interactionElements.value[detail.data.closestInteractionElementKeyAtStart].x,
          y: interactionElements.value[detail.data.closestInteractionElementKeyAtStart].y
        };
        detail.data.startingPointInteraction = relativeElementCoordinates;
      } else {
        let relativeCoordinates = {
          x: canvasCoords.x / canvasInstance.value.width,
          y: canvasCoords.y / canvasInstance.value.height
        };
        if (areRelativeCoordinatesInsideInteractionSquare(relativeCoordinates)) {
          canvasInteracting.value = true;
          detail.data.startingPointInsideRectangle = relativeCoordinates;
          detail.data.originalRectangle = _.cloneDeep(interactionElements.value);
        }
      }
    }

    /* Catch movement of cursor in canvas for moving interaction element */
    const canvasMoveInteract = function(detail){
      let canvasCoords = getCoordsRelativeToElementFromClientCoords(canvasInstance.value, detail.currentX, detail.currentY);

      let newCoordinates = {
        x: canvasCoords.x / canvasInstance.value.width,
        y: canvasCoords.y / canvasInstance.value.height
      };
      //If a interaction element is selected and it gets dragged, move it to its new location
      if (detail.data.closestInteractionElementKeyAtStart != null) {
        //Correct all illegal coordinates - prevents overlap by design
        let correctedCoordinates = getNewShapeIllegalCoordinates(detail.data.closestInteractionElementKeyAtStart, newCoordinates, detail.data.startingPointInteraction);

        //Build object of new coordinates overwritten by corrected illegal coordinates
        let targetCoordinates = _.assign(newCoordinates, correctedCoordinates);
        
        interactionElements.value[detail.data.closestInteractionElementKeyAtStart] = targetCoordinates;

        updateCanvas();
      } else if (detail.data.startingPointInsideRectangle != null && detail.data.originalRectangle != null) {
        let movedDistance = {
          x: newCoordinates.x - detail.data.startingPointInsideRectangle.x,
          y: newCoordinates.y - detail.data.startingPointInsideRectangle.y
        }

        let newRectangle = _.cloneDeep(detail.data.originalRectangle);

        //Used to move points back into the boundary
        let boundaryViolationDistance = {
          x: 0,
          y: 0
        }

        for (let element of Object.values(newRectangle)) {
          element.x += movedDistance.x;
          element.y += movedDistance.y;

          //Prevent movement in an invalid axis, if any of the elements would move outside the boundaries
          if (element.x < 0) boundaryViolationDistance.x = element.x;
          if (element.x > 1) boundaryViolationDistance.x = element.x - 1;
          if (element.y < 0) boundaryViolationDistance.y = element.y;
          if (element.y > 1) boundaryViolationDistance.y = element.y - 1;
        }

        //Correct invalid movements. Nees to be in a second loop, because we can detect a violation at any of the later points
        for (let element of Object.values(newRectangle)) {
          element.x -= boundaryViolationDistance.x;
          element.y -= boundaryViolationDistance.y;
        }

        //Apply the movement
        interactionElements.value = newRectangle;
        updateCanvas();
      }
    }

    /* Catch end of interaction */
    const canvasEndInteract = function(detail){
      //Always remove the class for UI interaction signal
      canvasInteracting.value = false;

      //If an element was moved, emit the new locations
      if (detail.data.closestInteractionElementKeyAtStart != null || (detail.data.startingPointInsideRectangle != null && detail.data.originalRectangle != null)) {
        context.emit('updateSelection', interactionElements);
      }
    }

    const getSelectionRectangle = function() {
      //Fallback is full rectangle
      let rectangle = {
        x: 0,
        y: 0,
        width: 1,
        height: 1
      };

      if ('left_upper' in interactionElements.value && 'right_lower' in interactionElements.value) {
        rectangle.x = interactionElements.value['left_upper'].x;
        rectangle.y = interactionElements.value['left_upper'].y;
        rectangle.width = interactionElements.value['right_lower'].x - interactionElements.value['left_upper'].x;
        rectangle.height = interactionElements.value['right_lower'].y - interactionElements.value['left_upper'].y;
      }

      return rectangle; 
    }

    watch([() => props.targetRect, canvasInstance], ([rect, currentCanvasInstance]) => {
      if (currentCanvasInstance != null && rect != null && rect.width != null && rect.height != null) {
        const ratio = Math.ceil(window.devicePixelRatio);

        let width = rect.width;
        let height = rect.height;
        if (currentCanvasInstance != null) {
          currentCanvasInstance.width = Math.ceil(width * ratio);
          currentCanvasInstance.height = Math.ceil(height * ratio);
          currentCanvasInstance.style.width = `${Math.ceil(width)}px`;
          currentCanvasInstance.style.height = `${Math.ceil(height)}px`;
        }
        canvasAspectRatio.value = (width/height);
        if (_.isEqual(interactionElements.value, initialInteractionElements.value)) { //If the user has not modified the rectangle yet, reset it to match the correct aspect ratio
          changeRectangleAspectRatio((width/height));
        }
        updateCanvas();
      }
    }, { immediate: true });

    onMounted(() => {
      //Gesture to detect clicks and movement
      const gesture = createGesture({
        el: canvasInstance.value,
        threshold: 0,
        direction: undefined,
        onStart: (detail) => { canvasStartInteract(detail) },
        onMove: (detail) => { canvasMoveInteract(detail) },
        onEnd: (detail) => { canvasEndInteract(detail) }
      });
      gesture.enable();

      //Clear canvas on load
      clearCanvas();
    });

    return {
      canvasInstance,
      getSelectionRectangle,
      cameraTemplateImageInstance,
      cameraTemplateImageProperties,
      updateCanvas
    }

  }
}
</script>

<!-- This element automatically fills a relative parent to the maximum! -->
<style scoped>
.editing-canvas {
  position: absolute;
}
.hidden-canvas {
  display: none;
}

#camera-template-image {
  position: absolute;
  display: none;
}
</style>
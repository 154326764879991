<template>
  <ion-page>
    <ion-tabs>
      <ion-tab-bar slot="bottom">
        <ion-tab-button class="map-tab" tab="map" href="/track-infect/map">
          <ion-icon :icon="map" />
          <ion-label>{{ i18n.$t('track-infect.map.title') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button ref="infoTab" tab="tracking" href="/track-infect/info">
          <ion-icon :icon="informationCircle" />
          <ion-label>{{ i18n.$t('track-infect.info.title') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="discussion" href="/track-infect/discussion">
          <ion-icon :icon="chatbubbles" />
          <ion-label>{{ i18n.$t('track-infect.discussion.title') }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage } from '@ionic/vue';
import { map, informationCircle, chatbubbles } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'TrackInfect',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage },
  setup() {
    const i18n = useI18n();

    const infoTab = ref(null);

    const smallScreenQuery = window.matchMedia("(max-width: 992px)");

    const router = useRouter();

    smallScreenQuery.addEventListener('change', (query) => { //Navigate away when screen size changes to big one and we are on the removed tab
      console.log();
      if (!query.matches && router.currentRoute.value.name === 'infectionMap')
        infoTab.value.$el.click(); //Use workaround as a click on the tab, since ion-tabs does not expose method select as stated
        //$el has to be used with ionic to get the element itself
    });

    return {
      i18n,
      map,
      informationCircle,
      chatbubbles,
      infoTab
    }
  }
}
</script>

<style scoped>
  @media (min-width: 992px) {
    .map-tab {
      display: none;
    }
  }
</style>

<template id="upload-status-modal">
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ i18n.$t("upload-status.title") }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeModal()">{{ i18n.$t('default_interaction.close') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-item lines="none">
      <ProgressButton class="retry-button" color="success" fill="solid" @click="triggerResync()" :disabled="!canRetry" :loading="isCurrentlyResyncing">
        <ion-icon slot="start" :icon="refresh"></ion-icon>
        {{ i18n.$t("upload-status.retry") }}
      </ProgressButton>
    </ion-item>
    <ion-item class="upload-queue" lines="none">
      <b>{{ i18n.$t("upload-status.upload-queue") }}{{ (uploadStatus.length >= 0) ? ` (${uploadStatus.length})` : '' }}:</b>
    </ion-item>
    <ion-content>
      <ion-list>
        <ion-item class="no-uploads" lines="none" v-if="uploadStatus.length <= 0">
          {{ i18n.$t("upload-status.no-uploads") }}
        </ion-item>
        <ion-item v-for="(status, index) in uploadStatus" :key="index"
          lines="full"
          class="report-entry-item"
          detail
          :button="status.displayInformation.route != null"
          @click="navigateToRoute(status.displayInformation.route)">
          <div class="delete-button-container" v-if="status.key != null">
            <ion-button slot="start" fill="clear" color="danger" class="delete-button" expand="block" @click.stop="openDeleteConfirmation(status)">
              <ion-icon slot="icon-only" :icon="closeCircleOutline"></ion-icon>
            </ion-button>
          </div>
          <ion-label>
            <h3 class="type-indicator">
              <b>#{{status.index}}</b> <ion-icon v-if="status.displayInformation.icon != null" :icon="status.displayInformation.icon"></ion-icon> 
              <ion-icon v-if="status.displayInformation.statusIcon != null" :icon="status.displayInformation.statusIcon" :color="status.displayInformation.statusIconColor"></ion-icon> 
              {{ status.displayInformation.typeIndicator }}
            </h3>
            <h2>{{ status.displayInformation.title }}</h2>
            <h3>{{ status.displayInformation.identifier }}</h3>
            <h3 :style="'color: var(' + status.design.color + ');'">
              {{ i18n.$t("upload-status." + status.design.text) }}
            </h3>
            <p class="wrap">{{ status.displayInformation.description }}</p>
          </ion-label>
          <CircularProgress
            class="upload-progress"
            :style="'--color: var(' + status.design.color + ');'"
            :progress="(status.progress >= 0) ? status.progress : 0">
            <ion-icon :icon="status.design.icon"></ion-icon>
          </CircularProgress>
          
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>

import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonList, IonItem, IonLabel, IonIcon, modalController, alertController } from '@ionic/vue';
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { cloudUpload, cloudOffline, alert, trashOutline, refresh, documentTextOutline, documentsOutline, clipboardOutline, chatboxEllipsesOutline, syncOutline, pencil, closeCircleOutline } from 'ionicons/icons';

import CircularProgress from '@/components/CircularProgress.vue';
import ProgressButton from '@/components/ProgressButton.vue';

import _ from 'lodash';

import { useI18n } from "@/utils/i18n";

import { useDayjs } from '@/utils/dayjs';

const UploadStatusModal = defineComponent({
  name: 'UploadStatusModal',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonList, IonItem, IonLabel, IonIcon, CircularProgress, ProgressButton },
  setup() {
    const i18n = useI18n();

    const { dayjs } = useDayjs();

    const router = useRouter();

    const store = useStore();

    const closeModal = function(){
      modalController.dismiss();
    }

    //Get all statuses and map them into their displayed texts and design
    const uploadStatus = computed(() => _.map((store.getters['getUploadStatus'] || []), (statusObject) => {
      let originalStatus = (statusObject != null) ? statusObject.status : null;
      let mappedStatus = {
        ..._.pick(statusObject, ['key', 'index', 'route']),
        progress: (originalStatus != null && originalStatus.progress != null) ? originalStatus.progress : -1,
        design: getUploadStatusDesign(originalStatus),
      }

      //Add texts, if there are valid details
      if (mappedStatus != null && mappedStatus.route != null) {
        switch (mappedStatus.route) {
          case '/reports':
            mappedStatus.displayInformation = {
              message: 'report',
              typeIndicator: i18n.$t('upload-status.type.report'),
              icon: documentTextOutline,
              route: {name: 'view-report', params: { id: mappedStatus.key }},
              title: (originalStatus.detail != null && originalStatus.detail.type != null) ? getLocalizedMainCategory(originalStatus.detail.type) : null,
              identifier: (originalStatus.detail != null && originalStatus.detail.timestamp != null) ? timestampToDateTime.value(originalStatus.detail.timestamp) : null,
              description: (originalStatus.detail != null && originalStatus.detail.type != null) ? getLocalizedReportTypeName(originalStatus.detail.type) : null
            };
            break;
          case '/reports/connection':
            mappedStatus.displayInformation = {
              message: 'report',
              typeIndicator: i18n.$t('upload-status.type.connection'),
              icon: documentsOutline,
              route: {name: 'view-connected-reports', params: { connection_id: mappedStatus.key }},
            };
            break;
          case '/personal-horse-infos':
            mappedStatus.displayInformation = {
              message: 'animal',
              typeIndicator: i18n.$t('upload-status.type.animal'),
              icon: clipboardOutline,
              route: {name: 'view-animal', params: { animalId: mappedStatus.key }},
              title: (originalStatus.detail != null && originalStatus.detail.name != null) ? originalStatus.detail.name : null,
              identifier: (originalStatus.detail != null && originalStatus.detail.unique_identifier != null) ? `# ${originalStatus.detail.unique_identifier}` : null
            };
            break;
          case '/general-feedback':
            mappedStatus.displayInformation = {
              message: 'general-feedback',
              typeIndicator: i18n.$t('upload-status.type.general-feedback'),
              icon: chatboxEllipsesOutline,
              route: {name: 'general-feedback'},
            };
            break;
        
          default:
            break;
        }
      }

      //Set as fallback, if no displayInformation has been set yet
      if (mappedStatus.displayInformation == null) mappedStatus.displayInformation = {
        message: 'general',
        typeIndicator: i18n.$t('upload-status.type.general'),
        icon: syncOutline
      }

      //Set a different indicator icon depending on the status!
      if (originalStatus != null && originalStatus.method != null) {
        switch (originalStatus.method) {
          case 'put':
            _.assign(mappedStatus.displayInformation, {
              statusIcon: pencil,
              statusIconColor: 'success'
            });
            break;
          case 'delete':
            _.assign(mappedStatus.displayInformation, {
              statusIcon: trashOutline,
              statusIconColor: 'danger'
            });
            break;
        
          default:
            break;
        }
      }

      return mappedStatus;
    }));

    //Can retry uploads if at least one of the uploads is not in a state where a retry can't be executed (at the moment)
    const canRetry = computed(() => {
      return _.some(uploadStatus.value, (status) => {
        if (status != null && status.design != null && status.design.canRetry === true) return true;
        return false;
      })
    });

    //Can restart syncing, when the current sync is not running, every item has to be in a stopped state
    const isCurrentlyResyncing = computed(() => {
      return _.some(uploadStatus.value, (status) => {
        if (status != null && status.design != null && status.design.identifier === 'uploading') return true;
        return false;
      })
    });

    const getUploadStatusDesign = function(status){
      return store.getters['getUploadStatusDesign'](status);
    }

    const timestampToDateTime = computed(() => {
      return (timestamp) => {
        if (timestamp == null) return null;
        try {
          //Use js internal Date object for parsing the ISO String to treat 0-padded years correctly, e.g. 0001 is not 1901!
          let localDateTime = dayjs((new Date(timestamp))).millisecond(0).second(0); //Remove precision of seconds and milliseconds!
          if (localDateTime != null && localDateTime.isValid()) {         
            return localDateTime.format('LL LTS');
          }
        } catch {
          //Skip conversion to date on error!
          return null;
        }
      }
    });

    const getLocalizedMainCategory = function(reportTypeId){
      let categoryNames = store.getters['reports/getMainCategoryNamesOfReportTypeId'](reportTypeId);
      if (categoryNames) {
        return categoryNames[i18n.locale.value];
      } else {
        return null;
      }
    }

    const getLocalizedReportTypeName = function(reportTypeId){
      let reportType = store.getters['reports/getReportTypesByIdentifiers'](reportTypeId);
      if (reportType && reportType.descriptor) {
        return reportType.descriptor; //TODO Use the translated name here
      } else {
        return null;
      }
    }

    const triggerResync = function(){
      store.dispatch('retryUploads', true).catch((errors) => console.error('Retry failed', errors));
    }

    const openDeleteConfirmation = async function(status){
      //Do not continue if it can't be deleted
      if (status == null || status.key == null) return;

      //Use more specific texts if specified otherwise fallback to general texts
      let messageType = 'general';
      if (status.displayInformation != null && status.displayInformation.message != null) {
        messageType = status.displayInformation.message;
      }

      let identifierText = '';
      //Only add identifier text if it is valid, otherwise leave it away
      if (status.displayInformation != null && status.displayInformation.identifier != null) {
        identifierText =  i18n.$t(`upload-status.delete-confirmation.message.${messageType}.before_identifier`) + 
                          '<b>' + status.displayInformation.identifier + '</b>' + 
                          i18n.$t(`upload-status.delete-confirmation.message.${messageType}.after_identifier`);
      }

      const alert = await alertController
      .create({
        cssClass: 'delete-confirmation-alert',
        header: i18n.$t('upload-status.delete-confirmation.title') + ((status.index != null) ? ` #${status.index}` : ''),
        message: i18n.$t(`upload-status.delete-confirmation.message.${messageType}.before`) + identifierText + i18n.$t(`upload-status.delete-confirmation.message.${messageType}.after`),
        buttons: [
          {
            text: i18n.$t('default_interaction.cancel'),
            role: 'cancel'
          },
          {
            text: i18n.$t('upload-status.delete-confirmation.delete'),
            cssClass: 'delete-upload-confirmation-okay',
            handler: () => {
              store.dispatch('removeUpload', status.key);
            },
          },
        ],
      });
      return alert.present();
    }

    const navigateToRoute = function(newRoute){
      let currentRoute = router.currentRoute.value;
      let routingPromise;
      //If we are already navigated to the same route, replace it, else push it
      if (currentRoute.name === newRoute.name) {
        routingPromise = router.replace(newRoute);
      } else {
        routingPromise = router.push(newRoute);
      }
      routingPromise.finally(() => closeModal());
    }

    return { i18n, closeModal, uploadStatus, canRetry, isCurrentlyResyncing, getUploadStatusDesign, timestampToDateTime, getLocalizedMainCategory, getLocalizedReportTypeName, triggerResync, openDeleteConfirmation, navigateToRoute, cloudUpload, cloudOffline, alert, closeCircleOutline, refresh };
  }
});

export async function openUploadStatusModal(component){
  if (component != null) {
    const modal = await modalController
      .create({
        component
      })
    modal.present();
    return modal.onWillDismiss();
  }
}

export default UploadStatusModal;
</script>

<style>
.delete-upload-confirmation-okay {
  color: var(--ion-color-danger)!important;
}
</style>

<style scoped>
ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-item ion-label * {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.retry-button {
  --padding-start: 20px;
  --padding-end: 20px;
  height: 3em;
  margin: 10px 0px;
  text-transform: none;
  font-weight: bold;
  font-size: 0.8em;
}

.no-uploads {
  --padding-start: 40px;
}

.report-entry-item {
  --padding-start: 0px;
}

.delete-button-container {
  height: 100%;
}

.delete-button {
  --padding-start: 15px;
  --padding-end: 15px;
  height: 100%;
  --padding-top: 0px;
  --padding-bottom: 0px;
  margin: 0px;
}

.upload-progress {
  width: 2.5em;
  height: 2.5em;
}

.type-indicator ion-icon {
  vertical-align: -0.15em;
}
</style>
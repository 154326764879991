<template>
  <div :class="['animated-logo', instensityClass, animationClass, stopPatternClass]" :style="animationStyle" alt="App-Logo">
    <svg version="1.1" viewBox="0 0 127 127" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g stroke-width=".93869" id="medium">
          <circle class="offset outside-square" cx="86.85" cy="40.175" r="9.125"/>
          <circle class="offset outside-square" cx="86.85" cy="86.85" r="9.125"/>
          <circle class="offset outside-square" cx="40.175" cy="86.85" r="9.125"/>
          <circle class="offset outside-square" cx="40.175" cy="40.175" r="9.125"/>
        </g>
        <g stroke-width=".6558" id="small">
          <circle class="cross outside-square" cx="63.525" cy="37.375" r="6.375"/>
          <circle class="cross outside-square" cx="89.625" cy="63.525" r="6.375"/>
          <circle class="cross outside-square" cx="37.375" cy="63.525" r="6.375"/>
          <circle class="offset" cx="37.375" cy="115.68" r="6.375"/>
          <circle cx="89.625" cy="115.68" r="6.375"/>
          <circle cx="37.375" cy="11.175" r="6.375"/>
          <circle class="offset" cx="89.625" cy="11.175" r="6.375"/>
          <circle cx="11.175" cy="37.375" r="6.375"/>
          <circle class="offset" cx="11.175" cy="89.675" r="6.375"/>
          <circle class="cross outside-square" cx="63.525" cy="89.675" r="6.375"/>
          <circle cx="115.68" cy="89.675" r="6.375"/>
          <circle class="offset" cx="115.68" cy="37.375" r="6.375"/>
        </g>
        <g stroke-width="1.1573" id="big">
          <circle class="cross outside-square" cx="63.5" cy="115.75" r="11.25"/>
          <circle class="cross outside-square offset" cx="11.25" cy="63.5" r="11.25"/>
          <circle class="cross outside-square offset" cx="115.75" cy="63.5" r="11.25"/>
          <circle class="cross outside-square" cx="63.5" cy="11.25" r="11.25" />
          <circle class="cross" cx="63.5" cy="63.5" r="11.25" />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    name: {
      type: String,
      default: null
    },
    intensity: {
      type: String,
      default: undefined
    },
    duration: {
      type: [Number, String],
      default: 1000
    },
    paused: {
      type: Boolean,
      default: false
    },
    stopPattern: {
      type: String,
      default: undefined
    },
    enabled: {
      type: Boolean,
      default: true
    },
    count: {
      type: Number,
      default: undefined
    }
  },
  setup(props) {
    const animationStyle = computed(() => {
      return `--duration: ${props.duration}ms; --play-state: ${ props.paused ? 'paused' : 'running' }; --iteration-count: ${props.count || 'infinite'};`;
    });

    const instensityClass = computed(() => {
      if (!props.enabled) return '';
      switch (props.intensity) {
        case 'soft':
          return 'soft';
        case 'medium':
          return 'medium-soft';
      
        default:
          break;
      }
      return '';
    });

    const animationClass = computed(() => {
      if (!props.enabled) return 'disabled';
      switch (props.name) {
        case 'cycle':
          return 'cycle';
        case 'crosshair':
          return 'crosshair';
        case 'crosshair-fade':
          return 'crosshair-fade';
        case 'crosshair-move':
          return 'crosshair-move';
      
        default:
          break;
      }
      return 'disabled';
    });

    const stopPatternClass = computed(() => {
      switch (props.stopPattern) {
        case 'cross':
          return 'cross';
        case 'small':
          return 'small';
        case 'offset':
          return 'offset';
        case 'outside-square':
          return 'outside-square';
      
        default:
          break;
      }
      return undefined;
    });

    return { animationStyle, instensityClass, animationClass, stopPatternClass }
  }
});
</script>

<style scoped>
@keyframes cycle {
  0% {
    /* First show the cross */
    transform: scale(var(--scale-in-cross, var(--minimum-scale, 0)));
  }
  
  25% {
    /* Then show all the small dots */
    transform: scale(var(--scale-in-small, var(--minimum-scale, 0)));
  }
  
  50% {
    /* Then show the offset dots */
    transform: scale(var(--scale-in-offset, var(--minimum-scale, 0)));
  }
  
  75% {
    /* Then show the outside square dots */
    transform: scale(var(--scale-in-outside-square, var(--minimum-scale, 0)));
  }
  
  100% {
    /* And finish with the cross again */
    transform: scale(var(--scale-in-cross, var(--minimum-scale, 0)));
  }
}

@keyframes crosshair {
  0% {
    /* Start with all dots being minimum */
    transform: scale(var(--minimum-scale, 0));
  }

  25% {
    /* First show the outside of the cross */
    transform: scale(var(--scale-in-outside, var(--minimum-scale, 0)));
  }
  
  50% {
    /* Then show the in between dots of the cross */
    transform: scale(var(--scale-in-middle, var(--minimum-scale, 0)));
  }
  
  75% {
    /* Then show the center dot */
    transform: scale(var(--scale-in-center, var(--minimum-scale, 0)));
  }

  100% {
    /* End with all dots being minimum */
    transform: scale(var(--minimum-scale, 0));
  }
}

/* Same as above, just fading it out in the same order */
@keyframes crosshair-fade {
  0% {
    /* Start with all dots being minimum */
    transform: scale(var(--minimum-scale, 0));
  }

  17% {
    /* First show the outside of the cross */
    transform: scale(var(--scale-in-outside, var(--minimum-scale, 0)));
  }
  
  34% {
    /* Then show the in between dots of the cross */
    transform: scale(var(--scale-in-middle, var(--minimum-scale, 0)));
  }
  
  51% {
    /* Then show the center dot */
    transform: scale(var(--scale-in-center, var(--minimum-scale, 0)));
  }

  68% {
    /* Then fade out the outside ones */
    transform: scale(var(--scale-in-outside-fade, var(--minimum-scale, 0)));
  }

  85% {
    /* Then fade out the in between ones ones */
    transform: scale(var(--scale-in-middle-fade, var(--minimum-scale, 0)));
  }

  100% {
    /* End with all dots being minimum */
    transform: scale(var(--minimum-scale, 0));
  }
}

/* Same as above but the ones before immediately fade out */
@keyframes crosshair-move {
  0% {
    /* Start with all dots being minimum */
    transform: scale(var(--minimum-scale, 0));
  }

  25% {
    /* First show the outside of the cross */
    transform: scale(var(--scale-in-outside-only, var(--minimum-scale, 0)));
  }
  
  50% {
    /* Then show the in between dots of the cross */
    transform: scale(var(--scale-in-middle-only, var(--minimum-scale, 0)));
  }
  
  75% {
    /* Then show the center dot */
    transform: scale(var(--scale-in-center-only, var(--minimum-scale, 0)));
  }

  100% {
    /* End with all dots being minimum */
    transform: scale(var(--minimum-scale, 0));
  }
}

.animated-logo {
  height: 100%;
  width: 100%;
  position: relative;

  display: inline-flex;
  width: 1em;
  height: 1em;
  contain: strict;
  fill: currentcolor;
  box-sizing: content-box !important;
}

.animated-logo svg circle {
  animation-name: none;
  animation-direction: normal;
  animation-duration: var(--duration, 1000ms);
  animation-timing-function: cubic-bezier( .6, 0, .8, 1 );
  animation-play-state: var(--play-state, running);
  animation-iteration-count: var(--iteration-count, infinite);
  transform-box: fill-box;
  transform-origin: center center;
  position: absolute;

  --minimum-scale: 0;
  --maximum-scale: 1;
  /* Animate transform when leaving the animation (disabled) */
  transition: transform 1s ease-in-out;

  transform: scale(var(--minimum-scale, 0));
}

.animated-logo.disabled svg circle {
  transform: scale(var(--minimum-scale, 0));
  animation-name: none!important;
}

/* Set the stop pattern once disabled */
.animated-logo.disabled.full svg circle {
  transform: scale(var(--maximum-scale, 0));
}

.animated-logo.disabled.cross svg circle.cross {
  transform: scale(var(--maximum-scale, 0));
}

.animated-logo.disabled.small svg g#small circle {
  transform: scale(var(--maximum-scale, 0));
}

.animated-logo.disabled.offset svg circle.offset {
  transform: scale(var(--maximum-scale, 0));
}

.animated-logo.disabled.outside-square svg circle.outside-square {
  transform: scale(var(--maximum-scale, 0));
}

/* Set animation properties */
.animated-logo.soft svg circle {
  --minimum-scale: 0.95;
  animation-timing-function: ease-in-out;
}

/* Set animation properties */
.animated-logo.medium-soft svg circle {
  --minimum-scale: 0.65;
  animation-timing-function: ease-in-out;
}

.animated-logo.cycle svg circle {
  animation-name: cycle;
}

.animated-logo.crosshair svg circle {
  animation-name: crosshair;
  animation-fill-mode: forwards;
}

.animated-logo.crosshair-fade svg circle {
  animation-name: crosshair-fade;
  animation-fill-mode: forwards;
}

.animated-logo.crosshair-move svg circle {
  animation-name: crosshair-move;
  animation-fill-mode: forwards;
}

/* Define with variables, which are visible in which pattern. The value is the scale when fully visible in that pattern. Immediately visible ones have scale(1) already set. */
.animated-logo.cycle svg circle.cross:not(.offset):not(.outside-square) {
  --scale-in-cross: var(--maximum-scale, 1);
  transform: scale(var(--maximum-scale, 1));
}

.animated-logo.cycle svg g#small circle.offset:not(.cross):not(.outside-square) {
  --scale-in-small: var(--maximum-scale, 1);
  --scale-in-offset: var(--maximum-scale, 1);
}

.animated-logo.cycle svg g:not(#small) circle.outside-square.cross:not(.offset) {
  --scale-in-cross: var(--maximum-scale, 1);
  --scale-in-outside-square: var(--maximum-scale, 1);
  transform: scale(var(--maximum-scale, 1));
}

.animated-logo.cycle svg g#small circle.outside-square.cross:not(.offset) {
  --scale-in-cross: var(--maximum-scale, 1);
  --scale-in-small: var(--maximum-scale, 1);
  --scale-in-outside-square: var(--maximum-scale, 1);
  transform: scale(var(--maximum-scale, 1));
}

.animated-logo.cycle svg g#small circle:not(.offset):not(.cross):not(.outside-square) {
  --scale-in-small: var(--maximum-scale, 1);
}

.animated-logo.cycle svg circle.outside-square.offset:not(.cross) {
  --scale-in-offset: var(--maximum-scale, 1);
  --scale-in-outside-square: var(--maximum-scale, 1);
}

.animated-logo.cycle svg circle.cross.offset.outside-square {
  --scale-in-cross: var(--maximum-scale, 1);
  --scale-in-offset: var(--maximum-scale, 1);
  --scale-in-outside-square: var(--maximum-scale, 1);
  transform: scale(var(--maximum-scale, 1));
}

/* Animates the cross from outside to inside gradually revealing it, leaving the ones from the previous step still visible */
.animated-logo.crosshair svg g#big circle.cross.outside-square, .animated-logo.crosshair-fade svg g#big circle.cross.outside-square, .animated-logo.crosshair-move svg g#big circle.cross.outside-square {
  --scale-in-outside-only: var(--maximum-scale, 1);
  --scale-in-outside: var(--maximum-scale, 1);
  --scale-in-middle: var(--maximum-scale, 1);
  --scale-in-center: var(--maximum-scale, 1);
  transform: scale(var(--maximum-scale, 1));
}

.animated-logo.crosshair svg g#small circle.cross, .animated-logo.crosshair-fade svg g#small circle.cross, .animated-logo.crosshair-move svg g#small circle.cross {
  --scale-in-middle-only: var(--maximum-scale, 1);
  --scale-in-middle: var(--maximum-scale, 1);
  --scale-in-center: var(--maximum-scale, 1);
  --scale-in-outside-fade: var(--maximum-scale, 1);
  transform: scale(var(--maximum-scale, 1));
}

.animated-logo.crosshair svg circle.cross:not(.offset):not(.outside-square), .animated-logo.crosshair-fade svg circle.cross:not(.offset):not(.outside-square), .animated-logo.crosshair-move svg circle.cross:not(.offset):not(.outside-square) {
  --scale-in-center-only: var(--maximum-scale, 1);
  --scale-in-center: var(--maximum-scale, 1);
  --scale-in-outside-fade: var(--maximum-scale, 1);
  --scale-in-middle-fade: var(--maximum-scale, 1);
  transform: scale(var(--maximum-scale, 1));
}

.animated-logo.crosshair svg circle:not(.cross), .animated-logo.crosshair-fade svg circle:not(.cross), .animated-logo.crosshair-move svg circle:not(.cross) {
  --scale-in-outside-only: 0;
  --scale-in-middle-only: 0;
  --scale-in-center-only: 0;
  --scale-in-outside: 0;
  --scale-in-middle: 0;
  --scale-in-center: 0;
  --scale-in-outside-fade: 0;
  --scale-in-middle-fade: 0;
  --minimum-scale: 0;
}

</style>

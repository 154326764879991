import { ref, watch, provide, inject, computed } from "vue";

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday'; //To calculate the weekday offsets
import localeData from 'dayjs/plugin/localeData'; //To get the short localized weekdays and Month names
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat'; //To allow parsing with a custom format
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);

dayjs.tz.setDefault("Europe/Berlin");

export const ageYearFormat = 'YYYY';

export const ageDateFormat = 'DD.MM.YYYY';

function calculateAgeFromBirthdate(birthday, format = ageDateFormat, asInteger = false) {
  try {
    let dateObject = dayjs(birthday, format);
    let age = dayjs().startOf('day').diff(dateObject, 'year');
    if (asInteger) return parseInt(age);
    return age;
  } catch (error) {
    console.error('Dayjs', error);
    return undefined;
  }
}

function calculateBirthyearFromAge(age) {
  try {
    //Subtract the age amount from today and just return the year
    return dayjs().subtract(age, 'year').format(ageYearFormat);
  } catch (error) {
    console.error('Dayjs', error);
    return undefined;
  }
}

function parseString(dateString, format) {
  try {
    if (dateString == null) return undefined;
    return dayjs(dateString, format);
  } catch (error) {
    console.error('Dayjs', error);
    return undefined;
  }
}

function parseAndFormat(dateString, format, inputFormat) {
  try {
    let date = parseString(dateString, inputFormat);
    if (date == null) return undefined;
    return date.format(format);
  } catch (error) {
    console.error('Dayjs', error);
    return undefined;
  }
}

function getSimpleLocalDate(dateString, inputFormat) {
  try {
    let date = parseString(dateString, inputFormat);
    if (date == null) return undefined;
    return date.format('l');
  } catch (error) {
    console.error('Dayjs', error);
    return undefined;
  }
}

function getSimpleLocalDateAndTime(dateString, inputFormat) {
  try {
    let date = parseString(dateString, inputFormat);
    if (date == null) return undefined;
    return date.format('l LT');
  } catch (error) {
    console.error('Dayjs', error);
    return undefined;
  }
}

const isReady = ref(false);

const configureDayjs = config => ({
  dayjsLocale: config.localeRef,
  activeLocaleData: ref(dayjs.localeData()), //Locale instance for computed properties that are not dependant on any already localized dates
  importedLocales: config.importedLocales,
  timezone: dayjs.tz.guess(),
  localeImportPromise: config.localeImportPromise,
  isReady,
  dayjs: dayjs,
  //For all the following functions change the function once locale is loaded
  calculateAgeFromBirthdate: computed(() => isReady.value ? calculateAgeFromBirthdate : calculateAgeFromBirthdate),
  calculateBirthyearFromAge: computed(() => isReady.value ? calculateBirthyearFromAge : calculateBirthyearFromAge),
  parseString: computed(() => isReady.value ? parseString : parseString),
  parseAndFormat: computed(() => isReady.value ? parseAndFormat : parseAndFormat),
  getSimpleLocalDate: computed(() => isReady.value ? getSimpleLocalDate : getSimpleLocalDate),
  getSimpleLocalDateAndTime: computed(() => isReady.value ? getSimpleLocalDateAndTime : getSimpleLocalDateAndTime),
});

const updateLocale = function(container, newLocale){
  container.dayjs.locale(newLocale); //Set locale globally for all new calls. If the locale is not loaded or not implemented, it will fallback to en silently
  container.activeLocaleData.value = container.dayjs.localeData();
}

const dayjsSymbol = Symbol();

export function provideDayjs(dayjsConfig) {
  dayjsConfig.importedLocales = {};

  const importLocales = async function() {
    //Import all needed locales dynamically
    for (let language of dayjsConfig.availableLanguages){
      dayjsConfig.importedLocales[language.lang] = await import('dayjs/locale/' + language.lang + '.js');
    }

    return dayjsConfig.importedLocales;
  };

  //Save execution state of the imports in a variable as a promise
  dayjsConfig.localeImportPromise = importLocales();

  const dayjsContainer = configureDayjs(dayjsConfig);

  //After promise is resolved, set initial locale
  dayjsConfig.localeImportPromise.then(() => {
    updateLocale(dayjsContainer, dayjsContainer.dayjsLocale.value);
    dayjsContainer.isReady.value = true;
  });

  //update locale if reference changes
  watch(() => dayjsContainer.dayjsLocale.value, (newLocale) => {
    updateLocale(dayjsContainer, newLocale);
  });

  provide(dayjsSymbol, dayjsContainer);
}

export function useDayjs() {
  const dayjsContainer = inject(dayjsSymbol);
  if (!dayjsContainer) throw new Error("dayjs has to be provided first!");

  return dayjsContainer;
}

//Get a list of all dates in the given timespan in the specified interval and in the specified string format, including or excluding start and end
export function getTimespanListInRange(startDayjsObject, endDayjsObject, interval = 'month', format = 'MM.YYYY', inclusivity = '[]') { //By default get each month including first and last
  let dates = [];
  if (startDayjsObject != null && endDayjsObject != null) {
    let currentDate = startDayjsObject;
    while (currentDate.isBetween(startDayjsObject, endDayjsObject, interval, inclusivity)) {
      dates.push(currentDate.format(format));
      currentDate = currentDate.add(1, interval);
    }
  }
  return dates;
}

export default dayjs;
<template id="here-map">
  <div class="full-container">
    <ion-searchbar v-if="enableSearch" :placeholder="i18n.$t('map.search')"></ion-searchbar> <!-- TODO Implement search. Maybe with suggestions -->
    <ion-card>
      <ion-card-content>
        <div id="map-container" ref="mapContainer"></div> <!-- TODO restrict api key to domain -->
      </ion-card-content>
    </ion-card>
  </div>
</template>



<script>
import { IonSearchbar, IonCard, IonCardContent } from '@ionic/vue';
import { defineComponent, onMounted, ref, watch, computed } from 'vue';
import { useStore } from 'vuex';

import { useI18n } from "@/utils/i18n";

import H from '@here/maps-api-for-javascript';
import '@here/maps-api-for-javascript/bin/mapsjs-ui.css'

export default defineComponent({
  name: 'HereMap',
  components: { IonSearchbar, IonCard, IonCardContent },
  props: {
    enableSearch: Boolean,
    icons: Object,
    iconSize: Object
  },
  emits: [
    'selected-marker-change'
  ],
  setup(props, context) {
    const i18n = useI18n();

    const store = useStore();

    const platform = new H.service.Platform({
      'apikey': process.env.VUE_APP_HERE_API_KEY //TODO Lock API key to specific domain?
    });

    const defaultLayers = ref(platform.createDefaultLayers({lg: i18n.$getCurrentProperties().full_locale})); //Create initial layers with current selected language

    const mapContainer = ref(null);

    const map = ref(null);

    const ui = ref(null);

    const mapEvents = ref(null);

    const behavior = ref(null);

    //const mapMarkers = ref([]);

    const selectedMapMarker = ref(null);

    //Map Icon Instances of the given icon files
    const mapIcons = ref(null);

    const mapBaseLayer = computed(() => {
      if (store.getters.isDarkModeActive){
        return defaultLayers.value.raster.normal.mapnight; //TODO Geht das auch mit Vector Maps?
      } else {
        return defaultLayers.value.vector.normal.map;
      }
    });

    watch(mapBaseLayer, (newBaseLayer) => map.value.setBaseLayer(newBaseLayer));

    const mapContainerResizeObserver = new ResizeObserver(entries => {
      entries.forEach(() => {
        map.value.getViewPort().resize();
      });
    });

    //Set map locale when app locale changes
    watch(
      i18n.locale,
      (newLocale) => {
        let language = i18n.$getCurrentProperties().here_map;

        defaultLayers.value = platform.createDefaultLayers({
          lg: newLocale
        });

        map.value.setBaseLayer(mapBaseLayer.value);

        ui.value = H.ui.UI.createDefault(map.value, defaultLayers.value, language);
      }
    );

    const markerClickHandler = function(event){
      if (selectedMapMarker.value !== null){
        selectedMapMarker.value.setIcon(mapIcons.value[selectedMapMarker.value.getData().iconKey].icon); //Deselect the current selected icon
      }
      event.target.setIcon(mapIcons.value[event.target.getData().iconKey].highlightedIcon);
      selectedMapMarker.value = event.target;
      context.emit('selected-marker-change', selectedMapMarker.value.getData());
    }

    const placeMarkers = function(markerList){      
      for (let markerData of markerList){
        /*{ 
          id: markerDescription.id,
          description: markerDescription.description,
          coords: markerDescription.coords,
          icon: new H.map.Icon(markerDescription.icon, {size: markerDescription.size}),
          highlightedIcon: new H.map.Icon(markerDescription.icon, {size: {w: markerDescription.size.w * 1.25, h: markerDescription.size.h * 1.25}})
        }*/
        let marker = new H.map.Marker(markerData.coords, {icon: mapIcons.value[markerData.iconKey].icon});
        marker.setData(markerData); //save as custom data to access later in click handler

        marker.addEventListener('tap', markerClickHandler); //FIXME Klappt nicht richtig und deselected auch nicht! Sollte auch besser sichtbar sein!
        map.value.addObject(marker);
      }
    }

    /*watch(
      availableLocations,
      (newLocations) => {
        mapInstance.value.placeMarkers(newLocations);
      },
      {deep: true}
    );*/

    onMounted(() => {
      //FIXME Map is white while loading, not optimal for dark mode!
      map.value = new H.Map(
        mapContainer.value,
        mapBaseLayer.value,
        {
          zoom: 10,
          center: { lat: 48.14, lng: 11.51 } /* TODO Set to current location if permission is given */
      });

      ui.value = H.ui.UI.createDefault(map.value, defaultLayers.value, i18n.$getCurrentProperties().here_map); //TODO Enable UI in dark mode aswell

      mapEvents.value = new H.mapevents.MapEvents(map.value);

      behavior.value = new H.mapevents.Behavior(mapEvents.value);

      mapIcons.value = {}
      for(let [key, icon] of Object.entries(props.icons)){
        mapIcons.value[key] = {
          icon: new H.map.Icon(icon, {size: props.iconSize}),
          highlightedIcon: new H.map.Icon(icon, {size: {w: props.iconSize.w * 1.25, h: props.iconSize.h * 1.25}})
        }
      }

      console.log(mapIcons);

      //Observe size of the container to set the new size, when the container size changes (HereMaps uses canvas)
      mapContainerResizeObserver.observe(mapContainer.value);
    });

    return { mapContainer, i18n, placeMarkers };
  }
});
</script>

<style scoped>
.full-container {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}

ion-card, ion-card-content {
  height: 100%;
  padding: 0px;
}

ion-card {
  margin-top: 8px;
  margin-bottom: 8px;
}

#map-container{
  height: 100%;
  width: 100%;
}
</style>


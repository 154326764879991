<template id="media-preview">
  <div class="outer-container">
    <div class="media-thumbnail-container" v-if="thumbnailUrl != null">
      <AuthenticatedMedia cssClass="media-thumbnail" :mediaUrl="thumbnailUrl" type="image"></AuthenticatedMedia>
      <ion-icon class="play-overlay" draggable="false" v-if="type != null && type.includes('video')" :icon="play" color="primary"></ion-icon>
    </div>
    <div class="media-thumbnail-container" v-else-if="type != null && type.includes('image')">
      <AuthenticatedMedia cssClass="media-thumbnail" :mediaUrl="mediaUrl" type="image"></AuthenticatedMedia>
      <AuthenticatedMedia v-if="imposedImageUrl != null" cssClass="imposed-image-thumbnail" :style="imposedStyles" :mediaUrl="imposedImageUrl" type="image"></AuthenticatedMedia>
    </div>
    <div class="media-thumbnail video-placeholder" v-else-if="type != null && type.includes('video')">
      <ion-icon draggable="false" :icon="film" color="primary"></ion-icon>
    </div>
  </div>
</template>

<script>
import { IonIcon } from '@ionic/vue';
import { defineComponent, computed } from 'vue';

import AuthenticatedMedia from '@/components/AuthenticatedMedia.vue';

import { film, play } from 'ionicons/icons';

export default defineComponent({
  name: 'MediaPreview',
  components: { IonIcon, AuthenticatedMedia },
  props: {
    'type': String,
    'mediaUrl': String,
    'thumbnailUrl': String,
    'imposedImageUrl': String,
    'imposedOpacity': {
      type: Number,
      default: 0.6
    },
    'imposedParameters': Object
  },
  setup(props) {
    const imposedStyles = computed(() => {
      let style = `opacity: ${props.imposedOpacity};`
      if (props.imposedParameters != null) {
        if (props.imposedParameters.origin != null) style += `object-position: ${props.imposedParameters.origin};`
      }
      return style;
    });

    return { imposedStyles, film, play }
  },
})
</script>

<style scoped>
.video-placeholder { /* TODO Check height again when viewing reports! */
  position: relative;
  min-width: 100px;
  max-width: 100%;
  min-height: 100%;
  height: 100%;
}

.media-thumbnail, :deep(.media-thumbnail) {
  cursor: pointer;
  position: relative;
  height: auto;
  width: auto;
  border: var(--outer-border-width, 2px) solid var(--border-color, transparent);
  border-radius: var(--border-radius, 6px);
  /* max-height has to be explicit, because height is never set explicitly on container! */
  max-height: var(--height, 4em);
  max-width: 100%;
}

:deep(.imposed-image-thumbnail) {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%!important;
  height: 100%!important;
  border: var(--outer-border-width, 2px) solid transparent;
  border-radius: var(--border-radius, 6px);
  object-fit: contain;
  object-position: center;
}

.outer-container {
  position: relative;
  display: block;
  height: 100%;
  max-height: 100%;
  line-height: 0px; /* Line-height fixes alignment issue on scaled down images, because inline elements rely on baseline for sizing, and if image is smaller than line-height it is not aligned anymore */
  height: var(--height, 4em);
}

.media-thumbnail-container {
  cursor: pointer;
  position: relative;
  display: block;
  max-height: 100%;
  height: auto;
}

.play-overlay, .video-placeholder ion-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 2em;
  max-width: 2em;
  height: calc(75% - (var(--outer-border-width, 2px) * 2));
  width: calc(75% - (var(--outer-border-width, 2px) * 2));
  opacity: 0.9;
}

.video-placeholder ion-icon {
  opacity: 1!important;
  min-height: 90%;
  min-width: 90%;
}
</style>
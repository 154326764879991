<template>
  <ion-button :class="['scan-codes-button', (text != null) ? 'with-text' : undefined]" :color="color" :shape="shape" :fill="fill" :expand="expand" @click="startCodeScan()">
    <ion-icon class="scan-codes-icon" :icon="scanCodesIcon" ></ion-icon>
    <template v-if="text != null">{{ text }}</template>
  </ion-button>
</template>

<script>
import { IonButton, IonIcon } from '@ionic/vue';
import { defineComponent } from 'vue';

import scanCodesIcon from '@/assets/icons/scan_codes.svg';

import { useQR } from '@/components/composables/QR';

export default defineComponent({
  components: { IonButton, IonIcon },
  props: {
    'removeOnNextClick': {
      type: Boolean,
      default: false
    },
    'color': String,
    'shape': String,
    'fill': String,
    'expand': String,
    'text': [String, Number],
    'onlyAllowInternalCodes': {
      type: Boolean,
      default: false
    },
    'onlyAllowInternalType': String,
  },
  emits: ['codeScanned'],
  setup(props, { emit }) {
    const { scanCode } = useQR();

    const startCodeScan = function () {
      if (props.removeOnNextClick) {
        emit('codeScanned', null);
        return;
      }

      scanCode(props.onlyAllowInternalType, props.onlyAllowInternalCodes).then((code) => {
        if (code != null) emit('codeScanned', code);
      }).catch(() => null);
    }

    return { startCodeScan, scanCodesIcon }
  }
});
</script>

<style>
.scan-codes-button {
  text-transform: none;
  font-size: 1em;
  height: calc(var(--icon-font-size, 2em) + var(--icon-padding, 0.5em))!important;
  --padding-start: var(--button-padding-inline, 5px)!important;
  --padding-end: var(--button-padding-inline, 5px)!important;
}

.scan-codes-button > .scan-codes-icon {
  font-size: var(--icon-font-size, 2em);
}

.scan-codes-button.with-text > .scan-codes-icon {
  margin-inline-end: 5px;
}
</style>
